export const societyship = [
        {
                MsgNum: "1",
                MsgForwardTimes: "0",
                MMSICode: "413843904",
                NavigationStatus: "未定义",
                SteeringRate: "无法获得",
                GroundVelocity: "1",
                ShipPositioningAccuracy: "低",
                longitude: "114°17.41′",
                latitude: "30°34.29′",
                GroundHeading: "0.0°",
                TrueHeading: "12°",
                TimeStamp: "5",
                PreserveData1: "0000",
                PreserveData2: "0",
                IsRAIMUsed: "未使用",
                HowToObtainUTC: "其他方式获取UTC",
                TimeSlotTimeoutValue: "0",
                TransmitMsgTimeSlotNum: "0"
        },
        {
                MsgNum: "2",
                MsgForwardTimes: "5",
                MMSICode: "413843905",
                NavigationStatus: "未定义",
                SteeringRate: "无法获得",
                GroundVelocity: "1",
                ShipPositioningAccuracy: "低",
                longitude: "114°17.40′",
                latitude: "30°34.30′",
                GroundHeading: "0.0°",
                TrueHeading: "12°",
                TimeStamp: "5",
                PreserveData1: "0000",
                PreserveData2: "0",
                IsRAIMUsed: "未使用",
                HowToObtainUTC: "其他方式获取UTC",
                TimeSlotTimeoutValue: "0",
                TransmitMsgTimeSlotNum: "0"
        }
];