import './style.css';
import mapOptions from './options.js';
import { getAllHb, getAllPort, getAllMd, getAllSocialShip } from './api.js';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { transform, transformExtent } from "ol/proj.js";
import TileGrid from "ol/tilegrid/TileGrid";
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { bbox } from 'ol/loadingstrategy';
import { Point, LineString } from "ol/geom";
import { Feature } from 'ol';
import { Icon, Stroke, Style, Text, Fill } from "ol/style";
import { defaults as defaultControls } from "ol/control"; //默认缩放
import { defaults as defaultInteractions } from "ol/interaction";
import { ScaleLine } from "ol/control"; //全屏，比例尺控件
import WKT from "ol/format/WKT";
import store from '../store'
import { convertGPSToXY } from "@/utils/helpers/map";
const sucessCode = process.env.VUE_APP_SUCCESS;
const shipTypeImgList = [
  require("@/assets/icons/ship/01.png"),
  require("@/assets/icons/ship/02.png"),
  require("@/assets/icons/ship/03.png"),
  require("@/assets/icons/ship/04.png"),
  require("@/assets/icons/ship/05.png"),
  require("@/assets/icons/ship/06.png"),
  require("@/assets/icons/ship/07.png"),
  require("@/assets/icons/ship/08.png"),
  require("@/assets/icons/ship/09.png"),
];
export function initMap(target) {
  document.getElementById(target).innerHTML = "";
  let layers = [];
  for (let layerName in mapOptions.layersOptions) {
    let _layer = createLayers(layerName);
    layers.push(_layer);
  }
  return new Map({
    target: target,
    controls: defaultControls({
      zoom: false,
    }).extend([
      // new FullScreen(),
      new ScaleLine({
        units: "metric",
      }),
    ]),
    loadTilesWhileAnimating: true,
    layers: layers,
    view: new View({
      projection: 'EPSG:3857',
      center: transform(store.state.center, 'EPSG:4326', 'EPSG:3857'),
      //extent: transformExtent(mapOptions.tianditu.extent, "EPSG:4326", "EPSG:3857"),
      zoom: mapOptions.tianditu.zoom,
      minZoom: mapOptions.tianditu.minZoom,
      maxZoom: mapOptions.tianditu.maxZoom,
      //.设置缩放级别为整数
      constrainResolution: true,
      //.关闭无级缩放地图
      smoothResolutionConstraint: false,
      smoothExtentConstraint: false,
    }),
    interactions: defaultInteractions({
      pinchRotate: false, // 移动端禁止地图旋转
      shiftDragZoom: false,
      // pinchZoom: false,
      zoomDelta: 1, // 使用键盘或双击缩放时的缩放级别增量
    }),
  });
}

export function createLayers(name) {
  let layersOption = mapOptions.layersOptions[name];
  let { type, title, zIndex, visible, zoomData, urls, url } = layersOption;
  let _layer = null;
  if (type == "base") {
    let baseUrls = [];
    for (let url of urls) {
      baseUrls.push(url + mapOptions.tianditu.tk);
    }
    _layer = new TileLayer({
      title: title,
      name: name,
      source: new XYZ({
        projection: 'EPSG:3857',
        urls: baseUrls,
      }),
      zIndex: zIndex,
      visible: visible,
    });
  } else if (type == "cjienc") {
    let tileGrid = new TileGrid({
      tileSize: mapOptions.tianditu.tileSize,
      origin: mapOptions.tianditu.origin,
      extent: mapOptions.tianditu.extent,
      resolutions: mapOptions.tianditu.resolutions,
    });
    _layer = new TileLayer({
      title: title,
      name: name,
      source: new XYZ({
        tileGrid: tileGrid,
        projection: mapOptions.tianditu.projection,
        url: url,
      }),
      zIndex: zIndex,
      visible: visible,
    });
  } else if (type == "vector") {
    _layer = new VectorLayer({
      title: title,
      name: name,
      source: getVectorSource(name),
      style: (feature, resolution) => {
        return getStyle(name, feature, resolution);
      },
      zIndex: zIndex,
      visible: visible,
      minZoom: zoomData.minZoom,
      maxZoom: zoomData.maxZoom,
    });
  }
  return _layer;
}

export function createVectorLayer(layerName, featureInfoList) {
  let _layer = null;
  switch (layerName) {
    case 'myShip': {
      _layer = new VectorLayer({
        title: '我的船舶',
        name: layerName,
        source: getVectorSource('myShip', featureInfoList),
        style: (feature, resolution) => {
          return getStyle('myShip', feature, resolution);
        },
        zIndex: 12,
        minZoom: 5,
        maxZoom: 22,
      });
    }
      break;
    case 'shipFocus': {
      _layer = new VectorLayer({
        title: '船舶导航框',
        name: layerName,
        source: getVectorSource('shipFocus', featureInfoList),
        style: (feature, resolution) => {
          return getStyle('shipFocus', feature, resolution);
        },
        zIndex: 11,
      });
    }
      break;
    case 'selectShip': {
      _layer = new VectorLayer({
        title: '船舶选择框',
        name: layerName,
        source: getVectorSource('selectShip', featureInfoList),
        style: (feature, resolution) => {
          return getStyle('selectShip', feature, resolution);
        },
        zIndex: 13,
        minZoom: 13,
        maxZoom: 22,
      });
    }
      break;
    case 'mob': {
      _layer = new VectorLayer({
        title: 'mob定位',
        name: layerName,
        source: getVectorSource('mob', featureInfoList),
        style: (feature, resolution) => {
          return getStyle('mob', feature, resolution);
        },
        zIndex: 13,
        minZoom: 13,
        maxZoom: 22,
      });
    }
      break;
    case 'shipRouteLine': {
      _layer = new VectorLayer({
        title: '航线',
        name: layerName,
        source: getVectorSource('shipRouteLine', featureInfoList),
        style: (feature, resolution) => {
          return getStyle('shipRouteLine', feature, resolution);
        },
        zIndex: 11,
        minZoom: 5,
        maxZoom: 22,
      });
    }
      break;
    case 'shipRoutePoint': {
      _layer = new VectorLayer({
        title: '航线起点终点',
        name: layerName,
        source: getVectorSource('shipRoutePoint', featureInfoList),
        style: (feature, resolution) => {
          return getStyle('shipRoutePoint', feature, resolution);
        },
        zIndex: 11,
        minZoom: 5,
        maxZoom: 22,
      });
    }
      break;
  }
  return _layer;
}

export function getVectorSource(layerName, featureInfoList) {
  let vectorSource = null;
  switch (layerName) {
    case 'hb':
      vectorSource = new VectorSource({
        format: new GeoJSON(),
        loader: async function (extent, resolution, projection, success, failure) {
          let extent4326 = transformExtent(extent, "EPSG:3857", "EPSG:4326");
          let params = {
            minLon: String(extent4326[0]),
            minLat: String(extent4326[1]),
            maxLon: String(extent4326[2]),
            maxLat: String(extent4326[3]),
          };
          const result = await getAllHb(params);
          const onError = function () {
            vectorSource.removeLoadedExtent(extent);
            failure();
          }
          if (result.code == sucessCode) {
            let featureInfoList = result.data;
            let features = [];
            featureInfoList.forEach((featureInfo) => {
              let attributes = transform_data(featureInfo);
              attributes = { ...attributes, layerName };
              let feature = new Feature({
                geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
                  "EPSG:4326",
                  "EPSG:3857"
                ),
                attributes,
              });
              features.push(feature);
            });
            vectorSource.addFeatures(features);
            success(features);
          } else {
            onError();
          }
        },
        strategy: bbox,
      });
      break;
    case 'port':
      vectorSource = new VectorSource({
        format: new GeoJSON(),
        loader: async function (extent, resolution, projection, success, failure) {
          const result = await getAllPort();
          const onError = function () {
            failure();
          }
          if (result.code == sucessCode) {
            let featureInfoList = result.data;
            let features = [];
            featureInfoList.forEach((featureInfo) => {
              let attributes = transform_data(featureInfo);
              attributes = { ...attributes, layerName };
              let feature = new Feature({
                geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
                  "EPSG:4326",
                  "EPSG:3857"
                ),
                attributes,
              });
              features.push(feature);
            });
            vectorSource.addFeatures(features);
            success(features);
          } else {
            onError();
          }
        },
      });
      break;
    case 'md':
      vectorSource = new VectorSource({
        format: new GeoJSON(),
        loader: async function (extent, resolution, projection, success, failure) {
          const result = await getAllMd();
          const onError = function () {
            failure();
          }
          if (result.code == sucessCode) {
            let featureInfoList = result.data;
            let features = [];
            const wkt = new WKT();
            featureInfoList.forEach((featureInfo) => {
              featureInfo.coordinates=featureInfo.x84.split('#');
              if (featureInfo.coordinates.length > 0) {
                let attributes = transform_data(featureInfo);
                attributes = { ...attributes, layerName };
                let feature = new Feature({
                  geometry: wkt.readGeometry(attributes.polygon, {
                    dataProjection: "EPSG:4326",
                    featureProjection: "EPSG:3857",
                  }),
                  attributes,
                });
                features.push(feature);
              }
            });
            vectorSource.addFeatures(features);
            success(features);
          } else {
            onError();
          }
        },
      });
      break;
    case 'ship':
      vectorSource = new VectorSource({
        format: new GeoJSON(),
        loader: async function (extent, resolution, projection, success, failure) {
          let extent4326 = transformExtent(extent, "EPSG:3857", "EPSG:4326");
          let params = {
            minLon: String(extent4326[0]),
            minLat: String(extent4326[1]),
            maxLon: String(extent4326[2]),
            maxLat: String(extent4326[3]),
          };
          const result = await getAllSocialShip(params);
          const onError = function () {
            vectorSource.removeLoadedExtent(extent);
            failure();
          }
          if (result.code == sucessCode) {
            let featureInfoList = result.data;
            let features = [];
            featureInfoList.forEach((featureInfo) => {
              let attributes = transform_data(featureInfo);
              attributes = { ...attributes, layerName };
              let feature = new Feature({
                geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
                  "EPSG:4326",
                  "EPSG:3857"
                ),
                attributes,
              });
              features.push(feature);
            });
            vectorSource.addFeatures(features);
            success(features);
          } else {
            onError();
          }
        },
        strategy: bbox,
      });
      break;
    case 'myShip': {
      let features = [];
      featureInfoList.forEach((featureInfo) => {
        let attributes = transform_data(featureInfo);
        attributes = { ...attributes, layerName };
        let feature = new Feature({
          geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
            "EPSG:4326",
            "EPSG:3857"
          ),
          attributes,
        });
        features.push(feature);
      });
      vectorSource = new VectorSource({
        features: features
      });
      break;
    }
    case 'shipFocus': {
      let features = [];
      featureInfoList.forEach((featureInfo) => {
        let attributes = transform_data(featureInfo);
        attributes = { ...attributes, layerName };
        let feature = new Feature({
          geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
            "EPSG:4326",
            "EPSG:3857"
          ),
          attributes,
        });
        features.push(feature);
      });
      vectorSource = new VectorSource({
        features: features
      });
      break;
    }
    case 'selectShip': {
      let features = [];
      featureInfoList.forEach((featureInfo) => {
        let attributes = transform_data(featureInfo);
        attributes = { ...attributes, layerName };
        let feature = new Feature({
          geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
            "EPSG:4326",
            "EPSG:3857"
          ),
          attributes,
        });
        features.push(feature);
      });
      vectorSource = new VectorSource({
        features: features
      });
      break;
    }
    case 'mob': {
      let features = [];
      featureInfoList.forEach((featureInfo) => {
        let attributes = transform_data(featureInfo);
        attributes = { ...attributes, layerName };
        let feature = new Feature({
          geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
            "EPSG:4326",
            "EPSG:3857"
          ),
          attributes,
        });
        features.push(feature);
      });
      vectorSource = new VectorSource({
        features: features
      });
      break;
    }
    case 'shipRouteLine': {
      let features = [];
      let lineCoordinates = featureInfoList.map(featureInfo => {
        let attributes = transform_data(featureInfo);
        let coordinate = [attributes.longitude, attributes.latitude];
        return coordinate;
      })
      let lineFeature = new Feature({ geometry: new LineString(lineCoordinates).transform("EPSG:4326", "EPSG:3857") });
      features.push(lineFeature);
      vectorSource = new VectorSource({
        features: features
      });
      break;
    }
    case 'shipRoutePoint': {
      let features = [];
      featureInfoList.map((featureInfo, index) => {
        let attributes = transform_data(featureInfo);
        if (index == featureInfoList.length - 1) {
          let feature = new Feature({
            geometry: new Point([attributes.longitude, attributes.latitude], "XY").transform(
              "EPSG:4326",
              "EPSG:3857"
            ),
            attributes,
          });
          features.push(feature);
        }
      })
      vectorSource = new VectorSource({
        features: features
      });
      break;
    }
  }
  return vectorSource;
}

function getStyle(layerName, feature, resolution) {
  let styles = [];
  let attributes = feature.get("attributes");
  switch (layerName) {
    case 'port': {
      let name = attributes.mtmc;
      let style = new Style({
        // image: new Circle({
        //   radius: 6,
        //   fill: new Fill({
        //     color: '#ff0000'
        //   }),
        //   stroke: new Stroke({
        //     color: '#ff0000',
        //     width: 2
        //   })
        // }),
        image: new Icon({
          src: require(`@/assets/icons/others/mt.png`),
          scale: 0.1,
        }),
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3,
          }),
          text: '',
          offsetX: 0,
          offsetY: 22
        })
      });
      style.getText().setText(resolution < 13 ? name : '');
      styles.push(style);
    }
      break;
    case 'hb': {
      let hbtbId = attributes.hbtbId?attributes.hbtbId:'TAXZYTHAB';
      let name = attributes.hbmc;
      let style = new Style({
        image: new Icon({
          src: require(`@/assets/icons/hb/${hbtbId}.png`),
        }),
        text: new Text({
          font: "500  13px Microsoft YaHei",
          text: '',
          offsetX: 0,
          offsetY: 22,
          stroke: new Stroke({
            color: "#f0f0f0",
            width: 2,
          }),
        }),
      });
      style.getText().setText(resolution < 13 ? name : '');
      styles.push(style);
    }
      break;
    case 'md': {
      let name = attributes.mdmc;
      let style = new Style({
        stroke: new Stroke({
          width: 3,
          color: "#a86b06",
        }),
        fill: new Fill({
          color: "rgba(168,107,6,0.33)",
        }),
        // 移动图标样式 name
        text: new Text({
          font: "500  20px Microsoft YaHei",
          text: "",
          offsetX: -2,
          offsetY: 0,
          backgroundFill: new Fill({
            color: "#FFAA25",
          }),
          fill: new Fill({
            color: "#fff",
          }),
          backgroundStroke: new Stroke({
            color: "#FFAA25",
            width: 20,
          }),
          padding: [4, 4, 9, 9],
        }),
      });
      style.getText().setText(name);
      styles.push(style);
    }
      break;
    case 'ship': {
      // 社会船舶图层
      let attributes = feature.get("attributes");
      let imgType = filterShipType(attributes.shipType);
      let course = attributes.course;
      let speed = attributes.speed;
      if (speed > 0) {
        let style = new Style({
          image: new Icon({
            src: imgType,
            scale: 0.6,
          }),
        });
        let _course = isNaN((course / 180) * Math.PI) ? 0 : (course / 180) * Math.PI;
        style.getImage().setRotation(_course);
        styles.push(style);
      }
    }
      break;
    case 'myShip': {
      //我的船舶
      let course = feature.get("attributes").course;
      let name = feature.get("attributes").name;

      //console.log(store.state.mapLv)

      let style = new Style({
        image: new Icon({
          src: require("@/assets/icons/others/myship.gif"),
          scale: 0.9,
        }),
        text: new Text({
          font: "500  13px Microsoft YaHei",
          text: '',
          offsetX: 0,
          offsetY: 46,
          stroke: new Stroke({
            color: "#f0f0f0",
            width: 2,
          }),
        }),
      });
      let _course = isNaN((course / 180) * Math.PI) ? 0 : (course / 180) * Math.PI;
      style.getImage().setRotation(_course);
      style.getText().setText(name);
      styles.push(style);
    }
      break;
    case 'shipFocus': {
      //导航框图层
      let style = new Style({
        image: new Icon({
          src: require("@/assets/icons/others/shipfocus.png"),
          scale: 0.7,
        }),
      });
      styles.push(style);
    }
      break;
    case 'selectShip': {
      //选中框图层
      let course = feature.get("attributes").course;
      let style = new Style({
        image: new Icon({
          src: require("@/assets/icons/others/current.png"),
          scale: 1.5,
        }),
      });
      let _course = isNaN((course / 180) * Math.PI) ? 0 : (course / 180) * Math.PI;
      style.getImage().setRotation(_course);
      styles.push(style);
    }
      break;
    case 'mob': {
      //mob定位
      let course = feature.get("attributes").course;
      let style = new Style({
        image: new Icon({
          src: require("@/assets/icons/others/mob.png"),
          scale: 0.7,
        }),
      });
      let _course = isNaN((course / 180) * Math.PI) ? 0 : (course / 180) * Math.PI;
      style.getImage().setRotation(_course);
      styles.push(style);
    }
      break;
    case 'shipRouteLine': {
      //船舶航线
      let style1 = new Style({
        stroke: new Stroke({
          color: '#C2D5FF',
          width: 20,
          lineCap: 'square',
        })
      });
      let style2 = new Style({
        stroke: new Stroke({
          color: '#4D85FF',
          width: 10,
        })
      });
      styles = [style1, style2];
      let geometry = feature.getGeometry();
      let length = geometry.getLength();//获取线段长度
      let radio = (50 * resolution) / length;
      let dradio = 1;//投影坐标系，如3857等，在EPSG:4326下可以设置dradio=10000
      for (let i = 0; i <= 1; i += radio) {
        let arrowLocation = geometry.getCoordinateAt(i);
        geometry.forEachSegment(function (start, end) {
          if (start[0] == end[0] || start[1] == end[1]) return;
          let dx1 = end[0] - arrowLocation[0];
          let dy1 = end[1] - arrowLocation[1];
          let dx2 = arrowLocation[0] - start[0];
          let dy2 = arrowLocation[1] - start[1];
          if (dx1 != dx2 && dy1 != dy2) {
            if (Math.abs(dradio * dx1 * dy2 - dradio * dx2 * dy1) < 0.001) {
              let dx = end[0] - start[0];
              let dy = end[1] - start[1];
              let rotation = Math.atan2(dy, dx);
              styles.push(new Style({
                geometry: new Point(arrowLocation),
                image: new Icon({
                  src: require('@/assets/icons/others/routearrow.png'),
                  anchor: [0.5, 0.5],
                  rotateWithView: false,
                  rotation: -rotation + Math.PI / 2,
                  scale: 0.5,
                })
              }));

            }
          }
        })
      }
    }
      break;
    case 'shipRoutePoint': {
      //船舶航线起点终点
      // let name = attributes.name;
      let name = attributes.id;
      let style = new Style({
        image: new Icon({
          src: require(`@/assets/icons/others/${name}.png`),
          scale: 0.5,
        }),
      });
      styles.push(style);
    }
      break;
  }
  return styles;
}

export function transform_data(data) {
  let longitude = '', latitude = '';
  if (data.longitude) {
    longitude = data.longitude;
  } else if (data.jdzbjd) {
    longitude = data.jdzbjd;
  } else if (data.zbjd) {
    longitude = data.zbjd;
  } else if (data.lng) {
    longitude = data.lng;
  }

  if (data.latitude) {
    latitude = data.latitude;
  } else if (data.jdzbwd) {
    latitude = data.jdzbwd;
  } else if (data.zbwd) {
    latitude = data.zbwd;
  } else if (data.lat) {
    latitude = data.lat;
  }
  if(typeof longitude=='string'&&longitude!=''){
    longitude = parseFloat(longitude);
  }
  if(typeof latitude=='string'&&latitude!=''){
    latitude = parseFloat(latitude);
  }
  if (typeof data.x84=='string'&&data.x84.indexOf(',')>-1) {
    let xy = data.x84.split(',');
    let regLon=/^((\d|[1-9]\d|1[0-7]\d)[°](\d|[0-5]\d)[′](\d|[0-5]\d)(\.\d{1,2})?[″]?$)|(180[°]0[′]0[″]?$)/;
    let regLat=/^((\d|[1-8]\d)[°](\d|[0-5]\d)[′](\d|[0-5]\d)(\.\d{1,2})?[″]?$)|(90[°]0[′]0[″]?$)/;
    if(regLon.test(xy[0])){
      xy[0]=convertGPSToXY(xy[0])
    }
    if(regLat.test(xy[1])){
      xy[1]=convertGPSToXY(xy[1])
    }
    longitude = parseFloat(xy[0]);
    latitude = parseFloat(xy[1]);
  }
  if (data.coordinates && data.coordinates.length > 0) {
    let arr = [];
    data.coordinates.forEach((item) => {
      if (typeof item=='string'&&item.indexOf(',')>-1) {
        if(item!=','){
          let xy = item.split(',');
          arr.push(xy[0] + " " + xy[1]);
        }
      }else{
        arr.push(item.lon + " " + item.lat);
      }
    });
    let polygon = "POLYGON((" + arr.join(",") + "))";
    data.polygon = polygon;
    const wkt = new WKT();
    let geometry = wkt.readGeometry(polygon);
    let res = geometry.getFlatInteriorPoint();
    longitude = res[0];
    latitude = res[1];
  }
  data.longitude = longitude;
  data.latitude = latitude;
  return data;
}

export function getLayer(layers, layerName) {
  let layerIndex = -1;
  layers.forEach(function (item, index) {
    if (item.values_.name == layerName) {
      layerIndex = index;
    }
  });
  return layerIndex;
}

function filterShipType(data) {
  if(data==undefined)return shipTypeImgList[0];
  let img;
  if (data.indexOf("8") == 0) {
    img = shipTypeImgList[0];
  } else if (data.indexOf("6") == 0) {
    img = shipTypeImgList[1];
  } else if (data.indexOf("7") == 0) {
    img = shipTypeImgList[2];
  } else if (data.indexOf("5") == 0) {
    img = shipTypeImgList[3];
  } else if (data.indexOf("4") == 0) {
    img = shipTypeImgList[4];
  } else if (data.indexOf("2") == 0) {
    img = shipTypeImgList[5];
  } else {
    img = shipTypeImgList[6];
  }
  return img;
}

