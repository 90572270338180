import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from "vue-cookies";
import { Toast } from 'vant';
import 'vant/es/toast/style';
import { Dialog } from 'vant';
import 'vant/es/dialog/style'
import './assets/icons';
import './assets/js/rem';
import 'normalize.css/normalize.css'
import 'default-passive-events'
import "@/assets/css/style.sass";
import { Lazyload } from 'vant';

if (process.env.VUE_APP_MOCK == 'true') {
    require('./utils/mock/index');
}
createApp(App)
    .use(store)
    .use(router)
    .use(VueCookies)
    .use(Toast)
    .use(Dialog)
    .use(Lazyload)
    .mount('#app')
