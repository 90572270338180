const mapOptions= {
    tianditu:{
      tk:"6cb00678519a9a32a7df6dff1d31c898",
      projection:'EPSG:4326',
      center:[121.144590,31.705300],
      zoom: 14,
      tileSize:256,
      origin: [-400,400],
      // extent: [103.87588002846373, 19.45228966609097, 121.40686460052386,41.24664597260153,],
      resolutions: [
        0.023794610058302804, 0.00951784402332112, 0.00475892201166056,
        0.00237946100583028, 0.00118973050291514, 5.9486525145757e-4,
        2.97432625728785e-4, 1.487163128643925e-4, 7.435815643219625e-5,
        3.7179078216098126e-5, 1.859072883855198e-5, 9.294174688773075e-6,
        4.647087344386537e-6, 2.37946100583028e-6,
      ],
      minZoom: 8,
      maxZoom: 17,
    },
    layersOptions:{
      vec:{
        title:'天地图矢量图',
        type:'base',
        urls:[
        "http://t0.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t1.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t2.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t3.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t4.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t5.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t6.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
        "http://t7.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=",
      ],
        zIndex: 1,
        visible: false,
        isMenu: true,
      },
      cva:{
        title:'天地图矢量注记图层（中文）',
        type:'base',
        urls:[
          "http://t0.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t1.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t2.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t3.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t4.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t5.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t6.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
          "http://t7.tianditu.gov.cn/DataServer?T=cva_w&x={x}&y={y}&l={z}&tk=",
        ],
        zIndex: 2,
        visible: false,
        isMenu: true,
      },
      img:{
        title:'天地图卫星影像',
        type:'base',
        urls:[
          "http://t0.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t1.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t2.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t3.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t4.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t5.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t6.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
          "http://t7.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=",
        ],
        zIndex: 3,
        visible: false,
        isMenu: false,
      },
      cia:{
        title:'天地图卫星影像注记图层（中文）',
        type:'base',
        urls:[
          "http://t0.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t1.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t2.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t3.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t4.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t5.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t6.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
          "http://t7.tianditu.gov.cn/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=",
        ],
        zIndex: 4,
        visible: false,
        isMenu: false,
      },
      cjinobeacon:{
        title:'航道图',
        type:'cjienc',
        url:"https://api.cjienc.cn/dtfw/arcgis/rest/services/cjinobeacon2/MapServer/tile/{z}/{y}/{x}", 
        zIndex: 5,
        visible: true,
        isMenu:false,
      },
      frames:{
        title:'线层图',
        type:'cjienc',
        url:"https://api.cjienc.cn/dtfw/arcgis/rest/services/frames/MapServer/tile/{z}/{y}/{x}", 
        zIndex: 6,
        visible: false,
        isMenu:false,
      },
      soundg:{
        title:'水深图',
        type:'cjienc',
        url:"https://api.cjienc.cn/dtfw/arcgis/rest/services/soundg/MapServer/tile/{z}/{y}/{x}", 
        zIndex: 7,
        visible: false,
        isMenu:true,
      },
      port:{
        title:'港口',
        type:'vector',
        zIndex: 8,
        visible: true,
        isMenu:false,
        zoomData:{
          minZoom:13,
          maxZoom:22,
        }
      },
      hb:{
        title:'航标',
        type:'vector',
        zIndex: 9,
        visible: true,
        isMenu:true,
        zoomData:{
          minZoom:13,
          maxZoom:22,
        }
      },
      md:{
        title:'锚地',
        type:'vector',
        zIndex: 10,
        visible: true,
        isMenu:true,
        zoomData:{
          minZoom:13,
          maxZoom:22,
        },
      },
      ship:{
        title:'船舶',
        type:'vector',
        zIndex: 11,
        visible: true,
        isMenu:true,
        zoomData:{
          minZoom:13,
          maxZoom:22,
        },
      },
    },
};
export default mapOptions;