import TopNav from "@/components/TopNav";
import { mapGetters } from 'vuex';
export default {
  name: "DesktopView",
  components: {
    TopNav
  },
  computed: { ...mapGetters(['appStatus'])
  },

  data() {
    return {
      gutter: 0,
      appList: [{
        icon: "desktop_icon06",
        title: "助航地图",
        url: "/navigationmap",
        isDownload: true
      }, {
        icon: "desktop_icon03",
        title: "AIS列表",
        url: "/naislist",
        isDownload: true
      }, {
        icon: "desktop_icon04",
        title: "信号管理",
        url: "/signal",
        isDownload: true
      }, {
        icon: "desktop_device",
        title: "设备管理",
        url: "/device",
        isDownload: true
      }, {
        icon: "desktop_icon05",
        title: "开关机记录",
        url: "/switchrecords",
        isDownload: true
      }, {
        icon: "desktop_icon02",
        title: "视频监控",
        url: "/monitor",
        isDownload: true
      }, {
        icon: "desktop_icon01",
        title: "本船参数",
        url: "/shipinfo",
        isDownload: true
      }, {
        icon: "desktop_icon11",
        title: "船员服务",
        url: "/crew",
        isDownload: true
      }, {
        icon: "desktop_icon09",
        title: "官方应用",
        url: "/officialapp",
        isDownload: false
      }, {
        icon: "desktop_icon10",
        title: "其他应用",
        url: "/othersapp",
        isDownload: false
      }, {
        icon: "desktop_iconmob",
        title: "MOB",
        url: "/mobView",
        isDownload: true
      }, {
        icon: "desktop_setting",
        title: "系统设置",
        url: "/system-setting",
        isDownload: true
      }],
      appDownList: [{
        icon: "app-icon01",
        title: "船e行",
        type: "downLoad",
        isDownload: false,
        key: 'CYX',
        flag: 1
      }, {
        icon: "app-icon03",
        title: "长江航道网",
        type: "downLoad",
        isDownload: false,
        key: 'CJHDW',
        flag: 2
      }, {
        icon: "app-icon04",
        title: "长江北斗网",
        type: "downLoad",
        isDownload: false,
        key: 'CJBDW',
        flag: 3
      }, {
        icon: "app-icon05",
        title: "船讯网",
        type: "downLoad",
        isDownload: false,
        key: 'CXW',
        flag: 4
      }, {
        icon: "desktop_icon07",
        title: "一江通",
        type: "downLoad",
        isDownload: false,
        key: 'YJTCYD',
        flag: 5
      }],
      tabsList: [// {
      //   icon: "desktop_wechart",
      //   title: "在线沟通",
      //   url: "/message",
      //   badge: 12
      // },
      {
        icon: "desktop_sos",
        title: "长按右侧SOS按钮3s后开始报警",
        url: "/sos"
      }],
      gridThemeVars: {
        "grid-item-text-font-size": "0.26rem",
        "grid-item-text-color": "#ffffff",
        "grid-item-content-background-color": "transparent",
        "grid-item-content-active-color": "transparent"
      },
      tabbarThemeVars: {
        "tabbar-item-text-color": "#fff",
        "tabbar-item-active-color": "#fff",
        "tabbar-item-active-background-color": "transparent"
      }
    };
  },

  created() {},

  mounted() {
    try {
      let resData = window.AndroidFunction.getAppInstalledState(); // alert(resData);

      let initialInstalledState = JSON.parse(resData); // let initialInstalledState={"CYX":1,"CJHDW":1,"CJBDW":1,"CXW":1,"YJTCYD":1}

      for (let i in initialInstalledState) {
        if (initialInstalledState[i] == 1) {
          for (let j in this.appDownList) {
            if (this.appDownList[j].key == i) {
              this.appDownList[j].isDownload = true;
            }
          }
        }
      }

      this.appList = this.appList.concat(this.appDownList);
    } catch (e) {
      console.log('非android环境下访问!');
    }
  },

  methods: {
    // 跳转
    toDetail(data) {
      if (data.type == "downLoad") {
        try {
          // alert(data.flag)
          window.AndroidFunction.openApp(data.flag);
        } catch (e) {
          console.log('非android环境下访问!');
        }
      } else {
        let str = new RegExp("http");

        if (str.test(data.url)) {
          window.location.href = data.url;
        } else {
          this.$router.push({
            path: data.url
          });
        }
      }
    }

  }
};