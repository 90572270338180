export const queryURLParams = function (url, parame) {
  let search = url.replace(/(.*)?\?/, '')
  let pattern = /(.*)=(.*)/ig; //定义正则表达式

  let parames = {}; // 定义参数对象

  search.replace(pattern, ($, $1, $2) => {
    parames[$1] = decodeURI($2);

  });
  if (parame) {
    return parames[parame];
  } else {
    return parames;
  }
}

export const _isMobile = function () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

//大到小排序
export const sortBy = function (arr, key) {
  return arr.sort((a, b) => {
    let x = a[key]
    let y = b[key]
    return ((x > y) ? -1 : (x < y) ? 1 : 0)
  })
}

//去除左右两边空格
export const trim = function (s) {
  return s.replace(/(^\s*)|(\s*$)/g, "");
}

export const getRandomInRange = function (from, to, fixed) {
  return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
  // .toFixed() returns string, so ' * 1' is a trick to convert to number
}

export function randomNum(minNum, maxNum) {
  let a = 0;
  switch (arguments.length) {
    case 1:
      a = parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      a = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      a = 0;
      break;
  }
  return a;
} 