// import TopNav from '@/components/TopNav'
// @ is an alias to /src
import MapUnit from "@/components/MapUnit.vue"; // import CameraPlayer from "@/components/CameraPlayer.vue";

import { dateExtent } from "@/utils/helpers/date"; // import { convertXYToGPS } from "@/utils/helpers/map";

import { getDistance, convertGPSToXY } from "@/utils/helpers/map"; // import { sortBy } from '@/utils/helpers/common';
// import indexVideo from "@/components/indexVideo.vue";
// import NoBox from "@/components/NoBoxToast";

import { mapGetters } from 'vuex';
import { selectAisShipPageVoList, NavigationRecordStart, NavigationRecordEnd } from "@/api";
import { searchPort, searchRoute } from "@/api";
const sucessCode = process.env.VUE_APP_SUCCESS;
export default {
  name: "HomeView",
  components: {
    MapUnit // TopNav,
    // indexVideo,
    // NoBox

  },

  data() {
    return {
      NoBoxShow: false,
      interval: null,
      searchCard: {
        show: true,
        isSearch: false,
        ports: [],
        loadding: false
      },
      searchCard1: {
        isSearch: false,
        ports: [],
        loadding: false
      },
      navigationAidForm: {
        port: "",
        shipTonnage: ""
      },
      keywords: "",
      port: {},
      videos: [{
        cameraId: "c0a87bac",
        cover: require(`@/assets/images/video-no.png`),
        realPlayUrl: "",
        contrl: "no"
      }, {
        cameraId: "c0a87bac",
        cover: require(`@/assets/images/video-no.png`),
        realPlayUrl: "",
        contrl: "no"
      } // {
      //   cameraId: "c0a87bac-1",
      //   cover: require(`@/assets/images/video-ship2.png`),
      //   realPlayUrl: "",
      //   contrl: "pause"
      // }
      ],
      now: {
        date: "",
        time: ""
      },
      navigationLoaddingPopup: {
        show: false,
        width: "6.7rem",
        height: "4.39rem"
      },
      navigationAidPopup: {
        show: true,
        closeable: false,
        showMessage: false
      },
      navigationFormPopup: {
        show: true,
        width: "6.7rem",
        height: "4.39rem"
      },
      userinfo: {
        hasUser: false,
        name: "",
        englishName: "",
        shipType: "",
        mmsi: "",
        speed: "",
        course: "",
        x84: ""
      },
      navigationData: {
        menu: {
          sailedMileage: {
            name: "sailedMileage",
            label: "已航行(海里)",
            value: "",
            icon: "sailedmileage",
            colorClass: "yellow",
            visable: false
          },
          speed: {
            name: "speed",
            label: "当前航速(节)",
            value: "",
            icon: "speed",
            colorClass: "blue",
            visable: true
          },
          course: {
            name: "course",
            label: "当前航向(°)",
            value: "",
            icon: "course",
            colorClass: "green",
            visable: true
          },
          location: {
            name: "location",
            label: "经纬度",
            value: "",
            icon: "location",
            colorClass: "red",
            visable: true,
            latitude: "",
            longitude: ""
          }
        },
        remainingMileage: "",
        remainingTime: ""
      },
      searchThemeVars: {
        searchInputHeight: "0.8rem",
        searchActionTextColor: "#4980f9",
        searchActionFontSize: "0.3rem",
        searchActionPadding: "0 0.3rem 0 0.3rem",
        FieldIconSize: "0.3rem",
        cellFontSize: "0.3rem",
        paddingSm: "0px"
      },
      popupThemeVars: {
        "popup-round-border-radius": "0.3rem",
        "button-round-border-radius": "0rem 0rem 0.3rem 0.3rem",
        "button-primary-background-color": "#ecf2ff",
        "button-default-height": " 0.96rem",
        "button-border-width": "0px",
        "button-primary-color": "#336FF3",
        "button-normal-font-size": "0.24rem",
        "cell-background-color": "#f6f6f6",
        "cell-font-size": "0.24rem"
      },
      shipInterval: null,
      JL: null,
      //导航总距离
      endPort: [],
      //终点坐标
      endPorttributaryId: null,
      navInterval: null,
      //每秒获取剩余距离
      beiDouPositionData: '',
      recordId: ""
    };
  },

  computed: {
    //计算属性
    columnNum: function () {
      let num = 0;

      for (let i in this.navigationData.menu) {
        if (this.navigationData.menu[i].visable == true) {
          num++;
        }
      }

      return num;
    },
    ...mapGetters(["mmsi"]),

    hasUser() {
      return this.mmsi ? true : false;
    }

  },

  async created() {
    if (sessionStorage.getItem("navigationFormShow") === "false") {
      this.navigationFormPopup.show = false;
    }

    this.getNow();
    setInterval(this.getNow, 1000);
    this.userinfo.hasUser = this.hasUser;

    if (this.mmsi) {
      let userRes = await selectAisShipPageVoList({
        mmsi: this.mmsi
      });

      if (userRes.data[0]) {
        let userinfo = userRes.data[0];
        this.userinfo = Object.assign(this.userinfo, userinfo);
        let shipTypes = JSON.parse(localStorage.getItem('shipType'));
        let shipData = shipTypes.filter(function (item) {
          return item.id = userinfo.shipType;
        });
        this.userinfo.shipType = shipData[0].type;
      } // console.log(userRes);

    }
  },

  mounted() {
    // this.$refs.nobox.getCamera();
    this.getShipData();

    if (this.userinfo.x84 != '') {
      this.$refs.map.setCenter({
        x84: this.userinfo.x84
      });
      this.$store.center = this.userinfo.x84.split(',');
    }

    this.shipInterval = setInterval(() => {
      this.getShipData();
    }, 1 * 1000);

    if (this.$route.query.record) {
      let record = JSON.parse(this.$route.query.record);
      let point = `${record.Lon},${record.Lat}`;
      this.$refs.map.getVectorLayer("mob", {
        x84: point
      });
      this.$refs.map.setCenter({
        x84: point
      }, 16);
      console.log(point);
    }
  },

  methods: {
    getVIS(val) {
      this.NoBoxShow = val;
    },

    //获取航速航向经纬度
    getShipData() {
      try {
        let resData = window.AndroidFunction.getBeiDouPositionData();
        this.beiDouPositionData = resData;
        let beiDouPositionData = JSON.parse(resData);
        this.navigationData.menu.speed.value = beiDouPositionData.spd;
        this.navigationData.menu.course.value = beiDouPositionData.cog ? beiDouPositionData.cog : "10.3°";
        this.navigationData.menu.location.value = `${beiDouPositionData.Lat}N ${beiDouPositionData.Lon}E`;
        this.navigationData.menu.location.latitude = beiDouPositionData.Lat;
        this.navigationData.menu.location.longitude = beiDouPositionData.Lon;
        this.userinfo.Lat = beiDouPositionData.Lat;
        this.userinfo.Lon = beiDouPositionData.Lon;
        this.userinfo.speed = beiDouPositionData.spd;
        this.userinfo.course = beiDouPositionData.cog ? beiDouPositionData.cog : "10.3°";
        this.userinfo.x84 = `${convertGPSToXY(beiDouPositionData.Lon)},${convertGPSToXY(beiDouPositionData.Lat)}`;
        this.$refs.map.getVectorLayer("myShip", this.userinfo);
        this.$refs.map.getVectorLayer("shipFocus", this.userinfo); // if (this.searchCard.show == false) {
        //   this.$refs.map.getVectorLayer("shipFocus", data);
        // }
      } catch (e) {
        // console.log('非android环境下访问!')
        let beiDouPositionData = {
          spd: 0,
          Lat: '31°42′19.080″',
          Lon: '121°8′40.524″'
        };
        this.navigationData.menu.speed.value = beiDouPositionData.spd;
        this.navigationData.menu.course.value = beiDouPositionData.cog ? beiDouPositionData.cog : "10.3°";
        this.navigationData.menu.location.value = `${beiDouPositionData.Lat}N ${beiDouPositionData.Lon}E`;
        this.navigationData.menu.location.latitude = beiDouPositionData.Lat;
        this.navigationData.menu.location.longitude = beiDouPositionData.Lon;
        this.userinfo.Lat = beiDouPositionData.Lat;
        this.userinfo.Lon = beiDouPositionData.Lon;
        this.userinfo.speed = beiDouPositionData.spd;
        this.userinfo.course = beiDouPositionData.cog ? beiDouPositionData.cog : "10.3°";
        this.userinfo.x84 = `${convertGPSToXY(beiDouPositionData.Lon)},${convertGPSToXY(beiDouPositionData.Lat)}`;
        this.$refs.map.getVectorLayer("myShip", this.userinfo);
        this.$refs.map.getVectorLayer("shipFocus", this.userinfo); // if (this.searchCard.show == false) {
        //   this.$refs.map.getVectorLayer("shipFocus", data);
        // }
      }
    },

    goDesktop() {
      this.$router.push('/');
    },

    getNow() {
      let now = dateExtent.getNowTime("HH:mm:ss yyyy-MM-dd");
      let arr = now.split(" ");
      this.now.time = arr[0];
      this.now.date = arr[1];
    },

    onSearch(type) {
      if (type == 1) {
        // if(this.searchCard1.isSearch==false){
        this.searchPort(type); // }
      } else {
        // if(this.searchCard.isSearch==false){
        this.searchPort(type); // }
      }
    },

    onClickButton(event) {
      event.stopPropagation(); //if(this.searchCard.isSearch==false){

      this.searchPort(); //}
    },

    selectPort(item) {
      this.port = item;
      this.navigationAidForm.port = item.mtmc;
      this.searchCard1.isSearch = false;
    },

    onSubmit() {
      if (this.navigationAidForm.port == "" || JSON.stringify(this.port) === '{}') {
        this.$toast.fail("请查询之后选择您的目的港口");
        return false;
      } // if (this.navigationAidForm.shipTonnage == "") {
      //   this.$toast.fail("请输入您的船舶吨位");
      //   return false;
      // }


      this.navigation(this.port, 1);
    },

    async searchPort(type) {
      if (type == 1) {
        if (this.navigationAidForm.port == "") {
          this.searchCard1.isSearch = false;
          this.$toast.fail("请输入目的港关键字");
          return false;
        }

        this.searchCard1.isSearch = true;
        this.searchCard1.loadding = true;
        const {
          code,
          data
        } = await searchPort({
          portName: this.navigationAidForm.port
        });

        if (code != sucessCode || data.length == 0) {
          this.searchCard1.isSearch = false;
          this.$toast.fail("暂无数据");
          return false;
        }

        this.searchCard1.loadding = false;
        this.searchCard1.ports = data;
      } else {
        this.navigationFormPopup.show = false;

        if (this.keywords == "") {
          this.searchCard.isSearch = false;
          this.$toast.fail("请输入目的港关键字");
          return false;
        }

        this.searchCard.isSearch = true;
        this.searchCard.loadding = true;
        const {
          code,
          data
        } = await searchPort({
          portName: this.keywords
        });

        if (code != sucessCode || data.length == 0) {
          this.searchCard.isSearch = false;
          this.$toast.fail("暂无数据");
          return false;
        }

        this.searchCard.loadding = false;
        this.searchCard.ports = data;
      }
    },

    async getSearchRoute(Lon, Lat) {
      let parms = {
        startLon: Lon,
        startLat: Lat,
        speed: this.navigationData.menu.speed.value,
        endLon: this.endPort[0] ? this.endPort[0] : null,
        endLat: this.endPort[1] ? this.endPort[1] : null,
        tributaryId: this.endPorttributaryId
      };
      console.log(parms);
      if (this.navigationData.menu.speed.value && this.navigationData.menu.speed.value == 0 || this.navigationData.menu.speed.value == "") delete parms.speed;
      const data = await searchRoute(parms);
      return data;
    },

    async navigationStart(hbmc) {
      const aisSn = sessionStorage.getItem("aisSn");
      const startTime = Math.round(new Date().getTime() / 1000);
      let data = {
        "aisSn": aisSn,
        "hbmc": hbmc,
        "startTime": startTime
      };
      let res = await NavigationRecordStart(data);
      this.recordId = res.data.id;
      console.log(res);
    },

    async navigationEnd(id) {
      const endTime = Math.round(new Date().getTime() / 1000);
      let data = {
        "endTime": endTime,
        "id": id
      };
      let res2 = await NavigationRecordEnd(data);
      console.log(res2);
    },

    async navigationAid(item, type) {
      this.navigationLoaddingPopup.show = true;
      let startLon = this.userinfo.x84.split(",")[0];
      let startLat = this.userinfo.x84.split(",")[1];
      this.endPort = item.x84 ? item.x84.split(",") : [];
      this.endPorttributaryId = item.tributary_id ? item.tributary_id : null;
      const res = await this.getSearchRoute(startLon, startLat);

      if (this.navigationLoaddingPopup.show == false) {
        return false;
      }

      this.navigationLoaddingPopup.show = false;
      this.navigationStart(item.mtmc);

      if (res.code != sucessCode) {
        this.$toast.fail("暂无数据");
        this.navigationAidPopup.showMessage = false;
        this.navigationAidPopup.closeable = false;
        this.navigationData.menu.sailedMileage.visable = false;
        this.searchCard.show = true;
        if (type == 1) this.navigationFormPopup.show = true;else {
          this.navigationFormPopup.show = false;
        }
        return false;
      }

      this.navigationData.menu.sailedMileage.visable = true;
      this.navigationAidPopup.showMessage = true;
      this.navigationAidPopup.closeable = true;
      this.searchCard.show = false;
      this.navigationFormPopup.show = false;
      let lineCoordinates = res.data;
      let jl = null; //计算总距离

      for (let i = 0; i < lineCoordinates.length - 1; i++) {
        let item = lineCoordinates[i];
        let nextItem = lineCoordinates[i + 1];
        jl = getDistance(item.lat, item.lng, nextItem.lat, nextItem.lng);
        this.JL += jl;
      } // let moveLon = this.navigationData.menu.location.longitude;
      // let moveLat = this.navigationData.menu.location.latitude;
      // let moveLon = this.userinfo.x84.split(",")[0];    //移动时当前经纬度
      // let moveLat = this.userinfo.x84.split(",")[1]
      // await this.getLeave(moveLon,moveLat);
      // this.navInterval = setInterval(() => {
      //   this.getLeave(moveLon,moveLat);
      // }, 2 * 1000);


      lineCoordinates[0].id = "start";
      lineCoordinates[lineCoordinates.length - 1].id = "end";
      this.$refs.map.navigationRoute(lineCoordinates);

      let _this = this;

      setTimeout(function () {
        _this.$refs.map.setCenter({
          x84: _this.userinfo.x84
        }, 16);
      }, 1500);
    },

    // 获取动态航行距离
    async getLeave(moveLon, moveLat) {
      const res = await this.getSearchRoute(moveLon, moveLat);

      if (res.data.nauticalMileVo) {
        if (res.data.nauticalMileVo.arrivalTime) {
          let arrivalTime = res.data.nauticalMileVo.arrivalTime.split(":");
          this.navigationData.remainingTime = `${arrivalTime[0]}小时${arrivalTime[1]}分钟${arrivalTime[2]}秒`;
        } else {
          this.navigationData.remainingTime = "-";
        }

        this.navigationData.remainingMileage = res.data.nauticalMileVo.distance; //剩余距离
      } //计算已航行距离


      this.navigationData.menu.sailedMileage.value = (this.JL - this.navigationData.remainingMileage).toFixed(2);
    },

    navigation(item, type) {
      if (type != 1) {
        this.searchCard.isSearch = false;
      }

      this.keywords = item.mtmc;
      this.navigationAid(item, type);
    },

    closenavigation() {
      this.$dialog.confirm({
        title: '是否退出助航？'
      }).then(() => {
        let res = this.navigationEnd(this.recordId);
        console.log(res);
        this.searchCard.show = true;
        this.navigationAidPopup.showMessage = false;
        this.navigationAidPopup.closeable = false;
        this.navigationData.menu.sailedMileage.visable = false;
        this.$refs.map.closeNavigationRoute();

        if (sessionStorage.getItem("navigationFormShow") !== "false") {
          this.navigationFormPopup.show = true;
        }
      }).catch(() => {
        return false;
      });
    },

    async closeMavigationForm() {
      sessionStorage.setItem("navigationFormShow", "false");
    },

    settingShip() {
      this.$router.push("shipinfo");
    },

    changeZoom(action) {
      this.$refs.map.changeZoom(action);
    },

    postion() {
      if (this.navigationAidPopup.closeable == true) {
        this.$refs.map.setCenter({
          x84: this.userinfo.x84
        }, 16);
      } else {
        this.$refs.map.setCenter({
          x84: this.userinfo.x84
        });
      }
    },

    toChatPage() {
      try {
        window.AndroidFunction.toChatPage();
      } catch (e) {
        console.log('非android环境下访问!');
        this.$router.push('/message');
      }
    }

  },

  beforeUnmount() {
    if (this.shipInterval) {
      clearInterval(this.shipInterval);
    }

    if (this.navInterval) {
      clearInterval(this.navInterval);
    }
  }

};