import { selectAisShipPageVoList } from '@/api';
import { mapMutations } from 'vuex';
export default {
  created() {
    try {
      let aisSn = window.AndroidFunction.getAISSerialPort();
      sessionStorage.setItem("aisSn", aisSn);
    } catch (e) {
      console.log("非android环境下访问!");
      sessionStorage.setItem("aisSn", "AIS65412");
    }

    let aisSn = sessionStorage.getItem('aisSn');

    if (aisSn) {
      this.getShipiIfo(aisSn);
    }
  },

  methods: { ...mapMutations(["setUser"]),

    async getShipiIfo(aisSn) {
      let userRes = await selectAisShipPageVoList({
        aisSn
      });

      if (userRes.data[0]) {
        let shipinfo = userRes.data[0];
        this.setUser({
          name: shipinfo.name,
          mmsi: shipinfo.mmsi
        });
      }
    }

  }
};