import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DesktopView from '../views/DesktopView.vue'
const routes = [
  {
    path: '/',
    name: '',
    component: DesktopView
  },
  {
    path: '/navigationmap',
    name: 'navigationmap',
    component: HomeView,
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/SettingView.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/MessageView.vue')
  },
  {
    path: '/message1',
    name: 'message1',
    component: () => import('../views/Message1View.vue')
  },
  {
    path: '/shipinfo',
    name: 'shipinfo',
    component: () => import('../views/ShipinfoView.vue')
  },
  {
    path: '/naislist',
    name: 'aislist',
    component: () => import('../views/AislistView.vue')
  },
  {
    path: '/shipdetail',
    name: 'shipdetail',
    component: () => import('../views/ShipdetailView.vue')
  },
  {
    path: '/signal',
    name: 'signal',
    component: () => import('../views/SignalView.vue')
  },
  {
    path: '/switchrecords',
    name: 'switchrecords',
    component: () => import('../views/SwitchrecordsView.vue')
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('../views/DeviceView.vue')
  },
  {
    path: '/crew',
    name: 'crew',
    component: () => import('../views/CrewView.vue')
  },
  {
    path: '/crewdetail/:id',
    name: 'crewdetail',
    component: () => import('../views/CrewdetailView.vue')
  },
  {
    path: '/shipparams',
    name: 'shipparams',
    component: () => import('../views/ShipParams.vue')
  },
  {
    path: '/recommentdetail/:id',
    name: 'recommentdetail',
    component: () => import('../views/RecommentDetailView.vue')
  },
  {
    path: '/sos',
    name: 'sos',
    component: () => import('../views/SosView.vue')
  },
  {
    path: '/sosrecord',
    name: 'sosrecord',
    component: () => import('../views/SosRecordView.vue')
  },
  {
    path: '/monitor',
    name: 'monitor',
    component: () => import('../views/MonitorView.vue')
  },
  {
    path: '/system-setting',
    name: 'system-setting',
    component: () => import('../views/SystemSettingView.vue')
  },
  {
    path: '/system-setting',
    name: 'system-setting',
    component: () => import('../views/SystemSettingView.vue')
  },
  {
    path: '/system-ship',
    name: 'system-ship',
    component: () => import('../views/SystemShipView.vue')
  },
  {
    path: '/video-setting',
    name: 'video-setting',
    component: () => import('../views/VideoSettingView.vue')
  },
  {
    path: '/music-setting',
    name: 'music-setting',
    component: () => import('../views/MusicSettingView.vue')
  },
  {
    path: '/fingerprint-setting',
    name: 'fingerprint-setting',
    component: () => import('../views/FingerprintSettingView.vue')
  },
  {
    path: '/monitor-records',
    name: 'monitor-records',
    component: () => import('../views/MonitorRecordsView.vue')
  },
  {
    path: '/monitor-setting',
    name: 'monitor-setting',
    component: () => import('../views/MonitorSettingView.vue')
  },
  {
    path: '/communication-setting',
    name: 'communication-setting',
    component: () => import('../views/CommunicationSettingView.vue')
  },
  {
    path: '/ais-setting',
    name: 'ais-setting',
    component: () => import('../views/AisSettingView.vue')
  },
  {
    path: '/beidou-setting',
    name: 'beidou-setting',
    component: () => import('../views/BeidouSettingView.vue')
  },
  {
    path: '/officialapp',
    name: 'officialapp',
    component: () => import('../views/OfficialappView.vue')
  },
  {
    path: '/othersapp',
    name: 'othersapp',
    component: () => import('../views/OthersappView.vue')
  },
  {
    path: '/keyborad-setting',
    name: 'keyborad-setting',
    component: () => import('../views/KeyboradSettingView.vue')
  },
  {
    path: '/mobView',
    name: 'mobView',
    component: () => import('../views/MobView.vue')
  },
  {
    path: '/call-logs',
    name: 'call-logs',
    component: () => import('../views/CallLogsView.vue')
  },
  {
    path: '/system-voice',
    name: 'system-voice',
    component: () => import('../views/SystemVoiceView.vue')
  },
  {
    path: '/system-brightness',
    name: 'system-brightness',
    component: () => import('../views/SystemBrightnessView.vue')
  },
  {
    path: '/system-native',
    name: 'system-native',
    component: () => import('../views/SystemNativeView.vue')
  },
  {
    path: '/sos-desktop',
    name: 'sos-desktop',
    component: () => import('../views/SosDesktopView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
