import http from '@/api/http.js';
const resquest = process.env.VUE_APP_API;
//港口
export function getAllPort() {
  return http.post(`${resquest}/ais/navigationWharf/queryAllWharf`)
}
//航标
export function getAllHb(data) {
  return http.post(`${resquest}/ais/navigationMark/getAll`, data)
}
//锚地
export function getAllMd() {
  return http.post(`${resquest}/ais/navigationAnchor/queryAllAnchor`)
}
//船舶
export function getAllSocialShip(params){
  return http.post(`/societyship/getAll`, params)
}

