import http from "./http";
const resquest = process.env.VUE_APP_API;
const yjzhyUrl = process.env.VUE_APP_YJZHYAPI
// const crewUrl = process.env.VUE_APP_CREWAPI

//港口查询
export function searchPort(params){
    return http.post(`${resquest}/ais/navigationWharf/queryWharfByName`, params);
}
//导航
export function searchRoute(data){
    return http.post(`${resquest}/ais/aisMarkApi/getAllMark`, data)
}
//新增或更新 AIS船舶动态基础信息
export function insertOrUpdateShipActiveInfo(data){
    return http.post(`${resquest}/ais/navigationShip/insertOrUpdateShipActiveInfo`, data)
}
//新增AIS船舶静态基础信息
export function insetShipInfo(data){
    return http.post(`${resquest}/ais/navigationShip/insetShipInfo`, data)
}
//查询ais船舶静态基础信息
export function queryShipInfoList(){
    return http.post(`${resquest}/ais/navigationShip/queryShipInfoList`)
}
//查询所有国家编码
export function selectAisAreaList(){
    return http.post(`${resquest}/ais/navigationShip/selectAisAreaList`)
}
//查询字典值
export function selectAisShipDict(data){
    return http.post(`${resquest}/ais/navigationShip/selectAisShipDict`, data)
}
//查询Ais列表
export function selectAisShipPageVoList(data){
    return http.post(`${resquest}/ais/navigationShip/selectAisShipPageVoList`, data)
}
//导航记录列表
export function NavigationEecordList(data){
    return http.get(`${resquest}/ais/aisNavigationRecord/NavigationEecord`, data)
}

//导航记录开始
export function NavigationRecordStart(data){
    return http.post(`${resquest}/ais/aisNavigationRecord/AddNavigationRecordStart`, data)
}
//导航记录结束
export function NavigationRecordEnd(data){
    return http.post(`${resquest}/ais/aisNavigationRecord/AddNavigationRecordEnd`, data)
}


// 获取摄像头列表
export function getCamera(params){
    return http.get(`${yjzhyUrl}/boxManage/unauth/deviceInfo/queryCameraByAisSn?aisSn=` + params)
}
// 获取告警列表
export function getAlarmList(data){
    return http.post(`${yjzhyUrl}/boxManage/alarm/ais/querByList`, data)
}
// export function getAlarmList(){
//     return http.get(`/alarmList`)
// }

// 获取告警详情
export function getAlarmDetail(data){
    return http.get(`${yjzhyUrl}/boxManage/alarm/ais/detailById?alarmId=${data}`)
}
// export function getAlarmDetail(data){
//     return http.get(`/alarmDetail?alarmId=${data}`)
// }

// 获取告警类别
export function getAlarmType(params){
    return http.get(`${yjzhyUrl}/boxManage/alarm/ais/queryCauseList?aisSn=`+ params)
}

//船员分页列表
export function getCrewPageList(data){
    return http.post(`${resquest}/ais/ship/user/getShipUserInfoPageListOfAIS`,data)
}

//船员详情
export function getCrewDetail(data){
    return http.post(`${resquest}/ais/ship/user/getShipUserDetail`,data)
}

//船舶认证
export function shipInfoOfAIS(data){
    return http.post(`${resquest}/ais/ship/user/shipInfoOfAIS`,data)
}

//船员考勤
export function getCrewAttendance(data){
    return http.post(`${yjzhyUrl}/boxManage/crew/attendance/queryByPage`,data)
}



