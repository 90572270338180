/**
 * 度分秒转经纬度
 * @param dfm
 * @returns {number}
 */
 export function convertGPSToXY(dfm) {
    const arr1 = dfm.split('°');
    const d = arr1[0];
    const arr2 = arr1[1].split("′")
    let f = arr2[0] || 0;
    const m = arr2[1].replace('″', '') || 0;
    f = parseFloat(f) + parseFloat(m / 60);
    var du = parseFloat(f / 60) + parseFloat(d);
    return du;
}

/**
 * 经纬度转度分秒
 * @param point
 * @returns {*}
 */
export function convertXYToGPS(point) {

    let xy;
    if (point instanceof Array) {
        xy = point;
    } else {
        point = point + "";
        xy = point.split(',');
    }

    let dPoint = [];
    let dPointStr = "";
    for (let i = 0; i < xy.length; i++) {
        const mElement = xy[i] + "";
        const arr1 = mElement.split(".");
        const d = arr1[0];
        let tp = "0." + arr1[1]
        tp = String(tp * 60); //这里进行了强制类型转换
        const arr2 = tp.split(".");
        const f = arr2[0];
        tp = "0." + arr2[1];
        tp = tp * 60;
        const m = tp.toFixed(2);
        let dfm = d + "°" + f + "'" + m + "\"";
        if(i==0){
            dfm=dfm+'E';
        }else if(i==1){
            dfm=dfm+'N';
        }
        dPointStr += "\n" + dfm;
        dPoint.push(dfm);
    }

    dPointStr = dPointStr.replace(',', '');

    return point instanceof Array ? dPoint : dPointStr;
}

//根据经纬度计算距离
export function getDistance(lat1,lng1,lat2,lng2){
    let EARTH_R = 6378.137;
    lat1 = lat1  * Math.PI / 180;
    lat2 = lat2  * Math.PI / 180;
    lng1 = lng1  * Math.PI / 180;
    lng2 = lng2  * Math.PI / 180;
    let cos = Math.cos(lat2) * Math.cos(lat1) * Math.cos(lng2 -lng1) + Math.sin(lat1) * Math.sin(lat2);
    return EARTH_R * Math.acos(cos) * 0.5399568;   //返回海里
}