import { createStore } from 'vuex'
import mapOptions from '@/map/options';
var layersOption=mapOptions.layersOptions;
var lv={};
for(let layerName in layersOption){
  let item=layersOption[layerName];
  if(item.isMenu==true){
    lv[layerName]=item.visible;
  }
}
export default createStore({
  state: {
    layerVisible: lv,
    mapLv: 14,
    shipdetail:{},
    center:mapOptions.tianditu.center,
    name:sessionStorage.getItem('name')?sessionStorage.getItem('name'):'',
    mmsi:sessionStorage.getItem('mmsi')?sessionStorage.getItem('mmsi'):'',
  },
  getters: {
    cvaVisible: state => {
      return state.layerVisible.cva;
    },
    vecVisible: state => {
      return state.layerVisible.vec;
    },
    hbVisible: state => {
      return state.layerVisible.hb;
    },
    soundgVisible: state => {
      return state.layerVisible.soundg;
    },
    mdVisible: state => {
      return state.layerVisible.md;
    },
    shipVisible: state => {
      return state.layerVisible.ship;
    },
    name: state => {
      return state.name;
    },
    mmsi: state => {
      return state.mmsi;
    },
  },
  mutations: {
    mapLvChange(state,data){
      state.mapLv = data;
    },
    vecChange(state,visible){
      state.layerVisible.vec=visible;
      state.layerVisible.cva=visible;
    },
    hbChange(state,visible){
      state.layerVisible.hb=visible;
    },
    soundgChange(state,visible){
      state.layerVisible.soundg=visible;
    },
    mdChange(state,visible){
      state.layerVisible.md=visible;
    },
    shipChange(state,visible){
      state.layerVisible.ship=visible;
    },
    setShipdetail(state,data){
      state.shipdetail=data;
    },
    setUser(state,data){
      sessionStorage.setItem("name", data.name);
      sessionStorage.setItem("mmsi", data.mmsi);
      state.name=data.name;
      state.mmsi=data.mmsi;
    }
  },
  actions: {
  },
  modules: {
  }
})
