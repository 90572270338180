/****   http.js   ****/
// 导入封装好的axios实例
import request from "./config";

const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params url请求参数
   * @param data 请求参数
   */
  get(url, params) {
    const config = {
      headers:{
        "Content-Type": "application/json",
      },
      method: "get",
      url: url,
    };
    if (params) config.params = params;
    return request(config);
  },
  post(url, data) {
    const config = {
      method: "post",
      url: url,
    };
    if (data) config.data = data;
    return request(config);
  },
  //查看文件流
  fileget(url, params) {
    const config = {
      method: "get",
      url: url,
      responseType: "blob",
    };
    if (params) config.params = params;
    return request(config);
  },
  //下载文件
  filepost(url, data) {
    const config = {
      method: "post",
      url: url,
      responseType: "arraybuffer",
    };
    if (data) config.data = data;
    return request(config);
  },
  formpost(url, data){
    const config = {
      headers:{
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "post",
      url: url,
    };
    if (data) config.data = data;
    return request(config);
  },
  formdatapost(url, data){
    const config = {
      headers:{
        "Content-Type": "application/formdata",
      },
      method: "post",
      url: url,
    };
    if (data) config.data = data;
    return request(config);
  }
};
//导出
export default http;
