export const alarmList = {
  "records": 
  [
    {
      "alarmId": 79854,
      "alarmCount": 3,
      "alarmLevel": 3,
      "causeId": 1,
      "boxId": 129,
      "boxName": "演示专用",
      "causeName": "未戴安全帽",
      "deviceId": 321,
      "deviceName": "前照",
      "deviceTypeId": 2,
      "deviceTypeName": "普通枪机摄像头",
      "boatCount": "1",
      "dealResult": 1,
      "eventTime": "2023-04-18 11:23:44",
      "startTime": "2023-04-18 11:23:44",
      "endTime": "2023-04-18 11:29:32",
      "alarmExtList": [{
        "alarmExtId": 111154238998660611,
        "alarmId": 79854,
        "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230418/1681788574449.jpg",
        "alarmValueType": 1,
        "alias": "事件图片",
        "eventTime": "2023-04-18 11:29:32"
      }],
      "cameraIp": "192.168.1.226"
    }, 
  {
    "alarmId": 79842,
    "alarmCount": 23,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "人员识别",
    "deviceId": 321,
    "deviceName": "前照",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-18 10:53:46",
    "startTime": "2023-04-18 10:53:46",
    "endTime": "2023-04-18 11:22:52",
    "alarmExtList": [{
      "alarmExtId": 111154238998660380,
      "alarmId": 79842,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230418/1681788176974.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-18 11:22:52"
    }],
    "cameraIp": "192.168.1.226"
  }, {
    "alarmId": 79830,
    "alarmCount": 21,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "人员识别",
    "deviceId": 321,
    "deviceName": "左舷",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-18 10:22:19",
    "startTime": "2023-04-18 10:22:19",
    "endTime": "2023-04-18 10:46:40",
    "alarmExtList": [{
      "alarmExtId": 111154238998659853,
      "alarmId": 79830,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230418/1681786002524.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-18 10:46:40"
    }],
    "cameraIp": "192.168.1.226"
  }, {
    "alarmId": 79816,
    "alarmCount": 36,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "未戴安全帽",
    "deviceId": 321,
    "deviceName": "右舷",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-18 09:51:12",
    "startTime": "2023-04-18 09:51:12",
    "endTime": "2023-04-18 10:20:55",
    "alarmExtList": [{
      "alarmExtId": 111154238998659451,
      "alarmId": 79816,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230418/1681784459970.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-18 10:20:55"
    }],
    "cameraIp": "192.168.1.226"
  }, {
    "alarmId": 79796,
    "alarmCount": 16,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "未戴安全帽",
    "deviceId": 321,
    "deviceName": "后照",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-18 09:15:22",
    "startTime": "2023-04-18 09:15:22",
    "endTime": "2023-04-18 09:42:06",
    "alarmExtList": [{
      "alarmExtId": 111154238998656008,
      "alarmId": 79796,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230418/1681782127717.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-18 09:42:06"
    }],
    "cameraIp": "192.168.1.226"
  }, {
    "alarmId": 79781,
    "alarmCount": 6,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "人脸检测",
    "deviceId": 302,
    "deviceName": "后照",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-17 22:12:20",
    "startTime": "2023-04-17 22:12:20",
    "endTime": "2023-04-17 22:28:08",
    "alarmExtList": [{
      "alarmExtId": 111154238998654301,
      "alarmId": 79781,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_103/picture/20230417/1681741689988.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-17 22:28:08"
    }],
    "cameraIp": "192.168.1.103"
  }, {
    "alarmId": 79776,
    "alarmCount": 4,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "人脸检测",
    "deviceId": 302,
    "deviceName": "前照",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-17 21:37:36",
    "startTime": "2023-04-17 21:37:36",
    "endTime": "2023-04-17 22:03:56",
    "alarmExtList": [{
      "alarmExtId": 111154238998652801,
      "alarmId": 79776,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_103/picture/20230417/1681740237991.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-17 22:03:56"
    }],
    "cameraIp": "192.168.1.103"
  }, {
    "alarmId": 79771,
    "alarmCount": 1,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "未穿救生衣",
    "deviceId": 321,
    "deviceName": "右舷",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-17 21:20:44",
    "startTime": "2023-04-17 21:20:44",
    "endTime": "2023-04-17 21:20:44",
    "alarmExtList": [{
      "alarmExtId": 111154238998650051,
      "alarmId": 79771,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230417/1681737648414.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-17 21:20:44"
    }],
    "cameraIp": "192.168.1.226"
  }, {
    "alarmId": 79768,
    "alarmCount": 6,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "未戴安全帽",
    "deviceId": 302,
    "deviceName": "右舷",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-17 21:01:04",
    "startTime": "2023-04-17 21:01:04",
    "endTime": "2023-04-17 21:21:36",
    "alarmExtList": [{
      "alarmExtId": 111154238998650109,
      "alarmId": 79768,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_103/picture/20230417/1681737700386.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-17 21:21:36"
    }],
    "cameraIp": "192.168.1.103"
  }, {
    "alarmId": 79760,
    "alarmCount": 5,
    "alarmLevel": 3,
    "causeId": 1,
    "boxId": 129,
    "boxName": "演示专用",
    "causeName": "未穿救生衣",
    "deviceId": 321,
    "deviceName": "左舷",
    "deviceTypeId": 2,
    "deviceTypeName": "普通枪机摄像头",
    "boatCount": "1",
    "dealResult": 1,
    "eventTime": "2023-04-17 20:40:12",
    "startTime": "2023-04-17 20:40:12",
    "endTime": "2023-04-17 21:09:14",
    "alarmExtList": [{
      "alarmExtId": 111154238998649381,
      "alarmId": 79760,
      "alarmValue": "http://oss.jsyj56.com/getFile/smartbox/event_report/YZBG1AN003B3/YZBG1AN003B3_226/picture/20230417/1681736956051.jpg",
      "alarmValueType": 1,
      "alias": "事件图片",
      "eventTime": "2023-04-17 21:09:14"
    }],
    "cameraIp": "192.168.1.226"
  }]
}
