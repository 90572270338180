export const md=[
    {
        "id": "YC_ff8080816d670f70016d675ebd080017",
        "cjsj": "2019-09-25 18:02:52",
        "gxsj": "2022-03-30 14:06:51",
        "sjly": "2",
        "scbj": 0,
        "mdmc": "临江坪锚地",
        "gldw": "无",
        "scsdId": "YC_4a3ef03cf2ca452da243e72f0430ca84",
        "sshdName": "白沙脑水道",
        "hdHdly": "中游",
        "hdlc": 615,
        "tyAnb": "左岸",
        "yt": "干杂货",
        "jcsj": "1993-12-31",
        "x54": "3386500,536932,37#3386324,536645,37#3389431,534029,37#3389671,534321,37",
        "zb2000": "3386449,536982,37#3386273,536696,37#3389380,534079,37#3389620,534371,37",
        "x84": "111.385618,30.597816#111.382625,30.596236#111.355447,30.624343#111.358496,30.626499",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 0,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "111.385618",
                "lat": "30.597816"
            },
            {
                "lon": "111.382625",
                "lat": "30.596236"
            },
            {
                "lon": "111.355447",
                "lat": "30.624343"
            },
            {
                "lon": "111.358496",
                "lat": "30.626499"
            }
        ]
    },
    {
        "id": "YC_ff8080816d670f70016d6763789c0019",
        "cjsj": "2019-09-25 18:02:52",
        "gxsj": "2022-03-30 14:06:51",
        "sjly": "2",
        "scbj": 0,
        "mdmc": "陈家河锚地",
        "gldw": "无",
        "scsdId": "YC_ffd16686a3064c6d9417e234ffe3f94a",
        "sshdName": "虎牙峡水道",
        "hdHdly": "中游",
        "hdlc": 613,
        "tyAnb": "左岸",
        "yt": "危险品",
        "jcsj": "1993-12-31",
        "x54": "3385132 ,537370 ,37#3386232 ,536867 ,37#3385286,537715,37#3386293,537066,37",
        "zb2000": "3385081,537420,37#3386181,536917,37#3385235,537766,37#3386243,537117,37",
        "x84": "111.390139,30.585464#111.384934,30.595402#111.393745,30.586840#111.387016,30.595949",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 0,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "111.390139",
                "lat": "30.585464"
            },
            {
                "lon": "111.384934",
                "lat": "30.595402"
            },
            {
                "lon": "111.393745",
                "lat": "30.586840"
            },
            {
                "lon": "111.387016",
                "lat": "30.595949"
            }
        ]
    },
    {
        "id": "YC_ff8080816d670f70016d67676c00001b",
        "cjsj": "2019-09-25 18:02:52",
        "gxsj": "2020-11-23 15:01:30",
        "sjly": "2",
        "scbj": 0,
        "mdmc": "古老背锚地",
        "gldw": "无",
        "scsdId": "YC_5cf534559903467db773ceef6521cc22",
        "sshdName": "古老背水道",
        "hdHdly": "中游",
        "hdlc": 602,
        "tyAnb": "左岸",
        "yt": "散货船 ",
        "jcsj": "2016-12-31",
        "x54": "3378293,539370,37#3378410,539583,37#3375461,541486,37#3375331,541238,37",
        "zb2000": "3378242,539420,37#3378359,539634,37#3375410,541536,37#3375280,541289,37",
        "x84": "111.410730,30.523714#111.412960,30.524756#111.432664,30.498098#111.430080,30.496927",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 0,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "111.410730",
                "lat": "30.523714"
            },
            {
                "lon": "111.412960",
                "lat": "30.524756"
            },
            {
                "lon": "111.432664",
                "lat": "30.498098"
            },
            {
                "lon": "111.430080",
                "lat": "30.496927"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed90720172127d42480748",
        "cjsj": "2020-05-14 17:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "海门锚地",
        "gldw": "南通市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa62a2120466",
        "sshdName": "白茆沙北水道",
        "hdHdly": "下游",
        "hdlc": 52.7,
        "tyAnb": "左岸",
        "yt": "供船锚泊",
        "jcsj": "2009-05-01",
        "x54": "3516753.74 ,610790.85 ,40#3515816.44 ,610580.11 ,40#3517410.16 ,607867.17 ,40#3516469.67 ,607654.42,40",
        "zb2000": "3516704,610867,40#3515766,610656,40#3517360,607943,40#3516420,607730,40",
        "x84": "121.17030018,31.76782205#121.16796987,31.75939104#121.13951544,31.77402054#121.13716617,31.76556034",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "121.17030018",
                "lat": "31.76782205"
            },
            {
                "lon": "121.16796987",
                "lat": "31.75939104"
            },
            {
                "lon": "121.13951544",
                "lat": "31.77402054"
            },
            {
                "lon": "121.13716617",
                "lat": "31.76556034"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212819d450749",
        "cjsj": "2020-05-14 17:59:04",
        "gxsj": "2021-09-30 12:10:26",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "常熟港海轮锚地",
        "gldw": "常熟市港口管理处",
        "scsdId": "NJ_2c9240214e527d39014e626c6a27005b",
        "sshdName": "白茆沙水道",
        "hdHdly": "下游",
        "hdlc": 62,
        "tyAnb": "左岸",
        "yt": "供海轮锚泊",
        "jcsj": "2003-01-01",
        "x54": "3517303,601793,40#3517965,596301,40#3518664,596383,40#3518332,599127,40#3517998,601873,40",
        "zb2000": "3517253,601869,40#3517915,596377,40#3518614,596459,40#3518282,599203,40#3517948,601949,40",
        "x84": "121.07539051,31.77361152#121.01748608,31.78005695#121.01842073,31.78635305#121.04735475,31.78312451#121.07630746,31.77987123",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "121.07539051",
                "lat": "31.77361152"
            },
            {
                "lon": "121.01748608",
                "lat": "31.78005695"
            },
            {
                "lon": "121.01842073",
                "lat": "31.78635305"
            },
            {
                "lon": "121.04735475",
                "lat": "31.78312451"
            },
            {
                "lon": "121.07630746",
                "lat": "31.77987123"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed90720172129aa064074c",
        "cjsj": "2020-05-14 17:59:04",
        "gxsj": "2021-09-30 12:10:26",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "常熟港过驳锚地",
        "gldw": "常熟市港口管理处",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa68ed96047d",
        "sshdName": "通州沙东水道",
        "hdHdly": "下游",
        "hdlc": 72.5,
        "tyAnb": "右岸",
        "yt": "供海轮过驳锚泊",
        "jcsj": "1996-01-01",
        "x54": "3516919,587757,40#3517344,586276,40#3518277,583935,40#3518849,584094,40#3518196,586529,40#3517608,588534,40",
        "zb2000": "3516869,587832,40#3517294,586351,40#3518227,584010,40#3518799,584169,40#3518146,586604,40#3517558,588609,40",
        "x84": "120.92719907,31.77131300#120.91160404,31.77525821#120.88697284,31.78384595#120.88870070,31.78899228#120.91435023,31.78292196#120.93546309,31.77746608",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.92719907",
                "lat": "31.77131300"
            },
            {
                "lon": "120.91160404",
                "lat": "31.77525821"
            },
            {
                "lon": "120.88697284",
                "lat": "31.78384595"
            },
            {
                "lon": "120.88870070",
                "lat": "31.78899228"
            },
            {
                "lon": "120.91435023",
                "lat": "31.78292196"
            },
            {
                "lon": "120.93546309",
                "lat": "31.77746608"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212b1ff1a0753",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "南通危险品锚地",
        "gldw": "南通市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa68ed96047d",
        "sshdName": "通州沙东水道",
        "hdHdly": "下游",
        "hdlc": 82.7,
        "tyAnb": "左岸",
        "yt": "供危险品船锚泊",
        "jcsj": "2007-08-10",
        "x54": "3528072,584953,40#3528272,585799,40#3530700,585237,40#3530501,584378,40",
        "zb2000": "3528022,585028,40#3528222,585874,40#3530650,585312,40#3530451,584453,40",
        "x84": "120.89857455,31.87209656#120.90753185,31.87383658#120.90180631,31.89577294#120.89270966,31.89404254",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.89857455",
                "lat": "31.87209656"
            },
            {
                "lon": "120.90753185",
                "lat": "31.87383658"
            },
            {
                "lon": "120.90180631",
                "lat": "31.89577294"
            },
            {
                "lon": "120.89270966",
                "lat": "31.89404254"
            }
        ]
    },
    {
        "id": "WH_def129c656af438f9f97835aff67deb1",
        "cjsj": "2019-09-24 16:24:35",
        "gxsj": "2022-01-25 09:07:36",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "华能锚地",
        "gldw": "华能湖南岳阳发电有限责任公司",
        "scsdId": "WH_0123f9a11d2b409e9004bc4e47d76554",
        "sshdName": "仙峰水道",
        "hdHdly": "中游",
        "hdlc": 225.5,
        "tyAnb": "左岸",
        "yt": "煤炭船舶停泊区",
        "jcsj": "2009-08-19",
        "x54": "3261902,418720,38#3262105,418478,38#3263131,419543,38#3263230,419362,38",
        "zb2000": "3261849,418778,38#3262052,418536,38#3263078,419601,38#3263177,419420,38",
        "x84": "113.162619,29.471740#113.160109,29.473556#113.171013,29.482880#113.169140,29.483762",
        "lhHdshyj": "1",
        "hxbz": 2,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.162619",
                "lat": "29.471740"
            },
            {
                "lon": "113.160109",
                "lat": "29.473556"
            },
            {
                "lon": "113.171013",
                "lat": "29.482880"
            },
            {
                "lon": "113.169140",
                "lat": "29.483762"
            }
        ]
    },
    {
        "id": "WH_6b8925e0f574441799562fdbd12eb200",
        "cjsj": "2019-12-04 21:18:33",
        "gxsj": "2022-01-25 09:25:47",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "芦席洲锚地",
        "gldw": "岳阳城陵矶港务有限责任公司",
        "scsdId": "WH_b9d8f72a4cfc493494be328049db9c49",
        "sshdName": "洞庭湖口",
        "hdHdly": "中游",
        "hdlc": 230,
        "tyAnb": "左岸",
        "yt": "散货船停泊区",
        "jcsj": "1982-09-13",
        "x54": "3258177,415788,38#3258337,415905,38#3258231,416051,38#3258070,415932,38",
        "zb2000": "3258124,415846,38#3258284,415963,38#3258178,416109,38#3258017,415990,38",
        "x84": "113.132677,29.437946#113.133870,29.439397#113.135383,29.438450#113.134169,29.436990",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 1,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.132677",
                "lat": "29.437946"
            },
            {
                "lon": "113.133870",
                "lat": "29.439397"
            },
            {
                "lon": "113.135383",
                "lat": "29.438450"
            },
            {
                "lon": "113.134169",
                "lat": "29.436990"
            }
        ]
    },
    {
        "id": "WH_e71634fce5494ca095709018811de7e0",
        "cjsj": "2019-12-10 10:17:04",
        "gxsj": "2020-06-03 15:47:19",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "联检锚地",
        "gldw": "黄石新港开发有限公司",
        "scsdId": "WH_cc466235d3ca4c70815ab3284ff0ac4e",
        "sshdName": "蕲春水道",
        "hdHdly": "下游",
        "hdlc": 881,
        "tyAnb": "右岸",
        "yt": "干散货停泊区",
        "jcsj": "2019-01-01",
        "x54": "3332736,625166,38#3332630,624998,38#3333053,624728,38#3333159,624898,38",
        "zb2000": "3332684,625226,38#3332578,625058,38#3333001,624788,38#3333107,624958,38",
        "x84": "115.299202,30.106975#115.297446,30.106036#115.294696,30.109878#115.296471,30.110817",
        "lhHdshyj": "",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "115.299202",
                "lat": "30.106975"
            },
            {
                "lon": "115.297446",
                "lat": "30.106036"
            },
            {
                "lon": "115.294696",
                "lat": "30.109878"
            },
            {
                "lon": "115.296471",
                "lat": "30.110817"
            }
        ]
    },
    {
        "id": "WH_da85470ede6b438a829d27f4be16d6c3",
        "cjsj": "2019-12-10 10:27:04",
        "gxsj": "2020-06-03 15:47:19",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "李家下洲危险品锚地",
        "gldw": "黄石新港开发有限公司",
        "scsdId": "WH_cc466235d3ca4c70815ab3284ff0ac4e",
        "sshdName": "蕲春水道",
        "hdHdly": "下游",
        "hdlc": 880,
        "tyAnb": "右岸",
        "yt": "危险品停泊区",
        "jcsj": "2019-01-01",
        "x54": "3330171,626978,38#3329729,627244,38#3329603,627035,38#3330045,626769,38",
        "zb2000": "3330119,627038,38#3329677,627304,38#3329551,627095,38#3329993,626829,38",
        "x84": "115.317690,30.083655#115.320396,30.079641#115.318213,30.078526#115.315508,30.082540",
        "lhHdshyj": "",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "115.317690",
                "lat": "30.083655"
            },
            {
                "lon": "115.320396",
                "lat": "30.079641"
            },
            {
                "lon": "115.318213",
                "lat": "30.078526"
            },
            {
                "lon": "115.315508",
                "lat": "30.082540"
            }
        ]
    },
    {
        "id": "WH_e2f15d0eceb04e87af0c8cc26751b26d",
        "cjsj": "2019-12-10 10:27:04",
        "gxsj": "2020-06-03 15:47:19",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "李家下洲锚地",
        "gldw": "黄石新港开发有限公司",
        "scsdId": "WH_cc466235d3ca4c70815ab3284ff0ac4e",
        "sshdName": "蕲春水道",
        "hdHdly": "下游",
        "hdlc": 880.8,
        "tyAnb": "右岸",
        "yt": "干散货停泊区",
        "jcsj": "2019-01-01",
        "x54": "3331164,626380,38#3330342,626875,38#3330212,626659,38#3331034,626164,38",
        "zb2000": "3331112,626440,38#3330290,626935,38#3330160,626719,38#3330982,626224,38",
        "x84": "115.311607,30.092672#115.316642,30.085208#115.314387,30.084058#115.309351,30.091522",
        "lhHdshyj": "",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "115.311607",
                "lat": "30.092672"
            },
            {
                "lon": "115.316642",
                "lat": "30.085208"
            },
            {
                "lon": "115.314387",
                "lat": "30.084058"
            },
            {
                "lon": "115.309351",
                "lat": "30.091522"
            }
        ]
    },
    {
        "id": "WH_476eaa25580a4d468f651b8499457c0d",
        "cjsj": "2020-01-02 09:55:06",
        "gxsj": "2022-01-20 14:46:35",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "荆州港玉南锚地",
        "gldw": "荆州港商务调度中心",
        "scsdId": "WH_8f01fa9b93394e7091435b34cd0721d4",
        "sshdName": "太平口水道",
        "hdHdly": "中游",
        "hdlc": 476,
        "tyAnb": "右岸",
        "yt": "干散货停泊区",
        "jcsj": null,
        "x54": "3353490,619637,37#3352871,620445,37#3352590,620845,37#3352824,621309,37#3353735,619908,37",
        "zb2000": "3353439,619689,37#3352820,620497,37#3352539,620897,37#3352773,621360,37#3353684,619959,37",
        "x84": "112.244123,30.294714#112.252448,30.289049#112.256568,30.286474#112.261415,30.288536#112.246960,30.296893",
        "lhHdshyj": "",
        "hxbz": 2,
        "zyb": 3,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "112.244123",
                "lat": "30.294714"
            },
            {
                "lon": "112.252448",
                "lat": "30.289049"
            },
            {
                "lon": "112.256568",
                "lat": "30.286474"
            },
            {
                "lon": "112.261415",
                "lat": "30.288536"
            },
            {
                "lon": "112.246960",
                "lat": "30.296893"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed90720172126dc6cb0735",
        "cjsj": "2020-05-14 17:59:04",
        "gxsj": "2021-09-30 12:10:26",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "浏河海轮锚地",
        "gldw": "太仓港口管理委员会",
        "scsdId": "NJ_2c9240214e527d39014e626655a10052",
        "sshdName": "浏河水道",
        "hdHdly": "下游",
        "hdlc": 29,
        "tyAnb": "左岸",
        "yt": "海轮锚泊",
        "jcsj": "2009-05-01",
        "x54": "3496455,626939,40#3495551,625646,40#3499122,623029,40#3499708,623830,40#3498077,625389,40",
        "zb2000": "3496404.66,627015.18,40#3495500.92,625721.67,40#3499071.45,623104.91,40#3499658.10,623906.34,40#3498027.03,625464.69,40",
        "x84": "121.33809735,31.58312328#121.32436338,31.57511421#121.29724745,31.60759494#121.30576015,31.61279266#121.32197814,31.59791787",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "121.33809735",
                "lat": "31.58312328"
            },
            {
                "lon": "121.32436338",
                "lat": "31.57511421"
            },
            {
                "lon": "121.29724745",
                "lat": "31.60759494"
            },
            {
                "lon": "121.30576015",
                "lat": "31.61279266"
            },
            {
                "lon": "121.32197814",
                "lat": "31.59791787"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed90720172126fa648073c",
        "cjsj": "2020-05-14 17:59:04",
        "gxsj": "2021-09-30 12:10:26",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "太仓CJJS#5锚地",
        "gldw": "太仓港口管理委员会",
        "scsdId": "NJ_2c9240214e527d39014e626c6a27005b",
        "sshdName": "白茆沙水道",
        "hdHdly": "下游",
        "hdlc": 37,
        "tyAnb": "左岸",
        "yt": "海轮锚泊",
        "jcsj": "2013-08-01",
        "x54": "3502023,619380,40#3506170,614118,40#3508273,611207,40#3508846,611644,40#3506899,614206,40#3505675,616012,40#3505042,618171,40#3503881,619645,40#3502691,619984,40",
        "zb2000": "3501973,619456,40#3506120,614194,40#3508223,611283,40#3508796,611720,40#3506849,614282,40#3505625,616088,40#3504992,618247,40#3503831,619721,40#3502641,620060,40",
        "x84": "121.25915489,31.63413682#121.20418134,31.67206420#121.17372832,31.69131253#121.17840197,31.69643670#121.20519401,31.67862867#121.22409156,31.66741097#121.24677671,31.66148312#121.26217387,31.65086214#121.26560180,31.64009700",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 7,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "121.25915489",
                "lat": "31.63413682"
            },
            {
                "lon": "121.20418134",
                "lat": "31.67206420"
            },
            {
                "lon": "121.17372832",
                "lat": "31.69131253"
            },
            {
                "lon": "121.17840197",
                "lat": "31.69643670"
            },
            {
                "lon": "121.20519401",
                "lat": "31.67862867"
            },
            {
                "lon": "121.22409156",
                "lat": "31.66741097"
            },
            {
                "lon": "121.24677671",
                "lat": "31.66148312"
            },
            {
                "lon": "121.26217387",
                "lat": "31.65086214"
            },
            {
                "lon": "121.26560180",
                "lat": "31.64009700"
            }
        ]
    },
    {
        "id": "WH_25f7b4b17e7a479faa2fcd1f3d326ab3",
        "cjsj": "2019-12-10 09:46:47",
        "gxsj": "2020-12-25 14:39:51",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "五丈港锚地",
        "gldw": "鄂州市捷达港埠有限公司",
        "scsdId": "WH_6528c39b786b4506a793dbf16b88b8de",
        "sshdName": "巴河水道",
        "hdHdly": "下游",
        "hdlc": 942,
        "tyAnb": "右岸",
        "yt": "干散货停泊区",
        "jcsj": "2017-01-01",
        "x54": "",
        "zb2000": "",
        "x84": "114.937425,30.407072#114.948787,30.405591#114.949232,30.407082",
        "lhHdshyj": "",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "",
        "bz": "",
        "coordinates": [
            {
                "lon": "114.937425",
                "lat": "30.407072"
            },
            {
                "lon": "114.948787",
                "lat": "30.405591"
            },
            {
                "lon": "114.949232",
                "lat": "30.407082"
            }
        ]
    },
    {
        "id": "WH_b7d0e6d0770c4c3a8990baf4748adc5f",
        "cjsj": "2019-12-13 16:54:02",
        "gxsj": "2022-01-25 09:26:15",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "城陵矶新港口岸联检锚地",
        "gldw": "岳阳市口岸办",
        "scsdId": "WH_0123f9a11d2b409e9004bc4e47d76554",
        "sshdName": "仙峰水道",
        "hdHdly": "中游",
        "hdlc": 224,
        "tyAnb": "左岸",
        "yt": "散货船舶",
        "jcsj": "2019-10-19",
        "x54": "3263784,419805,38#3264714,420810,38#3263625,419998,38#3264851,420601,38",
        "zb2000": "3263731,419863,38#3264661,420868,38#3263572,420056,38#3264798,420659,38",
        "x84": "113.173664,29.488788#113.183959,29.497241#113.175666,29.487366#113.181794,29.498464",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 0,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.173664",
                "lat": "29.488788"
            },
            {
                "lon": "113.183959",
                "lat": "29.497241"
            },
            {
                "lon": "113.175666",
                "lat": "29.487366"
            },
            {
                "lon": "113.181794",
                "lat": "29.498464"
            }
        ]
    },
    {
        "id": "WH_3b161841c94a427fa0aa93045ac28491",
        "cjsj": "2019-12-13 17:04:08",
        "gxsj": "2022-01-25 09:48:10",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "岳化锚地",
        "gldw": "中石化巴陵分公司",
        "scsdId": "WH_52714bf6c14e413aa60054fc5bac564b",
        "sshdName": "道仁矶水道",
        "hdHdly": "中游",
        "hdlc": 220,
        "tyAnb": "左岸",
        "yt": "油驳停泊区",
        "jcsj": "2000-01-10",
        "x54": "3268012,422675,38#3267839,422944,38#3266957,421980,38#3266742,422292,38",
        "zb2000": "3267959,422733,38#3267786,423002,38#3266904,422038,38#3266689,422350,38",
        "x84": "113.202960,29.527108#113.205747,29.525564#113.195866,29.517548#113.199100,29.515628",
        "lhHdshyj": "1",
        "hxbz": 2,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.202960",
                "lat": "29.527108"
            },
            {
                "lon": "113.205747",
                "lat": "29.525564"
            },
            {
                "lon": "113.195866",
                "lat": "29.517548"
            },
            {
                "lon": "113.199100",
                "lat": "29.515628"
            }
        ]
    },
    {
        "id": "WH_a73de56006dd4879a1cd5d869e096dbd",
        "cjsj": "2020-05-27 09:00:53",
        "gxsj": "2020-06-03 15:47:20",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "护县洲锚地",
        "gldw": "嘉鱼县港航管理局",
        "scsdId": "WH_d6a2810078884fd3a2fb14303125b6d6",
        "sshdName": "嘉鱼水道",
        "hdHdly": "中游",
        "hdlc": 135,
        "tyAnb": "右岸",
        "yt": "散装货",
        "jcsj": "2019-01-01",
        "x54": "3317935,486511,37#3315749,485017,37#3315400,485127,37#3317808,486805,37",
        "zb2000": "3321321,776145,37#3319094,774707,37#3318748,774826,37#3321202,776443,37",
        "x84": "113.860832,29.979802#113.845383,29.960061#113.846527,29.956914#113.863884,29.978658",
        "lhHdshyj": "有",
        "hxbz": 0,
        "zyb": 6,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.860832",
                "lat": "29.979802"
            },
            {
                "lon": "113.845383",
                "lat": "29.960061"
            },
            {
                "lon": "113.846527",
                "lat": "29.956914"
            },
            {
                "lon": "113.863884",
                "lat": "29.978658"
            }
        ]
    },
    {
        "id": "WH_ba48b573cc2d476998b909e6dd0caa5c",
        "cjsj": "2020-05-27 09:00:53",
        "gxsj": "2020-06-03 15:47:20",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "李家矶锚地",
        "gldw": "阳逻航道处",
        "scsdId": "WH_f1103837f95d45ff95c465ac8d865855",
        "sshdName": "阳逻水道",
        "hdHdly": "下游",
        "hdlc": 1005,
        "tyAnb": "左岸",
        "yt": "散装货",
        "jcsj": "2019-01-01",
        "x54": "3386535,555638,38#3386459,555236,38#3385373,555836,38#3385428,556284,38",
        "zb2000": "3386483,555697,38#3386407,555295,38#3385321,555895,38#3385377,556343,38",
        "x84": "114.580761,30.597401#114.576565,30.596734#114.582764,30.586911#114.587437,30.587388",
        "lhHdshyj": "",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "114.580761",
                "lat": "30.597401"
            },
            {
                "lon": "114.576565",
                "lat": "30.596734"
            },
            {
                "lon": "114.582764",
                "lat": "30.586911"
            },
            {
                "lon": "114.587437",
                "lat": "30.587388"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7236336e017291e1a23b1bb3",
        "cjsj": "2020-06-08 11:59:13",
        "gxsj": "2020-12-18 10:10:50",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "武穴港务局锚地",
        "gldw": "武穴津鑫港务有限公司",
        "scsdId": "NJ_402881eb4dff264c014dff3aac2b0039",
        "sshdName": "武穴水道",
        "hdHdly": "下游",
        "hdlc": 834,
        "tyAnb": "左岸",
        "yt": "武穴港普通货船锚地",
        "jcsj": "2007-05-01",
        "x54": "3303790,366280,39#3303610,364800,39#3303990,366250,39#3303810,364780,39",
        "zb2000": "3303736,366345,39#3303556,364865,39#3303936,366315,39#3303756,364845,39",
        "x84": "115.61699223,29.84501831#115.60170399,29.84323363#115.61665700,29.84681880#115.60147194,29.84503517",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "115.61699223",
                "lat": "29.84501831"
            },
            {
                "lon": "115.60170399",
                "lat": "29.84323363"
            },
            {
                "lon": "115.61665700",
                "lat": "29.84681880"
            },
            {
                "lon": "115.60147194",
                "lat": "29.84503517"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b5b2976b1264",
        "cjsj": "2020-09-22 21:01:36",
        "gxsj": "2020-09-25 17:11:31",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "南钢锚地",
        "gldw": "南京钢铁股份有限公司",
        "scsdId": "NJ_402881e85142e48a015142e7f0e40002",
        "sshdName": "宝塔水道",
        "hdHdly": "下游",
        "hdlc": 339.5,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3562993,383465,40#3562963,383367,40#3562451,383649,40#3562392,383489,40",
        "zb2000": "3562942,383538,40#3562912,383440,40#3562400,383722,40#3562341,383562,40",
        "x84": "118.76507336,32.18415981#118.76403805,32.18387918#118.76708997,32.17929221#118.76540097,32.17874371",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.76507336",
                "lat": "32.18415981"
            },
            {
                "lon": "118.76403805",
                "lat": "32.18387918"
            },
            {
                "lon": "118.76708997",
                "lat": "32.17929221"
            },
            {
                "lon": "118.76540097",
                "lat": "32.17874371"
            }
        ]
    },
    {
        "id": "NJ_2c92402175f45aa201761c2c880713e1",
        "cjsj": "2020-12-01 12:02:14",
        "gxsj": "2021-08-05 11:02:00",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "天星洲海轮锚地",
        "gldw": "泰州市泰轮锚地服务有限公司",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7046f0048b",
        "sshdName": "泰兴水道",
        "hdHdly": "下游",
        "hdlc": 191,
        "tyAnb": "左岸",
        "yt": "船舶靠泊",
        "jcsj": "2020-11-07",
        "x54": "3549037,493907,40#3549458,493742,40#3551858,493033,40#3552799,492889,40#3552842,493265,40#3550553,493830,40#3549602,494323,40#3549167,493830,40",
        "zb2000": "3548987,493981,40#3549408,493816,40#3551808,493107,40#3552749,492963,40#3552792,493339,40#3550503,493904,40#3549552,494226,40#3549117,494397,40",
        "x84": "119.93625947,32.06432121#119.93450934,32.06811698#119.92698317,32.08975650#119.92545081,32.09824170#119.92943393,32.09863178#119.93543432,32.07799239#119.93885067,32.06941779#119.94066427,32.06549574",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 8,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.93625947",
                "lat": "32.06432121"
            },
            {
                "lon": "119.93450934",
                "lat": "32.06811698"
            },
            {
                "lon": "119.92698317",
                "lat": "32.08975650"
            },
            {
                "lon": "119.92545081",
                "lat": "32.09824170"
            },
            {
                "lon": "119.92943393",
                "lat": "32.09863178"
            },
            {
                "lon": "119.93543432",
                "lat": "32.07799239"
            },
            {
                "lon": "119.93885067",
                "lat": "32.06941779"
            },
            {
                "lon": "119.94066427",
                "lat": "32.06549574"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212b79fe50759",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "张家港危险品锚地",
        "gldw": "张家市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6c5e530481",
        "sshdName": "浏海沙水道",
        "hdHdly": "下游",
        "hdlc": 122,
        "tyAnb": "左岸",
        "yt": "供危险品船锚泊",
        "jcsj": "2020-05-01",
        "x54": "3544645.00 ,550685.00 ,40#3545130.00 ,550850.00 ,40#3543821.00 ,554760.00 ,40#3543350.00 ,554605.00 ,40",
        "zb2000": "3544595,550760,40#3545080,550925,40#3543771,554835,40#3543300,554680,40",
        "x84": "120.53731445,32.02359359#120.53908659,32.02795985#120.58040193,32.01597302#120.57873471,32.01173311",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.53731445",
                "lat": "32.02359359"
            },
            {
                "lon": "120.53908659",
                "lat": "32.02795985"
            },
            {
                "lon": "120.58040193",
                "lat": "32.01597302"
            },
            {
                "lon": "120.57873471",
                "lat": "32.01173311"
            }
        ]
    },
    {
        "id": "WH_ec48ebad226d4123b9e2291bb1c057dd",
        "cjsj": "2019-12-13 17:04:08",
        "gxsj": "2022-05-11 10:24:49",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "邹码头锚地",
        "gldw": "城陵矶港务局",
        "scsdId": "WH_8c27b7cfdd4e481aa0a0245fe8fc79db",
        "sshdName": "螺山水道-上",
        "hdHdly": "中游",
        "hdlc": 209,
        "tyAnb": "左岸",
        "yt": "空油驳区",
        "jcsj": "1990-12-22",
        "x54": "3275809,429279,38#3276752,429974,38#3276906,429704,38#3275868,428957,38",
        "zb2000": "3275756,429337,38#3276699,430032,38#3276853,429762,38#3275815,429015,38",
        "x84": "113.270573,29.597836#113.277686,29.606382#113.274889,29.607756#113.267246,29.598350",
        "lhHdshyj": "1",
        "hxbz": 2,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.270573",
                "lat": "29.597836"
            },
            {
                "lon": "113.277686",
                "lat": "29.606382"
            },
            {
                "lon": "113.274889",
                "lat": "29.607756"
            },
            {
                "lon": "113.267246",
                "lat": "29.598350"
            }
        ]
    },
    {
        "id": "WH_238e9c1ae1ef4d1a9e5cfcdfc0be9203",
        "cjsj": "2019-12-13 17:04:08",
        "gxsj": "2022-01-25 09:02:56",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "新港锚地",
        "gldw": "岳阳城陵矶港务有限责任公司",
        "scsdId": "WH_8c27b7cfdd4e481aa0a0245fe8fc79db",
        "sshdName": "螺山水道-上",
        "hdHdly": "中游",
        "hdlc": 205,
        "tyAnb": "右岸",
        "yt": "重油驳区",
        "jcsj": "1990-12-29",
        "x54": "3279353,432742,38#3278173,431817,38#3278341,431568,38#3279563,432437,38",
        "zb2000": "3279300,432800,38#3278120,431875,38#3278288,431626,38#3279510,432495,38",
        "x84": "113.306100,29.629998#113.296623,29.619303#113.294041,29.620805#113.302937,29.631876",
        "lhHdshyj": "1",
        "hxbz": 2,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.306100",
                "lat": "29.629998"
            },
            {
                "lon": "113.296623",
                "lat": "29.619303"
            },
            {
                "lon": "113.294041",
                "lat": "29.620805"
            },
            {
                "lon": "113.302937",
                "lat": "29.631876"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed90720172127b385f0743",
        "cjsj": "2020-05-14 17:59:04",
        "gxsj": "2021-09-30 12:10:26",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "常熟港下海轮锚地",
        "gldw": "常熟市港口管理处",
        "scsdId": "NJ_2c9240214e527d39014e626c6a27005b",
        "sshdName": "白茆沙水道",
        "hdHdly": "下游",
        "hdlc": 48,
        "tyAnb": "右岸",
        "yt": "供海轮锚泊",
        "jcsj": "2008-12-01",
        "x54": "3508846,611644,40#3508273,611207,40#3509597,609121,40#3513842,605136,40#3514226,605516,40#3510785,609132,40",
        "zb2000": "3508796,611720,40#3508223,611283,40#3509547,609197,40#3513792,605212,40#3514176,605592,40#3510735,609208,40",
        "x84": "121.17840197,31.69643670#121.17372832,31.69131253#121.15187774,31.70345138#121.11030377,31.74210115#121.11435477,31.74552868#121.15212614,31.71416241",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 6,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "121.17840197",
                "lat": "31.69643670"
            },
            {
                "lon": "121.17372832",
                "lat": "31.69131253"
            },
            {
                "lon": "121.15187774",
                "lat": "31.70345138"
            },
            {
                "lon": "121.11030377",
                "lat": "31.74210115"
            },
            {
                "lon": "121.11435477",
                "lat": "31.74552868"
            },
            {
                "lon": "121.15212614",
                "lat": "31.71416241"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74521f7b01746c9fb9b20af3",
        "cjsj": "2020-09-08 15:59:44",
        "gxsj": "2021-08-05 12:01:41",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "常州港海轮锚地",
        "gldw": "常州港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7046f0048b",
        "sshdName": "泰兴水道",
        "hdHdly": "下游",
        "hdlc": 188.37,
        "tyAnb": "左岸",
        "yt": "海轮锚泊",
        "jcsj": "2008-01-01",
        "x54": "3546398,494989,40#3548580,494017,40#3548687,494632,40#3546750,495378,40",
        "zb2000": "3546348,495063,40#3548530,494091,40#3548637,494706,40#3546700,495452,40",
        "x84": "119.94773185,32.04052739#119.93742723,32.06020047#119.94393938,32.06116850#119.95184872,32.04370346",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.94773185",
                "lat": "32.04052739"
            },
            {
                "lon": "119.93742723",
                "lat": "32.06020047"
            },
            {
                "lon": "119.94393938",
                "lat": "32.06116850"
            },
            {
                "lon": "119.95184872",
                "lat": "32.04370346"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74521f7b01746ca1fda70af4",
        "cjsj": "2020-09-08 15:59:44",
        "gxsj": "2021-08-05 11:02:00",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "常州港危险品锚地",
        "gldw": "常州港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7046f0048b",
        "sshdName": "泰兴水道",
        "hdHdly": "下游",
        "hdlc": 186.35,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "2008-01-01",
        "x54": "3544714,495970,40#3545936,495231,40#3546240,495720,40#3544923,496395,40",
        "zb2000": "3544664,496044,40#3545886,495305,40#3546190,495794,40#3544873,496469,40",
        "x84": "119.95812525,32.02534454#119.95029631,32.03636200#119.95547205,32.03910550#119.96262344,32.02723079",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 3,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.95812525",
                "lat": "32.02534454"
            },
            {
                "lon": "119.95029631",
                "lat": "32.03636200"
            },
            {
                "lon": "119.95547205",
                "lat": "32.03910550"
            },
            {
                "lon": "119.96262344",
                "lat": "32.02723079"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212b39eb00755",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "南通港＃2甲锚地",
        "gldw": "南通市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6b9268047f",
        "sshdName": "南通水道",
        "hdHdly": "下游",
        "hdlc": 95,
        "tyAnb": "右岸",
        "yt": "供长江驳船锚泊",
        "jcsj": "2013-01-01",
        "x54": "3538926,578641,40#3539477,578891,40#3541170,577429,40#3542066,575998,40#3542607,573504,40#3541635,572363,40",
        "zb2000": "3538876,578716,40#3539427,578966,40#3541120,577504,40#3542016,576073,40#3542557,573579,40#3541585,572438,40",
        "x84": "120.83275608,31.97042576#120.83544566,31.97537697#120.82011490,31.99074424#120.80504434,31.99892074#120.77869459,32.00396379#120.76654720,31.99527226",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 7,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.83275608",
                "lat": "31.97042576"
            },
            {
                "lon": "120.83544566",
                "lat": "31.97537697"
            },
            {
                "lon": "120.82011490",
                "lat": "31.99074424"
            },
            {
                "lon": "120.80504434",
                "lat": "31.99892074"
            },
            {
                "lon": "120.77869459",
                "lat": "32.00396379"
            },
            {
                "lon": "120.76654720",
                "lat": "31.99527226"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212b2d6dc0754",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "南通联检锚地",
        "gldw": "南通市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa68ed96047d",
        "sshdName": "通州沙东水道",
        "hdHdly": "下游",
        "hdlc": 87,
        "tyAnb": "左岸",
        "yt": "供海轮检疫检验锚泊",
        "jcsj": "2012-03-30",
        "x54": "3532693,584198,40#3532466,583642,40#3535531,582392,40#3535801,583054,40#3535050,583517,40#3534254,583841,40",
        "zb2000": "3532643,584273,40#3532416,583717,40#3535481,582467,40#3535751,583129,40#3535000,583592,40#3534204,583916,40",
        "x84": "120.89099746,31.91382193#120.88509998,31.91181604#120.87214620,31.93954559#120.87916984,31.94193203#120.88400127,31.93512605#120.88735840,31.92792439",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 3,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.89099746",
                "lat": "31.91382193"
            },
            {
                "lon": "120.88509998",
                "lat": "31.91181604"
            },
            {
                "lon": "120.87214620",
                "lat": "31.93954559"
            },
            {
                "lon": "120.87916984",
                "lat": "31.94193203"
            },
            {
                "lon": "120.88400127",
                "lat": "31.93512605"
            },
            {
                "lon": "120.88735840",
                "lat": "31.92792439"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212b4ef990758",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "NO.8锚地",
        "gldw": "张家港市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6b9268047f",
        "sshdName": "南通水道",
        "hdHdly": "下游",
        "hdlc": 105,
        "tyAnb": "后岸",
        "yt": "供海轮锚泊",
        "jcsj": "2014-05-10",
        "x54": "3542809,571988,40#3541872,571297,40#3541430,569266,40#3542311,569107,40",
        "zb2000": "3542759,572063,40#3541822,571372,40#3541380,569341,40#3542261,569182,40",
        "x84": "120.76266674,32.00588271#120.75528490,31.99747701#120.73376170,31.99361726#120.73214235,32.00157146",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.76266674",
                "lat": "32.00588271"
            },
            {
                "lon": "120.75528490",
                "lat": "31.99747701"
            },
            {
                "lon": "120.73376170",
                "lat": "31.99361726"
            },
            {
                "lon": "120.73214235",
                "lat": "32.00157146"
            }
        ]
    },
    {
        "id": "WH_b66f9d4f4bfc40f4a3607ba21c60d17a",
        "cjsj": "2020-06-02 12:11:14",
        "gxsj": "2020-06-03 15:47:20",
        "sjly": "1",
        "scbj": 0,
        "mdmc": "王家屋锚地",
        "gldw": "武汉港务集团",
        "scsdId": "WH_f1103837f95d45ff95c465ac8d865855",
        "sshdName": "阳逻水道",
        "hdHdly": "下游",
        "hdlc": 1020,
        "tyAnb": "右岸",
        "yt": "",
        "jcsj": "1950-01-01",
        "x54": "3395746,547137,38#3395754,548929,38#3394787,550212,38#3395078,549005,38",
        "zb2000": "3395694,547196,38#3395703,548987,38#3394736,550271,38#3395026,549064,38",
        "x84": "114.492545,30.680848#114.511237,30.680848#114.524588,30.672074#114.512000,30.674744",
        "lhHdshyj": "",
        "hxbz": 2,
        "zyb": 0,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "114.492545",
                "lat": "30.680848"
            },
            {
                "lon": "114.511237",
                "lat": "30.680848"
            },
            {
                "lon": "114.524588",
                "lat": "30.672074"
            },
            {
                "lon": "114.512000",
                "lat": "30.674744"
            }
        ]
    },
    {
        "id": "NJ_2c92402174294d7f017433c43b630562",
        "cjsj": "2020-08-28 15:08:01",
        "gxsj": "2020-08-28 15:08:01",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "马鞍山郑蒲锚地",
        "gldw": "马鞍山市港航管理局",
        "scsdId": "NJ_402881eb4dff264c014dff394d0d002c",
        "sshdName": "江心洲水道",
        "hdHdly": "下游",
        "hdlc": 418.4,
        "tyAnb": "左岸",
        "yt": "公用普货",
        "jcsj": "2017-09-30",
        "x54": "3493495,631269,39#3493388,631456,39#3491773,630845,39#3491884,630658,39",
        "zb2000": "3493445,631337,39#3493338,631524,39#3491723,630913,39#3491834,630726,39",
        "x84": "118.38322023,31.55595459#118.38517497,31.55496854#118.37852756,31.54047666#118.37657357,31.54149868",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.38322023",
                "lat": "31.55595459"
            },
            {
                "lon": "118.38517497",
                "lat": "31.55496854"
            },
            {
                "lon": "118.37852756",
                "lat": "31.54047666"
            },
            {
                "lon": "118.37657357",
                "lat": "31.54149868"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe71913a4801722a74c2cd1970",
        "cjsj": "2020-05-19 09:59:06",
        "gxsj": "2021-01-25 16:09:41",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "南京港联检锚地",
        "gldw": "南京港务管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa78732a049e",
        "sshdName": "仪征水道(上段)",
        "hdHdly": "下游",
        "hdlc": 296,
        "tyAnb": "右岸",
        "yt": "停靠船舶",
        "jcsj": "2011-09-15",
        "x54": "3568344,421613,40#3568745,421785,40#3567085,424544,40#3567516,424729,40#3568245,423286,40",
        "zb2000": "3568293,421686,40#3568694,421858,40#3567034,424617,40#3567465,424802,40#3568194,423359,40",
        "x84": "119.16910254,32.23571457#119.17089443,32.23934239#119.20029722,32.22456281#119.20222569,32.22846160#119.18685943,32.23493742",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.16910254",
                "lat": "32.23571457"
            },
            {
                "lon": "119.17089443",
                "lat": "32.23934239"
            },
            {
                "lon": "119.20029722",
                "lat": "32.22456281"
            },
            {
                "lon": "119.20222569",
                "lat": "32.22846160"
            },
            {
                "lon": "119.18685943",
                "lat": "32.23493742"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe76e08c320176f47985470937",
        "cjsj": "2021-01-12 11:02:36",
        "gxsj": "2021-08-05 15:01:18",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "定易洲锚地",
        "gldw": "镇江市港航事业发展中心",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7781b6049c",
        "sshdName": "焦山水道",
        "hdHdly": "下游",
        "hdlc": 265,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "2020-12-24",
        "x54": "3570889,452383,40#3571147,451443,40#3571213,449587,40#3570801,447596,40#3570246,446309,40#3569891,445123,40#3570057,445038,40#3570630,446191,40#3571137,447534,40#3571588,449524,40#3571504,451268,40#3571050,452461,40",
        "zb2000": "3570839,452457,40#3571097,451517,40#3571163,449661,40#3570751,447670,40#3570196,446382,40#3569841,445196,40#3570007,445111,40#3570580,446264,40#3571087,447608,40#3571538,449598,40#3571454,451342,40#3571000,452535,40",
        "x84": "119.49543000,32.26039062#119.48544103,32.26267687#119.46574015,32.26319017#119.44463262,32.25938372#119.43100575,32.25431817#119.41844063,32.25105959#119.41752910,32.25255234#119.42973189,32.25777523#119.44395616,32.26241071#119.46505168,32.26656891#119.48356553,32.26588855#119.49624975,32.26184576",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 12,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.49543000",
                "lat": "32.26039062"
            },
            {
                "lon": "119.48544103",
                "lat": "32.26267687"
            },
            {
                "lon": "119.46574015",
                "lat": "32.26319017"
            },
            {
                "lon": "119.44463262",
                "lat": "32.25938372"
            },
            {
                "lon": "119.43100575",
                "lat": "32.25431817"
            },
            {
                "lon": "119.41844063",
                "lat": "32.25105959"
            },
            {
                "lon": "119.41752910",
                "lat": "32.25255234"
            },
            {
                "lon": "119.42973189",
                "lat": "32.25777523"
            },
            {
                "lon": "119.44395616",
                "lat": "32.26241071"
            },
            {
                "lon": "119.46505168",
                "lat": "32.26656891"
            },
            {
                "lon": "119.48356553",
                "lat": "32.26588855"
            },
            {
                "lon": "119.49624975",
                "lat": "32.26184576"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7acd698b017ae0cb1c570974",
        "cjsj": "2021-07-26 12:00:31",
        "gxsj": "2021-08-06 10:10:34",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "高资锚地",
        "gldw": "镇江市港航事业发展中心",
        "scsdId": "NJ_402881ed5142f0a4015142f27be90000",
        "sshdName": "仪征水道(下段)",
        "hdHdly": "下游",
        "hdlc": 287,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "2021-05-27",
        "x54": "3563795,433420,40#3563788,432842,40#3564144,431714,40#3565236,430202,40#3565553,430409,40#3564614,431802,40#3564270,432699,40#3564086,433494,40",
        "zb2000": "3563744,433493,40#3563737,432915,40#3564093,431787,40#3565185,430275,40#3565502,430482,40#3564563,431875,40#3564219,432772,40#3564035,433567,40",
        "x84": "119.29468261,32.19545690#119.28855361,32.19535941#119.27656619,32.19850166#119.26045180,32.20825569#119.26262417,32.21112699#119.27746591,32.20274513#119.28700323,32.19969722#119.29544711,32.19808532",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 7,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.29468261",
                "lat": "32.19545690"
            },
            {
                "lon": "119.28855361",
                "lat": "32.19535941"
            },
            {
                "lon": "119.27656619",
                "lat": "32.19850166"
            },
            {
                "lon": "119.26045180",
                "lat": "32.20825569"
            },
            {
                "lon": "119.26262417",
                "lat": "32.21112699"
            },
            {
                "lon": "119.27746591",
                "lat": "32.20274513"
            },
            {
                "lon": "119.28700323",
                "lat": "32.19969722"
            },
            {
                "lon": "119.29544711",
                "lat": "32.19808532"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7acd698b017b14f337d51876",
        "cjsj": "2021-08-05 15:01:18",
        "gxsj": "2021-08-05 15:01:18",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "镇江港危险品锚地",
        "gldw": "镇江市口岸与港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa70edbf048d",
        "sshdName": "口岸直水道",
        "hdHdly": "下游",
        "hdlc": 230,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "2015-10-13",
        "x54": "3576996,476270,40#3575882,474638,40#3576317,474333,40#3577325,476042,40",
        "zb2000": "3576946,476344,40#3575832,474712,40#3576267,474407,40#3577275,476116,40",
        "x84": "119.74878613,32.31622173#119.73148496,32.30614018#119.72823468,32.31005596#119.74635661,32.31918372",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 3,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.74878613",
                "lat": "32.31622173"
            },
            {
                "lon": "119.73148496",
                "lat": "32.30614018"
            },
            {
                "lon": "119.72823468",
                "lat": "32.31005596"
            },
            {
                "lon": "119.74635661",
                "lat": "32.31918372"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7acd698b017b14fda9ab1877",
        "cjsj": "2021-08-05 15:01:18",
        "gxsj": "2021-08-05 15:01:18",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "镇江港海轮联检锚地",
        "gldw": "镇江市港航事业发展中心",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa70edbf048d",
        "sshdName": "口岸直水道",
        "hdHdly": "下游",
        "hdlc": 235.6,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "2016-05-17",
        "x54": "3573145,472410,40#3571414,471418,40#3571056,471880,40#3572850,472935,40",
        "zb2000": "3573095,472484,40#3571364,471492,40#3571006,471954,40#3572800,473009,40",
        "x84": "119.70790692,32.28140588#119.69742844,32.26577124#119.70234243,32.26255454#119.71348834,32.27875843",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 3,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.70790692",
                "lat": "32.28140588"
            },
            {
                "lon": "119.69742844",
                "lat": "32.26577124"
            },
            {
                "lon": "119.70234243",
                "lat": "32.26255454"
            },
            {
                "lon": "119.71348834",
                "lat": "32.27875843"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7acd698b017b150696d41884",
        "cjsj": "2021-08-05 15:00:29",
        "gxsj": "2021-08-06 16:02:50",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "泰州港海轮联检锚地",
        "gldw": "江苏省泰州市港务管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7046f0048b",
        "sshdName": "泰兴水道",
        "hdHdly": "下游",
        "hdlc": 195.6,
        "tyAnb": "不区分",
        "yt": "锚泊",
        "jcsj": "2006-12-29",
        "x54": "3557641,491592,40#3553319,491592,40#3553503,492072,40#3557871,490997,40",
        "zb2000": "3557591,490273,40#3553269,491666,40#3553453,492146,40#3557821,491071,40",
        "x84": "119.89690306,32.14188735#119.91170558,32.10292225#119.91678955,32.10458505#119.90535910,32.14396816",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.89690306",
                "lat": "32.14188735"
            },
            {
                "lon": "119.91170558",
                "lat": "32.10292225"
            },
            {
                "lon": "119.91678955",
                "lat": "32.10458505"
            },
            {
                "lon": "119.90535910",
                "lat": "32.14396816"
            }
        ]
    },
    {
        "id": "07bec7f760764c72aae5110f05b33118",
        "cjsj": "2022-01-24 20:13:22",
        "gxsj": "2022-01-25 09:23:42",
        "sjly": "0",
        "scbj": 0,
        "mdmc": "城陵矶新增锚地",
        "gldw": "岳阳城陵矶港务有限责任公司",
        "scsdId": "WH_b9d8f72a4cfc493494be328049db9c49",
        "sshdName": "洞庭湖口",
        "hdHdly": "中游",
        "hdlc": 230,
        "tyAnb": "左岸",
        "yt": "散货船舶停泊区",
        "jcsj": "2016-12-23",
        "x54": "3258611,416044,38#3258546,416188,38#3259258,416478,38#3259332,416336,38",
        "zb2000": "3258558,416102,38#3258493,416246,38#3259205,416536,38#3259279,416394,38",
        "x84": "113.135282,29.441878#113.136771,29.441301#113.139705,29.447743#113.138236,29.448401",
        "lhHdshyj": "3",
        "hxbz": 0,
        "zyb": 1,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.135282",
                "lat": "29.441878"
            },
            {
                "lon": "113.136771",
                "lat": "29.441301"
            },
            {
                "lon": "113.139705",
                "lat": "29.447743"
            },
            {
                "lon": "113.138236",
                "lat": "29.448401"
            }
        ]
    },
    {
        "id": "2738b158f2c84fe79ad41ee12ac8e02b",
        "cjsj": "2022-01-24 20:22:28",
        "gxsj": "2022-01-25 09:21:32",
        "sjly": "0",
        "scbj": 0,
        "mdmc": "城陵矶联检锚地",
        "gldw": "岳阳城陵矶港务有限责任公司",
        "scsdId": "WH_b9d8f72a4cfc493494be328049db9c49",
        "sshdName": "洞庭湖口",
        "hdHdly": "中游",
        "hdlc": 230,
        "tyAnb": "左岸",
        "yt": "普货待泊锚地",
        "jcsj": "1996-11-18",
        "x54": "3258337,415905,38#3258595,416048,38#3258529,416185,38#3258258,416045,38",
        "zb2000": "3258284,415963,38#3258542,416106,38#3258476,416243,38#3258205,416103,38",
        "x84": "113.133870,29.439397#113.135324,29.441734#113.136741,29.441147#113.135319,29.438693",
        "lhHdshyj": "3",
        "hxbz": 0,
        "zyb": 1,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "113.133870",
                "lat": "29.439397"
            },
            {
                "lon": "113.135324",
                "lat": "29.441734"
            },
            {
                "lon": "113.136741",
                "lat": "29.441147"
            },
            {
                "lon": "113.135319",
                "lat": "29.438693"
            }
        ]
    },
    {
        "id": "LZ_ff8080816d7090eb016d70c808a80003",
        "cjsj": "2019-09-27 17:47:31",
        "gxsj": "2022-06-29 15:07:10",
        "sjly": "3",
        "scbj": 0,
        "mdmc": "黄舣沱锚地",
        "gldw": "长江南京油运责任有限公司泸州分公司",
        "scsdId": "LZ_c53d0744e7114ea4a65bd3f58de7d274",
        "sshdName": "泰安水道（江阳处）",
        "hdHdly": "上游",
        "hdlc": 897.15,
        "tyAnb": "右岸",
        "yt": "危化品专用锚地",
        "jcsj": "2010-06-01",
        "x54": "3198031,554416,35#3198296,554627,35#3198187,554753,35#3197925,554518,35",
        "zb2000": "3197980,554448,35#3198245,554660,35#3198136,554786,35#3197874,554551,35",
        "x84": "105.558235,28.896986#105.560419,28.899365#105.561702,28.898380#105.559285,28.896028",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "105.558235",
                "lat": "28.896986"
            },
            {
                "lon": "105.560419",
                "lat": "28.899365"
            },
            {
                "lon": "105.561702",
                "lat": "28.898380"
            },
            {
                "lon": "105.559285",
                "lat": "28.896028"
            }
        ]
    },
    {
        "id": "LZ_ff8080816d7090eb016d70cb3e070004",
        "cjsj": "2019-09-27 17:47:31",
        "gxsj": "2022-06-30 12:05:04",
        "sjly": "3",
        "scbj": 0,
        "mdmc": "黄舣沱锚地",
        "gldw": "泸州江渝水上救助有限责任公司",
        "scsdId": "LZ_c53d0744e7114ea4a65bd3f58de7d274",
        "sshdName": "泰安水道（江阳处）",
        "hdHdly": "上游",
        "hdlc": 897.59,
        "tyAnb": "右岸",
        "yt": "普通船舶锚地",
        "jcsj": "2016-11-01",
        "x54": "3198039,554398,35#3198279,554651,35#3198179,554758,35#3197920,554508,35",
        "zb2000": "3197988,554431,35#3198228,554684,35#3198128,554791,35#3197869,554540,35",
        "x84": "105.558058,28.897060#105.560661,28.899217#105.561758,28.898306#105.559174,28.895982",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "105.558058",
                "lat": "28.897060"
            },
            {
                "lon": "105.560661",
                "lat": "28.899217"
            },
            {
                "lon": "105.561758",
                "lat": "28.898306"
            },
            {
                "lon": "105.559174",
                "lat": "28.895982"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7e053110017e6b28018568be",
        "cjsj": "2022-01-18 12:04:40",
        "gxsj": "2022-01-18 12:04:40",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "南京仪化锚地",
        "gldw": "南京市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7a70ef04a2",
        "sshdName": "龙潭水道",
        "hdHdly": "下游",
        "hdlc": 309.6,
        "tyAnb": "左岸",
        "yt": "仪化码头到港船舶锚泊",
        "jcsj": "1991-01-01",
        "x54": "",
        "zb2000": "",
        "x84": "",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": []
    },
    {
        "id": "NJ_2c9240fe7236336e017297236c8c1d82",
        "cjsj": "2020-06-09 11:59:13",
        "gxsj": "2022-06-24 10:49:25",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "九江港湖口港区永和洲锚地",
        "gldw": "九江市港口管理局",
        "scsdId": "NJ_402881eb4dff264c014dff381efc001f",
        "sshdName": "湖口水道",
        "hdHdly": "下游",
        "hdlc": 756,
        "tyAnb": "左岸",
        "yt": "危化品船舶锚泊区",
        "jcsj": "2018-01-01",
        "x54": "3300256,433930,39#3300070,433634,39#3300446,433810,39#3300260,433514,39",
        "zb2000": "3300203,433996,39#3300017,433700,39#3300393,433876,39#3300207,433580,39",
        "x84": "116.31717137,29.81861839#116.31412077,29.81692467#116.31591831,29.82032590#116.31286769,29.81863215",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "116.31717137",
                "lat": "29.81861839"
            },
            {
                "lon": "116.31412077",
                "lat": "29.81692467"
            },
            {
                "lon": "116.31591831",
                "lat": "29.82032590"
            },
            {
                "lon": "116.31286769",
                "lat": "29.81863215"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7236336e0172972bf0881d8c",
        "cjsj": "2020-06-09 11:59:13",
        "gxsj": "2022-02-28 09:36:02",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "九江新油锚锚地",
        "gldw": "上港集团九江港务有限公司",
        "scsdId": "NJ_402881eb4dff264c014dff3880410023",
        "sshdName": "张家洲南水道",
        "hdHdly": "下游",
        "hdlc": 780,
        "tyAnb": "右岸",
        "yt": "对外船舶安全停泊",
        "jcsj": "2005-12-25",
        "x54": "3294230,413475,39#3293922,413460,39#3293930,412925,39#3294230,412775,39#3294156,412157,39",
        "zb2000": "3294176,413541,39#3293868,413526,39#3293876,412991,39#3294176,412841,39#3294102,412223,39",
        "x84": "116.10605850,29.76299821#116.10592811,29.76021890#116.10039649,29.76025354#116.09882152,29.76294907#116.09243834,29.76223786",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "116.10605850",
                "lat": "29.76299821"
            },
            {
                "lon": "116.10592811",
                "lat": "29.76021890"
            },
            {
                "lon": "116.10039649",
                "lat": "29.76025354"
            },
            {
                "lon": "116.09882152",
                "lat": "29.76294907"
            },
            {
                "lon": "116.09243834",
                "lat": "29.76223786"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe7236336e0172973035aa1d93",
        "cjsj": "2020-06-09 11:59:13",
        "gxsj": "2022-02-28 09:35:15",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "九江姚港锚地",
        "gldw": "上港集团九江港务有限公司",
        "scsdId": "NJ_402881eb4dff264c014dff39dbda0031",
        "sshdName": "九江水道",
        "hdHdly": "下游",
        "hdlc": 797,
        "tyAnb": "右岸",
        "yt": "对外船舶安全停泊",
        "jcsj": "2005-12-25",
        "x54": "3289484,398303,39#3289327,396668,39#3289262,398294,39#3289107,396635,39",
        "zb2000": "3289430,398369,39#3289273,396734,39#3289208,398360,39#3289053,396701,39",
        "x84": "115.94965290,29.71903582#115.93277257,29.71748450#115.94958076,29.71703265#115.93245258,29.71549736",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "115.94965290",
                "lat": "29.71903582"
            },
            {
                "lon": "115.93277257",
                "lat": "29.71748450"
            },
            {
                "lon": "115.94958076",
                "lat": "29.71703265"
            },
            {
                "lon": "115.93245258",
                "lat": "29.71549736"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b5afbd501263",
        "cjsj": "2020-09-22 21:01:36",
        "gxsj": "2020-11-27 12:20:53",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "新生圩锚地",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7a70ef04a2",
        "sshdName": "龙潭水道",
        "hdHdly": "下游",
        "hdlc": 330.7,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3561882.7,391576.5,40#3561733,391660,40#3562691,392952.4,40#3562544.6,393045.5,40",
        "zb2000": "3561832,391649,40#3561682,391733,40#3562640,393025,40#3562494,393118,40",
        "x84": "118.85119640,32.17495997#118.85209852,32.17361828#118.86569189,32.18237952#118.86669528,32.18106839",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 0,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.85119640",
                "lat": "32.17495997"
            },
            {
                "lon": "118.85209852",
                "lat": "32.17361828"
            },
            {
                "lon": "118.86569189",
                "lat": "32.18237952"
            },
            {
                "lon": "118.86669528",
                "lat": "32.18106839"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212bb51df075a",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 13:09:44",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "＃12海轮锚地",
        "gldw": "靖江市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6f39b60487",
        "sshdName": "福姜沙北水道",
        "hdHdly": "下游",
        "hdlc": 130.2,
        "tyAnb": "右岸",
        "yt": "供海轮锚泊",
        "jcsj": "2007-01-01",
        "x54": "3549306,547585,40#3549806,547581,40#3549729,545880,40#3549243,543789,40#3548765,543960,40#3549241,545988,40",
        "zb2000": "3549256,547660,40#3549756,547656,40#3549679,545955,40#3549193,543864,40#3548715,544035,40#3549191,546063,40",
        "x84": "120.50473074,32.06576064#120.50471311,32.07026974#120.48669484,32.06964574#120.46452786,32.06534605#120.46631691,32.06102884#120.48781528,32.06524062",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.50473074",
                "lat": "32.06576064"
            },
            {
                "lon": "120.50471311",
                "lat": "32.07026974"
            },
            {
                "lon": "120.48669484",
                "lat": "32.06964574"
            },
            {
                "lon": "120.46452786",
                "lat": "32.06534605"
            },
            {
                "lon": "120.46631691",
                "lat": "32.06102884"
            },
            {
                "lon": "120.48781528",
                "lat": "32.06524062"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212bd1440075d",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 11:07:16",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "福中锚地",
        "gldw": "张家港市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6db1af0483",
        "sshdName": "福姜沙南水道",
        "hdHdly": "下游",
        "hdlc": 146,
        "tyAnb": "不区分",
        "yt": "供长江船舶锚泊",
        "jcsj": "2018-12-01",
        "x54": "3539268,532381,40#3538896,532715,40#3537952,531503,40#3538270,531274,40",
        "zb2000": "3539218,532456,40#3538846,532790,40#3537902,531578,40#3538220,531349,40",
        "x84": "120.34337997,31.97577500#120.34690107,31.97241064#120.33404788,31.96393180#120.33163567,31.96680593",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.34337997",
                "lat": "31.97577500"
            },
            {
                "lon": "120.34690107",
                "lat": "31.97241064"
            },
            {
                "lon": "120.33404788",
                "lat": "31.96393180"
            },
            {
                "lon": "120.33163567",
                "lat": "31.96680593"
            }
        ]
    },
    {
        "id": "NJ_2c92402171ed9072017212bf354a0760",
        "cjsj": "2020-05-14 18:59:04",
        "gxsj": "2020-09-28 12:09:52",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "蟛蜞锚地",
        "gldw": "靖江市交通运输局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6f39b60487",
        "sshdName": "福姜沙北水道",
        "hdHdly": "下游",
        "hdlc": 146.5,
        "tyAnb": "左岸",
        "yt": "供海轮锚泊",
        "jcsj": "2019-04-01",
        "x54": "3540690,532531,40#3540019,531724,40#3540407,531393,40#3541077,532199,40",
        "zb2000": "3540640,532606,40#3539969,531799,40#3540357,531468,40#3541027,532274,40",
        "x84": "120.34501487,31.98859455#120.33645362,31.98256624#120.33296402,31.98607451#120.34151475,31.99209406",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 3,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.34501487",
                "lat": "31.98859455"
            },
            {
                "lon": "120.33645362",
                "lat": "31.98256624"
            },
            {
                "lon": "120.33296402",
                "lat": "31.98607451"
            },
            {
                "lon": "120.34151475",
                "lat": "31.99209406"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b587fa081213",
        "cjsj": "2020-09-22 20:01:36",
        "gxsj": "2020-09-22 21:02:31",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "仪征油轮锚地",
        "gldw": "南京港务管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa78732a049e",
        "sshdName": "仪征水道(上段)",
        "hdHdly": "下游",
        "hdlc": 301.5,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3569430,416810,40#3569350,418710,40#3569140,420500,40#3568740,420480,40#3569000,418700,40#3569200,416780,40",
        "zb2000": "3569379,416883,40#3569299,418783,40#3569089,420573,40#3568689,420553,40#3568949,418773,40#3569149,416853,40",
        "x84": "119.11805320,32.24516139#119.13821903,32.24457923#119.15722842,32.24281385#119.15704955,32.23920564#119.13814275,32.24142258#119.11775496,32.24308528",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 6,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.11805320",
                "lat": "32.24516139"
            },
            {
                "lon": "119.13821903",
                "lat": "32.24457923"
            },
            {
                "lon": "119.15722842",
                "lat": "32.24281385"
            },
            {
                "lon": "119.15704955",
                "lat": "32.23920564"
            },
            {
                "lon": "119.13814275",
                "lat": "32.24142258"
            },
            {
                "lon": "119.11775496",
                "lat": "32.24308528"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b58998851218",
        "cjsj": "2020-09-22 20:01:36",
        "gxsj": "2022-06-22 11:09:15",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "上元门锚地",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaab12e340513",
        "sshdName": "南京大桥水道",
        "hdHdly": "下游",
        "hdlc": 342.4,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3556075,383350,40#3556834,384875,40#3556438,383168,40#3557190,384690,40",
        "zb2000": "3556024,383423,40#3556783,384948,40#3556387,383241,40#3557139,384763,40",
        "x84": "118.76469621,32.12177531#118.78076177,32.12877520#118.76272383,32.12502928#118.77875885,32.13196602",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 1,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.76469621",
                "lat": "32.12177531"
            },
            {
                "lon": "118.78076177",
                "lat": "32.12877520"
            },
            {
                "lon": "118.76272383",
                "lat": "32.12502928"
            },
            {
                "lon": "118.77875885",
                "lat": "32.13196602"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b58c8ba61219",
        "cjsj": "2020-09-22 20:11:59",
        "gxsj": "2022-06-22 11:10:59",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "扬子锚地",
        "gldw": "南京扬子物流有限责任公司",
        "scsdId": "NJ_402881e85142e48a015142e7f0e40002",
        "sshdName": "宝塔水道",
        "hdHdly": "下游",
        "hdlc": 335.1,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3568045,388634,40#3568240,388580,40#3567941,387268,40#3567835,387270,40",
        "zb2000": "3567994,388707,40#3568189,388653,40#3567890,387341,40#3567784,387343,40",
        "x84": "118.81928550,32.23023259#118.81868995,32.23198537#118.80480834,32.22915866#118.80484207,32.22820316",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.81928550",
                "lat": "32.23023259"
            },
            {
                "lon": "118.81868995",
                "lat": "32.23198537"
            },
            {
                "lon": "118.80480834",
                "lat": "32.22915866"
            },
            {
                "lon": "118.80484207",
                "lat": "32.22820316"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b590aebc121a",
        "cjsj": "2020-09-22 20:11:59",
        "gxsj": "2022-06-22 11:07:43",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "梅子洲锚地（中）",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaab2be5a051b",
        "sshdName": "南京水道",
        "hdHdly": "下游",
        "hdlc": 353.4,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3546058,375685,40#3547778,376624,40#3546145,375509,40#3547890,376436,40",
        "zb2000": "3546007,375758,40#3547727,376697,40#3546094,375582,40#3547839,376509,40",
        "x84": "118.68478571,32.03064513#118.69450359,32.04625523#118.68291176,32.03141017#118.69249916,32.04724449",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 1,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.68478571",
                "lat": "32.03064513"
            },
            {
                "lon": "118.69450359",
                "lat": "32.04625523"
            },
            {
                "lon": "118.68291176",
                "lat": "32.03141017"
            },
            {
                "lon": "118.69249916",
                "lat": "32.04724449"
            }
        ]
    },
    {
        "id": "NJ_2c92402172362a80017273f20018176f",
        "cjsj": "2020-06-02 15:59:11",
        "gxsj": "2022-02-28 09:32:13",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "九江港湖口港区永和洲锚地",
        "gldw": "九江市港口管理局",
        "scsdId": "NJ_402881eb4dff264c014dff381efc001f",
        "sshdName": "湖口水道",
        "hdHdly": "下游",
        "hdlc": 757,
        "tyAnb": "左岸",
        "yt": "普通货船舶锚泊区",
        "jcsj": "2018-01-01",
        "x54": "3299961,433464,39#3299110,432110,39#3300152,433345,39#3299301,431991,39",
        "zb2000": "3299908,433530,39#3299057,432176,39#3300099,433411,39#3299248,432057,39",
        "x84": "116.31236892,29.81593226#116.29841612,29.80818196#116.31112610,29.81764880#116.29717316,29.80989837",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "116.31236892",
                "lat": "29.81593226"
            },
            {
                "lon": "116.29841612",
                "lat": "29.80818196"
            },
            {
                "lon": "116.31112610",
                "lat": "29.81764880"
            },
            {
                "lon": "116.29717316",
                "lat": "29.80989837"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe746ce54c0174905e86c91efd",
        "cjsj": "2020-09-15 15:01:32",
        "gxsj": "2021-08-05 11:02:00",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "江阴港江轮锚地",
        "gldw": "江阴市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa6fd9de0489",
        "sshdName": "江阴水道(上段)",
        "hdHdly": "下游",
        "hdlc": 159.2,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "2005-01-01",
        "x54": "3534831,517445,40#3535229,514295,40#3535932,514394,40#3535484,517618,40#,,40",
        "zb2000": "3534781,517520,40#3535179,514369,40#3535882,514468,40#3535434,517693,40#,,40",
        "x84": "120.18527660,31.93608911#120.15196956,31.93972249#120.15302705,31.94606115#120.18711806,31.94197542#,",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "120.18527660",
                "lat": "31.93608911"
            },
            {
                "lon": "120.15196956",
                "lat": "31.93972249"
            },
            {
                "lon": "120.15302705",
                "lat": "31.94606115"
            },
            {
                "lon": "120.18711806",
                "lat": "31.94197542"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b59a4749121b",
        "cjsj": "2020-09-22 20:01:36",
        "gxsj": "2022-06-22 11:07:56",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "梅子洲锚地",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaab2be5a051b",
        "sshdName": "南京水道",
        "hdHdly": "下游",
        "hdlc": 351.3,
        "tyAnb": "右岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3549402,378349,40#3551078,379485,40#3549610,378090,40#3551240,379260,40",
        "zb2000": "3549351,378422,40#3551027,379558,40#3549559,378163,40#3551189,379333,40",
        "x84": "118.71255846,32.06108396#118.72437573,32.07631639#118.70979003,32.06293137#118.72197286,32.07775295",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.71255846",
                "lat": "32.06108396"
            },
            {
                "lon": "118.72437573",
                "lat": "32.07631639"
            },
            {
                "lon": "118.70979003",
                "lat": "32.06293137"
            },
            {
                "lon": "118.72197286",
                "lat": "32.07775295"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b5aab6ff125e",
        "cjsj": "2020-09-22 20:01:36",
        "gxsj": "2020-11-27 12:20:53",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "乌鱼洲锚地",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7a70ef04a2",
        "sshdName": "龙潭水道",
        "hdHdly": "下游",
        "hdlc": 310.4,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3564969,409579,40#3565426,409999,40#3566181,410691,40#3566835,411129,40#3567500,411574,40#3567794,411141,40#3567131,410694,40#3566478,410253,40#3565639,409686,40#3565160,409363,40",
        "zb2000": "3564918,409652,40#3565375,410072,40#3566130,410764,40#3566784,411202,40#3567449,411647,40#3567743,411214,40#3567080,410767,40#3566427,410326,40#3565588,409759,40#3565109,409436,40",
        "x84": "119.04175593,32.20437863#119.04616701,32.20853299#119.05343546,32.21539585#119.05802019,32.22132754#119.06267875,32.22735880#119.05805824,32.22997556#119.05337846,32.22396201#119.04876200,32.21803893#119.04282736,32.21042846#119.03944714,32.20608344",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 5,
        "lhXkqk": "1",
        "bz": "",
        "coordinates": [
            {
                "lon": "119.04175593",
                "lat": "32.20437863"
            },
            {
                "lon": "119.04616701",
                "lat": "32.20853299"
            },
            {
                "lon": "119.05343546",
                "lat": "32.21539585"
            },
            {
                "lon": "119.05802019",
                "lat": "32.22132754"
            },
            {
                "lon": "119.06267875",
                "lat": "32.22735880"
            },
            {
                "lon": "119.05805824",
                "lat": "32.22997556"
            },
            {
                "lon": "119.05337846",
                "lat": "32.22396201"
            },
            {
                "lon": "119.04876200",
                "lat": "32.21803893"
            },
            {
                "lon": "119.04282736",
                "lat": "32.21042846"
            },
            {
                "lon": "119.03944714",
                "lat": "32.20608344"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b5ac2ef9125f",
        "cjsj": "2020-09-22 20:01:36",
        "gxsj": "2020-11-27 12:20:53",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "栖霞危险品锚地",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7a70ef04a2",
        "sshdName": "龙潭水道",
        "hdHdly": "下游",
        "hdlc": 316.4,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3562853,405348,40#3562358,405800,40#3563248,407110,40#3563122,407156,40",
        "zb2000": "3562802,405421,40#3562307,405873,40#3563197,407183,40#3563071,407229,40",
        "x84": "118.99709595,32.18495118#119.00193706,32.18052586#119.01573947,32.18865963#119.01623944,32.18752732",
        "lhHdshyj": "1",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.99709595",
                "lat": "32.18495118"
            },
            {
                "lon": "119.00193706",
                "lat": "32.18052586"
            },
            {
                "lon": "119.01573947",
                "lat": "32.18865963"
            },
            {
                "lon": "119.01623944",
                "lat": "32.18752732"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174b5ae0a411260",
        "cjsj": "2020-09-22 20:11:59",
        "gxsj": "2020-09-22 20:11:59",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "栖霞油轮锚地",
        "gldw": "南京市港口管理局",
        "scsdId": "NJ_2c9240214e9f1ed9014eaa7a70ef04a2",
        "sshdName": "龙潭水道",
        "hdHdly": "下游",
        "hdlc": 319.5,
        "tyAnb": "左岸",
        "yt": "锚泊",
        "jcsj": "1950-01-01",
        "x54": "3562706,400795,40#3562711,402616,40#3562712,404208,40#3562118,404221,40",
        "zb2000": "3562655,400868,40#3562660,402689,40#3562661,404281,40#3562067,404294,40",
        "x84": "118.94883797,32.18323353#118.96814423,32.18343769#118.98502312,32.18358336#118.98522039,32.17822859",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 4,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "118.94883797",
                "lat": "32.18323353"
            },
            {
                "lon": "118.96814423",
                "lat": "32.18343769"
            },
            {
                "lon": "118.98502312",
                "lat": "32.18358336"
            },
            {
                "lon": "118.98522039",
                "lat": "32.17822859"
            }
        ]
    },
    {
        "id": "NJ_2c9240fe74a058e70174d29d0a2a35b6",
        "cjsj": "2020-09-28 11:07:16",
        "gxsj": "2020-09-28 11:07:16",
        "sjly": "6",
        "scbj": 0,
        "mdmc": "太仓危险品锚地",
        "gldw": "太仓港管理委员会",
        "scsdId": "NJ_2c9240214e527d39014e626655a10052",
        "sshdName": "浏河水道",
        "hdHdly": "下游",
        "hdlc": 27.78,
        "tyAnb": "左岸",
        "yt": "供危险品海轮锚泊",
        "jcsj": "2008-03-10",
        "x54": "3494005,627090,40#3493509,626463,40#3494761,625471,40#3495253,626101,40",
        "zb2000": "3493955.09,627166.49,40#3493458.26,626539.47,40#3494710.26,625547.45,40#3495203.00,626177.00,40",
        "x84": "121.33937202,31.56101633#121.33270604,31.55661313#121.32241980,31.56801024#121.32911723,31.57237757",
        "lhHdshyj": "2",
        "hxbz": 0,
        "zyb": 2,
        "lhXkqk": "2",
        "bz": "",
        "coordinates": [
            {
                "lon": "121.33937202",
                "lat": "31.56101633"
            },
            {
                "lon": "121.33270604",
                "lat": "31.55661313"
            },
            {
                "lon": "121.32241980",
                "lat": "31.56801024"
            },
            {
                "lon": "121.32911723",
                "lat": "31.57237757"
            }
        ]
    }
]