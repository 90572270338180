import Mock from 'mockjs'
import { port } from '@/utils/data/port';
import { md } from '@/utils/data/md';
import { societyship } from '@/utils/data/societyship';
import { queryURLParams } from '@/utils/helpers/common';
import { alarmList } from '@/utils/data/alarmList';

const sucessCode = process.env.VUE_APP_SUCCESS;

Mock.mock(RegExp('/port/search' + ".*"), 'get', (request) => {
  const parames = queryURLParams(request.url);
  let keywords = parames.keywords;
  let res = port.filter(function (item) {
    return item.mtmc.indexOf(keywords) > -1;
  });
  return {
    code: sucessCode,
    data: res
  }
});

Mock.mock('/getAllMt', 'get', () => {
  return {
    code: sucessCode,
    data: port,
  }
});
Mock.mock('/getAllMd', 'get', () => {
  return {
    code: sucessCode,
    data: md,
  }
});

Mock.mock('/societyship/getAll', 'post', () => {
  return {
    code: sucessCode,
    data: societyship,
  }
});

Mock.mock('/alarmList', 'get', () => {
  return {
    code: sucessCode,
    success: true,
    data: alarmList,
  }
});

Mock.mock(RegExp('/alarmDetail' + ".*"), 'get', (request) => {
  const parames = queryURLParams(request.url);
  let alarmId = parames.alarmId;
  let res = alarmList.records.filter(function (item) {
    return item.alarmId == alarmId;
  });
  return {
    code: sucessCode,
    success: true,
    data: res[0],
  }
});


