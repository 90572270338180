export  const port = [
    {
        "mtmc": "新厂砂石集并中心（临时性）",
        "sdmc": "天星洲水道",
        "x84": "112.409712,29.892646",
        "id": "0ba41982306943d0803939a1f8e6f158",
        "mt_id": "0ba41982306943d0803939a1f8e6f158",
        "hdHdly": "2",
        "hdlc": "410.60"
    },
    {
        "mtmc": "临湘鸭栏码头#2趸船",
        "sdmc": "螺山水道-下",
        "x84": "113.342838,29.663546",
        "id": "0d8dc948665b4d579629aca5ce3d42e5",
        "mt_id": "0d8dc948665b4d579629aca5ce3d42e5",
        "hdHdly": "2",
        "hdlc": "199.30"
    },
    {
        "mtmc": "嘉鱼县赤壁轮渡码头",
        "sdmc": "石头关水道",
        "x84": "113.619616,29.882860",
        "id": "1c27e14230c746e298bdd5302ed45eec",
        "mt_id": "1c27e14230c746e298bdd5302ed45eec",
        "hdHdly": "2",
        "hdlc": "162.30"
    },
    {
        "mtmc": "荆州区长江船舶污水垃圾接受设施项目码头",
        "sdmc": "太平口水道-下",
        "x84": "112.129332,30.305690",
        "id": "3df40eb1e5a94ac8b76e6d8e0e1fff97",
        "mt_id": "3df40eb1e5a94ac8b76e6d8e0e1fff97",
        "hdHdly": "2",
        "hdlc": "490.00"
    },
    {
        "mtmc": "柱力码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.287832,30.263269",
        "id": "4c2874d1d0914198a503339d332d73a4",
        "mt_id": "4c2874d1d0914198a503339d332d73a4",
        "hdHdly": "2",
        "hdlc": "472.10"
    },
    {
        "mtmc": "监利地方海事码头",
        "sdmc": "螺山水道-上",
        "x84": "113.266584,29.599455",
        "id": "611e513905694fe1803d6357f358eb46",
        "mt_id": "611e513905694fe1803d6357f358eb46",
        "hdHdly": "2",
        "hdlc": "209.90"
    },
    {
        "mtmc": "荆州盐卡船舶污水垃圾接受专用码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.289377,30.251131",
        "id": "6b0a793ce59841ec99610eb65f687c5e",
        "mt_id": "6b0a793ce59841ec99610eb65f687c5e",
        "hdHdly": "2",
        "hdlc": "470.80"
    },
    {
        "mtmc": "建华管桩码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.289042,30.244532",
        "id": "8b1e8dbd7460436792b77fc0025915e2",
        "mt_id": "8b1e8dbd7460436792b77fc0025915e2",
        "hdHdly": "2",
        "hdlc": "470.10"
    },
    {
        "mtmc": "洪湖市地方海事码头",
        "sdmc": "新堤水道",
        "x84": "113.456609,29.798060",
        "id": "8d9d66d07304494fa75306a298d4eaed",
        "mt_id": "8d9d66d07304494fa75306a298d4eaed",
        "hdHdly": "2",
        "hdlc": "180.40"
    },
    {
        "mtmc": "防汛码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.286757,30.266112",
        "id": "a1b161306bd6419587f24b293c1f3d5d",
        "mt_id": "a1b161306bd6419587f24b293c1f3d5d",
        "hdHdly": "2",
        "hdlc": "472.30"
    },
    {
        "mtmc": "荆州开发区工业综合码头",
        "sdmc": "瓦口子水道-下",
        "x84": "112.288644,30.240232",
        "id": "ae0b6b5b52e24c1b8d86c5acae21e46a",
        "mt_id": "ae0b6b5b52e24c1b8d86c5acae21e46a",
        "hdHdly": "2",
        "hdlc": "469.20"
    },
    {
        "mtmc": "公安县长江砂石埠河集并中心（临时性）",
        "sdmc": "太平口水道-下",
        "x84": "112.241841,30.295245",
        "id": "c5a6b74163614e73af852ac328074483",
        "mt_id": "c5a6b74163614e73af852ac328074483",
        "hdHdly": "2",
        "hdlc": "477.90"
    },
    {
        "mtmc": "临湘鸭栏码头#1趸船",
        "sdmc": "螺山水道-下",
        "x84": "113.343568,29.664091",
        "id": "c98e53a1c54d466b98b12249adf1548e",
        "mt_id": "c98e53a1c54d466b98b12249adf1548e",
        "hdHdly": "2",
        "hdlc": "199.20"
    },
    {
        "mtmc": "石沱码头",
        "sdmc": "青岩子水道",
        "x84": "107.141896,29.710668",
        "id": "CQ_2c929139746b667701749b12fb850002",
        "mt_id": "CQ_2c929139746b667701749b12fb850002",
        "hdHdly": "1",
        "hdlc": "566.70"
    },
    {
        "mtmc": "强盗庙散货、长明码头",
        "sdmc": "扇沱水道",
        "x84": "107.020807,29.785297",
        "id": "CQ_2c929139746b667701749b303bdf0003",
        "mt_id": "CQ_2c929139746b667701749b303bdf0003",
        "hdHdly": "1",
        "hdlc": "590.10"
    },
    {
        "mtmc": "丰都港务站客运码头",
        "sdmc": "丰都水道",
        "x84": "107.726192,29.874335",
        "id": "CQ_2c929139746b667701749b3d26530004",
        "mt_id": "CQ_2c929139746b667701749b3d26530004",
        "hdHdly": "1",
        "hdlc": "483.10"
    },
    {
        "mtmc": "忠县港务站码头",
        "sdmc": "忠县水道",
        "x84": "108.045784,30.304478",
        "id": "CQ_2c929139746b667701749b42409e0005",
        "mt_id": "CQ_2c929139746b667701749b42409e0005",
        "hdHdly": "1",
        "hdlc": "418.60"
    },
    {
        "mtmc": "广阳镇喻家沱码头",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.79184,29.592183",
        "id": "CQ_2c92913974ae7d060174b4d42d100000",
        "mt_id": "CQ_2c92913974ae7d060174b4d42d100000",
        "hdHdly": "1",
        "hdlc": "626.20"
    },
    {
        "mtmc": "丰都县高镇玉溪货运码头",
        "sdmc": "楠竹坝水道",
        "x84": "107.860511,30.032286",
        "id": "CQ_2c92913974ae7d060174b8901b240002",
        "mt_id": "CQ_2c92913974ae7d060174b8901b240002",
        "hdHdly": "1",
        "hdlc": "461.00"
    },
    {
        "mtmc": "长叶碛茂霞建材码头",
        "sdmc": "长叶碛水道",
        "x84": "106.7577,29.6117",
        "id": "CQ_2c92913974ae7d060174b9efd23a001f",
        "mt_id": "CQ_2c92913974ae7d060174b9efd23a001f",
        "hdHdly": "1",
        "hdlc": "630.70"
    },
    {
        "mtmc": "鱼嘴沙湾码头",
        "sdmc": "长叶碛水道",
        "x84": "106.7519,29.6152",
        "id": "CQ_2c92913974ae7d060174b9f43aac0021",
        "mt_id": "CQ_2c92913974ae7d060174b9f43aac0021",
        "hdHdly": "1",
        "hdlc": "631.30"
    },
    {
        "mtmc": "太平冲铜田坝码头",
        "sdmc": "铜田坝水道",
        "x84": "106.631973,29.596414",
        "id": "CQ_2c92913974ae7d060174ba1c57fa0028",
        "mt_id": "CQ_2c92913974ae7d060174ba1c57fa0028",
        "hdHdly": "1",
        "hdlc": "648.00"
    },
    {
        "mtmc": "船舶废弃物接收码头",
        "sdmc": "铜田坝水道",
        "x84": "106.625035,29.599589",
        "id": "CQ_2c92913974ae7d060174ba22602c0029",
        "mt_id": "CQ_2c92913974ae7d060174ba22602c0029",
        "hdHdly": "1",
        "hdlc": "648.50"
    },
    {
        "mtmc": "重庆长江轮船有限公司长石尾码头",
        "sdmc": "铜田坝水道",
        "x84": "106.62885,29.60367",
        "id": "CQ_2c92913974ae7d060174ba2851d4002a",
        "mt_id": "CQ_2c92913974ae7d060174ba2851d4002a",
        "hdHdly": "1",
        "hdlc": "648.40"
    },
    {
        "mtmc": "鱼洞段环卫船舶停靠码头",
        "sdmc": "鱼洞水道",
        "x84": "106.52185,29.386909",
        "id": "CQ_2c92913974ae7d060174bde7d8ac002e",
        "mt_id": "CQ_2c92913974ae7d060174bde7d8ac002e",
        "hdHdly": "1",
        "hdlc": "691.70"
    },
    {
        "mtmc": "老泥沱菜蔬码头",
        "sdmc": "白沙沱水道",
        "x84": "106.417610,29.349214",
        "id": "CQ_2c92913974ae7d060174be15b16b002f",
        "mt_id": "CQ_2c92913974ae7d060174be15b16b002f",
        "hdHdly": "1",
        "hdlc": "704.00"
    },
    {
        "mtmc": "黄磏港口物流码头",
        "sdmc": "红花碛水道",
        "x84": "106.317571,29.263509",
        "id": "CQ_2c92913974ae7d060174be2e65120030",
        "mt_id": "CQ_2c92913974ae7d060174be2e65120030",
        "hdHdly": "1",
        "hdlc": "721.60"
    },
    {
        "mtmc": "万州江南沱口货运码头",
        "sdmc": "万州水道",
        "x84": "108.412569,30.778778",
        "id": "CQ_2c92913974ae7d060174be8d16800032",
        "mt_id": "CQ_2c92913974ae7d060174be8d16800032",
        "hdHdly": "1",
        "hdlc": "336.00"
    },
    {
        "mtmc": "长江重庆航道局万州航标维护基地",
        "sdmc": "狐滩水道",
        "x84": "108.426456,30.720751",
        "id": "CQ_2c92913974ae7d060174be9499db0033",
        "mt_id": "CQ_2c92913974ae7d060174be9499db0033",
        "hdHdly": "1",
        "hdlc": "342.00"
    },
    {
        "mtmc": "西南水泥有限公司配套码头",
        "sdmc": "万州水道",
        "x84": "108.430992,30.744127",
        "id": "CQ_2c92913974ae7d060174beb933f90035",
        "mt_id": "CQ_2c92913974ae7d060174beb933f90035",
        "hdHdly": "1",
        "hdlc": "340.00"
    },
    {
        "mtmc": "泽胜洗舱基地码头",
        "sdmc": "中堆水道",
        "x84": "107.143457,29.718587",
        "id": "CQ_2c9291397590ed7d017641546afb023a",
        "mt_id": "CQ_2c9291397590ed7d017641546afb023a",
        "hdHdly": "1",
        "hdlc": "567.20"
    },
    {
        "mtmc": "忠县新生港码头（作业区）",
        "sdmc": "塘土坝水道",
        "x84": "107.937324,30.203295",
        "id": "CQ_2c9291397590ed7d0176417989c5023c",
        "mt_id": "CQ_2c9291397590ed7d0176417989c5023c",
        "hdHdly": "1",
        "hdlc": "437.40"
    },
    {
        "mtmc": "忠县海螺水泥厂专用码头",
        "sdmc": "塘土坝水道",
        "x84": "107.986130,30.201357",
        "id": "CQ_2c9291397590ed7d01764183f560023d",
        "mt_id": "CQ_2c9291397590ed7d01764183f560023d",
        "hdHdly": "1",
        "hdlc": "433.00"
    },
    {
        "mtmc": "巫山港区北门客运码头",
        "sdmc": "巫山水道",
        "x84": "110.878431,31.071409",
        "id": "CQ_2c929139771dbbd2017728175fc7003c",
        "mt_id": "CQ_2c929139771dbbd2017728175fc7003c",
        "hdHdly": "1",
        "hdlc": "170.25"
    },
    {
        "mtmc": "云阳县双梅集镇码头",
        "sdmc": "故陵水道",
        "x84": "109.06361,30.947677",
        "id": "CQ_2c929139771dbbd20177281f1574003d",
        "mt_id": "CQ_2c929139771dbbd20177281f1574003d",
        "hdHdly": "1",
        "hdlc": "255.30"
    },
    {
        "mtmc": "重庆市环湖船务有限公司苎溪河码头",
        "sdmc": "万州水道",
        "x84": "108.381102,30.815933",
        "id": "CQ_2c929139771dbbd201772824d8cd003e",
        "mt_id": "CQ_2c929139771dbbd201772824d8cd003e",
        "hdHdly": "1",
        "hdlc": "331.50"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门13码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.586469,29.565142",
        "id": "CQ_2c929139771dbbd2017739ab2d12004f",
        "mt_id": "CQ_2c929139771dbbd2017739ab2d12004f",
        "hdHdly": "1",
        "hdlc": "660.30"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门15码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.586281,29.563056",
        "id": "CQ_2c929139771dbbd2017739ae18ea0050",
        "mt_id": "CQ_2c929139771dbbd2017739ae18ea0050",
        "hdHdly": "1",
        "hdlc": "660.50"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门17码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.585638,29.561083",
        "id": "CQ_2c929139771dbbd2017739b2e5fb0051",
        "mt_id": "CQ_2c929139771dbbd2017739b2e5fb0051",
        "hdHdly": "1",
        "hdlc": "660.70"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（重庆港十二码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.586005,29.562133",
        "id": "CQ_2c929139771dbbd2017739b564b90052",
        "mt_id": "CQ_2c929139771dbbd2017739b564b90052",
        "hdHdly": "1",
        "hdlc": "660.60"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门8码头）",
        "sdmc": "朝天门水道",
        "x84": "106.581678,29.569086",
        "id": "CQ_2c929139771dbbd2017739c1da5b0053",
        "mt_id": "CQ_2c929139771dbbd2017739c1da5b0053",
        "hdHdly": "1",
        "hdlc": "0.33"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（重庆港十七码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.57844,29.552734",
        "id": "CQ_2c929139771dbbd2017739c757ec0054",
        "mt_id": "CQ_2c929139771dbbd2017739c757ec0054",
        "hdHdly": "1",
        "hdlc": "662.10"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门1码头）",
        "sdmc": "朝天门水道",
        "x84": "106.586349,29.571545",
        "id": "CQ_2c929139771dbbd2017739de3e890055",
        "mt_id": "CQ_2c929139771dbbd2017739de3e890055",
        "hdHdly": "1",
        "hdlc": "659.40"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门7码头）",
        "sdmc": "朝天门水道",
        "x84": "106.587514,29.569374",
        "id": "CQ_2c929139771dbbd2017739e0a9450056",
        "mt_id": "CQ_2c929139771dbbd2017739e0a9450056",
        "hdHdly": "1",
        "hdlc": "659.70"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门9码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.588085,29.567621",
        "id": "CQ_2c929139771dbbd2017739e325ab0057",
        "mt_id": "CQ_2c929139771dbbd2017739e325ab0057",
        "hdHdly": "1",
        "hdlc": "659.90"
    },
    {
        "mtmc": "重庆市轮渡客运站铜元局轮渡码头",
        "sdmc": "猪儿碛水道",
        "x84": "106.539019,29.54206",
        "id": "CQ_2c929139771dbbd201773a381d2a0058",
        "mt_id": "CQ_2c929139771dbbd201773a381d2a0058",
        "hdHdly": "1",
        "hdlc": "666.30"
    },
    {
        "mtmc": "望龙门圆通寺码头",
        "sdmc": "猪儿碛水道",
        "x84": "106.582383,29.556258",
        "id": "CQ_2c929139771dbbd201773a4559840059",
        "mt_id": "CQ_2c929139771dbbd201773a4559840059",
        "hdHdly": "1",
        "hdlc": "661.40"
    },
    {
        "mtmc": "重庆港江津港区兰家沱作业区粮食码头",
        "sdmc": "兰家沱水道",
        "x84": "106.23093,29.243973",
        "id": "CQ_2c929139771dbbd201773a5f96c9005a",
        "mt_id": "CQ_2c929139771dbbd201773a5f96c9005a",
        "hdHdly": "1",
        "hdlc": "740.09"
    },
    {
        "mtmc": "重庆市巴南区麻柳嘴复建码头",
        "sdmc": "洛碛水道",
        "x84": "106.937986,29.700853",
        "id": "CQ_2c929139771dbbd2017746af099a008d",
        "mt_id": "CQ_2c929139771dbbd2017746af099a008d",
        "hdHdly": "1",
        "hdlc": "603.80"
    },
    {
        "mtmc": "乌杨镇大溪河货运码头",
        "sdmc": "塘土坝水道",
        "x84": "107.998724,30.205875",
        "id": "CQ_2c929139771dbbd2017746d62b31008f",
        "mt_id": "CQ_2c929139771dbbd2017746d62b31008f",
        "hdHdly": "1",
        "hdlc": "431.90"
    },
    {
        "mtmc": "滑石子货运码头",
        "sdmc": "忠县水道",
        "x84": "108.033635,30.279935",
        "id": "CQ_2c929139771dbbd2017746dc4fc20090",
        "mt_id": "CQ_2c929139771dbbd2017746dc4fc20090",
        "hdHdly": "1",
        "hdlc": "421.70"
    },
    {
        "mtmc": "长寿棺材石码头",
        "sdmc": "长寿水道",
        "x84": "107.057073,29.820365",
        "id": "CQ_2c929139771dbbd20177482d290400a0",
        "mt_id": "CQ_2c929139771dbbd20177482d290400a0",
        "hdHdly": "1",
        "hdlc": "584.50"
    },
    {
        "mtmc": "白鹭嘴货运码头",
        "sdmc": "扇沱水道",
        "x84": "107.016648,29.784159",
        "id": "CQ_2c929139771dbbd201774830722300a1",
        "mt_id": "CQ_2c929139771dbbd201774830722300a1",
        "hdHdly": "1",
        "hdlc": "590.30"
    },
    {
        "mtmc": "黄旗场码头",
        "sdmc": "涪陵水道",
        "x84": "107.359000,29.739000",
        "id": "CQ_2c9291c87225dfe8017253eb96da0261",
        "mt_id": "CQ_2c9291c87225dfe8017253eb96da0261",
        "hdHdly": "1",
        "hdlc": "540.50"
    },
    {
        "mtmc": "龙海石化码头",
        "sdmc": "中堆水道",
        "x84": "107.136200,29.717300",
        "id": "CQ_2c9291c87225dfe801725412de7a0274",
        "mt_id": "CQ_2c9291c87225dfe801725412de7a0274",
        "hdHdly": "1",
        "hdlc": "567.50"
    },
    {
        "mtmc": "蓬威石化码头",
        "sdmc": "剪刀峡水道",
        "x84": "107.268000,29.704200",
        "id": "CQ_2c9291c87225dfe801725418f7e70276",
        "mt_id": "CQ_2c9291c87225dfe801725418f7e70276",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "特固建材码头",
        "sdmc": "剪刀峡水道",
        "x84": "107.286500,29.714600",
        "id": "CQ_2c9291c87225dfe80172541fa34c027e",
        "mt_id": "CQ_2c9291c87225dfe80172541fa34c027e",
        "hdHdly": "1",
        "hdlc": "549.20"
    },
    {
        "mtmc": "中机热电码头",
        "sdmc": "剪刀峡水道",
        "x84": "107.289200,29.715400",
        "id": "CQ_2c9291c87225dfe801725422157b027f",
        "mt_id": "CQ_2c9291c87225dfe801725422157b027f",
        "hdHdly": "1",
        "hdlc": "549.00"
    },
    {
        "mtmc": "冯家湾化危码头",
        "sdmc": "扇沱水道",
        "x84": "107.000400,29.778200",
        "id": "CQ_2c9291c87225dfe8017254332d570282",
        "mt_id": "CQ_2c9291c87225dfe8017254332d570282",
        "hdHdly": "1",
        "hdlc": "592.30"
    },
    {
        "mtmc": "川维化工码头",
        "sdmc": "扇沱水道",
        "x84": "107.021000,29.789200",
        "id": "CQ_2c9291c87225dfe801725435916f0283",
        "mt_id": "CQ_2c9291c87225dfe801725435916f0283",
        "hdHdly": "1",
        "hdlc": "589.70"
    },
    {
        "mtmc": "重钢码头",
        "sdmc": "长寿水道",
        "x84": "107.034200,29.791500",
        "id": "CQ_2c9291c87225dfe80172543a4bac0285",
        "mt_id": "CQ_2c9291c87225dfe80172543a4bac0285",
        "hdHdly": "1",
        "hdlc": "590.00"
    },
    {
        "mtmc": "长寿江南移民码头",
        "sdmc": "长寿水道",
        "x84": "107.054300,29.811800",
        "id": "CQ_2c9291c87225dfe8017254414fc00286",
        "mt_id": "CQ_2c9291c87225dfe8017254414fc00286",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "詹家沱散货码头",
        "sdmc": "长寿水道",
        "x84": "107.089100,29.805600",
        "id": "CQ_2c9291c87225dfe80172544504890287",
        "mt_id": "CQ_2c9291c87225dfe80172544504890287",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "李家坪复建散货码头",
        "sdmc": "长寿水道",
        "x84": "107.0469,29.8179",
        "id": "CQ_2c9291c87225dfe8017254cf9096028a",
        "mt_id": "CQ_2c9291c87225dfe8017254cf9096028a",
        "hdHdly": "1",
        "hdlc": "585.40"
    },
    {
        "mtmc": "羊驼背码头",
        "sdmc": "和尚滩水道",
        "x84": "107.4157,29.7542",
        "id": "CQ_2c9291c87225dfe8017254d29121028c",
        "mt_id": "CQ_2c9291c87225dfe8017254d29121028c",
        "hdHdly": "1",
        "hdlc": "531.60"
    },
    {
        "mtmc": "武警支队船艇码头",
        "sdmc": "菜子梁水道",
        "x84": "107.4305,29.7859",
        "id": "CQ_2c9291c87225dfe8017254e1d625028e",
        "mt_id": "CQ_2c9291c87225dfe8017254e1d625028e",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "顺洋物流码头",
        "sdmc": "半边峡水道",
        "x84": "107.5964,29.8350",
        "id": "CQ_2c9291c87225dfe8017254e72e62028f",
        "mt_id": "CQ_2c9291c87225dfe8017254e72e62028f",
        "hdHdly": "1",
        "hdlc": "496.20"
    },
    {
        "mtmc": "东方希望码头",
        "sdmc": "观音滩水道",
        "x84": "107.6084,29.8370",
        "id": "CQ_2c9291c87225dfe80172550503c70291",
        "mt_id": "CQ_2c9291c87225dfe80172550503c70291",
        "hdHdly": "1",
        "hdlc": "495.00"
    },
    {
        "mtmc": "丰都湛普货运码头",
        "sdmc": "观音滩水道",
        "x84": "107.6414,29.8431",
        "id": "CQ_2c9291c87225dfe80172550bd36f0292",
        "mt_id": "CQ_2c9291c87225dfe80172550bd36f0292",
        "hdHdly": "1",
        "hdlc": "491.90"
    },
    {
        "mtmc": "渝鑫船务公司",
        "sdmc": "伞把溪水道",
        "x84": "108.7895,30.9378",
        "id": "CQ_2c9291c8741f227d01744272d60f0023",
        "mt_id": "CQ_2c9291c8741f227d01744272d60f0023",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "瓦罐窑化危品码头",
        "sdmc": "黄草峡水道",
        "x84": "107.100374,29.783644",
        "id": "CQ_2c9291c87467919501749aed5d570027",
        "mt_id": "CQ_2c9291c87467919501749aed5d570027",
        "hdHdly": "1",
        "hdlc": "577.00"
    },
    {
        "mtmc": "北拱码头",
        "sdmc": "剪刀峡水道",
        "x84": "107.260795,29.702285",
        "id": "CQ_2c9291c87467919501749af49da40028",
        "mt_id": "CQ_2c9291c87467919501749af49da40028",
        "hdHdly": "1",
        "hdlc": "552.00"
    },
    {
        "mtmc": "袁家溪码头",
        "sdmc": "青岩子水道",
        "x84": "107.141040,29.711189",
        "id": "CQ_2c9291c87467919501749b074a910029",
        "mt_id": "CQ_2c9291c87467919501749b074a910029",
        "hdHdly": "1",
        "hdlc": "566.80"
    },
    {
        "mtmc": "镇安码头",
        "sdmc": "青岩子水道",
        "x84": "107.146301,29.717565",
        "id": "CQ_2c9291c87467919501749b0ca017002a",
        "mt_id": "CQ_2c9291c87467919501749b0ca017002a",
        "hdHdly": "1",
        "hdlc": "567.00"
    },
    {
        "mtmc": "忠县长通码头",
        "sdmc": "忠县水道",
        "x84": "108.044659,30.302262",
        "id": "CQ_2c9291c87467919501749b3b704d002c",
        "mt_id": "CQ_2c9291c87467919501749b3b704d002c",
        "hdHdly": "1",
        "hdlc": "418.90"
    },
    {
        "mtmc": "重庆东港作业区一期",
        "sdmc": "长叶碛水道",
        "x84": "106.738218,29.593335",
        "id": "CQ_2c9291c874ae66550174b48a455c0000",
        "mt_id": "CQ_2c9291c874ae66550174b48a455c0000",
        "hdHdly": "1",
        "hdlc": "634.00"
    },
    {
        "mtmc": "重庆港江津港区兰家沱作业区一期",
        "sdmc": "兰家沱水道",
        "x84": "108.227914,29.241593",
        "id": "CQ_2c9291c874ae66550174b48e91a70001",
        "mt_id": "CQ_2c9291c874ae66550174b48e91a70001",
        "hdHdly": "1",
        "hdlc": "741.30"
    },
    {
        "mtmc": "五举沱码头",
        "sdmc": "兰家沱水道",
        "x84": "106.265134,29.248862",
        "id": "CQ_2c9291c874ae66550174b49517910002",
        "mt_id": "CQ_2c9291c874ae66550174b49517910002",
        "hdHdly": "1",
        "hdlc": "737.50"
    },
    {
        "mtmc": "重庆鱼嘴镇五磊石码头",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.742415,29.611912",
        "id": "CQ_2c9291c874ae66550174b49947150003",
        "mt_id": "CQ_2c9291c874ae66550174b49947150003",
        "hdHdly": "1",
        "hdlc": "632.20"
    },
    {
        "mtmc": "远程货运码头",
        "sdmc": "江津水道",
        "x84": "108.235064,29.28423",
        "id": "CQ_2c9291c874ae66550174b49c74f70004",
        "mt_id": "CQ_2c9291c874ae66550174b49c74f70004",
        "hdHdly": "1",
        "hdlc": "732.80"
    },
    {
        "mtmc": "重庆港主城港区小南海水泥厂码头工程",
        "sdmc": "白沙沱水道",
        "x84": "106.418828,29.353023",
        "id": "CQ_2c9291c874ae66550174b4a894910005",
        "mt_id": "CQ_2c9291c874ae66550174b4a894910005",
        "hdHdly": "1",
        "hdlc": "704.70"
    },
    {
        "mtmc": "茄子溪码头",
        "sdmc": "乌木桩水道",
        "x84": "106.5017,29.4347",
        "id": "CQ_2c9291c874ae66550174b4b4b9e00006",
        "mt_id": "CQ_2c9291c874ae66550174b4b4b9e00006",
        "hdHdly": "1",
        "hdlc": "685.50"
    },
    {
        "mtmc": "丰都县高镇港务站码头",
        "sdmc": "楠竹坝水道",
        "x84": "107.859173,30.026225",
        "id": "CQ_2c9291c874ae66550174b884e2490009",
        "mt_id": "CQ_2c9291c874ae66550174b884e2490009",
        "hdHdly": "1",
        "hdlc": "461.70"
    },
    {
        "mtmc": "民生股份有限公司龙子洞靠泊基地",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.799708,29.593327",
        "id": "CQ_2c9291c874ae66550174b9d1b3000037",
        "mt_id": "CQ_2c9291c874ae66550174b9d1b3000037",
        "hdHdly": "1",
        "hdlc": "625.70"
    },
    {
        "mtmc": "重庆航顺船舶维修有限公司杨家店基地",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.781912,29.607424",
        "id": "CQ_2c9291c874ae66550174b9d99a380039",
        "mt_id": "CQ_2c9291c874ae66550174b9d99a380039",
        "hdHdly": "1",
        "hdlc": "628.20"
    },
    {
        "mtmc": "重庆江翔货运代理有限公司斗套石码头",
        "sdmc": "寸滩水道",
        "x84": "106.612412,29.622744",
        "id": "CQ_2c9291c874ae66550174b9e43c6b003a",
        "mt_id": "CQ_2c9291c874ae66550174b9e43c6b003a",
        "hdHdly": "1",
        "hdlc": "651.40"
    },
    {
        "mtmc": "果园作业区重大件码头",
        "sdmc": "长叶碛水道",
        "x84": "106.740373,29.606778",
        "id": "CQ_2c9291c874ae66550174b9ec5f52003b",
        "mt_id": "CQ_2c9291c874ae66550174b9ec5f52003b",
        "hdHdly": "1",
        "hdlc": "632.50"
    },
    {
        "mtmc": "石碾场码头",
        "sdmc": "长叶碛水道",
        "x84": "106.746866,29.605125",
        "id": "CQ_2c9291c874ae66550174b9f2934f003c",
        "mt_id": "CQ_2c9291c874ae66550174b9f2934f003c",
        "hdHdly": "1",
        "hdlc": "632.40"
    },
    {
        "mtmc": "江贵建材有限公司码头",
        "sdmc": "铜田坝水道",
        "x84": "106.6462,29.5903",
        "id": "CQ_2c9291c874ae66550174ba05422e003e",
        "mt_id": "CQ_2c9291c874ae66550174ba05422e003e",
        "hdHdly": "1",
        "hdlc": "646.00"
    },
    {
        "mtmc": "中石化鸡冠石码头",
        "sdmc": "铜田坝水道",
        "x84": "106.627022,29.596128",
        "id": "CQ_2c9291c874ae66550174ba0ab246003f",
        "mt_id": "CQ_2c9291c874ae66550174ba0ab246003f",
        "hdHdly": "1",
        "hdlc": "647.90"
    },
    {
        "mtmc": "大沙坝码头",
        "sdmc": "铜罐驿水道",
        "x84": "108.391668,29.330423",
        "id": "CQ_2c9291c874ae66550174bdff5b730043",
        "mt_id": "CQ_2c9291c874ae66550174bdff5b730043",
        "hdHdly": "1",
        "hdlc": "708.90"
    },
    {
        "mtmc": "下横梁子码头",
        "sdmc": "占碛子水道",
        "x84": "106.384752,29.291609",
        "id": "CQ_2c9291c874ae66550174be04ca550044",
        "mt_id": "CQ_2c9291c874ae66550174be04ca550044",
        "hdHdly": "1",
        "hdlc": "714.00"
    },
    {
        "mtmc": "九龙码头",
        "sdmc": "万州水道",
        "x84": "108.420768,30.738067",
        "id": "CQ_2c9291c874ae66550174beb41e71004a",
        "mt_id": "CQ_2c9291c874ae66550174beb41e71004a",
        "hdHdly": "1",
        "hdlc": "340.00"
    },
    {
        "mtmc": "马夫沱码头",
        "sdmc": "白沙沱水道",
        "x84": "106.395949,29.340454",
        "id": "CQ_2c9291c874ae66550174bed42a3a004b",
        "mt_id": "CQ_2c9291c874ae66550174bed42a3a004b",
        "hdHdly": "1",
        "hdlc": "707.20"
    },
    {
        "mtmc": "张飞庙旅游码头",
        "sdmc": "双江水道",
        "x84": "108.694034,30.913813",
        "id": "CQ_2c9291c874ae66550174bf0fc624004c",
        "mt_id": "CQ_2c9291c874ae66550174bf0fc624004c",
        "hdHdly": "1",
        "hdlc": "292.00"
    },
    {
        "mtmc": "夔门港码头",
        "sdmc": "奉节水道",
        "x84": "109.505950,31.038586",
        "id": "CQ_2c9291c874ae66550174bf16cc8a004d",
        "mt_id": "CQ_2c9291c874ae66550174bf16cc8a004d",
        "hdHdly": "1",
        "hdlc": "209.00"
    },
    {
        "mtmc": "陶家坝码头",
        "sdmc": "石宝寨水道",
        "x84": "108.222346,30.419949",
        "id": "CQ_2c9291c874ae66550174bf221ab3004e",
        "mt_id": "CQ_2c9291c874ae66550174bf221ab3004e",
        "hdHdly": "1",
        "hdlc": "383.30"
    },
    {
        "mtmc": "沿溪码头",
        "sdmc": "黄花城水道",
        "x84": "108.128354,30.300544",
        "id": "CQ_2c9291c874ae66550174bf44dfd70050",
        "mt_id": "CQ_2c9291c874ae66550174bf44dfd70050",
        "hdHdly": "1",
        "hdlc": "409.00"
    },
    {
        "mtmc": "青石码头",
        "sdmc": "青石洞水道",
        "x84": "110.007199,31.018026",
        "id": "CQ_2c9291c874ae66550174bf5074490051",
        "mt_id": "CQ_2c9291c874ae66550174bf5074490051",
        "hdHdly": "1",
        "hdlc": "155.50"
    },
    {
        "mtmc": "奉节宝塔坪作业区",
        "sdmc": "奉节水道",
        "x84": "109.552053,31.043307",
        "id": "CQ_2c9291c874ae66550174c3ab82240057",
        "mt_id": "CQ_2c9291c874ae66550174c3ab82240057",
        "hdHdly": "1",
        "hdlc": "206.00"
    },
    {
        "mtmc": "重庆市江北区黑石子码头",
        "sdmc": "寸滩水道",
        "x84": "106.611859,29.623383",
        "id": "CQ_2c9291c87590e9720175fa1b3949019b",
        "mt_id": "CQ_2c9291c87590e9720175fa1b3949019b",
        "hdHdly": "1",
        "hdlc": "651.60"
    },
    {
        "mtmc": "重庆市渝中区朝天门11码头",
        "sdmc": "朝天门水道",
        "x84": "106.588367,29.565938",
        "id": "CQ_2c9291c87590e9720175fa1f19a4019c",
        "mt_id": "CQ_2c9291c87590e9720175fa1f19a4019c",
        "hdHdly": "1",
        "hdlc": "660.00"
    },
    {
        "mtmc": "丰都紫光化工码头",
        "sdmc": "流沙坡水道",
        "x84": "107.753027,29.925879",
        "id": "CQ_2c9291c87590e9720176415c7ce8029d",
        "mt_id": "CQ_2c9291c87590e9720176415c7ce8029d",
        "hdHdly": "1",
        "hdlc": "477.20"
    },
    {
        "mtmc": "万州区鞍子坝港区(水井湾)客运码头",
        "sdmc": "万州水道",
        "x84": "108.38417,30.807021",
        "id": "CQ_2c9291c8771dadcf01772433d089003d",
        "mt_id": "CQ_2c9291c8771dadcf01772433d089003d",
        "hdHdly": "1",
        "hdlc": "332.10"
    },
    {
        "mtmc": "重庆市万州区驷马桥码头",
        "sdmc": "万州水道",
        "x84": "108.385554,30.802706",
        "id": "CQ_2c9291c8771dadcf0177291c385a005c",
        "mt_id": "CQ_2c9291c8771dadcf0177291c385a005c",
        "hdHdly": "1",
        "hdlc": "332.50"
    },
    {
        "mtmc": "重庆市巴南区木洞剑村码头",
        "sdmc": "木洞水道",
        "x84": "106.846965,29.573504",
        "id": "CQ_2c9291c8771dadcf017739629e600062",
        "mt_id": "CQ_2c9291c8771dadcf017739629e600062",
        "hdHdly": "1",
        "hdlc": "620.40"
    },
    {
        "mtmc": "重庆市巴南区木洞码头",
        "sdmc": "木洞水道",
        "x84": "106.834375,29.578811",
        "id": "CQ_2c9291c8771dadcf0177396d16f10063",
        "mt_id": "CQ_2c9291c8771dadcf0177396d16f10063",
        "hdHdly": "1",
        "hdlc": "621.70"
    },
    {
        "mtmc": "重庆公路运输（集团）有限公司储奇门码头",
        "sdmc": "猪儿碛水道",
        "x84": "106.573533,29.550986",
        "id": "CQ_2c9291c8771dadcf0177397de3880064",
        "mt_id": "CQ_2c9291c8771dadcf0177397de3880064",
        "hdHdly": "1",
        "hdlc": "662.50"
    },
    {
        "mtmc": "重庆市客轮有限公司客运码头（朝天门#2码头）",
        "sdmc": "朝天门水道",
        "x84": "106.583825,29.571202",
        "id": "CQ_2c9291c8771dadcf01773988585b0065",
        "mt_id": "CQ_2c9291c8771dadcf01773988585b0065",
        "hdHdly": "1",
        "hdlc": "0.05"
    },
    {
        "mtmc": " 重庆市客轮有限公司客运码头（朝天门#4码头）",
        "sdmc": "朝天门水道",
        "x84": "106.58347,29.57081",
        "id": "CQ_2c9291c8771dadcf0177398aff880066",
        "mt_id": "CQ_2c9291c8771dadcf0177398aff880066",
        "hdHdly": "1",
        "hdlc": "0.13"
    },
    {
        "mtmc": "重庆市客轮有限公司客运码头（朝天门#3码头）",
        "sdmc": "朝天门水道",
        "x84": "106.586842,29.570973",
        "id": "CQ_2c9291c8771dadcf01773995c7490067",
        "mt_id": "CQ_2c9291c8771dadcf01773995c7490067",
        "hdHdly": "1",
        "hdlc": "659.50"
    },
    {
        "mtmc": "重庆市客轮有限公司客运码头（朝天门#5码头）",
        "sdmc": "朝天门水道",
        "x84": "106.587264,29.570247",
        "id": "CQ_2c9291c8771dadcf0177399b9d7c0068",
        "mt_id": "CQ_2c9291c8771dadcf0177399b9d7c0068",
        "hdHdly": "1",
        "hdlc": "659.60"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（重庆港十五码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.583109,29.556717",
        "id": "CQ_2c9291c8771dadcf017739a1a1210069",
        "mt_id": "CQ_2c9291c8771dadcf017739a1a1210069",
        "hdHdly": "1",
        "hdlc": "661.30"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门6码头）",
        "sdmc": "朝天门水道",
        "x84": "106.582607,29.569809",
        "id": "CQ_2c9291c8771dadcf017739bcdac2006a",
        "mt_id": "CQ_2c9291c8771dadcf017739bcdac2006a",
        "hdHdly": "1",
        "hdlc": "0.23"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（朝天门10码头）",
        "sdmc": "朝天门水道",
        "x84": "106.579079,29.567534",
        "id": "CQ_2c9291c8771dadcf017739c2cce6006b",
        "mt_id": "CQ_2c9291c8771dadcf017739c2cce6006b",
        "hdHdly": "1",
        "hdlc": "0.58"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（重庆港十八码头）",
        "sdmc": "猪儿碛水道",
        "x84": "106.577685,29.552131",
        "id": "CQ_2c9291c8771dadcf017739c88d94006c",
        "mt_id": "CQ_2c9291c8771dadcf017739c88d94006c",
        "hdHdly": "1",
        "hdlc": "662.20"
    },
    {
        "mtmc": "重庆港九股份有限公司客运总站朝天门客运码头（重庆港七码头）",
        "sdmc": "朝天门水道",
        "x84": "106.587902,29.568188",
        "id": "CQ_2c9291c8771dadcf017739e446b2006d",
        "mt_id": "CQ_2c9291c8771dadcf017739e446b2006d",
        "hdHdly": "1",
        "hdlc": "659.80"
    },
    {
        "mtmc": "重庆市轮渡客运站龙门浩轮渡码头",
        "sdmc": "猪儿碛水道",
        "x84": "106.588664,29.556415",
        "id": "CQ_2c9291c8771dadcf01773a2cd4ba006e",
        "mt_id": "CQ_2c9291c8771dadcf01773a2cd4ba006e",
        "hdHdly": "1",
        "hdlc": "661.10"
    },
    {
        "mtmc": "重庆市轮渡客运站海棠溪轮渡码头",
        "sdmc": "猪儿碛水道",
        "x84": "106.578114,29.546516",
        "id": "CQ_2c9291c8771dadcf01773a31bf08006f",
        "mt_id": "CQ_2c9291c8771dadcf01773a31bf08006f",
        "hdHdly": "1",
        "hdlc": "662.40"
    },
    {
        "mtmc": "望龙门轮渡码头",
        "sdmc": "猪儿碛水道",
        "x84": "106.58367,29.557923",
        "id": "CQ_2c9291c8771dadcf01773a408b4c0070",
        "mt_id": "CQ_2c9291c8771dadcf01773a408b4c0070",
        "hdHdly": "1",
        "hdlc": "661.10"
    },
    {
        "mtmc": "梅塘湾码头",
        "sdmc": "流沙坡水道",
        "x84": "107.798031,29.935657",
        "id": "CQ_2c9291c8771dadcf017746b74ea8008f",
        "mt_id": "CQ_2c9291c8771dadcf017746b74ea8008f",
        "hdHdly": "1",
        "hdlc": "473.15"
    },
    {
        "mtmc": "珍溪码头",
        "sdmc": "丝瓜碛水道",
        "x84": "107.457164,29.893961",
        "id": "CQ_2c9291c8771dadcf017746e365eb0093",
        "mt_id": "CQ_2c9291c8771dadcf017746e365eb0093",
        "hdHdly": "1",
        "hdlc": "511.60"
    },
    {
        "mtmc": "川维物流码头",
        "sdmc": "红花碛水道",
        "x84": "106.309765,29.260796",
        "id": "CQ_988D22A550C6A9E9E053B41112AC7E9B",
        "mt_id": "CQ_988D22A550C6A9E9E053B41112AC7E9B",
        "hdHdly": "",
        "hdlc": "0.00"
    },
    {
        "mtmc": "佛耳岩码头一期工程",
        "sdmc": "鱼洞水道",
        "x84": "106.486907,29.399379",
        "id": "CQ_988DBEC8B230A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B230A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "695.70"
    },
    {
        "mtmc": "重庆市巴南区梅家梁码头",
        "sdmc": "鱼洞水道",
        "x84": "108.527948,29.38858",
        "id": "CQ_988DBEC8B231A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B231A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "691.40"
    },
    {
        "mtmc": "窎鱼嘴码头",
        "sdmc": "鱼洞水道",
        "x84": "106.52589326,29.39456919",
        "id": "CQ_988DBEC8B232A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B232A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "691.10"
    },
    {
        "mtmc": "水子坝码头",
        "sdmc": "乌木桩水道",
        "x84": "106.52815266,29.4092733",
        "id": "CQ_988DBEC8B233A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B233A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "689.00"
    },
    {
        "mtmc": "重庆新港",
        "sdmc": "胡家滩水道",
        "x84": "106.4940,29.4730",
        "id": "CQ_988DBEC8B234A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B234A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "682.00"
    },
    {
        "mtmc": "建设码头",
        "sdmc": "三角碛水道",
        "x84": "106.5266,29.5192",
        "id": "CQ_988DBEC8B235A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B235A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "669.50"
    },
    {
        "mtmc": "渔鳅浩货运码头",
        "sdmc": "胡家滩水道",
        "x84": "103.51685571,29.48477001",
        "id": "CQ_988DBEC8B236A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B236A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "677.80"
    },
    {
        "mtmc": "港湾码头",
        "sdmc": "胡家滩水道",
        "x84": "106.5422,29.4803",
        "id": "CQ_988DBEC8B237A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B237A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "675.00"
    },
    {
        "mtmc": "李家沱青岩子货运集装码头",
        "sdmc": "胡家滩水道",
        "x84": "103.53961597,29.47350294",
        "id": "CQ_988DBEC8B238A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B238A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "675.60"
    },
    {
        "mtmc": "重庆警备区码头",
        "sdmc": "三角碛水道",
        "x84": "106.54983559,29.48231049",
        "id": "CQ_988DBEC8B239A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B239A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "675.30"
    },
    {
        "mtmc": "哑巴洞货运码头",
        "sdmc": "三角碛水道",
        "x84": "106.535082,29.516751",
        "id": "CQ_988DBEC8B23BA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B23BA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "669.90"
    },
    {
        "mtmc": "重庆港九码头",
        "sdmc": "三角碛水道",
        "x84": "106.54281342,29.49969702",
        "id": "CQ_988DBEC8B23CA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B23CA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "671.50"
    },
    {
        "mtmc": "九龙坡ABB大件运输临时过渡码头",
        "sdmc": "三角碛水道",
        "x84": "106.542912,29.499301",
        "id": "CQ_988DBEC8B23DA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B23DA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "672.20"
    },
    {
        "mtmc": "重庆港码头（嘉陵江）",
        "sdmc": "朝天门水道",
        "x84": "106.576305,29.56665667",
        "id": "CQ_988DBEC8B23EA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B23EA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "659.00"
    },
    {
        "mtmc": "重庆港码头（长江）",
        "sdmc": "猪儿碛水道",
        "x84": "106.5726,29.5505",
        "id": "CQ_988DBEC8B23FA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B23FA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "661.50"
    },
    {
        "mtmc": "黄沙溪工程局码头",
        "sdmc": "三角碛水道",
        "x84": "106.531706,29.535566",
        "id": "CQ_988DBEC8B240A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B240A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "667.50"
    },
    {
        "mtmc": "重庆主城港区寸滩国际邮轮母港 ",
        "sdmc": "寸滩水道",
        "x84": "106.58797118,29.61582432",
        "id": "CQ_988DBEC8B242A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B242A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "654.50"
    },
    {
        "mtmc": "黄桷沱货运码头",
        "sdmc": "铜田坝水道",
        "x84": "106.62692063,29.60896282",
        "id": "CQ_988DBEC8B244A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B244A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "649.65"
    },
    {
        "mtmc": "玖龙码头",
        "sdmc": "占碛子水道",
        "x84": "106.390906,29.296438",
        "id": "CQ_988DBEC8B245A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B245A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "713.50"
    },
    {
        "mtmc": "海外旅游公司码头",
        "sdmc": "寸滩水道",
        "x84": "106.59899869,29.62087531",
        "id": "CQ_988DBEC8B246A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B246A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "656.30"
    },
    {
        "mtmc": "鸡冠石作业区纳溪沟码头",
        "sdmc": "铜田坝水道",
        "x84": "106.631109,29.590839",
        "id": "CQ_988DBEC8B249A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B249A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "647.00"
    },
    {
        "mtmc": "重庆主城港区纳溪沟码头",
        "sdmc": "铜田坝水道",
        "x84": "106.636078,29.588148",
        "id": "CQ_988DBEC8B24AA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B24AA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "647.00"
    },
    {
        "mtmc": "东风厂码头",
        "sdmc": "铜锣峡水道",
        "x84": "106.65194541,29.5986566",
        "id": "CQ_988DBEC8B24BA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B24BA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "646.00"
    },
    {
        "mtmc": "廖家凼码头",
        "sdmc": "铜锣峡水道",
        "x84": "106.649457,29.592561",
        "id": "CQ_988DBEC8B24CA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B24CA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "645.50"
    },
    {
        "mtmc": "祥港码头",
        "sdmc": "铜田坝水道",
        "x84": "106.6490,29.5919",
        "id": "CQ_988DBEC8B24DA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B24DA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "645.50"
    },
    {
        "mtmc": "郭家沱滚装码头",
        "sdmc": "铜锣峡水道",
        "x84": "106.6700794,29.57590967",
        "id": "CQ_988DBEC8B24EA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B24EA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "642.00"
    },
    {
        "mtmc": "牛耳沱码头",
        "sdmc": "广阳坝水道",
        "x84": "106.66432508,29.56934819",
        "id": "CQ_988DBEC8B24FA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B24FA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "642.50"
    },
    {
        "mtmc": "武警码头",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.76358153,29.61073068",
        "id": "CQ_988DBEC8B251A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B251A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "630.20"
    },
    {
        "mtmc": "明月沱码头",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.78309783,29.60594979",
        "id": "CQ_988DBEC8B252A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B252A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "628.00"
    },
    {
        "mtmc": "重庆港主城港区果园作业区一期工程",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.783398,29.611238",
        "id": "CQ_988DBEC8B254A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B254A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "628.50"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "鱼嘴大桥水道",
        "x84": "106.759371,29.611467",
        "id": "CQ_988DBEC8B255A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B255A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "630.60"
    },
    {
        "mtmc": "新恒阳团山堡化危码头",
        "sdmc": "长寿水道",
        "x84": "107.03449366,29.80737785",
        "id": "CQ_988DBEC8B257A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B257A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "587.20"
    },
    {
        "mtmc": "龙头港码头",
        "sdmc": "剪刀峡水道",
        "x84": "107.234097,29.698432",
        "id": "CQ_988DBEC8B258A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B258A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "552.20"
    },
    {
        "mtmc": "长江航运公安局万州分局忠县派出所工作趸船",
        "sdmc": "忠县水道",
        "x84": "108.04497132,30.29866219",
        "id": "CQ_988DBEC8B25AA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B25AA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "419.50"
    },
    {
        "mtmc": "猫儿沱港",
        "sdmc": "铜罐驿水道",
        "x84": "106.38753712,29.30656561",
        "id": "CQ_988DBEC8B25BA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B25BA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "711.80"
    },
    {
        "mtmc": "忠县船舶废弃物接收暨长江清漂码头",
        "sdmc": "复兴水道",
        "x84": "108.0699228,30.32380399",
        "id": "CQ_988DBEC8B25CA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B25CA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "415.60"
    },
    {
        "mtmc": "重庆港忠县港区倒脱靴船舶修造厂码头",
        "sdmc": "复兴水道",
        "x84": "108.0997455,30.29312057",
        "id": "CQ_988DBEC8B25EA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B25EA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "411.50"
    },
    {
        "mtmc": "重庆万州港区新田作业区一期工程",
        "sdmc": "狐滩水道",
        "x84": "108.3782389,30.66841243",
        "id": "CQ_988DBEC8B25FA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B25FA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "350.00"
    },
    {
        "mtmc": "重庆港区新田作业区神华码头",
        "sdmc": "狐滩水道",
        "x84": "108.38441003,30.67897043",
        "id": "CQ_988DBEC8B260A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B260A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "万州渔政渔港码头",
        "sdmc": "狐滩水道",
        "x84": "108.39688109,30.70053214",
        "id": "CQ_988DBEC8B261A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B261A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "长江海事局万州监管救助综合基地工程",
        "sdmc": "狐滩水道",
        "x84": "108.42746247,30.72415281",
        "id": "CQ_988DBEC8B262A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B262A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重庆万州昊鼎物流有限公司九龙码头",
        "sdmc": "万州水道",
        "x84": "108.41854155,30.73170837",
        "id": "CQ_988DBEC8B263A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B263A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重庆饕盛商贸有限公司九龙仓储码头",
        "sdmc": "万州水道",
        "x84": "108.41885504,30.74347041",
        "id": "CQ_988DBEC8B264A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B264A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "鄂渝桐子园农资港口物流码头",
        "sdmc": "万州水道",
        "x84": "107.12492334,4.56216487",
        "id": "CQ_988DBEC8B265A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B265A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重钢芭蕉角大宝坡码头",
        "sdmc": "白沙沱水道",
        "x84": "106.41361,29.348513",
        "id": "CQ_988DBEC8B266A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B266A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "705.50"
    },
    {
        "mtmc": "重庆苏商港口物流有限公司万州桐子园码头",
        "sdmc": "万州水道",
        "x84": "108.42043136,30.73740123",
        "id": "CQ_988DBEC8B267A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B267A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "万州区环境卫生管理处清漂专用码头",
        "sdmc": "万州水道",
        "x84": "108.40551668,30.83111814",
        "id": "CQ_988DBEC8B268A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B268A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "万州港区青草背作业区登山码头复建工程",
        "sdmc": "太阳溪水道",
        "x84": "108.43695,30.841059",
        "id": "CQ_988DBEC8B26AA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B26AA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "325.00"
    },
    {
        "mtmc": "长江航运公安局万州分局云阳派出所工作趸船",
        "sdmc": "双江水道",
        "x84": "108.68258127,30.93492349",
        "id": "CQ_988DBEC8B26CA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B26CA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重庆港云阳港区三峡水库管理专用码头",
        "sdmc": "双江水道",
        "x84": "108.6877406,30.93176526",
        "id": "CQ_988DBEC8B26EA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B26EA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "云阳县张飞庙旅游码头",
        "sdmc": "双江水道",
        "x84": "108.695507,30.915628",
        "id": "CQ_988DBEC8B26FA6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B26FA6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重庆港云阳港区洋叉沱船舶废弃物接收处置暨水域清漂码头",
        "sdmc": "双江水道",
        "x84": "108.73053126,30.92306722",
        "id": "CQ_988DBEC8B270A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B270A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "珞璜电厂码头",
        "sdmc": "白沙沱水道",
        "x84": "106.4299,29.3536",
        "id": "CQ_988DBEC8B271A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B271A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "703.60"
    },
    {
        "mtmc": "佛耳岩码头二期",
        "sdmc": "鱼洞水道",
        "x84": "108.477725,29.39402",
        "id": "CQ_988DBEC8B273A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B273A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "696.50"
    },
    {
        "mtmc": "川维物流码头",
        "sdmc": "红花碛水道",
        "x84": "106.3114,29.2611",
        "id": "CQ_988DBEC8B274A6A8E053B41112AC315B",
        "mt_id": "CQ_988DBEC8B274A6A8E053B41112AC315B",
        "hdHdly": "1",
        "hdlc": "722.30"
    },
    {
        "mtmc": "广兴洲汽渡码头",
        "sdmc": "铁铺水道",
        "x84": "112.927142,29.528579",
        "id": "df6132cd89664013901dae0a22a967e0",
        "mt_id": "df6132cd89664013901dae0a22a967e0",
        "hdHdly": "2",
        "hdlc": "278.40"
    },
    {
        "mtmc": "荆州号码头",
        "sdmc": "太平口水道-下",
        "x84": "112.239942,30.304590",
        "id": "e09e93fe6207432db77df6e28c1428e4",
        "mt_id": "e09e93fe6207432db77df6e28c1428e4",
        "hdHdly": "2",
        "hdlc": "479.50"
    },
    {
        "mtmc": "泸州港纳溪区石龙岩作业区",
        "sdmc": "纳溪水道",
        "x84": ",",
        "id": "LZ_2c9281b57424978a01744cc1ac680003",
        "mt_id": "LZ_2c9281b57424978a01744cc1ac680003",
        "hdHdly": "1",
        "hdlc": "938.30"
    },
    {
        "mtmc": "长江海事兰家沱海巡执法大队",
        "sdmc": "油溪水道",
        "x84": "106.146283,29.211009",
        "id": "LZ_2c9281b57424978a0174523dfbbe0004",
        "mt_id": "LZ_2c9281b57424978a0174523dfbbe0004",
        "hdHdly": "1",
        "hdlc": "752.50"
    },
    {
        "mtmc": "白沙利华码头",
        "sdmc": "白沙水道",
        "x84": "106.129,29.076",
        "id": "LZ_ff8080816d5ddec7016d627aa4590063",
        "mt_id": "LZ_ff8080816d5ddec7016d627aa4590063",
        "hdHdly": "1",
        "hdlc": "771.00"
    },
    {
        "mtmc": "重庆石门码头",
        "sdmc": "石门水道",
        "x84": "106.037,29.098",
        "id": "LZ_ff8080816d5ddec7016d627d96ba0064",
        "mt_id": "LZ_ff8080816d5ddec7016d627d96ba0064",
        "hdHdly": "1",
        "hdlc": "781.50"
    },
    {
        "mtmc": "白沙海事码头",
        "sdmc": "白沙水道",
        "x84": "106.122,29.0661",
        "id": "LZ_ff8080816d5ddec7016d628014b30065",
        "mt_id": "LZ_ff8080816d5ddec7016d628014b30065",
        "hdHdly": "1",
        "hdlc": "771.70"
    },
    {
        "mtmc": "长江白沙航道处码头",
        "sdmc": "白沙水道",
        "x84": "106.121117,29.065246",
        "id": "LZ_ff8080816d5ddec7016d6282086f0066",
        "mt_id": "LZ_ff8080816d5ddec7016d6282086f0066",
        "hdHdly": "1",
        "hdlc": "772.00"
    },
    {
        "mtmc": "白沙渡口码头",
        "sdmc": "白沙水道",
        "x84": "106.119,29.065",
        "id": "LZ_ff8080816d5ddec7016d6284013d0067",
        "mt_id": "LZ_ff8080816d5ddec7016d6284013d0067",
        "hdHdly": "1",
        "hdlc": "772.20"
    },
    {
        "mtmc": "滩盘渡口码头",
        "sdmc": "石门水道",
        "x84": "106.030,29.095",
        "id": "LZ_ff8080816d5ddec7016d628636cc0068",
        "mt_id": "LZ_ff8080816d5ddec7016d628636cc0068",
        "hdHdly": "1",
        "hdlc": "772.00"
    },
    {
        "mtmc": "白沙镇滨江路码头",
        "sdmc": "白沙水道",
        "x84": "106.114,29.064",
        "id": "LZ_ff8080816d5ddec7016d62a79b2f006d",
        "mt_id": "LZ_ff8080816d5ddec7016d62a79b2f006d",
        "hdHdly": "1",
        "hdlc": "772.50"
    },
    {
        "mtmc": "徐家嘴码头",
        "sdmc": "大吉脑水道",
        "x84": "105.926311,29.059508",
        "id": "LZ_ff8080816d6679f6016d67a7f1a70037",
        "mt_id": "LZ_ff8080816d6679f6016d67a7f1a70037",
        "hdHdly": "1",
        "hdlc": "795.80"
    },
    {
        "mtmc": "希望码头",
        "sdmc": "大吉脑水道",
        "x84": "105.905798,29.058882",
        "id": "LZ_ff8080816d6679f6016d67aa51380038",
        "mt_id": "LZ_ff8080816d6679f6016d67aa51380038",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重庆理文码头",
        "sdmc": "温中坝水道",
        "x84": "105.853152,29.024955",
        "id": "LZ_ff8080816d6679f6016d67ac6e6a0039",
        "mt_id": "LZ_ff8080816d6679f6016d67ac6e6a0039",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "长江永川海事处",
        "sdmc": "温中坝水道",
        "x84": "105.851423,29.019273",
        "id": "LZ_ff8080816d6679f6016d67af2914003a",
        "mt_id": "LZ_ff8080816d6679f6016d67af2914003a",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "长江永川航道处",
        "sdmc": "温中坝水道",
        "x84": "105.850784,29.015457",
        "id": "LZ_ff8080816d6679f6016d67b1e36d003c",
        "mt_id": "LZ_ff8080816d6679f6016d67b1e36d003c",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "朱沱水文站",
        "sdmc": "温中坝水道",
        "x84": "105.85057,29.014301",
        "id": "LZ_ff8080816d6679f6016d67b3e900003f",
        "mt_id": "LZ_ff8080816d6679f6016d67b3e900003f",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "永川港航",
        "sdmc": "温中坝水道",
        "x84": "105.8956,29.056075",
        "id": "LZ_ff8080816d6679f6016d67b694f90040",
        "mt_id": "LZ_ff8080816d6679f6016d67b694f90040",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "渝西码头",
        "sdmc": "温中坝水道",
        "x84": "105.902793,29.058704",
        "id": "LZ_ff8080816d6679f6016d67b9b61f0041",
        "mt_id": "LZ_ff8080816d6679f6016d67b9b61f0041",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "重庆港江津港区朱杨溪火车站码头",
        "sdmc": "大吉脑水道",
        "x84": "105.942567,29.062363",
        "id": "LZ_ff8080816d6679f6016d67d6c4450044",
        "mt_id": "LZ_ff8080816d6679f6016d67d6c4450044",
        "hdHdly": "1",
        "hdlc": "793.40"
    },
    {
        "mtmc": "合江县地方海事处停泊趸船",
        "sdmc": "合江水道（榕山处）",
        "x84": "105.837592,28.810193",
        "id": "LZ_ff8080816d6679f6016d682ddbbd004d",
        "mt_id": "LZ_ff8080816d6679f6016d682ddbbd004d",
        "hdHdly": "1",
        "hdlc": "842.00"
    },
    {
        "mtmc": "长江海事合江海事处",
        "sdmc": "合江水道（榕山处）",
        "x84": "105.839194,28.808037",
        "id": "LZ_ff8080816d6679f6016d68347f82004e",
        "mt_id": "LZ_ff8080816d6679f6016d68347f82004e",
        "hdHdly": "1",
        "hdlc": "841.80"
    },
    {
        "mtmc": "长江合江航道处",
        "sdmc": "合江水道（榕山处）",
        "x84": "105.840053,28.807344",
        "id": "LZ_ff8080816d6679f6016d684b73a70050",
        "mt_id": "LZ_ff8080816d6679f6016d684b73a70050",
        "hdHdly": "1",
        "hdlc": "841.60"
    },
    {
        "mtmc": "合江县密溪沟码头",
        "sdmc": "合江水道（榕山处）",
        "x84": "105.85306,28.80347",
        "id": "LZ_ff8080816d6679f6016d685a843c0052",
        "mt_id": "LZ_ff8080816d6679f6016d685a843c0052",
        "hdHdly": "1",
        "hdlc": "840.60"
    },
    {
        "mtmc": "合江县密溪沟码头二期",
        "sdmc": "合江水道（榕山处）",
        "x84": "105.85577,28.80395",
        "id": "LZ_ff8080816d6679f6016d6860514c0053",
        "mt_id": "LZ_ff8080816d6679f6016d6860514c0053",
        "hdHdly": "1",
        "hdlc": "840.40"
    },
    {
        "mtmc": "长江榕山航道处",
        "sdmc": "榕山水道",
        "x84": "105.913757,28.860999",
        "id": "LZ_ff8080816d6679f6016d686858270055",
        "mt_id": "LZ_ff8080816d6679f6016d686858270055",
        "hdHdly": "1",
        "hdlc": "831.90"
    },
    {
        "mtmc": "榕山客运趸船",
        "sdmc": "榕山水道",
        "x84": "105.913762,28.861615",
        "id": "LZ_ff8080816d6679f6016d686ac13f0056",
        "mt_id": "LZ_ff8080816d6679f6016d686ac13f0056",
        "hdHdly": "1",
        "hdlc": "831.80"
    },
    {
        "mtmc": "天华尿素码头、天华化工码头、天华大件码头",
        "sdmc": "榕山水道",
        "x84": "105.914447,28.865684",
        "id": "LZ_ff8080816d6679f6016d686e80280057",
        "mt_id": "LZ_ff8080816d6679f6016d686e80280057",
        "hdHdly": "1",
        "hdlc": "831.40"
    },
    {
        "mtmc": "榕山民用码头",
        "sdmc": "榕山水道",
        "x84": "105.914121,28.866649",
        "id": "LZ_ff8080816d6679f6016d687758040058",
        "mt_id": "LZ_ff8080816d6679f6016d687758040058",
        "hdHdly": "1",
        "hdlc": "831.10"
    },
    {
        "mtmc": "羊石盘渡口",
        "sdmc": "羊石盘水道",
        "x84": "105.888634,28.927242",
        "id": "LZ_ff8080816d6679f6016d68b2872e005c",
        "mt_id": "LZ_ff8080816d6679f6016d68b2872e005c",
        "hdHdly": "1",
        "hdlc": "822.70"
    },
    {
        "mtmc": "赵家中坝渡口",
        "sdmc": "羊石盘水道",
        "x84": "105.850758,28.933577",
        "id": "LZ_ff8080816d6679f6016d68b9f5be005d",
        "mt_id": "LZ_ff8080816d6679f6016d68b9f5be005d",
        "hdHdly": "1",
        "hdlc": "818.50"
    },
    {
        "mtmc": "三抛河渡口",
        "sdmc": "羊石盘水道",
        "x84": "105.831612,28.948285",
        "id": "LZ_ff8080816d6679f6016d68bbb3ba005e",
        "mt_id": "LZ_ff8080816d6679f6016d68bbb3ba005e",
        "hdHdly": "1",
        "hdlc": "815.90"
    },
    {
        "mtmc": "老渡口渡口",
        "sdmc": "榕山水道",
        "x84": "105.908663,28.864037",
        "id": "LZ_ff8080816d6679f6016d68bf2e03005f",
        "mt_id": "LZ_ff8080816d6679f6016d68bf2e03005f",
        "hdHdly": "1",
        "hdlc": "831.50"
    },
    {
        "mtmc": "中海沥青码头",
        "sdmc": "泰安水道（泸州处）",
        "x84": "3196884,552247",
        "id": "LZ_ff8080816d6bbd01016d6c03682c000a",
        "mt_id": "LZ_ff8080816d6bbd01016d6c03682c000a",
        "hdHdly": "1",
        "hdlc": "900.40"
    },
    {
        "mtmc": "泸州港多用途码头",
        "sdmc": "泰安水道（泸州处）",
        "x84": "105.528147,28.880433",
        "id": "LZ_ff8080816d6bbd01016d6c0ba53b000c",
        "mt_id": "LZ_ff8080816d6bbd01016d6c0ba53b000c",
        "hdHdly": "1",
        "hdlc": "901.00"
    },
    {
        "mtmc": "泰安码头",
        "sdmc": "泰安水道（泸州处）",
        "x84": "105.515467,28.870098",
        "id": "LZ_ff8080816d6bbd01016d6c0e3a69000d",
        "mt_id": "LZ_ff8080816d6bbd01016d6c0e3a69000d",
        "hdHdly": "1",
        "hdlc": "903.00"
    },
    {
        "mtmc": "神仙桥",
        "sdmc": "冰盘碛水道",
        "x84": "105.606274,28.944376",
        "id": "LZ_ff8080816d6bbd01016d7067bc7703b2",
        "mt_id": "LZ_ff8080816d6bbd01016d7067bc7703b2",
        "hdHdly": "1",
        "hdlc": "0.00"
    },
    {
        "mtmc": "方山航道综合码头",
        "sdmc": "石棚水道（纳溪处）",
        "x84": "105.346504,28.818710",
        "id": "LZ_ff8080816d7090eb016d715ca5090006",
        "mt_id": "LZ_ff8080816d7090eb016d715ca5090006",
        "hdHdly": "1",
        "hdlc": "929.10"
    },
    {
        "mtmc": "川渝码头",
        "sdmc": "大渡口水道",
        "x84": "105.276403,28.737594",
        "id": "LZ_ff8080816d7090eb016d716368980007",
        "mt_id": "LZ_ff8080816d7090eb016d716368980007",
        "hdHdly": "1",
        "hdlc": "945.40"
    },
    {
        "mtmc": "安达码头",
        "sdmc": "大渡口水道",
        "x84": "105.245962,28.737037",
        "id": "LZ_ff8080816d7090eb016d716b21dd0009",
        "mt_id": "LZ_ff8080816d7090eb016d716b21dd0009",
        "hdHdly": "1",
        "hdlc": "948.20"
    },
    {
        "mtmc": "青利码头",
        "sdmc": "大渡口水道",
        "x84": "105.245163,28.737632",
        "id": "LZ_ff8080816d7090eb016d716dff9c000a",
        "mt_id": "LZ_ff8080816d7090eb016d716dff9c000a",
        "hdHdly": "1",
        "hdlc": "948.30"
    },
    {
        "mtmc": "泸天化码头",
        "sdmc": "纳溪水道",
        "x84": "105.369935,28.782674",
        "id": "LZ_ff8080816d7090eb016d7173725d000b",
        "mt_id": "LZ_ff8080816d7090eb016d7173725d000b",
        "hdHdly": "1",
        "hdlc": "932.40"
    },
    {
        "mtmc": "启东中远海运海洋工程有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "120.85569956,31.68380352",
        "id": "NJ_2c92402171ed8fcf017210a0262a05b1",
        "mt_id": "NJ_2c92402171ed8fcf017210a0262a05b1",
        "hdHdly": "3",
        "hdlc": "2.50"
    },
    {
        "mtmc": "南通蓝岛来福士海洋工程有限公司基地码头",
        "sdmc": "长江北支水道",
        "x84": "121.84678018,31.68453488",
        "id": "NJ_2c92402171ed8fcf017210a40b3b05b3",
        "mt_id": "NJ_2c92402171ed8fcf017210a40b3b05b3",
        "hdHdly": "3",
        "hdlc": "7.00"
    },
    {
        "mtmc": "南通华滋奔腾船业有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.80506095,31.68813662",
        "id": "NJ_2c92402171ed8fcf017210a54c9405b4",
        "mt_id": "NJ_2c92402171ed8fcf017210a54c9405b4",
        "hdHdly": "3",
        "hdlc": "7.00"
    },
    {
        "mtmc": "启东中海阔港务有限公司通用码头",
        "sdmc": "长江北支水道",
        "x84": "121.79980693,31.69089805",
        "id": "NJ_2c92402171ed8fcf017210aa072505b5",
        "mt_id": "NJ_2c92402171ed8fcf017210aa072505b5",
        "hdHdly": "3",
        "hdlc": "7.80"
    },
    {
        "mtmc": "爱德华重工有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.79540576,31.69246744",
        "id": "NJ_2c92402171ed8fcf017210aca89a05b6",
        "mt_id": "NJ_2c92402171ed8fcf017210aca89a05b6",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "南通连兴港造船有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.78852153,31.69569242",
        "id": "NJ_2c92402171ed8fcf017210ae59d405b7",
        "mt_id": "NJ_2c92402171ed8fcf017210ae59d405b7",
        "hdHdly": "3",
        "hdlc": "15.00"
    },
    {
        "mtmc": "南通中集太平洋海洋工程有限公司装备码头",
        "sdmc": "长江北支水道",
        "x84": "121.78294813,31.69836166",
        "id": "NJ_2c92402171ed8fcf017210b1d5bb05b8",
        "mt_id": "NJ_2c92402171ed8fcf017210b1d5bb05b8",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "南通惠港造船有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.76625858,31.70433272",
        "id": "NJ_2c92402171ed8fcf017210b783c405b9",
        "mt_id": "NJ_2c92402171ed8fcf017210b783c405b9",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "武船集团顺融重工有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "118.74451115,31.71079852",
        "id": "NJ_2c92402171ed8fcf017210bed8e505ba",
        "mt_id": "NJ_2c92402171ed8fcf017210bed8e505ba",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "江苏京沪重工有限公司出运码头",
        "sdmc": "长江北支水道",
        "x84": "121.73616955,31.71290784",
        "id": "NJ_2c92402171ed8fcf017210c0a29805bb",
        "mt_id": "NJ_2c92402171ed8fcf017210c0a29805bb",
        "hdHdly": "3",
        "hdlc": "14.20"
    },
    {
        "mtmc": "南通润邦海洋工程装备有限公司 海洋工程装备配套项目码头",
        "sdmc": "长江北支水道",
        "x84": "121.72656364,31.71422182",
        "id": "NJ_2c92402171ed8fcf017210c432e205bc",
        "mt_id": "NJ_2c92402171ed8fcf017210c432e205bc",
        "hdHdly": "3",
        "hdlc": "15.20"
    },
    {
        "mtmc": "南通宏强船舶重工有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.72091909,31.71557343",
        "id": "NJ_2c92402171ed8fcf017210c9a44005bd",
        "mt_id": "NJ_2c92402171ed8fcf017210c9a44005bd",
        "hdHdly": "3",
        "hdlc": "15.40"
    },
    {
        "mtmc": "启东集胜造船有限公司造船项目船台",
        "sdmc": "长江北支水道",
        "x84": "121.71227023,31.71961665",
        "id": "NJ_2c92402171ed8fcf017210cc139905be",
        "mt_id": "NJ_2c92402171ed8fcf017210cc139905be",
        "hdHdly": "3",
        "hdlc": "16.60"
    },
    {
        "mtmc": "江苏向海船舶重工股份有限公司造船码头",
        "sdmc": "长江北支水道",
        "x84": "121.70947246,31.71856378",
        "id": "NJ_2c92402171ed8fcf017210d02fd805bf",
        "mt_id": "NJ_2c92402171ed8fcf017210d02fd805bf",
        "hdHdly": "3",
        "hdlc": "16.50"
    },
    {
        "mtmc": "启东丰顺船舶重工有限公司造船项目码头",
        "sdmc": "长江北支水道",
        "x84": "120.03378722,31.71977223",
        "id": "NJ_2c92402171ed8fcf017210d2776605c0",
        "mt_id": "NJ_2c92402171ed8fcf017210d2776605c0",
        "hdHdly": "3",
        "hdlc": "17.60"
    },
    {
        "mtmc": "启东太平港务有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.68457073,31.72284196",
        "id": "NJ_2c92402171ed8fcf017210d3bff305c1",
        "mt_id": "NJ_2c92402171ed8fcf017210d3bff305c1",
        "hdHdly": "3",
        "hdlc": "19.10"
    },
    {
        "mtmc": "海门海螺水泥有限责任公司散装码头",
        "sdmc": "白茆沙北水道",
        "x84": "121.10435173,31.78014813",
        "id": "NJ_2c92402171ed8fcf017210d7456005c3",
        "mt_id": "NJ_2c92402171ed8fcf017210d7456005c3",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "新世界通用码头",
        "sdmc": "浏海沙水道",
        "x84": "120.68349342,32.00726249",
        "id": "NJ_2c92402171ed8fcf017210f323ff05c4",
        "mt_id": "NJ_2c92402171ed8fcf017210f323ff05c4",
        "hdHdly": "3",
        "hdlc": "111.00"
    },
    {
        "mtmc": "格雷特码头",
        "sdmc": "南通水道",
        "x84": "120.67949753,32.05534068",
        "id": "NJ_2c92402171ed8fcf017210fb132e05c5",
        "mt_id": "NJ_2c92402171ed8fcf017210fb132e05c5",
        "hdHdly": "3",
        "hdlc": "112.50"
    },
    {
        "mtmc": "如皋港经济开发区泓北沙内港池",
        "sdmc": "福姜沙北水道",
        "x84": "120.62540164,32.00892364",
        "id": "NJ_2c92402171ed8fcf017210fc586f05c6",
        "mt_id": "NJ_2c92402171ed8fcf017210fc586f05c6",
        "hdHdly": "3",
        "hdlc": "117.00"
    },
    {
        "mtmc": "江苏熔盛重工舾装码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.61690419,32.01068766",
        "id": "NJ_2c92402171ed8fcf017210feca2a05c7",
        "mt_id": "NJ_2c92402171ed8fcf017210feca2a05c7",
        "hdHdly": "3",
        "hdlc": "118.00"
    },
    {
        "mtmc": "皋张汽渡码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.58800685,32.02837358",
        "id": "NJ_2c92402171ed8fcf01721102a8b405cc",
        "mt_id": "NJ_2c92402171ed8fcf01721102a8b405cc",
        "hdHdly": "3",
        "hdlc": "120.00"
    },
    {
        "mtmc": "如皋港务集团码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.57863844,32.03348515",
        "id": "NJ_2c92402171ed8fcf017211056b1e05d3",
        "mt_id": "NJ_2c92402171ed8fcf017211056b1e05d3",
        "hdHdly": "3",
        "hdlc": "120.00"
    },
    {
        "mtmc": "如皋长青沙港池长江港池码头西南段",
        "sdmc": "福姜沙北水道",
        "x84": "120.57691677,32.03580161",
        "id": "NJ_2c92402171ed8fcf0172111294dc05dc",
        "mt_id": "NJ_2c92402171ed8fcf0172111294dc05dc",
        "hdHdly": "3",
        "hdlc": "120.20"
    },
    {
        "mtmc": "如皋长青沙港池长江港池码头西北段",
        "sdmc": "福姜沙北水道",
        "x84": "120.57950023,32.03753032",
        "id": "NJ_2c92402171ed8fcf017211146cf705dd",
        "mt_id": "NJ_2c92402171ed8fcf017211146cf705dd",
        "hdHdly": "3",
        "hdlc": "120.20"
    },
    {
        "mtmc": "如皋江南德瑞斯船舶制造有限公司码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.58286454,32.03883155",
        "id": "NJ_2c92402171ed8fcf01721118670705e2",
        "mt_id": "NJ_2c92402171ed8fcf01721118670705e2",
        "hdHdly": "3",
        "hdlc": "120.20"
    },
    {
        "mtmc": "泰州国际集装箱码头",
        "sdmc": "口岸直水道",
        "x84": "119.892301,32.233902",
        "id": "NJ_2c92402171ed8fcf01722bcdabf4172b",
        "mt_id": "NJ_2c92402171ed8fcf01722bcdabf4172b",
        "hdHdly": "3",
        "hdlc": "210.50"
    },
    {
        "mtmc": "江苏海事局常熟白茆河口趸船浮码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.04539424,31.73885878",
        "id": "NJ_2c92402171ed907201720b96a864057c",
        "mt_id": "NJ_2c92402171ed907201720b96a864057c",
        "hdHdly": "3",
        "hdlc": "61.20"
    },
    {
        "mtmc": "亨通公共码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.04078894,31.74076170",
        "id": "NJ_2c92402171ed907201720b9a0902057d",
        "mt_id": "NJ_2c92402171ed907201720b9a0902057d",
        "hdHdly": "3",
        "hdlc": "63.20"
    },
    {
        "mtmc": "新泰通用件杂码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.03430186,31.74368646",
        "id": "NJ_2c92402171ed907201720b9cfb48057e",
        "mt_id": "NJ_2c92402171ed907201720b9cfb48057e",
        "hdHdly": "3",
        "hdlc": "63.70"
    },
    {
        "mtmc": "江苏理文造纸有限公司多用途码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.03291406,31.74431740",
        "id": "NJ_2c92402171ed907201720b9f7445057f",
        "mt_id": "NJ_2c92402171ed907201720b9f7445057f",
        "hdHdly": "3",
        "hdlc": "64.00"
    },
    {
        "mtmc": "苏州港常熟港区金泾塘作业区东港化工公用码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.02865599,31.74679733",
        "id": "NJ_2c92402171ed907201720ba1cccb0580",
        "mt_id": "NJ_2c92402171ed907201720ba1cccb0580",
        "hdHdly": "3",
        "hdlc": "64.40"
    },
    {
        "mtmc": "石大公用码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.02672396,31.74770072",
        "id": "NJ_2c92402171ed907201720ba455e70581",
        "mt_id": "NJ_2c92402171ed907201720ba455e70581",
        "hdHdly": "3",
        "hdlc": "64.60"
    },
    {
        "mtmc": "长春化工（江苏）有限公司液体化工码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.01814449,31.75298882",
        "id": "NJ_2c92402171ed907201720baae42d0583",
        "mt_id": "NJ_2c92402171ed907201720baae42d0583",
        "hdHdly": "3",
        "hdlc": "66.00"
    },
    {
        "mtmc": "长春化工（江苏）有限公司散货码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.01478526,31.75431241",
        "id": "NJ_2c92402171ed907201720bacd8e80585",
        "mt_id": "NJ_2c92402171ed907201720bacd8e80585",
        "hdHdly": "3",
        "hdlc": "66.20"
    },
    {
        "mtmc": "芬欧汇川码头",
        "sdmc": "常熟港专用航道",
        "x84": "121.01127810,31.75567162",
        "id": "NJ_2c92402171ed907201720baec95d0586",
        "mt_id": "NJ_2c92402171ed907201720baec95d0586",
        "hdHdly": "3",
        "hdlc": "66.40"
    },
    {
        "mtmc": "理文5号泊位",
        "sdmc": "常熟港专用航道",
        "x84": "121.00779876,31.75704782",
        "id": "NJ_2c92402171ed907201720bb127710587",
        "mt_id": "NJ_2c92402171ed907201720bb127710587",
        "hdHdly": "3",
        "hdlc": "66.60"
    },
    {
        "mtmc": "江苏理文码头发展有限公司（货主码头）",
        "sdmc": "常熟港专用航道",
        "x84": "121.00470110,31.75827636",
        "id": "NJ_2c92402171ed907201720bb371600588",
        "mt_id": "NJ_2c92402171ed907201720bb371600588",
        "hdHdly": "3",
        "hdlc": "66.90"
    },
    {
        "mtmc": "江苏皮尔金顿耀皮玻璃有限公司",
        "sdmc": "常熟港专用航道",
        "x84": "121.00000610,31.75967032",
        "id": "NJ_2c92402171ed907201720bb5fb560589",
        "mt_id": "NJ_2c92402171ed907201720bb5fb560589",
        "hdHdly": "3",
        "hdlc": "67.10"
    },
    {
        "mtmc": "江苏常熟发电二期煤码头",
        "sdmc": "常熟港专用航道",
        "x84": "120.96349851,31.76902735",
        "id": "NJ_2c92402171ed907201720bc0f0ae0598",
        "mt_id": "NJ_2c92402171ed907201720bc0f0ae0598",
        "hdHdly": "3",
        "hdlc": "70.30"
    },
    {
        "mtmc": "常熟发电一期煤码头码头",
        "sdmc": "常熟港专用航道",
        "x84": "120.96015557,31.77165171",
        "id": "NJ_2c92402171ed907201720bc2f1310599",
        "mt_id": "NJ_2c92402171ed907201720bc2f1310599",
        "hdHdly": "3",
        "hdlc": "70.50"
    },
    {
        "mtmc": "华润电力（常熟）有限公司煤码头",
        "sdmc": "常熟港专用航道",
        "x84": "120.95772995,31.77136209",
        "id": "NJ_2c92402171ed907201720bc553c8059a",
        "mt_id": "NJ_2c92402171ed907201720bc553c8059a",
        "hdHdly": "3",
        "hdlc": "70.70"
    },
    {
        "mtmc": "兴华码头",
        "sdmc": "通州沙东水道",
        "x84": "120.94542643,31.76632257",
        "id": "NJ_2c92402171ed907201720bc816cf059d",
        "mt_id": "NJ_2c92402171ed907201720bc816cf059d",
        "hdHdly": "3",
        "hdlc": "72.00"
    },
    {
        "mtmc": "江苏海事局常熟望虞河口海事趸船浮码头",
        "sdmc": "通州沙东水道",
        "x84": "120.89828416,31.76529389",
        "id": "NJ_2c92402171ed907201720bcb0be305a1",
        "mt_id": "NJ_2c92402171ed907201720bcb0be305a1",
        "hdHdly": "3",
        "hdlc": "75.00"
    },
    {
        "mtmc": "辻产业重机码头",
        "sdmc": "大新专用航道",
        "x84": "120.56863878,31.99574162",
        "id": "NJ_2c92402171ed907201720bd55f2005a4",
        "mt_id": "NJ_2c92402171ed907201720bd55f2005a4",
        "hdHdly": "3",
        "hdlc": "121.30"
    },
    {
        "mtmc": "永恒钢铁码头",
        "sdmc": "大新专用航道",
        "x84": "120.56460743,31.99629540",
        "id": "NJ_2c92402171ed907201720bd76ed505a5",
        "mt_id": "NJ_2c92402171ed907201720bd76ed505a5",
        "hdHdly": "3",
        "hdlc": "121.70"
    },
    {
        "mtmc": "如皋通茂船厂舾装码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.58314267,32.06435131",
        "id": "NJ_2c92402171ed907201720bda760905a6",
        "mt_id": "NJ_2c92402171ed907201720bda760905a6",
        "hdHdly": "3",
        "hdlc": "122.00"
    },
    {
        "mtmc": "华达码头",
        "sdmc": "大新专用航道",
        "x84": "120.54860174,32.00059804",
        "id": "NJ_2c92402171ed907201720bdf2faf05aa",
        "mt_id": "NJ_2c92402171ed907201720bdf2faf05aa",
        "hdHdly": "3",
        "hdlc": "122.70"
    },
    {
        "mtmc": "张家港重型装备制造基地重件和件杂码头",
        "sdmc": "浏海沙水道",
        "x84": "120.53634131,32.00552540",
        "id": "NJ_2c92402171ed907201720be17eaf05ab",
        "mt_id": "NJ_2c92402171ed907201720be17eaf05ab",
        "hdHdly": "3",
        "hdlc": "124.00"
    },
    {
        "mtmc": "张家港市港口支持系统（执法基地）",
        "sdmc": "福姜沙南水道",
        "x84": "120.44923674,32.01132837",
        "id": "NJ_2c92402171ed907201720be39a1c05ac",
        "mt_id": "NJ_2c92402171ed907201720be39a1c05ac",
        "hdHdly": "3",
        "hdlc": "134.00"
    },
    {
        "mtmc": "双狮散货码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.45123594,32.00806804",
        "id": "NJ_2c92402171ed907201720be5754405ad",
        "mt_id": "NJ_2c92402171ed907201720be5754405ad",
        "hdHdly": "3",
        "hdlc": "135.00"
    },
    {
        "mtmc": "双狮化工码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.45324128,32.00444952",
        "id": "NJ_2c92402171ed907201720be72e8d05ae",
        "mt_id": "NJ_2c92402171ed907201720be72e8d05ae",
        "hdHdly": "3",
        "hdlc": "135.50"
    },
    {
        "mtmc": "孚宝码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.45629496,31.99608052",
        "id": "NJ_2c92402171ed907201720be92ae905af",
        "mt_id": "NJ_2c92402171ed907201720be92ae905af",
        "hdHdly": "3",
        "hdlc": "136.70"
    },
    {
        "mtmc": "陶氏化工码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.45218094,31.98282603",
        "id": "NJ_2c92402171ed907201720bedcb2b05b0",
        "mt_id": "NJ_2c92402171ed907201720bedcb2b05b0",
        "hdHdly": "3",
        "hdlc": "138.30"
    },
    {
        "mtmc": "长江国际码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.44009110,31.97218810",
        "id": "NJ_2c92402171ed907201720bf18def05b2",
        "mt_id": "NJ_2c92402171ed907201720bf18def05b2",
        "hdHdly": "3",
        "hdlc": "138.80"
    },
    {
        "mtmc": "苏润国际码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.43478434,31.96996209",
        "id": "NJ_2c92402171ed907201720bf396ed05b3",
        "mt_id": "NJ_2c92402171ed907201720bf396ed05b3",
        "hdHdly": "3",
        "hdlc": "139.10"
    },
    {
        "mtmc": "张家港港务集团有限公司码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.38608613,31.96821390",
        "id": "NJ_2c92402171ed907201720c01229f05c0",
        "mt_id": "NJ_2c92402171ed907201720c01229f05c0",
        "hdHdly": "3",
        "hdlc": "142.40"
    },
    {
        "mtmc": "圣汇重件码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.37073795,31.96209861",
        "id": "NJ_2c92402171ed907201720c034a5005c1",
        "mt_id": "NJ_2c92402171ed907201720c034a5005c1",
        "hdHdly": "3",
        "hdlc": "143.80"
    },
    {
        "mtmc": "苏州港张家港机械重型设备码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.37280804,31.96639608",
        "id": "NJ_2c92402171ed907201720c0581c105c2",
        "mt_id": "NJ_2c92402171ed907201720c0581c105c2",
        "hdHdly": "3",
        "hdlc": "144.10"
    },
    {
        "mtmc": "久盛船业码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.36504917,31.96525875",
        "id": "NJ_2c92402171ed907201720c07926005c5",
        "mt_id": "NJ_2c92402171ed907201720c07926005c5",
        "hdHdly": "3",
        "hdlc": "144.60"
    },
    {
        "mtmc": "江苏江阴化工库码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.34365358,31.96115562",
        "id": "NJ_2c92402171ed907201720c098e0105c6",
        "mt_id": "NJ_2c92402171ed907201720c098e0105c6",
        "hdHdly": "3",
        "hdlc": "146.30"
    },
    {
        "mtmc": "石化储运码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.33741581,31.95944000",
        "id": "NJ_2c92402171ed907201720c0dc3d105c8",
        "mt_id": "NJ_2c92402171ed907201720c0dc3d105c8",
        "hdHdly": "3",
        "hdlc": "147.00"
    },
    {
        "mtmc": "辽河码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.33392898,31.95822803",
        "id": "NJ_2c92402171ed907201720c109add05c9",
        "mt_id": "NJ_2c92402171ed907201720c109add05c9",
        "hdHdly": "3",
        "hdlc": "147.30"
    },
    {
        "mtmc": "兴澄特钢码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.32895100,31.95645795",
        "id": "NJ_2c92402171ed907201720c12280505ca",
        "mt_id": "NJ_2c92402171ed907201720c12280505ca",
        "hdHdly": "3",
        "hdlc": "147.50"
    },
    {
        "mtmc": "江阴铁路联运码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.32135240,31.95371125",
        "id": "NJ_2c92402171ed907201720c13e7b505cb",
        "mt_id": "NJ_2c92402171ed907201720c13e7b505cb",
        "hdHdly": "3",
        "hdlc": "148.40"
    },
    {
        "mtmc": "中粮（江阴）散货码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.31359372,31.95075717",
        "id": "NJ_2c92402171ed907201720c15857705cc",
        "mt_id": "NJ_2c92402171ed907201720c15857705cc",
        "hdHdly": "3",
        "hdlc": "149.60"
    },
    {
        "mtmc": "如皋港区长青沙港池如皋港务集团码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.58286431,32.03879548",
        "id": "NJ_2c92402171ed9072017211bcc06206da",
        "mt_id": "NJ_2c92402171ed9072017211bcc06206da",
        "hdHdly": "3",
        "hdlc": "120.20"
    },
    {
        "mtmc": "国鼎管桩码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.55150513,32.05700038",
        "id": "NJ_2c92402171ed9072017211de7e2b06e3",
        "mt_id": "NJ_2c92402171ed9072017211de7e2b06e3",
        "hdHdly": "3",
        "hdlc": "125.00"
    },
    {
        "mtmc": "阳鸿码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.53457369,32.06386185",
        "id": "NJ_2c92402171ed9072017211dfd49206e4",
        "mt_id": "NJ_2c92402171ed9072017211dfd49206e4",
        "hdHdly": "3",
        "hdlc": "127.50"
    },
    {
        "mtmc": "焯晟码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.52381944,32.07066717",
        "id": "NJ_2c92402171ed9072017211e18d8b06e5",
        "mt_id": "NJ_2c92402171ed9072017211e18d8b06e5",
        "hdHdly": "3",
        "hdlc": "128.50"
    },
    {
        "mtmc": "靖江南洋造船码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.50447535,32.07519454",
        "id": "NJ_2c92402171ed9072017211e31e8b06e6",
        "mt_id": "NJ_2c92402171ed9072017211e31e8b06e6",
        "hdHdly": "3",
        "hdlc": "129.80"
    },
    {
        "mtmc": "靖江三峰港务物流码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.48849765,32.07607771",
        "id": "NJ_2c92402171ed9072017211e53d5306e7",
        "mt_id": "NJ_2c92402171ed9072017211e53d5306e7",
        "hdHdly": "3",
        "hdlc": "131.00"
    },
    {
        "mtmc": "靖江三峰港务物流内港池码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.48763643,32.08144677",
        "id": "NJ_2c92402171ed9072017211e67f0906e8",
        "mt_id": "NJ_2c92402171ed9072017211e67f0906e8",
        "hdHdly": "3",
        "hdlc": "131.00"
    },
    {
        "mtmc": "靖江新民船厂码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.46767255,32.07147581",
        "id": "NJ_2c92402171ed9072017211e7cb5e06e9",
        "mt_id": "NJ_2c92402171ed9072017211e7cb5e06e9",
        "hdHdly": "3",
        "hdlc": "132.90"
    },
    {
        "mtmc": "靖江联合安能石化码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.44879907,32.06488862",
        "id": "NJ_2c92402171ed9072017211f39e5006ea",
        "mt_id": "NJ_2c92402171ed9072017211f39e5006ea",
        "hdHdly": "3",
        "hdlc": "134.00"
    },
    {
        "mtmc": "靖江新港救助巡航一体化基地",
        "sdmc": "福姜沙北水道",
        "x84": "120.44097302,32.06491606",
        "id": "NJ_2c92402171ed9072017211f57ff006eb",
        "mt_id": "NJ_2c92402171ed9072017211f57ff006eb",
        "hdHdly": "3",
        "hdlc": "134.60"
    },
    {
        "mtmc": "东泽船厂码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.15510653,32.23820975",
        "id": "NJ_2c92402171ed907201722bb77b920ae7",
        "mt_id": "NJ_2c92402171ed907201722bb77b920ae7",
        "hdHdly": "3",
        "hdlc": "300.60"
    },
    {
        "mtmc": "江阴市长洋贸易码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.18133943,31.92371252",
        "id": "NJ_2c92402171ed907201722bf66f220b50",
        "mt_id": "NJ_2c92402171ed907201722bf66f220b50",
        "hdHdly": "3",
        "hdlc": "162.00"
    },
    {
        "mtmc": "中航宝胜件杂货码头",
        "sdmc": "焦山水道",
        "x84": "119.45723536,32.27378139",
        "id": "NJ_2c92402171ed907201722bfba0490b57",
        "mt_id": "NJ_2c92402171ed907201722bfba0490b57",
        "hdHdly": "3",
        "hdlc": "267.50"
    },
    {
        "mtmc": "泰兴港区七圩作业区公用码头",
        "sdmc": "泰兴水道",
        "x84": "119.99864763,31.99850807",
        "id": "NJ_2c92402171ed907201722c01ea520b58",
        "mt_id": "NJ_2c92402171ed907201722c01ea520b58",
        "hdHdly": "3",
        "hdlc": "181.00"
    },
    {
        "mtmc": "江苏中燃化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.22455951,31.94265441",
        "id": "NJ_2c92402172362a8001723f7e22a802c0",
        "mt_id": "NJ_2c92402172362a8001723f7e22a802c0",
        "hdHdly": "3",
        "hdlc": "158.20"
    },
    {
        "mtmc": "永益港务码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.20605482,31.94437213",
        "id": "NJ_2c92402172362a8001723f81612802ca",
        "mt_id": "NJ_2c92402172362a8001723f81612802ca",
        "hdHdly": "3",
        "hdlc": "159.50"
    },
    {
        "mtmc": "江苏长强钢铁有限公司码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.18813009,31.94556324",
        "id": "NJ_2c92402172362a8001723f834d9602cd",
        "mt_id": "NJ_2c92402172362a8001723f834d9602cd",
        "hdHdly": "3",
        "hdlc": "161.50"
    },
    {
        "mtmc": "江苏恒德港口发展有限公司码头#1泊位",
        "sdmc": "江阴水道(上段)",
        "x84": "120.18089715,31.94632224",
        "id": "NJ_2c92402172362a8001723f86376f02d0",
        "mt_id": "NJ_2c92402172362a8001723f86376f02d0",
        "hdHdly": "3",
        "hdlc": "162.80"
    },
    {
        "mtmc": "大通国际码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.17888796,31.94652348",
        "id": "NJ_2c92402172362a8001723f89109e02d1",
        "mt_id": "NJ_2c92402172362a8001723f89109e02d1",
        "hdHdly": "3",
        "hdlc": "162.70"
    },
    {
        "mtmc": "新扬子码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.17522910,31.94688930",
        "id": "NJ_2c92402172362a8001723f8b121a02d2",
        "mt_id": "NJ_2c92402172362a8001723f8b121a02d2",
        "hdHdly": "3",
        "hdlc": "162.80"
    },
    {
        "mtmc": "新扬子造船厂码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.15227245,31.95027363",
        "id": "NJ_2c92402172362a8001723f8da25902d7",
        "mt_id": "NJ_2c92402172362a8001723f8da25902d7",
        "hdHdly": "3",
        "hdlc": "163.30"
    },
    {
        "mtmc": "三江水泥码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.14682652,31.95110971",
        "id": "NJ_2c92402172362a800172400f4c4d0348",
        "mt_id": "NJ_2c92402172362a800172400f4c4d0348",
        "hdHdly": "3",
        "hdlc": "164.30"
    },
    {
        "mtmc": "上五圩汽渡码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.13805385,31.96255479",
        "id": "NJ_2c92402172362a8001724011295a034a",
        "mt_id": "NJ_2c92402172362a8001724011295a034a",
        "hdHdly": "3",
        "hdlc": "167.00"
    },
    {
        "mtmc": "亚星锚链码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.12809905,31.95464706",
        "id": "NJ_2c92402172362a800172401683d4034b",
        "mt_id": "NJ_2c92402172362a800172401683d4034b",
        "hdHdly": "3",
        "hdlc": "167.90"
    },
    {
        "mtmc": "夹港货运码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.09637405,31.96242174",
        "id": "NJ_2c92402172362a800172402dcb72036f",
        "mt_id": "NJ_2c92402172362a800172402dcb72036f",
        "hdHdly": "3",
        "hdlc": "171.10"
    },
    {
        "mtmc": "夹港煤码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.09527391,31.96244962",
        "id": "NJ_2c92402172362a8001724031ebd60370",
        "mt_id": "NJ_2c92402172362a8001724031ebd60370",
        "hdHdly": "3",
        "hdlc": "171.20"
    },
    {
        "mtmc": "夹港汽渡码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.09287339,31.96327206",
        "id": "NJ_2c92402172362a80017240332cf0037a",
        "mt_id": "NJ_2c92402172362a80017240332cf0037a",
        "hdHdly": "3",
        "hdlc": "171.30"
    },
    {
        "mtmc": "江阴船厂码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.25925388,31.93281258",
        "id": "NJ_2c92402172362a80017240351670037f",
        "mt_id": "NJ_2c92402172362a80017240351670037f",
        "hdHdly": "3",
        "hdlc": "155.10"
    },
    {
        "mtmc": "江阴扬子江船厂码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.25725963,31.93060712",
        "id": "NJ_2c92402172362a80017240388603038b",
        "mt_id": "NJ_2c92402172362a80017240388603038b",
        "hdHdly": "3",
        "hdlc": "155.20"
    },
    {
        "mtmc": "苏锡海工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.25882571,31.93096468",
        "id": "NJ_2c92402172362a800172403c88200392",
        "mt_id": "NJ_2c92402172362a800172403c88200392",
        "hdHdly": "3",
        "hdlc": "155.20"
    },
    {
        "mtmc": "江阴集装箱码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.24604112,31.92717526",
        "id": "NJ_2c92402172362a80017240444a9003a1",
        "mt_id": "NJ_2c92402172362a80017240444a9003a1",
        "hdHdly": "3",
        "hdlc": "156.00"
    },
    {
        "mtmc": "韭菜港汽渡码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.24140783,31.92654382",
        "id": "NJ_2c92402172362a800172404556b103a4",
        "mt_id": "NJ_2c92402172362a800172404556b103a4",
        "hdHdly": "3",
        "hdlc": "156.70"
    },
    {
        "mtmc": "#4江阴港码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.23884811,31.92626908",
        "id": "NJ_2c92402172362a8001724048a81c03a9",
        "mt_id": "NJ_2c92402172362a8001724048a81c03a9",
        "hdHdly": "3",
        "hdlc": "156.80"
    },
    {
        "mtmc": "#2江阴港码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.23668956,31.92573201",
        "id": "NJ_2c92402172362a8001724049a2b903ac",
        "mt_id": "NJ_2c92402172362a8001724049a2b903ac",
        "hdHdly": "3",
        "hdlc": "157.10"
    },
    {
        "mtmc": "#1江阴港码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.23307322,31.92576573",
        "id": "NJ_2c92402172362a800172404e162e03b4",
        "mt_id": "NJ_2c92402172362a800172404e162e03b4",
        "hdHdly": "3",
        "hdlc": "157.30"
    },
    {
        "mtmc": "澄西船厂码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.21581184,31.92403757",
        "id": "NJ_2c92402172362a80017240596e9403cb",
        "mt_id": "NJ_2c92402172362a80017240596e9403cb",
        "hdHdly": "3",
        "hdlc": "157.60"
    },
    {
        "mtmc": "夏港电厂煤码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.20230993,31.92462788",
        "id": "NJ_2c92402172362a800172405a8bdb03cc",
        "mt_id": "NJ_2c92402172362a800172405a8bdb03cc",
        "hdHdly": "3",
        "hdlc": "159.70"
    },
    {
        "mtmc": "长宏一号码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.19204888,31.92267775",
        "id": "NJ_2c92402172362a800172405d2c9403cd",
        "mt_id": "NJ_2c92402172362a800172405d2c9403cd",
        "hdHdly": "3",
        "hdlc": "161.10"
    },
    {
        "mtmc": "江阴拆船厂",
        "sdmc": "江阴水道(上段)",
        "x84": "120.18600273,31.92376893",
        "id": "NJ_2c92402172362a800172405fb82f03d2",
        "mt_id": "NJ_2c92402172362a800172405fb82f03d2",
        "hdHdly": "3",
        "hdlc": "161.60"
    },
    {
        "mtmc": "联合钢铁厂码头（联合物流）",
        "sdmc": "江阴水道(上段)",
        "x84": "120.17924614,31.92391389",
        "id": "NJ_2c92402172362a8001724061821103d3",
        "mt_id": "NJ_2c92402172362a8001724061821103d3",
        "hdHdly": "3",
        "hdlc": "162.40"
    },
    {
        "mtmc": "长江村港口#3码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.17224717,31.92451875",
        "id": "NJ_2c92402172362a800172406657e903db",
        "mt_id": "NJ_2c92402172362a800172406657e903db",
        "hdHdly": "3",
        "hdlc": "162.80"
    },
    {
        "mtmc": "长江村港口#4码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.16484630,31.92513277",
        "id": "NJ_2c92402172362a8001724067ed0003de",
        "mt_id": "NJ_2c92402172362a8001724067ed0003de",
        "hdHdly": "3",
        "hdlc": "163.60"
    },
    {
        "mtmc": "江阴港申夏港区#5码头二期",
        "sdmc": "江阴水道(上段)",
        "x84": "120.15114514,31.92705263",
        "id": "NJ_2c92402172362a800172406a6a2403e1",
        "mt_id": "NJ_2c92402172362a800172406a6a2403e1",
        "hdHdly": "3",
        "hdlc": "164.60"
    },
    {
        "mtmc": "江阴港务集团码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.14357548,31.92815261",
        "id": "NJ_2c92402172362a800172406e764403e8",
        "mt_id": "NJ_2c92402172362a800172406e764403e8",
        "hdHdly": "3",
        "hdlc": "164.80"
    },
    {
        "mtmc": "江阴海事码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.13987521,31.92872488",
        "id": "NJ_2c92402172362a80017240713dee03ec",
        "mt_id": "NJ_2c92402172362a80017240713dee03ec",
        "hdHdly": "3",
        "hdlc": "165.70"
    },
    {
        "mtmc": "申夏港区#6码头二期",
        "sdmc": "江阴水道(上段)",
        "x84": "120.13245492,31.93093329",
        "id": "NJ_2c92402172362a8001724072700503ed",
        "mt_id": "NJ_2c92402172362a8001724072700503ed",
        "hdHdly": "3",
        "hdlc": "166.00"
    },
    {
        "mtmc": "申夏港区#6码头一期",
        "sdmc": "江阴水道(上段)",
        "x84": "120.12713784,31.93248986",
        "id": "NJ_2c92402172362a8001724073dbba03f1",
        "mt_id": "NJ_2c92402172362a8001724073dbba03f1",
        "hdHdly": "3",
        "hdlc": "166.60"
    },
    {
        "mtmc": "三房巷多用途码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.11024500,31.93775428",
        "id": "NJ_2c92402172362a8001724079061b03fa",
        "mt_id": "NJ_2c92402172362a8001724079061b03fa",
        "hdHdly": "3",
        "hdlc": "168.50"
    },
    {
        "mtmc": "三房巷化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.10478984,31.93946335",
        "id": "NJ_2c92402172362a800172407bc1610400",
        "mt_id": "NJ_2c92402172362a800172407bc1610400",
        "hdHdly": "3",
        "hdlc": "169.10"
    },
    {
        "mtmc": "丽天化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.09942975,31.94126229",
        "id": "NJ_2c92402172362a800172407debb20403",
        "mt_id": "NJ_2c92402172362a800172407debb20403",
        "hdHdly": "3",
        "hdlc": "169.50"
    },
    {
        "mtmc": "双良集团化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.09624743,31.94232890",
        "id": "NJ_2c92402172362a8001724080050c0405",
        "mt_id": "NJ_2c92402172362a8001724080050c0405",
        "hdHdly": "3",
        "hdlc": "170.00"
    },
    {
        "mtmc": "江阴利港物质有限公司码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.08789412,31.94439111",
        "id": "NJ_2c92402172362a80017240895ee30417",
        "mt_id": "NJ_2c92402172362a80017240895ee30417",
        "hdHdly": "3",
        "hdlc": "171.00"
    },
    {
        "mtmc": "利港电厂#1煤码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.08533557,31.94543897",
        "id": "NJ_2c92402172362a800172408c8392041e",
        "mt_id": "NJ_2c92402172362a800172408c8392041e",
        "hdHdly": "3",
        "hdlc": "171.10"
    },
    {
        "mtmc": "利港电厂#2煤码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.07955178,31.94735460",
        "id": "NJ_2c92402172362a800172408e62f00421",
        "mt_id": "NJ_2c92402172362a800172408e62f00421",
        "hdHdly": "3",
        "hdlc": "171.60"
    },
    {
        "mtmc": "利港电厂大件码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.07435968,31.94877361",
        "id": "NJ_2c92402172362a800172408ff7b80422",
        "mt_id": "NJ_2c92402172362a800172408ff7b80422",
        "hdHdly": "3",
        "hdlc": "172.20"
    },
    {
        "mtmc": "江苏华西集团公司化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.04046377,31.95675201",
        "id": "NJ_2c92402172362a8001724091f9c10423",
        "mt_id": "NJ_2c92402172362a8001724091f9c10423",
        "hdHdly": "3",
        "hdlc": "174.80"
    },
    {
        "mtmc": "无锡南荣液化气码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.03785119,31.95717667",
        "id": "NJ_2c92402172362a80017240952b7f042e",
        "mt_id": "NJ_2c92402172362a80017240952b7f042e",
        "hdHdly": "3",
        "hdlc": "175.00"
    },
    {
        "mtmc": "澄星国际化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.03563008,31.95788073",
        "id": "NJ_2c92402172362a8001724096838d0431",
        "mt_id": "NJ_2c92402172362a8001724096838d0431",
        "hdHdly": "3",
        "hdlc": "175.30"
    },
    {
        "mtmc": "奥德费尔嘉盛化工码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.01787090,31.96535160",
        "id": "NJ_2c92402172362a80017240996da40438",
        "mt_id": "NJ_2c92402172362a80017240996da40438",
        "hdHdly": "3",
        "hdlc": "176.60"
    },
    {
        "mtmc": "常州建滔石化码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.01337505,31.96672292",
        "id": "NJ_2c92402172362a800172409b2304043c",
        "mt_id": "NJ_2c92402172362a800172409b2304043c",
        "hdHdly": "3",
        "hdlc": "177.50"
    },
    {
        "mtmc": "砂石集散中心码头",
        "sdmc": "九江水道",
        "x84": "115.78613846,29.77095659",
        "id": "NJ_2c92402172362a8001724ab5902406bd",
        "mt_id": "NJ_2c92402172362a8001724ab5902406bd",
        "hdHdly": "3",
        "hdlc": "813.00"
    },
    {
        "mtmc": "吉恩重工码头",
        "sdmc": "武穴水道",
        "x84": "115.64188492,29.84055031",
        "id": "NJ_2c92402172362a8001724ac8c64206c7",
        "mt_id": "NJ_2c92402172362a8001724ac8c64206c7",
        "hdHdly": "3",
        "hdlc": "830.90"
    },
    {
        "mtmc": "华中国际木材专用码头",
        "sdmc": "武穴水道",
        "x84": "115.64326650,29.84090728",
        "id": "NJ_2c92402172362a8001724ead204707f2",
        "mt_id": "NJ_2c92402172362a8001724ead204707f2",
        "hdHdly": "3",
        "hdlc": "830.60"
    },
    {
        "mtmc": "矶山园区公用码头",
        "sdmc": "马当南水道",
        "x84": "116.560814,29.939605",
        "id": "NJ_2c92402172362a8001724ed76e4d07f4",
        "mt_id": "NJ_2c92402172362a8001724ed76e4d07f4",
        "hdHdly": "3",
        "hdlc": "727.70"
    },
    {
        "mtmc": "海事码头趸船",
        "sdmc": "仪征水道(上段)",
        "x84": "119.21823740,32.21820949",
        "id": "NJ_2c92402172362a80017253b08a6e0e09",
        "mt_id": "NJ_2c92402172362a80017253b08a6e0e09",
        "hdHdly": "3",
        "hdlc": "294.30"
    },
    {
        "mtmc": "大唐南京电厂码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.21510806,32.21945255",
        "id": "NJ_2c92402172362a80017253b422f50e0a",
        "mt_id": "NJ_2c92402172362a80017253b422f50e0a",
        "hdHdly": "3",
        "hdlc": "294.50"
    },
    {
        "mtmc": "南京港龙潭港区汇能码头",
        "sdmc": "龙潭水道",
        "x84": "119.07881419,32.23368154",
        "id": "NJ_2c92402172362a80017253ed545b0e22",
        "mt_id": "NJ_2c92402172362a80017253ed545b0e22",
        "hdHdly": "3",
        "hdlc": "308.50"
    },
    {
        "mtmc": "龙潭水厂取水趸",
        "sdmc": "龙潭水道",
        "x84": "119.07598413,32.23027931",
        "id": "NJ_2c92402172362a80017254cf1f500e39",
        "mt_id": "NJ_2c92402172362a80017254cf1f500e39",
        "hdHdly": "3",
        "hdlc": "309.40"
    },
    {
        "mtmc": "南京港龙潭港码头",
        "sdmc": "龙潭水道",
        "x84": "119.04579117,32.21201991",
        "id": "NJ_2c92402172362a80017254e021a60e3b",
        "mt_id": "NJ_2c92402172362a80017254e021a60e3b",
        "hdHdly": "3",
        "hdlc": "311.00"
    },
    {
        "mtmc": "水政龙潭码头",
        "sdmc": "龙潭水道",
        "x84": "119.03512287,32.18872793",
        "id": "NJ_2c92402172362a80017254e40ca20e3c",
        "mt_id": "NJ_2c92402172362a80017254e40ca20e3c",
        "hdHdly": "3",
        "hdlc": "314.70"
    },
    {
        "mtmc": "南京港江海龙潭港区江海集团通用散杂货码头",
        "sdmc": "龙潭水道",
        "x84": "119.02856958,32.18473710",
        "id": "NJ_2c92402172362a80017254e7829d0e3d",
        "mt_id": "NJ_2c92402172362a80017254e7829d0e3d",
        "hdHdly": "3",
        "hdlc": "314.70"
    },
    {
        "mtmc": "南京港龙潭港区三期散货码头",
        "sdmc": "龙潭水道",
        "x84": "119.02441868,32.18221630",
        "id": "NJ_2c92402172362a80017254e9644c0e3e",
        "mt_id": "NJ_2c92402172362a80017254e9644c0e3e",
        "hdHdly": "3",
        "hdlc": "315.50"
    },
    {
        "mtmc": "宁港趸90-2",
        "sdmc": "龙潭水道",
        "x84": "119.02382736,32.18198630",
        "id": "NJ_2c92402172362a80017254edc3fa0e3f",
        "mt_id": "NJ_2c92402172362a80017254edc3fa0e3f",
        "hdHdly": "3",
        "hdlc": "316.80"
    },
    {
        "mtmc": "华能金陵电厂二期工程码头",
        "sdmc": "龙潭水道",
        "x84": "119.01679865,32.17792807",
        "id": "NJ_2c92402172362a8001725500c8770e42",
        "mt_id": "NJ_2c92402172362a8001725500c8770e42",
        "hdHdly": "3",
        "hdlc": "316.50"
    },
    {
        "mtmc": "长航公安(40-12)",
        "sdmc": "龙潭水道",
        "x84": "119.00275396,32.17270515",
        "id": "NJ_2c92402172362a80017259efdaf60f9a",
        "mt_id": "NJ_2c92402172362a80017259efdaf60f9a",
        "hdHdly": "3",
        "hdlc": "318.00"
    },
    {
        "mtmc": "小野田码头",
        "sdmc": "龙潭水道",
        "x84": "118.98484380,32.17012790",
        "id": "NJ_2c92402172362a80017259f80d850fa6",
        "mt_id": "NJ_2c92402172362a80017259f80d850fa6",
        "hdHdly": "3",
        "hdlc": "319.60"
    },
    {
        "mtmc": "长燃684基地码头（长轮26048）",
        "sdmc": "龙潭水道",
        "x84": "118.96872033,32.16902353",
        "id": "NJ_2c92402172362a80017259f9a3570faa",
        "mt_id": "NJ_2c92402172362a80017259f9a3570faa",
        "hdHdly": "3",
        "hdlc": "321.30"
    },
    {
        "mtmc": "长燃684基地码头（长轮29008）",
        "sdmc": "龙潭水道",
        "x84": "118.96676899,32.16907974",
        "id": "NJ_2c92402172362a80017259fb8ae30fad",
        "mt_id": "NJ_2c92402172362a80017259fb8ae30fad",
        "hdHdly": "3",
        "hdlc": "321.40"
    },
    {
        "mtmc": "长燃684基地码头（长轮26021）",
        "sdmc": "龙潭水道",
        "x84": "118.96540966,32.16928504",
        "id": "NJ_2c92402172362a80017259fd47480fb0",
        "mt_id": "NJ_2c92402172362a80017259fd47480fb0",
        "hdHdly": "3",
        "hdlc": "321.60"
    },
    {
        "mtmc": "长燃684基地码头（长轮28004）",
        "sdmc": "龙潭水道",
        "x84": "118.96322357,32.16947454",
        "id": "NJ_2c92402172362a80017259fe8ce60fb1",
        "mt_id": "NJ_2c92402172362a80017259fe8ce60fb1",
        "hdHdly": "3",
        "hdlc": "321.80"
    },
    {
        "mtmc": "大港股份码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.75548770,32.21449334",
        "id": "NJ_2c92402172362a8001725e02767710db",
        "mt_id": "NJ_2c92402172362a8001725e02767710db",
        "hdHdly": "3",
        "hdlc": "220.00"
    },
    {
        "mtmc": "园博园游艇码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.83303001,32.20090519",
        "id": "NJ_2c92402172362a8001725e22628410ef",
        "mt_id": "NJ_2c92402172362a8001725e22628410ef",
        "hdHdly": "3",
        "hdlc": "215.00"
    },
    {
        "mtmc": "安徽华谊化工有限公司液体化工码头",
        "sdmc": "芜湖大桥水道（上段）",
        "x84": "118.33248518,31.37358994",
        "id": "NJ_2c92402172362a80017273d7f7ba1765",
        "mt_id": "NJ_2c92402172362a80017273d7f7ba1765",
        "hdHdly": "3",
        "hdlc": "438.50"
    },
    {
        "mtmc": "南方水泥专用码头",
        "sdmc": "东北横水道",
        "x84": "116.55753515,29.92600237",
        "id": "NJ_2c92402172362a800172740d08521770",
        "mt_id": "NJ_2c92402172362a800172740d08521770",
        "hdHdly": "3",
        "hdlc": "730.00"
    },
    {
        "mtmc": "瑞昌港新洋丰肥业码头",
        "sdmc": "武穴水道",
        "x84": "115.60366388,29.83473953",
        "id": "NJ_2c92402172362a80017277f7402f18d8",
        "mt_id": "NJ_2c92402172362a80017277f7402f18d8",
        "hdHdly": "3",
        "hdlc": "834.80"
    },
    {
        "mtmc": "池州港牛头山港区长九专用码头一期码头",
        "sdmc": "安庆南水道",
        "x84": "117.21927238,30.48122743",
        "id": "NJ_2c92402172362a8001727e7b30771c45",
        "mt_id": "NJ_2c92402172362a8001727e7b30771c45",
        "hdHdly": "3",
        "hdlc": "623.80"
    },
    {
        "mtmc": "大地水泥码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.79931579,32.16603743",
        "id": "NJ_2c92402172362a8001729c9562a82b8b",
        "mt_id": "NJ_2c92402172362a8001729c9562a82b8b",
        "hdHdly": "3",
        "hdlc": "207.00"
    },
    {
        "mtmc": "大津清洁能源码头（原龙源码头）",
        "sdmc": "太平洲捷水道",
        "x84": "119.91022717,32.05491736",
        "id": "NJ_2c92402172362a8001729ca5dca62b92",
        "mt_id": "NJ_2c92402172362a8001729ca5dca62b92",
        "hdHdly": "3",
        "hdlc": "191.50"
    },
    {
        "mtmc": "通顺码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.89899138,32.06507239",
        "id": "NJ_2c92402172362a8001729cb613fe2b97",
        "mt_id": "NJ_2c92402172362a8001729cb613fe2b97",
        "hdHdly": "3",
        "hdlc": "193.00"
    },
    {
        "mtmc": "德胜港码头（含港益重工码头）",
        "sdmc": "录安洲右汊",
        "x84": "119.95515646,31.99205698",
        "id": "NJ_2c92402172362a8001729cc3e0802b9a",
        "mt_id": "NJ_2c92402172362a8001729cc3e0802b9a",
        "hdHdly": "3",
        "hdlc": "187.00"
    },
    {
        "mtmc": "常州录安洲夹江码头",
        "sdmc": "录安洲右汊",
        "x84": "119.96643903,31.98619860",
        "id": "NJ_2c92402172362a8001729ccb49572b9b",
        "mt_id": "NJ_2c92402172362a8001729ccb49572b9b",
        "hdHdly": "3",
        "hdlc": "187.00"
    },
    {
        "mtmc": "大津码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.90621252,32.05622208",
        "id": "NJ_2c92402172362a8001729cd0aff62ba0",
        "mt_id": "NJ_2c92402172362a8001729cd0aff62ba0",
        "hdHdly": "3",
        "hdlc": "193.00"
    },
    {
        "mtmc": "风头坝航道码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.828885,32.203423",
        "id": "NJ_2c92402172362a8001729d425f0e2c12",
        "mt_id": "NJ_2c92402172362a8001729d425f0e2c12",
        "hdHdly": "3",
        "hdlc": "210.00"
    },
    {
        "mtmc": "轮船港航道码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.894246,32.068088",
        "id": "NJ_2c92402172362a8001729d44d3492c15",
        "mt_id": "NJ_2c92402172362a8001729d44d3492c15",
        "hdHdly": "3",
        "hdlc": "195.00"
    },
    {
        "mtmc": "东方造船厂码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.25772792,31.94687529",
        "id": "NJ_2c92402172362aaf01723b8604680592",
        "mt_id": "NJ_2c92402172362aaf01723b8604680592",
        "hdHdly": "3",
        "hdlc": "154.20"
    },
    {
        "mtmc": "江苏中泰钢构码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.25108244,31.94567110",
        "id": "NJ_2c92402172362aaf01723b87f1140595",
        "mt_id": "NJ_2c92402172362aaf01723b87f1140595",
        "hdHdly": "3",
        "hdlc": "155.60"
    },
    {
        "mtmc": "(扬子)长博船厂码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.22760583,31.94279329",
        "id": "NJ_2c92402172362aaf01723b964ff10596",
        "mt_id": "NJ_2c92402172362aaf01723b964ff10596",
        "hdHdly": "3",
        "hdlc": "157.70"
    },
    {
        "mtmc": "江苏海事局龙潭趸船浮码头",
        "sdmc": "龙潭水道",
        "x84": "119.03451649,32.18891267",
        "id": "NJ_2c92402172362aaf01724e9bbd4f0ab7",
        "mt_id": "NJ_2c92402172362aaf01724e9bbd4f0ab7",
        "hdHdly": "3",
        "hdlc": "315.40"
    },
    {
        "mtmc": "江苏海事局新生圩趸船浮码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.79449276,32.13942088",
        "id": "NJ_2c92402172362aaf01724e9e79fe0abb",
        "mt_id": "NJ_2c92402172362aaf01724e9e79fe0abb",
        "hdHdly": "3",
        "hdlc": "339.00"
    },
    {
        "mtmc": "江苏海事局南京江宁趸船浮码头",
        "sdmc": "凡家矶水道",
        "x84": "118.54207077,31.84095990",
        "id": "NJ_2c92402172362aaf01724eb5bc100abc",
        "mt_id": "NJ_2c92402172362aaf01724eb5bc100abc",
        "hdHdly": "3",
        "hdlc": "382.20"
    },
    {
        "mtmc": "赤湖作业区公用码头",
        "sdmc": "新洲水道",
        "x84": "115.77417570,29.77689886",
        "id": "NJ_2c92402172362aaf01724fe115890b36",
        "mt_id": "NJ_2c92402172362aaf01724fe115890b36",
        "hdHdly": "3",
        "hdlc": "815.00"
    },
    {
        "mtmc": "三江口龙宇码头",
        "sdmc": "龙潭水道",
        "x84": "119.08349684,32.23645692",
        "id": "NJ_2c92402172362aaf01724fec97900b37",
        "mt_id": "NJ_2c92402172362aaf01724fec97900b37",
        "hdHdly": "3",
        "hdlc": "308.50"
    },
    {
        "mtmc": "南京仙新路过江通道临时栈桥码头",
        "sdmc": "龙潭水道",
        "x84": "118.89523682,32.18670231",
        "id": "NJ_2c92402172362aaf01724ffce1f10b38",
        "mt_id": "NJ_2c92402172362aaf01724ffce1f10b38",
        "hdHdly": "3",
        "hdlc": "328.00"
    },
    {
        "mtmc": "镇江港港务有限公司通用码头",
        "sdmc": "焦山水道",
        "x84": "119.37800553,32.21439905",
        "id": "NJ_2c92402172362aaf0172500f52200b3d",
        "mt_id": "NJ_2c92402172362aaf0172500f52200b3d",
        "hdHdly": "3",
        "hdlc": "277.40"
    },
    {
        "mtmc": "镇江港港务有限公司客运码头",
        "sdmc": "焦山水道",
        "x84": "119.38076470,32.21734338",
        "id": "NJ_2c92402172362aaf01725010bc3e0b40",
        "mt_id": "NJ_2c92402172362aaf01725010bc3e0b40",
        "hdHdly": "3",
        "hdlc": "277.00"
    },
    {
        "mtmc": "镇江海事征润州基地码头",
        "sdmc": "焦山水道",
        "x84": "119.39871133,32.23530332",
        "id": "NJ_2c92402172362aaf01725015651b0b41",
        "mt_id": "NJ_2c92402172362aaf01725015651b0b41",
        "hdHdly": "3",
        "hdlc": "274.80"
    },
    {
        "mtmc": "扬州二电厂1号煤码头",
        "sdmc": "焦山水道",
        "x84": "119.41394147,32.26093124",
        "id": "NJ_2c92402172362aaf0172501cedce0b43",
        "mt_id": "NJ_2c92402172362aaf0172501cedce0b43",
        "hdHdly": "3",
        "hdlc": "271.20"
    },
    {
        "mtmc": "河道采砂管理联合执法码头",
        "sdmc": "九江水道",
        "x84": "115.86745437,29.73367476",
        "id": "NJ_2c92402172362aaf0172502e6dc20b44",
        "mt_id": "NJ_2c92402172362aaf0172502e6dc20b44",
        "hdHdly": "3",
        "hdlc": "804.00"
    },
    {
        "mtmc": "鼎盛港机码头",
        "sdmc": "焦山水道",
        "x84": "119.52219163,32.25709509",
        "id": "NJ_2c92402172362aaf017254196cbf0c1f",
        "mt_id": "NJ_2c92402172362aaf017254196cbf0c1f",
        "hdHdly": "3",
        "hdlc": "261.80"
    },
    {
        "mtmc": "新民洲公用码头",
        "sdmc": "焦山水道",
        "x84": "119.52251224,32.25464346",
        "id": "NJ_2c92402172362aaf0172541c04410c25",
        "mt_id": "NJ_2c92402172362aaf0172541c04410c25",
        "hdHdly": "3",
        "hdlc": "260.20"
    },
    {
        "mtmc": "新民洲海事码头",
        "sdmc": "焦山水道",
        "x84": "119.55301569,32.23867639",
        "id": "NJ_2c92402172362aaf0172541ea46e0c29",
        "mt_id": "NJ_2c92402172362aaf0172541ea46e0c29",
        "hdHdly": "3",
        "hdlc": "258.30"
    },
    {
        "mtmc": "谏壁发电厂5000DWT煤码头",
        "sdmc": "丹徒直水道",
        "x84": "119.57319228,32.18516188",
        "id": "NJ_2c92402172362aaf01725420d41e0c2c",
        "mt_id": "NJ_2c92402172362aaf01725420d41e0c2c",
        "hdHdly": "3",
        "hdlc": "252.70"
    },
    {
        "mtmc": "港龙石化码头",
        "sdmc": "丹徒直水道",
        "x84": "119.59995609,32.18540279",
        "id": "NJ_2c92402172362aaf017254cc64f40ca5",
        "mt_id": "NJ_2c92402172362aaf017254cc64f40ca5",
        "hdHdly": "3",
        "hdlc": "249.90"
    },
    {
        "mtmc": "镇江港国际集装箱有限公司码头",
        "sdmc": "丹徒直水道",
        "x84": "119.62453781,32.19026679",
        "id": "NJ_2c92402172362aaf017254d3b8da0caa",
        "mt_id": "NJ_2c92402172362aaf017254d3b8da0caa",
        "hdHdly": "3",
        "hdlc": "247.10"
    },
    {
        "mtmc": "大港三期集装箱及多用泊位码头",
        "sdmc": "丹徒直水道",
        "x84": "119.63195739,32.19119945",
        "id": "NJ_2c92402172362aaf017254db1d7a0cc8",
        "mt_id": "NJ_2c92402172362aaf017254db1d7a0cc8",
        "hdHdly": "3",
        "hdlc": "246.40"
    },
    {
        "mtmc": "大港三期散货码头",
        "sdmc": "丹徒直水道",
        "x84": "119.63827050,32.19306637",
        "id": "NJ_2c92402172362aaf017254dc4e590cd2",
        "mt_id": "NJ_2c92402172362aaf017254dc4e590cd2",
        "hdHdly": "3",
        "hdlc": "245.80"
    },
    {
        "mtmc": "大港矿石码头",
        "sdmc": "丹徒直水道",
        "x84": "119.64982552,32.19701274",
        "id": "NJ_2c92402172362aaf01725f3462eb0ff7",
        "mt_id": "NJ_2c92402172362aaf01725f3462eb0ff7",
        "hdHdly": "3",
        "hdlc": "245.10"
    },
    {
        "mtmc": "镇江港务有限公司码头",
        "sdmc": "丹徒直水道",
        "x84": "119.65523874,32.19865989",
        "id": "NJ_2c92402172362aaf01725f386c4d0ff8",
        "mt_id": "NJ_2c92402172362aaf01725f386c4d0ff8",
        "hdHdly": "3",
        "hdlc": "244.30"
    },
    {
        "mtmc": "省物质储运公司码头",
        "sdmc": "丹徒直水道",
        "x84": "119.66869757,32.20738923",
        "id": "NJ_2c92402172362aaf01725f3a43100ff9",
        "mt_id": "NJ_2c92402172362aaf01725f3a43100ff9",
        "hdHdly": "3",
        "hdlc": "244.50"
    },
    {
        "mtmc": "金东纸业码头",
        "sdmc": "丹徒直水道",
        "x84": ",",
        "id": "NJ_2c92402172362aaf01725f3be92e0ffa",
        "mt_id": "NJ_2c92402172362aaf01725f3be92e0ffa",
        "hdHdly": "3",
        "hdlc": "243.90"
    },
    {
        "mtmc": "奇美化工#1码头",
        "sdmc": "丹徒直水道",
        "x84": "119.67719659,32.21853038",
        "id": "NJ_2c92402172362aaf01725f3f79680fff",
        "mt_id": "NJ_2c92402172362aaf01725f3f79680fff",
        "hdHdly": "3",
        "hdlc": "243.30"
    },
    {
        "mtmc": "奇美化工#2码头",
        "sdmc": "丹徒直水道",
        "x84": "119.67791341,32.21981276",
        "id": "NJ_2c92402172362aaf01725f40d56e1000",
        "mt_id": "NJ_2c92402172362aaf01725f40d56e1000",
        "hdHdly": "3",
        "hdlc": "242.90"
    },
    {
        "mtmc": "五峰山船厂#1码头",
        "sdmc": "丹徒直水道",
        "x84": "119.68673088,32.24082868",
        "id": "NJ_2c92402172362aaf01725f44172a1001",
        "mt_id": "NJ_2c92402172362aaf01725f44172a1001",
        "hdHdly": "3",
        "hdlc": "240.50"
    },
    {
        "mtmc": "镇江港高桥港区荷花池作业区一期散货码头",
        "sdmc": "丹徒直水道",
        "x84": "119.62989723,32.19974244",
        "id": "NJ_2c92402172362aaf01725f4632591002",
        "mt_id": "NJ_2c92402172362aaf01725f4632591002",
        "hdHdly": "3",
        "hdlc": "246.30"
    },
    {
        "mtmc": "高桥汽渡趸船",
        "sdmc": "丹徒直水道",
        "x84": "119.65268144,32.21293730",
        "id": "NJ_2c92402172362aaf01725f4b10cd1005",
        "mt_id": "NJ_2c92402172362aaf01725f4b10cd1005",
        "hdHdly": "3",
        "hdlc": "244.80"
    },
    {
        "mtmc": "高桥中长燃水上服务区",
        "sdmc": "丹徒直水道",
        "x84": "119.65864746,32.21736335",
        "id": "NJ_2c92402172362aaf01725f4d34491006",
        "mt_id": "NJ_2c92402172362aaf01725f4d34491006",
        "hdHdly": "3",
        "hdlc": "244.10"
    },
    {
        "mtmc": "中铁宝桥码头",
        "sdmc": "和畅洲北汊",
        "x84": "119.58192144,32.25880410",
        "id": "NJ_2c92402172362aaf01725f51dad41007",
        "mt_id": "NJ_2c92402172362aaf01725f51dad41007",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "扬州大洋造船厂码头",
        "sdmc": "和畅洲北汊",
        "x84": "119.58606229,32.25838490",
        "id": "NJ_2c92402172362aaf01725f5696ec1008",
        "mt_id": "NJ_2c92402172362aaf01725f5696ec1008",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "中铁十四局芜湖大盾构基地码头",
        "sdmc": "白茆水道",
        "x84": "118.29866641,31.30722127",
        "id": "NJ_2c92402172362aaf017272fc8f1b1352",
        "mt_id": "NJ_2c92402172362aaf017272fc8f1b1352",
        "hdHdly": "3",
        "hdlc": "452.00"
    },
    {
        "mtmc": "安徽省汇隆再生资源有限公司公用码头",
        "sdmc": "裕溪口水道",
        "x84": "118.31768072,31.50089802",
        "id": "NJ_2c92402172362aaf0172730b4b8d135c",
        "mt_id": "NJ_2c92402172362aaf0172730b4b8d135c",
        "hdHdly": "3",
        "hdlc": "427.50"
    },
    {
        "mtmc": "长航公安码头",
        "sdmc": "口岸直水道",
        "x84": "119.72489070,32.28168758",
        "id": "NJ_2c92402172362aaf01727cf31add157a",
        "mt_id": "NJ_2c92402172362aaf01727cf31add157a",
        "hdHdly": "3",
        "hdlc": "231.50"
    },
    {
        "mtmc": "长江引航码头",
        "sdmc": "口岸直水道",
        "x84": "119.72615170,32.28242980",
        "id": "NJ_2c92402172362aaf01727cf4da02157b",
        "mt_id": "NJ_2c92402172362aaf01727cf4da02157b",
        "hdHdly": "3",
        "hdlc": "235.00"
    },
    {
        "mtmc": "长江镇江航道处丰乐桥码头",
        "sdmc": "口岸直水道",
        "x84": "119.72676637,32.28277381",
        "id": "NJ_2c92402172362aaf01727cf620ba157e",
        "mt_id": "NJ_2c92402172362aaf01727cf620ba157e",
        "hdHdly": "3",
        "hdlc": "235.00"
    },
    {
        "mtmc": "丰乐桥大全码头",
        "sdmc": "口岸直水道",
        "x84": "119.73733974,32.29002862",
        "id": "NJ_2c92402172362aaf01727cf938681582",
        "mt_id": "NJ_2c92402172362aaf01727cf938681582",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "雷公岛大全码头",
        "sdmc": "口岸直水道",
        "x84": "119.73596594,32.29515690",
        "id": "NJ_2c92402172362aaf01727cfb15551584",
        "mt_id": "NJ_2c92402172362aaf01727cfb15551584",
        "hdHdly": "3",
        "hdlc": "234.00"
    },
    {
        "mtmc": "兴隆港务码头",
        "sdmc": "口岸直水道",
        "x84": "119.87923233,32.19921748",
        "id": "NJ_2c92402172362aaf01727cfd3edc1586",
        "mt_id": "NJ_2c92402172362aaf01727cfd3edc1586",
        "hdHdly": "3",
        "hdlc": "208.00"
    },
    {
        "mtmc": "江西煤炭储备中心九江城东通用码头",
        "sdmc": "张家洲南水道",
        "x84": "116.08290140,29.76157667",
        "id": "NJ_2c92402172362aaf0172824ea57e19aa",
        "mt_id": "NJ_2c92402172362aaf0172824ea57e19aa",
        "hdHdly": "3",
        "hdlc": "782.10"
    },
    {
        "mtmc": "九江联达码头",
        "sdmc": "张家洲南水道",
        "x84": "116.247206,29.772831",
        "id": "NJ_2c92402172362aaf0172829491761a5e",
        "mt_id": "NJ_2c92402172362aaf0172829491761a5e",
        "hdHdly": "3",
        "hdlc": "763.00"
    },
    {
        "mtmc": "万佳码头",
        "sdmc": "张家洲南水道",
        "x84": "116.22932600,29.75722512",
        "id": "NJ_2c92402172362aaf01729d62028d23ed",
        "mt_id": "NJ_2c92402172362aaf01729d62028d23ed",
        "hdHdly": "3",
        "hdlc": "766.50"
    },
    {
        "mtmc": "城西集装箱码头",
        "sdmc": "九江水道",
        "x84": "115.88911831,29.72522574",
        "id": "NJ_2c92402172362aaf01729d7c3c7e244d",
        "mt_id": "NJ_2c92402172362aaf01729d7c3c7e244d",
        "hdHdly": "3",
        "hdlc": "802.10"
    },
    {
        "mtmc": "翔升造船码头",
        "sdmc": "东北横水道",
        "x84": "116.53256139,29.89371081",
        "id": "NJ_2c924021729d90270172ab5d88570c2a",
        "mt_id": "NJ_2c924021729d90270172ab5d88570c2a",
        "hdHdly": "3",
        "hdlc": "733.80"
    },
    {
        "mtmc": "国电码头",
        "sdmc": "九江大桥水道",
        "x84": "116.02698578,29.74900790",
        "id": "NJ_2c924021729d929b0172db0bbe5e18f3",
        "mt_id": "NJ_2c924021729d929b0172db0bbe5e18f3",
        "hdHdly": "3",
        "hdlc": "788.10"
    },
    {
        "mtmc": "扬中市磊金建材码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.79825792,32.15538542",
        "id": "NJ_2c924021730d85b1017337ef96f80c56",
        "mt_id": "NJ_2c924021730d85b1017337ef96f80c56",
        "hdHdly": "3",
        "hdlc": "204.00"
    },
    {
        "mtmc": "安庆市化学品洗舱站及配套设施",
        "sdmc": "安庆水道",
        "x84": "117.01274557,30.49773722",
        "id": "NJ_2c92402174294d7f017433d730a20565",
        "mt_id": "NJ_2c92402174294d7f017433d730a20565",
        "hdHdly": "3",
        "hdlc": "642.30"
    },
    {
        "mtmc": "安徽群利港务有限公司码头",
        "sdmc": "成德洲东港水道",
        "x84": "117.77483268,31.00029687",
        "id": "NJ_2c92402174294fdf01742f555c7e021a",
        "mt_id": "NJ_2c92402174294fdf01742f555c7e021a",
        "hdHdly": "3",
        "hdlc": "532.00"
    },
    {
        "mtmc": "安徽长江物流有限公司码头",
        "sdmc": "太平府水道（下段）",
        "x84": "118.46266472,31.58549580",
        "id": "NJ_2c92402174294fdf01744d55b98410f3",
        "mt_id": "NJ_2c92402174294fdf01744d55b98410f3",
        "hdHdly": "3",
        "hdlc": "414.00"
    },
    {
        "mtmc": "马鞍山水上交通安全监管和应急救助采石基地工程",
        "sdmc": "太平府水道（下段）",
        "x84": "118.44836691,31.65683432",
        "id": "NJ_2c92402174294fdf01744d63d55010f6",
        "mt_id": "NJ_2c92402174294fdf01744d63d55010f6",
        "hdHdly": "3",
        "hdlc": "405.00"
    },
    {
        "mtmc": "安庆太子矶巡航执法码头",
        "sdmc": "太子矶水道",
        "x84": "117.25268471,30.57109998",
        "id": "NJ_2c92402174294fdf01745209142612b3",
        "mt_id": "NJ_2c92402174294fdf01745209142612b3",
        "hdHdly": "3",
        "hdlc": "615.00"
    },
    {
        "mtmc": "南粮壹号趸船",
        "sdmc": "龙潭水道",
        "x84": "118.94437090,32.16972432",
        "id": "NJ_2c92402174522c10017457f6c24806b7",
        "mt_id": "NJ_2c92402174522c10017457f6c24806b7",
        "hdHdly": "3",
        "hdlc": "323.60"
    },
    {
        "mtmc": "石埠寨粮库码头",
        "sdmc": "龙潭水道",
        "x84": "118.94326843,32.16971511",
        "id": "NJ_2c92402174522c10017457fac78306bc",
        "mt_id": "NJ_2c92402174522c10017457fac78306bc",
        "hdHdly": "3",
        "hdlc": "323.60"
    },
    {
        "mtmc": "无名氏码头",
        "sdmc": "龙潭水道",
        "x84": "118.93648210,32.16799901",
        "id": "NJ_2c92402174522c10017457fcd50506bf",
        "mt_id": "NJ_2c92402174522c10017457fcd50506bf",
        "hdHdly": "3",
        "hdlc": "324.30"
    },
    {
        "mtmc": "无名氏码头",
        "sdmc": "龙潭水道",
        "x84": "118.93601884,32.16863535",
        "id": "NJ_2c92402174522c10017457fec24d06c2",
        "mt_id": "NJ_2c92402174522c10017457fec24d06c2",
        "hdHdly": "3",
        "hdlc": "324.40"
    },
    {
        "mtmc": "南京海事禁航基地",
        "sdmc": "龙潭水道",
        "x84": "118.93584377,32.16910278",
        "id": "NJ_2c92402174522c1001745801162006c3",
        "mt_id": "NJ_2c92402174522c1001745801162006c3",
        "hdHdly": "3",
        "hdlc": "324.40"
    },
    {
        "mtmc": "南京弘龙物流有限公司码头",
        "sdmc": "龙潭水道",
        "x84": "118.88764991,32.17687571",
        "id": "NJ_2c92402174522c1001745819c6190700",
        "mt_id": "NJ_2c92402174522c1001745819c6190700",
        "hdHdly": "3",
        "hdlc": "329.00"
    },
    {
        "mtmc": "新生圩石化码头",
        "sdmc": "龙潭水道",
        "x84": "118.88223232,32.17685498",
        "id": "NJ_2c92402174522c10017458281365070f",
        "mt_id": "NJ_2c92402174522c10017458281365070f",
        "hdHdly": "3",
        "hdlc": "329.30"
    },
    {
        "mtmc": "南京港新生圩液体化工码头",
        "sdmc": "龙潭水道",
        "x84": "118.88012322,32.17679123",
        "id": "NJ_2c92402174522c100174582addde0710",
        "mt_id": "NJ_2c92402174522c100174582addde0710",
        "hdHdly": "3",
        "hdlc": "329.40"
    },
    {
        "mtmc": "南京港码头",
        "sdmc": "龙潭水道",
        "x84": "118.87561596,32.17602983",
        "id": "NJ_2c92402174522c100174582d26c30711",
        "mt_id": "NJ_2c92402174522c100174582d26c30711",
        "hdHdly": "3",
        "hdlc": "329.70"
    },
    {
        "mtmc": "长江镇江航道处码头",
        "sdmc": "焦山水道",
        "x84": "119.40986459,32.23822360",
        "id": "NJ_2c924021746cec5b01748b89f6401271",
        "mt_id": "NJ_2c924021746cec5b01748b89f6401271",
        "hdHdly": "3",
        "hdlc": "274.10"
    },
    {
        "mtmc": "扬州第二电二期煤码头",
        "sdmc": "焦山水道",
        "x84": "119.42656544,32.26683273",
        "id": "NJ_2c924021746cec5b01748b8d54ad1272",
        "mt_id": "NJ_2c924021746cec5b01748b8d54ad1272",
        "hdHdly": "3",
        "hdlc": "270.00"
    },
    {
        "mtmc": "扬州港务局浮码头",
        "sdmc": "焦山水道",
        "x84": "119.44768390,32.27249088",
        "id": "NJ_2c924021746cec5b01748b8fa488127f",
        "mt_id": "NJ_2c924021746cec5b01748b8fa488127f",
        "hdHdly": "3",
        "hdlc": "268.40"
    },
    {
        "mtmc": "扬州港#4码头",
        "sdmc": "焦山水道",
        "x84": "119.44900862,32.27283033",
        "id": "NJ_2c924021746cec5b01748b9204ce1281",
        "mt_id": "NJ_2c924021746cec5b01748b9204ce1281",
        "hdHdly": "3",
        "hdlc": "267.80"
    },
    {
        "mtmc": "大沙海事趸船",
        "sdmc": "焦山水道",
        "x84": "119.55613599,32.19052287",
        "id": "NJ_2c924021746cec5b01748b94bbfe1290",
        "mt_id": "NJ_2c924021746cec5b01748b94bbfe1290",
        "hdHdly": "3",
        "hdlc": "254.50"
    },
    {
        "mtmc": "索普船舶修造舾装码头",
        "sdmc": "焦山水道",
        "x84": "119.55150664,32.19167885",
        "id": "NJ_2c924021746cec5b01748b9866491292",
        "mt_id": "NJ_2c924021746cec5b01748b9866491292",
        "hdHdly": "3",
        "hdlc": "249.10"
    },
    {
        "mtmc": "索普CO2浮码头",
        "sdmc": "焦山水道",
        "x84": "119.55276453,32.19034866",
        "id": "NJ_2c924021746cec5b01748b9a4f6b129c",
        "mt_id": "NJ_2c924021746cec5b01748b9a4f6b129c",
        "hdHdly": "3",
        "hdlc": "254.80"
    },
    {
        "mtmc": "索普（集团）公司化工码头",
        "sdmc": "焦山水道",
        "x84": "119.55276043,32.19118731",
        "id": "NJ_2c924021746cec5b01748b9ce6ce129e",
        "mt_id": "NJ_2c924021746cec5b01748b9ce6ce129e",
        "hdHdly": "3",
        "hdlc": "254.50"
    },
    {
        "mtmc": "泰州海事码头",
        "sdmc": "口岸直水道",
        "x84": "119.88739208,32.24750734",
        "id": "NJ_2c924021746cec5b017495defc101b16",
        "mt_id": "NJ_2c924021746cec5b017495defc101b16",
        "hdHdly": "3",
        "hdlc": "211.80"
    },
    {
        "mtmc": "泰州航道管理处码头",
        "sdmc": "口岸直水道",
        "x84": "119.88907247,32.24441567",
        "id": "NJ_2c924021746cec5b017495e231b71b17",
        "mt_id": "NJ_2c924021746cec5b017495e231b71b17",
        "hdHdly": "3",
        "hdlc": "211.80"
    },
    {
        "mtmc": "江苏海企港务有限公司化工码头",
        "sdmc": "口岸直水道",
        "x84": "119.88925542,32.24231465",
        "id": "NJ_2c924021746cec5b017495e3ec951b1a",
        "mt_id": "NJ_2c924021746cec5b017495e3ec951b1a",
        "hdHdly": "3",
        "hdlc": "211.60"
    },
    {
        "mtmc": "永安港区#3码头",
        "sdmc": "口岸直水道",
        "x84": "119.89086319,32.23771690",
        "id": "NJ_2c924021746cec5b017495e7b4e71b1d",
        "mt_id": "NJ_2c924021746cec5b017495e7b4e71b1d",
        "hdHdly": "3",
        "hdlc": "211.00"
    },
    {
        "mtmc": "泰州梅兰码头",
        "sdmc": "口岸直水道",
        "x84": "119.89161894,32.23569753",
        "id": "NJ_2c924021746cec5b017495e973021b1e",
        "mt_id": "NJ_2c924021746cec5b017495e973021b1e",
        "hdHdly": "3",
        "hdlc": "210.80"
    },
    {
        "mtmc": "泰州高港港区永安作业区码头",
        "sdmc": "口岸直水道",
        "x84": "119.89316268,32.23121692",
        "id": "NJ_2c924021746cec5b017495eae8ca1b1f",
        "mt_id": "NJ_2c924021746cec5b017495eae8ca1b1f",
        "hdHdly": "3",
        "hdlc": "210.30"
    },
    {
        "mtmc": "马甸三福船厂码头",
        "sdmc": "口岸直水道",
        "x84": "119.89811862,32.22064294",
        "id": "NJ_2c924021746cec5b017495ed34fb1b20",
        "mt_id": "NJ_2c924021746cec5b017495ed34fb1b20",
        "hdHdly": "3",
        "hdlc": "209.00"
    },
    {
        "mtmc": "泰州电厂大件码头",
        "sdmc": "口岸直水道",
        "x84": "119.90462882,32.19351285",
        "id": "NJ_2c924021746cec5b017495eeffb81b21",
        "mt_id": "NJ_2c924021746cec5b017495eeffb81b21",
        "hdHdly": "3",
        "hdlc": "204.60"
    },
    {
        "mtmc": "泰州口岸船舶有限公司码头",
        "sdmc": "口岸直水道",
        "x84": "119.90718645,32.18139453",
        "id": "NJ_2c924021746cec5b017495f0b17e1b22",
        "mt_id": "NJ_2c924021746cec5b017495f0b17e1b22",
        "hdHdly": "3",
        "hdlc": "204.60"
    },
    {
        "mtmc": "阿尔贝尔化工码头",
        "sdmc": "口岸直水道",
        "x84": "119.91013326,32.16099781",
        "id": "NJ_2c924021746cec5b017495f99b881b31",
        "mt_id": "NJ_2c924021746cec5b017495f99b881b31",
        "hdHdly": "3",
        "hdlc": "202.40"
    },
    {
        "mtmc": "三木通用码头",
        "sdmc": "口岸直水道",
        "x84": "119.91122991,32.15618294",
        "id": "NJ_2c924021746cec5b017495fb9da01b34",
        "mt_id": "NJ_2c924021746cec5b017495fb9da01b34",
        "hdHdly": "3",
        "hdlc": "202.00"
    },
    {
        "mtmc": "三木化工码头",
        "sdmc": "口岸直水道",
        "x84": "119.91184791,32.15292784",
        "id": "NJ_2c924021746cec5b017495fd53ad1b38",
        "mt_id": "NJ_2c924021746cec5b017495fd53ad1b38",
        "hdHdly": "3",
        "hdlc": "201.60"
    },
    {
        "mtmc": "新浦化工码头",
        "sdmc": "口岸直水道",
        "x84": "119.91245524,32.14970881",
        "id": "NJ_2c924021746cec5b01749611a64a1b3c",
        "mt_id": "NJ_2c924021746cec5b01749611a64a1b3c",
        "hdHdly": "3",
        "hdlc": "201.30"
    },
    {
        "mtmc": "新浦化工#2码头",
        "sdmc": "口岸直水道",
        "x84": "119.91296923,32.14428929",
        "id": "NJ_2c924021746cec5b01749616225c1b3e",
        "mt_id": "NJ_2c924021746cec5b01749616225c1b3e",
        "hdHdly": "3",
        "hdlc": "200.70"
    },
    {
        "mtmc": "过船港万吨级通用码头",
        "sdmc": "口岸直水道",
        "x84": "119.91356513,32.14183678",
        "id": "NJ_2c924021746cec5b01749618bcad1b3f",
        "mt_id": "NJ_2c924021746cec5b01749618bcad1b3f",
        "hdHdly": "3",
        "hdlc": "200.40"
    },
    {
        "mtmc": "过船港海事码头",
        "sdmc": "口岸直水道",
        "x84": "119.91550979,32.13645429",
        "id": "NJ_2c924021746cec5b0174961a6f6e1b40",
        "mt_id": "NJ_2c924021746cec5b0174961a6f6e1b40",
        "hdHdly": "3",
        "hdlc": "199.80"
    },
    {
        "mtmc": "热电厂煤码头",
        "sdmc": "口岸直水道",
        "x84": "119.91773941,32.13212707",
        "id": "NJ_2c924021746cec5b0174962076a21b43",
        "mt_id": "NJ_2c924021746cec5b0174962076a21b43",
        "hdHdly": "3",
        "hdlc": "199.30"
    },
    {
        "mtmc": "通用码头",
        "sdmc": "口岸直水道",
        "x84": "119.91659520,32.13170247",
        "id": "NJ_2c924021746cec5b017496235fde1b45",
        "mt_id": "NJ_2c924021746cec5b017496235fde1b45",
        "hdHdly": "3",
        "hdlc": "199.20"
    },
    {
        "mtmc": "金燕仓储码头",
        "sdmc": "口岸直水道",
        "x84": "119.91859303,32.12567967",
        "id": "NJ_2c924021746cec5b01749624d20b1b46",
        "mt_id": "NJ_2c924021746cec5b01749624d20b1b46",
        "hdHdly": "3",
        "hdlc": "198.60"
    },
    {
        "mtmc": "中山码头",
        "sdmc": "南京水道",
        "x84": "118.72761503,32.08940590",
        "id": "NJ_2c92402174a057170174b352afa6076f",
        "mt_id": "NJ_2c92402174a057170174b352afa6076f",
        "hdHdly": "3",
        "hdlc": "347.50"
    },
    {
        "mtmc": "中山码头辅墩",
        "sdmc": "南京水道",
        "x84": "118.72764096,32.08906351",
        "id": "NJ_2c92402174a057170174b35801460770",
        "mt_id": "NJ_2c92402174a057170174b35801460770",
        "hdHdly": "3",
        "hdlc": "347.50"
    },
    {
        "mtmc": "大胜关航道处世茂码头",
        "sdmc": "南京水道",
        "x84": "118.72570560,32.08198357",
        "id": "NJ_2c92402174a057170174b3624a230771",
        "mt_id": "NJ_2c92402174a057170174b3624a230771",
        "hdHdly": "3",
        "hdlc": "348.20"
    },
    {
        "mtmc": "公安码头",
        "sdmc": "南京水道",
        "x84": "118.72548102,32.08137716",
        "id": "NJ_2c92402174a057170174b37547e00774",
        "mt_id": "NJ_2c92402174a057170174b37547e00774",
        "hdHdly": "3",
        "hdlc": "348.40"
    },
    {
        "mtmc": "中国水政码头",
        "sdmc": "南京水道",
        "x84": "118.72533902,32.08092487",
        "id": "NJ_2c92402174a057170174b3788b520775",
        "mt_id": "NJ_2c92402174a057170174b3788b520775",
        "hdHdly": "3",
        "hdlc": "348.30"
    },
    {
        "mtmc": "中国海事码头",
        "sdmc": "南京水道",
        "x84": "118.72523048,32.07958923",
        "id": "NJ_2c92402174a057170174b37b59650776",
        "mt_id": "NJ_2c92402174a057170174b37b59650776",
        "hdHdly": "3",
        "hdlc": "348.40"
    },
    {
        "mtmc": "公安码头",
        "sdmc": "南京水道",
        "x84": "118.72517654,32.07584654",
        "id": "NJ_2c92402174a057170174b37ff7b40777",
        "mt_id": "NJ_2c92402174a057170174b37ff7b40777",
        "hdHdly": "3",
        "hdlc": "348.80"
    },
    {
        "mtmc": "中国渔政码头",
        "sdmc": "南京水道",
        "x84": "118.72517359,32.07529646",
        "id": "NJ_2c92402174a057170174b383cb020778",
        "mt_id": "NJ_2c92402174a057170174b383cb020778",
        "hdHdly": "3",
        "hdlc": "348.80"
    },
    {
        "mtmc": "公安码头",
        "sdmc": "南京水道",
        "x84": "118.671758,31.985238",
        "id": "NJ_2c92402174a057170174b390a75b077e",
        "mt_id": "NJ_2c92402174a057170174b390a75b077e",
        "hdHdly": "3",
        "hdlc": "361.30"
    },
    {
        "mtmc": "金大妈码头",
        "sdmc": "南京水道",
        "x84": "118.64790742,31.97080667",
        "id": "NJ_2c92402174a057170174b397f0d40780",
        "mt_id": "NJ_2c92402174a057170174b397f0d40780",
        "hdHdly": "3",
        "hdlc": "361.00"
    },
    {
        "mtmc": "港政码头",
        "sdmc": "南京水道",
        "x84": "118.64458036,31.96542395",
        "id": "NJ_2c92402174a057170174b39ba2420781",
        "mt_id": "NJ_2c92402174a057170174b39ba2420781",
        "hdHdly": "3",
        "hdlc": "364.00"
    },
    {
        "mtmc": "大胜关航道处三桥码头",
        "sdmc": "南京水道",
        "x84": "118.64413262,31.96494125",
        "id": "NJ_2c92402174a057170174b3a746a70783",
        "mt_id": "NJ_2c92402174a057170174b3a746a70783",
        "hdHdly": "3",
        "hdlc": "364.20"
    },
    {
        "mtmc": "河校码头",
        "sdmc": "南京水道",
        "x84": "118.64330933,31.96410286",
        "id": "NJ_2c92402174a057170174b3ae811c0784",
        "mt_id": "NJ_2c92402174a057170174b3ae811c0784",
        "hdHdly": "3",
        "hdlc": "364.30"
    },
    {
        "mtmc": "水文站",
        "sdmc": "南京水道",
        "x84": "118.64178664,31.96263480",
        "id": "NJ_2c92402174a057170174b3b283d60785",
        "mt_id": "NJ_2c92402174a057170174b3b283d60785",
        "hdHdly": "3",
        "hdlc": "364.30"
    },
    {
        "mtmc": "南京水文防汛码头",
        "sdmc": "南京水道",
        "x84": "118.64072980,31.96185701",
        "id": "NJ_2c92402174a057170174b3b63c1a0786",
        "mt_id": "NJ_2c92402174a057170174b3b63c1a0786",
        "hdHdly": "3",
        "hdlc": "364.40"
    },
    {
        "mtmc": "中国水政",
        "sdmc": "南京水道",
        "x84": "118.63993563,31.96119926",
        "id": "NJ_2c92402174a057170174b3bf001b0787",
        "mt_id": "NJ_2c92402174a057170174b3bf001b0787",
        "hdHdly": "3",
        "hdlc": "364.50"
    },
    {
        "mtmc": "公安码头",
        "sdmc": "南京水道",
        "x84": "118.63943439,31.96076106",
        "id": "NJ_2c92402174a057170174b3c0ee2f0788",
        "mt_id": "NJ_2c92402174a057170174b3c0ee2f0788",
        "hdHdly": "3",
        "hdlc": "364.60"
    },
    {
        "mtmc": "雨花开发区大胜关码头",
        "sdmc": "南京水道",
        "x84": "118.63595359,31.95796442",
        "id": "NJ_2c92402174a057170174b3ceb4b8078e",
        "mt_id": "NJ_2c92402174a057170174b3ceb4b8078e",
        "hdHdly": "3",
        "hdlc": "364.90"
    },
    {
        "mtmc": "南京苏源热电有限公司专用出灰码头",
        "sdmc": "南京水道",
        "x84": "118.63032352,31.95319678",
        "id": "NJ_2c92402174a057170174b3e215440793",
        "mt_id": "NJ_2c92402174a057170174b3e215440793",
        "hdHdly": "3",
        "hdlc": "365.80"
    },
    {
        "mtmc": "南京苏源热电有限公司专用卸煤码头",
        "sdmc": "南京水道",
        "x84": "118.62943608,31.95242073",
        "id": "NJ_2c92402174a057170174b3e532bf0794",
        "mt_id": "NJ_2c92402174a057170174b3e532bf0794",
        "hdHdly": "3",
        "hdlc": "365.80"
    },
    {
        "mtmc": "南京华润热电有限公司二期码头",
        "sdmc": "南京水道",
        "x84": "118.62853821,31.95163555",
        "id": "NJ_2c92402174a057170174b3e792b70795",
        "mt_id": "NJ_2c92402174a057170174b3e792b70795",
        "hdHdly": "3",
        "hdlc": "366.00"
    },
    {
        "mtmc": "南京华润热电有限公司进煤码头",
        "sdmc": "南京水道",
        "x84": "118.62595190,31.94918194",
        "id": "NJ_2c92402174a057170174b3f25c2d0796",
        "mt_id": "NJ_2c92402174a057170174b3f25c2d0796",
        "hdHdly": "3",
        "hdlc": "366.20"
    },
    {
        "mtmc": "梅山码头",
        "sdmc": "南京水道",
        "x84": "118.62297362,31.94607478",
        "id": "NJ_2c92402174a057170174b3f4e557079a",
        "mt_id": "NJ_2c92402174a057170174b3f4e557079a",
        "hdHdly": "3",
        "hdlc": "366.40"
    },
    {
        "mtmc": "南京弘阳码头（弘阳码头）",
        "sdmc": "南京水道",
        "x84": "118.62024409,31.94402528",
        "id": "NJ_2c92402174a057170174b3f7c01c079f",
        "mt_id": "NJ_2c92402174a057170174b3f7c01c079f",
        "hdHdly": "3",
        "hdlc": "367.00"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "南京水道",
        "x84": "118.61967267,31.94334277",
        "id": "NJ_2c92402174a057170174b3f9043407a1",
        "mt_id": "NJ_2c92402174a057170174b3f9043407a1",
        "hdHdly": "3",
        "hdlc": "367.30"
    },
    {
        "mtmc": "板桥汽渡码头",
        "sdmc": "南京水道",
        "x84": "118.61700957,31.94110456",
        "id": "NJ_2c92402174a057170174b41c2d3d0869",
        "mt_id": "NJ_2c92402174a057170174b41c2d3d0869",
        "hdHdly": "3",
        "hdlc": "367.50"
    },
    {
        "mtmc": "水利铜井执法基地",
        "sdmc": "凡家矶水道",
        "x84": "118.54047236,31.83983244",
        "id": "NJ_2c92402174a057170174b422694c086a",
        "mt_id": "NJ_2c92402174a057170174b422694c086a",
        "hdHdly": "3",
        "hdlc": "381.10"
    },
    {
        "mtmc": "新济洲管委会码头",
        "sdmc": "凡家矶水道",
        "x84": "118.53901622,31.83772372",
        "id": "NJ_2c92402174a057170174b42569e8086b",
        "mt_id": "NJ_2c92402174a057170174b42569e8086b",
        "hdHdly": "3",
        "hdlc": "381.50"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "凡家矶水道",
        "x84": "118.53220028,31.82190115",
        "id": "NJ_2c92402174a057170174b428c21a086c",
        "mt_id": "NJ_2c92402174a057170174b428c21a086c",
        "hdHdly": "3",
        "hdlc": "383.30"
    },
    {
        "mtmc": "南京港铜井港区二期大件集团重件泊位码头",
        "sdmc": "凡家矶水道",
        "x84": "118.53071179,31.81919682",
        "id": "NJ_2c92402174a057170174b42bedfc086d",
        "mt_id": "NJ_2c92402174a057170174b42bedfc086d",
        "hdHdly": "3",
        "hdlc": "383.40"
    },
    {
        "mtmc": "永辉滨江港建设（南京）有限公司滨江港一期码头",
        "sdmc": "凡家矶水道",
        "x84": "118.52953456,31.81684776",
        "id": "NJ_2c92402174a057170174b43d93c60870",
        "mt_id": "NJ_2c92402174a057170174b43d93c60870",
        "hdHdly": "3",
        "hdlc": "383.70"
    },
    {
        "mtmc": "南京港铜井五期(中储码头)",
        "sdmc": "凡家矶水道",
        "x84": "118.52814594,31.81451426",
        "id": "NJ_2c92402174a057170174b4499e07089f",
        "mt_id": "NJ_2c92402174a057170174b4499e07089f",
        "hdHdly": "3",
        "hdlc": "384.10"
    },
    {
        "mtmc": "南京华能南方实业开发股份有限公司铜井港区三期工程码头",
        "sdmc": "凡家矶水道",
        "x84": "118.52642570,31.81091027",
        "id": "NJ_2c92402174a057170174b452aa3c08a0",
        "mt_id": "NJ_2c92402174a057170174b452aa3c08a0",
        "hdHdly": "3",
        "hdlc": "384.10"
    },
    {
        "mtmc": "南京港铜井港区四期江苏远锦滨江港港务有限公司码头",
        "sdmc": "凡家矶水道",
        "x84": "118.52529510,31.80851183",
        "id": "NJ_2c92402174a057170174b4620962092a",
        "mt_id": "NJ_2c92402174a057170174b4620962092a",
        "hdHdly": "3",
        "hdlc": "384.70"
    },
    {
        "mtmc": "南京海宏港口物流有限公司公用码头",
        "sdmc": "凡家矶水道",
        "x84": "118.50733650,31.78337877",
        "id": "NJ_2c92402174a057170174b464a951092b",
        "mt_id": "NJ_2c92402174a057170174b464a951092b",
        "hdHdly": "3",
        "hdlc": "387.90"
    },
    {
        "mtmc": "浦口轮渡码头",
        "sdmc": "南京水道",
        "x84": "118.71732180,32.09682237",
        "id": "NJ_2c92402174a057170174b46ca0ab092e",
        "mt_id": "NJ_2c92402174a057170174b46ca0ab092e",
        "hdHdly": "3",
        "hdlc": "347.40"
    },
    {
        "mtmc": "长航公安码头",
        "sdmc": "南京水道",
        "x84": "118.71611376,32.09609777",
        "id": "NJ_2c92402174a057170174b478f35e096d",
        "mt_id": "NJ_2c92402174a057170174b478f35e096d",
        "hdHdly": "3",
        "hdlc": "347.50"
    },
    {
        "mtmc": "宏波码头",
        "sdmc": "南京水道",
        "x84": "118.70194485,32.08007405",
        "id": "NJ_2c92402174a057170174b4a4a4960a29",
        "mt_id": "NJ_2c92402174a057170174b4a4a4960a29",
        "hdHdly": "3",
        "hdlc": "352.70"
    },
    {
        "mtmc": "中国水政",
        "sdmc": "南京水道",
        "x84": "118.61356614,31.95351075",
        "id": "NJ_2c92402174a057170174b4a8ea740a2c",
        "mt_id": "NJ_2c92402174a057170174b4a8ea740a2c",
        "hdHdly": "3",
        "hdlc": "367.20"
    },
    {
        "mtmc": "板桥汽渡码头",
        "sdmc": "南京水道",
        "x84": "118.60286736,31.94484512",
        "id": "NJ_2c92402174a057170174b4ab889d0a46",
        "mt_id": "NJ_2c92402174a057170174b4ab889d0a46",
        "hdHdly": "3",
        "hdlc": "368.50"
    },
    {
        "mtmc": "七坝国际物流码头",
        "sdmc": "凡家矶水道",
        "x84": "118.59950135,31.94295950",
        "id": "NJ_2c92402174a057170174b4ae7ab20a4a",
        "mt_id": "NJ_2c92402174a057170174b4ae7ab20a4a",
        "hdHdly": "3",
        "hdlc": "369.60"
    },
    {
        "mtmc": "七坝远锦码头",
        "sdmc": "凡家矶水道",
        "x84": "118.59422053,31.94043943",
        "id": "NJ_2c92402174a057170174b4b045fc0a4b",
        "mt_id": "NJ_2c92402174a057170174b4b045fc0a4b",
        "hdHdly": "3",
        "hdlc": "369.60"
    },
    {
        "mtmc": "七坝多用途码头",
        "sdmc": "凡家矶水道",
        "x84": "118.58755092,31.93747989",
        "id": "NJ_2c92402174a057170174b4b26ca30a59",
        "mt_id": "NJ_2c92402174a057170174b4b26ca30a59",
        "hdHdly": "3",
        "hdlc": "369.70"
    },
    {
        "mtmc": "南京长江管道公司码头",
        "sdmc": "凡家矶水道",
        "x84": "118.58508255,31.93639739",
        "id": "NJ_2c92402174a057170174b4b6c9a20a5a",
        "mt_id": "NJ_2c92402174a057170174b4b6c9a20a5a",
        "hdHdly": "3",
        "hdlc": "370.30"
    },
    {
        "mtmc": "苏海事趸04号第一执法大队",
        "sdmc": "乌江水道(下段)",
        "x84": "118.53732437,31.89600941",
        "id": "NJ_2c92402174a057170174b4b963660a5b",
        "mt_id": "NJ_2c92402174a057170174b4b963660a5b",
        "hdHdly": "3",
        "hdlc": "377.00"
    },
    {
        "mtmc": "宁工趸4号",
        "sdmc": "乌江水道(下段)",
        "x84": "118.53538995,31.89468865",
        "id": "NJ_2c92402174a057170174b4bb51990a5c",
        "mt_id": "NJ_2c92402174a057170174b4bb51990a5c",
        "hdHdly": "3",
        "hdlc": "377.10"
    },
    {
        "mtmc": "长江南京航道工程局码头",
        "sdmc": "乌江水道(下段)",
        "x84": "118.53071892,31.89132542",
        "id": "NJ_2c92402174a057170174b4be91130a5d",
        "mt_id": "NJ_2c92402174a057170174b4be91130a5d",
        "hdHdly": "3",
        "hdlc": "377.40"
    },
    {
        "mtmc": "渔政码头",
        "sdmc": "凡家矶水道",
        "x84": "118.53180442,31.84755959",
        "id": "NJ_2c92402174a057170174b54c12290c3b",
        "mt_id": "NJ_2c92402174a057170174b54c12290c3b",
        "hdHdly": "3",
        "hdlc": "381.30"
    },
    {
        "mtmc": "棉花堤轮渡码头",
        "sdmc": "南京水道",
        "x84": "118.70006466,32.00860228",
        "id": "NJ_2c92402174a057170174b55c0b130c40",
        "mt_id": "NJ_2c92402174a057170174b55c0b130c40",
        "hdHdly": "3",
        "hdlc": "356.80"
    },
    {
        "mtmc": "上新河轮渡码头",
        "sdmc": "南京水道",
        "x84": "118.70673190,32.03042914",
        "id": "NJ_2c92402174a057170174b55e18090c45",
        "mt_id": "NJ_2c92402174a057170174b55e18090c45",
        "hdHdly": "3",
        "hdlc": "353.30"
    },
    {
        "mtmc": "有恒厂码头",
        "sdmc": "南京水道",
        "x84": "118.72473783,32.07007110",
        "id": "NJ_2c92402174a057170174b5604b440c46",
        "mt_id": "NJ_2c92402174a057170174b5604b440c46",
        "hdHdly": "3",
        "hdlc": "349.30"
    },
    {
        "mtmc": "梅山运翔码头",
        "sdmc": "南京水道",
        "x84": "118.61540867,31.93996898",
        "id": "NJ_2c92402174a057170174b56f7c0c0c77",
        "mt_id": "NJ_2c92402174a057170174b56f7c0c0c77",
        "hdHdly": "3",
        "hdlc": "367.70"
    },
    {
        "mtmc": "上海梅山钢铁股份有限公司原料码头",
        "sdmc": "南京水道",
        "x84": "118.61237573,31.93702329",
        "id": "NJ_2c92402174a057170174b570c7c60c78",
        "mt_id": "NJ_2c92402174a057170174b570c7c60c78",
        "hdHdly": "3",
        "hdlc": "367.80"
    },
    {
        "mtmc": "南京港板桥港古雄码头",
        "sdmc": "凡家矶水道",
        "x84": "118.59492575,31.91633522",
        "id": "NJ_2c92402174a057170174b5725db70c79",
        "mt_id": "NJ_2c92402174a057170174b5725db70c79",
        "hdHdly": "3",
        "hdlc": "380.50"
    },
    {
        "mtmc": "凤翔码头",
        "sdmc": "凡家矶水道",
        "x84": "118.59083762,31.90988770",
        "id": "NJ_2c92402174a057170174b573c16d0c7a",
        "mt_id": "NJ_2c92402174a057170174b573c16d0c7a",
        "hdHdly": "3",
        "hdlc": "381.40"
    },
    {
        "mtmc": "宁港二公司#28、#255、#22码头",
        "sdmc": "南京水道",
        "x84": "118.71845296,32.09850201",
        "id": "NJ_2c92402174a057170174b579cc5c0c7d",
        "mt_id": "NJ_2c92402174a057170174b579cc5c0c7d",
        "hdHdly": "3",
        "hdlc": "346.50"
    },
    {
        "mtmc": "海事码头搜救中心",
        "sdmc": "南京水道",
        "x84": "118.69561644,32.04691593",
        "id": "NJ_2c92402174a057170174b57e407d0c80",
        "mt_id": "NJ_2c92402174a057170174b57e407d0c80",
        "hdHdly": "3",
        "hdlc": "352.90"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "南京水道",
        "x84": "118.69511806,32.04622549",
        "id": "NJ_2c92402174a057170174b57f9a120c81",
        "mt_id": "NJ_2c92402174a057170174b57f9a120c81",
        "hdHdly": "3",
        "hdlc": "353.00"
    },
    {
        "mtmc": "长航公安",
        "sdmc": "南京水道",
        "x84": "118.67603291,32.01585594",
        "id": "NJ_2c92402174a057170174b58217750c82",
        "mt_id": "NJ_2c92402174a057170174b58217750c82",
        "hdHdly": "3",
        "hdlc": "357.60"
    },
    {
        "mtmc": "五峰山船厂#2码头",
        "sdmc": "丹徒直水道",
        "x84": "119.68776570,32.24229215",
        "id": "NJ_2c92402174a057170174ba064e0f0dae",
        "mt_id": "NJ_2c92402174a057170174ba064e0f0dae",
        "hdHdly": "3",
        "hdlc": "240.20"
    },
    {
        "mtmc": "五峰山船厂#3码头",
        "sdmc": "丹徒直水道",
        "x84": "119.68851577,32.24325892",
        "id": "NJ_2c92402174a057170174ba0979300daf",
        "mt_id": "NJ_2c92402174a057170174ba0979300daf",
        "hdHdly": "3",
        "hdlc": "240.00"
    },
    {
        "mtmc": "游览码头",
        "sdmc": "南京大桥水道",
        "x84": "118.74219838,32.11117490",
        "id": "NJ_2c92402174a057d00174b49fece50390",
        "mt_id": "NJ_2c92402174a057d00174b49fece50390",
        "hdHdly": "3",
        "hdlc": "344.70"
    },
    {
        "mtmc": "长航公安码头",
        "sdmc": "南京大桥水道",
        "x84": "118.74113125,32.11019043",
        "id": "NJ_2c92402174a057d00174b4a2715c0391",
        "mt_id": "NJ_2c92402174a057d00174b4a2715c0391",
        "hdHdly": "3",
        "hdlc": "344.90"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "南京大桥水道",
        "x84": "118.74072394,32.10976257",
        "id": "NJ_2c92402174a057d00174b4a429c90392",
        "mt_id": "NJ_2c92402174a057d00174b4a429c90392",
        "hdHdly": "3",
        "hdlc": "344.90"
    },
    {
        "mtmc": "水上公安码头",
        "sdmc": "南京大桥水道",
        "x84": "118.74028511,32.10931636",
        "id": "NJ_2c92402174a057d00174b4a6ac7b0393",
        "mt_id": "NJ_2c92402174a057d00174b4a6ac7b0393",
        "hdHdly": "3",
        "hdlc": "345.00"
    },
    {
        "mtmc": "游览码头",
        "sdmc": "南京大桥水道",
        "x84": "118.73142196,32.09828096",
        "id": "NJ_2c92402174a057d00174b4acbcd70398",
        "mt_id": "NJ_2c92402174a057d00174b4acbcd70398",
        "hdHdly": "3",
        "hdlc": "346.40"
    },
    {
        "mtmc": "永福港务码头",
        "sdmc": "口岸直水道",
        "x84": "119.89326992,32.23219139",
        "id": "NJ_2c92402174a057d00174b4b5779f0399",
        "mt_id": "NJ_2c92402174a057d00174b4b5779f0399",
        "hdHdly": "3",
        "hdlc": "210.30"
    },
    {
        "mtmc": "下关电厂码头",
        "sdmc": "南京大桥水道",
        "x84": "118.72787238,32.09070697",
        "id": "NJ_2c92402174a057d00174b4bbb1e9039a",
        "mt_id": "NJ_2c92402174a057d00174b4bbb1e9039a",
        "hdHdly": "3",
        "hdlc": "347.30"
    },
    {
        "mtmc": "海企燃气加注站",
        "sdmc": "宝塔水道",
        "x84": "118.85311880,32.21144587",
        "id": "NJ_2c92402174a057d00174b4c09e47039b",
        "mt_id": "NJ_2c92402174a057d00174b4c09e47039b",
        "hdHdly": "3",
        "hdlc": "331.00"
    },
    {
        "mtmc": "海企01+02",
        "sdmc": "宝塔水道",
        "x84": "118.85188907,32.21308486",
        "id": "NJ_2c92402174a057d00174b4c262ab039c",
        "mt_id": "NJ_2c92402174a057d00174b4c262ab039c",
        "hdHdly": "3",
        "hdlc": "331.00"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "宝塔水道",
        "x84": "118.86131710,32.20467594",
        "id": "NJ_2c92402174a057d00174b4cf6d34039f",
        "mt_id": "NJ_2c92402174a057d00174b4cf6d34039f",
        "hdHdly": "3",
        "hdlc": "330.20"
    },
    {
        "mtmc": "龙翔#3码头",
        "sdmc": "宝塔水道",
        "x84": "118.85730176,32.21197070",
        "id": "NJ_2c92402174a057d00174b4d23d5a03a0",
        "mt_id": "NJ_2c92402174a057d00174b4d23d5a03a0",
        "hdHdly": "3",
        "hdlc": "331.20"
    },
    {
        "mtmc": "龙翔#2码头",
        "sdmc": "宝塔水道",
        "x84": "118.85617950,32.21350249",
        "id": "NJ_2c92402174a057d00174b4d4f58403a1",
        "mt_id": "NJ_2c92402174a057d00174b4d4f58403a1",
        "hdHdly": "3",
        "hdlc": "332.20"
    },
    {
        "mtmc": "龙翔#1码头",
        "sdmc": "宝塔水道",
        "x84": "118.85516473,32.21491802",
        "id": "NJ_2c92402174a057d00174b4d6ba5503a2",
        "mt_id": "NJ_2c92402174a057d00174b4d6ba5503a2",
        "hdHdly": "3",
        "hdlc": "333.20"
    },
    {
        "mtmc": "扬子石化巴斯夫#4码头",
        "sdmc": "宝塔水道",
        "x84": "118.84701587,32.22522272",
        "id": "NJ_2c92402174a057d00174b4e1513103a5",
        "mt_id": "NJ_2c92402174a057d00174b4e1513103a5",
        "hdHdly": "3",
        "hdlc": "330.40"
    },
    {
        "mtmc": "扬子石化巴斯夫#3码头",
        "sdmc": "宝塔水道",
        "x84": "118.85643461,31.32496001",
        "id": "NJ_2c92402174a057d00174b4e2fe2503a6",
        "mt_id": "NJ_2c92402174a057d00174b4e2fe2503a6",
        "hdHdly": "3",
        "hdlc": "330.40"
    },
    {
        "mtmc": "扬子石化巴斯夫#2码头",
        "sdmc": "宝塔水道",
        "x84": "118.84334176,32.22802955",
        "id": "NJ_2c92402174a057d00174b4e4af3f03a7",
        "mt_id": "NJ_2c92402174a057d00174b4e4af3f03a7",
        "hdHdly": "3",
        "hdlc": "330.50"
    },
    {
        "mtmc": "扬子石化巴斯夫#1码头",
        "sdmc": "宝塔水道",
        "x84": "118.84161754,32.22931222",
        "id": "NJ_2c92402174a057d00174b4e6d40303a8",
        "mt_id": "NJ_2c92402174a057d00174b4e6d40303a8",
        "hdHdly": "3",
        "hdlc": "330.60"
    },
    {
        "mtmc": "扬子石化公司#16码头",
        "sdmc": "宝塔水道",
        "x84": "118.83796742,32.23187564",
        "id": "NJ_2c92402174a057d00174b4ed8bab03af",
        "mt_id": "NJ_2c92402174a057d00174b4ed8bab03af",
        "hdHdly": "3",
        "hdlc": "330.70"
    },
    {
        "mtmc": "扬子石化公司#14码头",
        "sdmc": "宝塔水道",
        "x84": "118.83457109,32.23365680",
        "id": "NJ_2c92402174a057d00174b4f029a503b0",
        "mt_id": "NJ_2c92402174a057d00174b4f029a503b0",
        "hdHdly": "3",
        "hdlc": "330.70"
    },
    {
        "mtmc": "扬子石化公司#12码头",
        "sdmc": "宝塔水道",
        "x84": "118.83273435,32.23459566",
        "id": "NJ_2c92402174a057d00174b4f1808a03b1",
        "mt_id": "NJ_2c92402174a057d00174b4f1808a03b1",
        "hdHdly": "3",
        "hdlc": "330.80"
    },
    {
        "mtmc": "扬子石化公司#11码头",
        "sdmc": "宝塔水道",
        "x84": "118.83099361,32.23549029",
        "id": "NJ_2c92402174a057d00174b4f33dba03b2",
        "mt_id": "NJ_2c92402174a057d00174b4f33dba03b2",
        "hdHdly": "3",
        "hdlc": "330.90"
    },
    {
        "mtmc": "扬子石化公司10-2码头",
        "sdmc": "宝塔水道",
        "x84": "118.82975500,32.23611002",
        "id": "NJ_2c92402174a057d00174b4f485f903b3",
        "mt_id": "NJ_2c92402174a057d00174b4f485f903b3",
        "hdHdly": "3",
        "hdlc": "330.90"
    },
    {
        "mtmc": "扬子石化码头扩建工程",
        "sdmc": "宝塔水道",
        "x84": "118.82305045,32.23689533",
        "id": "NJ_2c92402174a057d00174b4f8463b03b4",
        "mt_id": "NJ_2c92402174a057d00174b4f8463b03b4",
        "hdHdly": "3",
        "hdlc": "331.00"
    },
    {
        "mtmc": "大厂海事处综合执法基地（公安、海事）",
        "sdmc": "宝塔水道",
        "x84": "118.81894794,32.23746119",
        "id": "NJ_2c92402174a057d00174b4fa7d7d03b5",
        "mt_id": "NJ_2c92402174a057d00174b4fa7d7d03b5",
        "hdHdly": "3",
        "hdlc": "334.20"
    },
    {
        "mtmc": "#9大件码头",
        "sdmc": "宝塔水道",
        "x84": "118.81696318,32.23752376",
        "id": "NJ_2c92402174a057d00174b4fc840b03b6",
        "mt_id": "NJ_2c92402174a057d00174b4fc840b03b6",
        "hdHdly": "3",
        "hdlc": "334.50"
    },
    {
        "mtmc": "煤码头",
        "sdmc": "宝塔水道",
        "x84": "118.80926183,32.23663083",
        "id": "NJ_2c92402174a057d00174b4fe678f03b7",
        "mt_id": "NJ_2c92402174a057d00174b4fe678f03b7",
        "hdHdly": "3",
        "hdlc": "335.20"
    },
    {
        "mtmc": "#3中小件码头",
        "sdmc": "宝塔水道",
        "x84": "118.80696368,32.23632060",
        "id": "NJ_2c92402174a057d00174b4ffdc3d03b8",
        "mt_id": "NJ_2c92402174a057d00174b4ffdc3d03b8",
        "hdHdly": "3",
        "hdlc": "335.30"
    },
    {
        "mtmc": "#2中小件码头",
        "sdmc": "宝塔水道",
        "x84": "118.80326023,32.23638475",
        "id": "NJ_2c92402174a057d00174b502332c03b9",
        "mt_id": "NJ_2c92402174a057d00174b502332c03b9",
        "hdHdly": "3",
        "hdlc": "335.40"
    },
    {
        "mtmc": "#1中小件码头",
        "sdmc": "宝塔水道",
        "x84": "118.80484399,32.23615628",
        "id": "NJ_2c92402174a057d00174b503d8be03ba",
        "mt_id": "NJ_2c92402174a057d00174b503d8be03ba",
        "hdHdly": "3",
        "hdlc": "335.50"
    },
    {
        "mtmc": "#0中小件码头",
        "sdmc": "宝塔水道",
        "x84": "118.80327288,32.23542004",
        "id": "NJ_2c92402174a057d00174b5057c4b03bb",
        "mt_id": "NJ_2c92402174a057d00174b5057c4b03bb",
        "hdHdly": "3",
        "hdlc": "335.60"
    },
    {
        "mtmc": "三航#1码头",
        "sdmc": "宝塔水道",
        "x84": "118.79681499,32.23438483",
        "id": "NJ_2c92402174a057d00174b5070cfd03bc",
        "mt_id": "NJ_2c92402174a057d00174b5070cfd03bc",
        "hdHdly": "3",
        "hdlc": "336.40"
    },
    {
        "mtmc": "三航#2码头",
        "sdmc": "宝塔水道",
        "x84": "118.79681761,32.23418648",
        "id": "NJ_2c92402174a057d00174b509540e03bd",
        "mt_id": "NJ_2c92402174a057d00174b509540e03bd",
        "hdHdly": "3",
        "hdlc": "336.50"
    },
    {
        "mtmc": "三航#3码头",
        "sdmc": "宝塔水道",
        "x84": "118.79503159,32.23366450",
        "id": "NJ_2c92402174a057d00174b50f70bf03be",
        "mt_id": "NJ_2c92402174a057d00174b50f70bf03be",
        "hdHdly": "3",
        "hdlc": "336.60"
    },
    {
        "mtmc": "南化#11码头",
        "sdmc": "宝塔水道",
        "x84": "118.78471911,32.22813740",
        "id": "NJ_2c92402174a057d00174b517906303bf",
        "mt_id": "NJ_2c92402174a057d00174b517906303bf",
        "hdHdly": "3",
        "hdlc": "337.60"
    },
    {
        "mtmc": "南化#10码头",
        "sdmc": "宝塔水道",
        "x84": "118.78089689,32.22599959",
        "id": "NJ_2c92402174a057d00174b519aba103c0",
        "mt_id": "NJ_2c92402174a057d00174b519aba103c0",
        "hdHdly": "3",
        "hdlc": "337.90"
    },
    {
        "mtmc": "南化#9码头",
        "sdmc": "宝塔水道",
        "x84": "118.77924721,32.22482045",
        "id": "NJ_2c92402174a057d00174b51b9fef03c1",
        "mt_id": "NJ_2c92402174a057d00174b51b9fef03c1",
        "hdHdly": "3",
        "hdlc": "338.10"
    },
    {
        "mtmc": "南化#8码头",
        "sdmc": "宝塔水道",
        "x84": "118.77564741,32.22193621",
        "id": "NJ_2c92402174a057d00174b51d185603c2",
        "mt_id": "NJ_2c92402174a057d00174b51d185603c2",
        "hdHdly": "3",
        "hdlc": "338.50"
    },
    {
        "mtmc": "南化#7码头",
        "sdmc": "宝塔水道",
        "x84": "118.77479630,32.22133283",
        "id": "NJ_2c92402174a057d00174b51eaeb503c3",
        "mt_id": "NJ_2c92402174a057d00174b51eaeb503c3",
        "hdHdly": "3",
        "hdlc": "338.50"
    },
    {
        "mtmc": "南化#6码头",
        "sdmc": "宝塔水道",
        "x84": "118.77259122,32.21966130",
        "id": "NJ_2c92402174a057d00174b52251ac03c4",
        "mt_id": "NJ_2c92402174a057d00174b52251ac03c4",
        "hdHdly": "3",
        "hdlc": "338.70"
    },
    {
        "mtmc": "南化#5码头",
        "sdmc": "宝塔水道",
        "x84": "118.76988860,32.21792176",
        "id": "NJ_2c92402174a057d00174b5240eec03c7",
        "mt_id": "NJ_2c92402174a057d00174b5240eec03c7",
        "hdHdly": "3",
        "hdlc": "339.00"
    },
    {
        "mtmc": "南化#4码头",
        "sdmc": "宝塔水道",
        "x84": "118.76785022,32.21648622",
        "id": "NJ_2c92402174a057d00174b5266aae03c8",
        "mt_id": "NJ_2c92402174a057d00174b5266aae03c8",
        "hdHdly": "3",
        "hdlc": "339.20"
    },
    {
        "mtmc": "南化灰#3码头",
        "sdmc": "宝塔水道",
        "x84": "118.76518034,32.21390831",
        "id": "NJ_2c92402174a057d00174b529ebc503c9",
        "mt_id": "NJ_2c92402174a057d00174b529ebc503c9",
        "hdHdly": "3",
        "hdlc": "339.40"
    },
    {
        "mtmc": "南化灰#2码头",
        "sdmc": "宝塔水道",
        "x84": "118.76448918,32.21325233",
        "id": "NJ_2c92402174a057d00174b52d206203cc",
        "mt_id": "NJ_2c92402174a057d00174b52d206203cc",
        "hdHdly": "3",
        "hdlc": "339.40"
    },
    {
        "mtmc": "南化灰#1码头",
        "sdmc": "宝塔水道",
        "x84": "118.76356656,32.21245881",
        "id": "NJ_2c92402174a057d00174b534390703cf",
        "mt_id": "NJ_2c92402174a057d00174b534390703cf",
        "hdHdly": "3",
        "hdlc": "339.50"
    },
    {
        "mtmc": "南化#0码头",
        "sdmc": "宝塔水道",
        "x84": "118.76010026,32.20918771",
        "id": "NJ_2c92402174a057d00174b536f8b203d1",
        "mt_id": "NJ_2c92402174a057d00174b536f8b203d1",
        "hdHdly": "3",
        "hdlc": "339.60"
    },
    {
        "mtmc": "南京热电厂卸煤码头",
        "sdmc": "宝塔水道",
        "x84": "118.75750248,32.20367980",
        "id": "NJ_2c92402174a057d00174b538d97703d2",
        "mt_id": "NJ_2c92402174a057d00174b538d97703d2",
        "hdHdly": "3",
        "hdlc": "339.70"
    },
    {
        "mtmc": "南钢和华能合作改建码头",
        "sdmc": "宝塔水道",
        "x84": "118.75734325,32.20135182",
        "id": "NJ_2c92402174a057d00174b53afe2b03d3",
        "mt_id": "NJ_2c92402174a057d00174b53afe2b03d3",
        "hdHdly": "3",
        "hdlc": "339.70"
    },
    {
        "mtmc": "南钢原料码头2号泊位码头",
        "sdmc": "宝塔水道",
        "x84": "118.75789378,32.19830044",
        "id": "NJ_2c92402174a057d00174b53d4a2e03d4",
        "mt_id": "NJ_2c92402174a057d00174b53d4a2e03d4",
        "hdHdly": "3",
        "hdlc": "339.00"
    },
    {
        "mtmc": "南钢港池码头",
        "sdmc": "宝塔水道",
        "x84": "118.76173773,32.17522727",
        "id": "NJ_2c92402174a057d00174b544843503dd",
        "mt_id": "NJ_2c92402174a057d00174b544843503dd",
        "hdHdly": "3",
        "hdlc": "339.00"
    },
    {
        "mtmc": "南钢二号排放口改造港池码头",
        "sdmc": "宝塔水道",
        "x84": "118.75781244,32.19726267",
        "id": "NJ_2c92402174a057d00174b546614203e0",
        "mt_id": "NJ_2c92402174a057d00174b546614203e0",
        "hdHdly": "3",
        "hdlc": "339.00"
    },
    {
        "mtmc": "天港码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.28064738,31.95780442",
        "id": "NJ_2c92402174a057d00174be30bdab0805",
        "mt_id": "NJ_2c92402174a057d00174be30bdab0805",
        "hdHdly": "3",
        "hdlc": "153.00"
    },
    {
        "mtmc": "谏壁发电厂3500DWT煤码头",
        "sdmc": "丹徒直水道",
        "x84": "119.57784626,32.18543002",
        "id": "NJ_2c92402174a057d00174bec004730815",
        "mt_id": "NJ_2c92402174a057d00174bec004730815",
        "hdHdly": "3",
        "hdlc": "251.60"
    },
    {
        "mtmc": "输灰码头",
        "sdmc": "丹徒直水道",
        "x84": "119.57879430,32.18450434",
        "id": "NJ_2c92402174a057d00174bec45481081d",
        "mt_id": "NJ_2c92402174a057d00174bec45481081d",
        "hdHdly": "3",
        "hdlc": "252.10"
    },
    {
        "mtmc": "江心汽渡浮码头",
        "sdmc": "丹徒直水道",
        "x84": "119.61885085,32.18840108",
        "id": "NJ_2c92402174a057d00174bec993f0081f",
        "mt_id": "NJ_2c92402174a057d00174bec993f0081f",
        "hdHdly": "3",
        "hdlc": "248.50"
    },
    {
        "mtmc": "马鞍山公铁大桥临时栈桥码头",
        "sdmc": "江心洲水道",
        "x84": "118.39068062,31.59173714",
        "id": "NJ_2c92402174d9200b0174dcea321e03cf",
        "mt_id": "NJ_2c92402174d9200b0174dcea321e03cf",
        "hdHdly": "3",
        "hdlc": "414.00"
    },
    {
        "mtmc": "棉船汽渡码头",
        "sdmc": "马当南水道",
        "x84": "116.63908098,29.99790558",
        "id": "NJ_2c924021751fc7600175451d45ff1195",
        "mt_id": "NJ_2c924021751fc7600175451d45ff1195",
        "hdHdly": "3",
        "hdlc": "717.50"
    },
    {
        "mtmc": "安徽华谊华工有限公司件杂货还建码头",
        "sdmc": "芜湖水道",
        "x84": "118.33416964,31.35948726",
        "id": "NJ_2c92402175739ad0017597a1bfba162a",
        "mt_id": "NJ_2c92402175739ad0017597a1bfba162a",
        "hdHdly": "3",
        "hdlc": "440.80"
    },
    {
        "mtmc": "太仓港四期码头",
        "sdmc": "白茆沙水道",
        "x84": "121.23375725,31.63434167",
        "id": "NJ_2c92402175d9b6380175df6fe04602ea",
        "mt_id": "NJ_2c92402175d9b6380175df6fe04602ea",
        "hdHdly": "3",
        "hdlc": "38.70"
    },
    {
        "mtmc": "红光码头",
        "sdmc": "东北横水道",
        "x84": "116.39657278,29.85612697",
        "id": "NJ_2c92402175f45bbb01763bf6db9410fc",
        "mt_id": "NJ_2c92402175f45bbb01763bf6db9410fc",
        "hdHdly": "3",
        "hdlc": "747.30"
    },
    {
        "mtmc": "南京港大厂港区扬子石化船舶洗舱站项目",
        "sdmc": "宝塔水道",
        "x84": "118.84962410,32.22280284",
        "id": "NJ_2c9240217641b22901766a78dd351554",
        "mt_id": "NJ_2c9240217641b22901766a78dd351554",
        "hdHdly": "3",
        "hdlc": "332.00"
    },
    {
        "mtmc": "九江钢铁2号码头",
        "sdmc": "湖口水道",
        "x84": "116.27061528,29.78341296",
        "id": "NJ_2c92402176e08eb701771eeaa8432c1a",
        "mt_id": "NJ_2c92402176e08eb701771eeaa8432c1a",
        "hdHdly": "3",
        "hdlc": "762.00"
    },
    {
        "mtmc": "九江萍钢#6码头",
        "sdmc": "湖口水道",
        "x84": "116.28272602,29.78909036",
        "id": "NJ_2c9240217727ada401773857bc4b0298",
        "mt_id": "NJ_2c9240217727ada401773857bc4b0298",
        "hdHdly": "3",
        "hdlc": "760.50"
    },
    {
        "mtmc": "源发砂石码头",
        "sdmc": "武穴水道",
        "x84": "115.59218483,29.84517727",
        "id": "NJ_2c9240217727ada401773c4197dc047a",
        "mt_id": "NJ_2c9240217727ada401773c4197dc047a",
        "hdHdly": "3",
        "hdlc": "835.90"
    },
    {
        "mtmc": "联合动力长江（江苏）有限公司码头",
        "sdmc": "口岸直水道",
        "x84": "119.87731570,32.20483385",
        "id": "NJ_2c9240217727ada40177418d0b910776",
        "mt_id": "NJ_2c9240217727ada40177418d0b910776",
        "hdHdly": "3",
        "hdlc": "209.00"
    },
    {
        "mtmc": "船舶污染物接收站",
        "sdmc": "武穴水道",
        "x84": "115.53424145,29.83499720",
        "id": "NJ_2c924021773edacb01774d02793f03c2",
        "mt_id": "NJ_2c924021773edacb01774d02793f03c2",
        "hdHdly": "3",
        "hdlc": "842.00"
    },
    {
        "mtmc": "无锡（江阴钢板申夏港区）#1~#4内港池及顺岸码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.15765002,3.19422229",
        "id": "NJ_2c924021773edacb01775b6bb20c0825",
        "mt_id": "NJ_2c924021773edacb01775b6bb20c0825",
        "hdHdly": "3",
        "hdlc": "161.40"
    },
    {
        "mtmc": "芜湖中石化码头",
        "sdmc": "白茆水道",
        "x84": "118.10758657,31.21836510",
        "id": "NJ_2c92402177671de5017780b8297906e8",
        "mt_id": "NJ_2c92402177671de5017780b8297906e8",
        "hdHdly": "3",
        "hdlc": "474.00"
    },
    {
        "mtmc": "马鞍山船舶污染物接收码头",
        "sdmc": "太平府水道（下段）",
        "x84": "118.46144961,31.57925168",
        "id": "NJ_2c92402178241fb4017848872b8b067f",
        "mt_id": "NJ_2c92402178241fb4017848872b8b067f",
        "hdHdly": "3",
        "hdlc": "414.70"
    },
    {
        "mtmc": "芜湖港客运码头",
        "sdmc": "芜湖水道",
        "x84": "118.35005500,31.34371985",
        "id": "NJ_2c9240217b331aba017b707ff8590dcb",
        "mt_id": "NJ_2c9240217b331aba017b707ff8590dcb",
        "hdHdly": "3",
        "hdlc": "442.00"
    },
    {
        "mtmc": "芜湖港朱家桥集装箱码头一期工程",
        "sdmc": "西华水道",
        "x84": "118.34715725,31.40209713",
        "id": "NJ_2c9240217b9a6c2b017b9adaafc4000a",
        "mt_id": "NJ_2c9240217b9a6c2b017b9adaafc4000a",
        "hdHdly": "3",
        "hdlc": "435.60"
    },
    {
        "mtmc": "铜陵有色经济化工园专用码头",
        "sdmc": "成德洲东港水道",
        "x84": "117.77164639,30.98224186",
        "id": "NJ_2c9240217c974aeb017cbbcae3b90af3",
        "mt_id": "NJ_2c9240217c974aeb017cbbcae3b90af3",
        "hdHdly": "3",
        "hdlc": "535.50"
    },
    {
        "mtmc": "宝矶码头",
        "sdmc": "马当南水道",
        "x84": "116.57654880,29.95306496",
        "id": "NJ_2c9240217e569340017e6bf4b0170c89",
        "mt_id": "NJ_2c9240217e569340017e6bf4b0170c89",
        "hdHdly": "3",
        "hdlc": "725.20"
    },
    {
        "mtmc": "马当港口码头",
        "sdmc": "马当南水道",
        "x84": "116.649628,29.988814",
        "id": "NJ_2c9240217e569340017e6bfba5f10e8d",
        "mt_id": "NJ_2c9240217e569340017e6bfba5f10e8d",
        "hdHdly": "3",
        "hdlc": "717.40"
    },
    {
        "mtmc": "南通港狼山港区国际邮轮码头",
        "sdmc": "南通水道",
        "x84": "120.84517864,31.98343771",
        "id": "NJ_2c9240217e56973a017e6b498bbc70df",
        "mt_id": "NJ_2c9240217e56973a017e6b498bbc70df",
        "hdHdly": "3",
        "hdlc": "94.90"
    },
    {
        "mtmc": "博联码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.21213411,31.94341522",
        "id": "NJ_2c9240217ea6c8ef017ed743fcb529f2",
        "mt_id": "NJ_2c9240217ea6c8ef017ed743fcb529f2",
        "hdHdly": "3",
        "hdlc": "160.00"
    },
    {
        "mtmc": "阳鸿石化码头",
        "sdmc": "浏河水道",
        "x84": "121.29089552,31.58008143",
        "id": "NJ_2c9240fe719137ca01720b8b0c821a21",
        "mt_id": "NJ_2c9240fe719137ca01720b8b0c821a21",
        "hdHdly": "3",
        "hdlc": "30.40"
    },
    {
        "mtmc": "阳鸿石化公用码头",
        "sdmc": "浏河水道",
        "x84": "121.28798864,31.57864906",
        "id": "NJ_2c9240fe719137ca01720b91d35e1a22",
        "mt_id": "NJ_2c9240fe719137ca01720b91d35e1a22",
        "hdHdly": "3",
        "hdlc": "30.70"
    },
    {
        "mtmc": "太仓环保发电有限公司",
        "sdmc": "浏河水道",
        "x84": "121.27661818,31.59554834",
        "id": "NJ_2c9240fe719137ca01720b9833941a25",
        "mt_id": "NJ_2c9240fe719137ca01720b9833941a25",
        "hdHdly": "3",
        "hdlc": "32.63"
    },
    {
        "mtmc": "玖龙纸业（太仓）有限公司",
        "sdmc": "浏河水道",
        "x84": "121.26893860,31.60531291",
        "id": "NJ_2c9240fe719137ca01720b99c1d21a26",
        "mt_id": "NJ_2c9240fe719137ca01720b99c1d21a26",
        "hdHdly": "3",
        "hdlc": "33.61"
    },
    {
        "mtmc": "太仓国际集装箱码头有限公司",
        "sdmc": "白茆沙水道",
        "x84": "121.21311792,31.65089658",
        "id": "NJ_2c9240fe719137ca01720b9b8bbc1a27",
        "mt_id": "NJ_2c9240fe719137ca01720b9b8bbc1a27",
        "hdHdly": "3",
        "hdlc": "38.84"
    },
    {
        "mtmc": "太仓华能电厂码头二期煤码头",
        "sdmc": "白茆沙水道",
        "x84": "121.18879980,31.66815733",
        "id": "NJ_2c9240fe719137ca01720b9d7f111a28",
        "mt_id": "NJ_2c9240fe719137ca01720b9d7f111a28",
        "hdHdly": "3",
        "hdlc": "44.20"
    },
    {
        "mtmc": "太仓华能电厂码头一期煤码头",
        "sdmc": "白茆沙水道",
        "x84": "121.18690534,31.66964435",
        "id": "NJ_2c9240fe719137ca01720b9f15be1a29",
        "mt_id": "NJ_2c9240fe719137ca01720b9f15be1a29",
        "hdHdly": "3",
        "hdlc": "44.60"
    },
    {
        "mtmc": "太仓华能电厂码头",
        "sdmc": "白茆沙水道",
        "x84": "121.18116858,31.67249518",
        "id": "NJ_2c9240fe719137ca01720ba0c29e1a2a",
        "mt_id": "NJ_2c9240fe719137ca01720ba0c29e1a2a",
        "hdHdly": "3",
        "hdlc": "45.25"
    },
    {
        "mtmc": "华能太仓发电有限责任公司华能太仓港煤炭储运中心码头",
        "sdmc": "白茆沙水道",
        "x84": "121.17555734,31.67808087",
        "id": "NJ_2c9240fe719137ca01720ba22d561a2b",
        "mt_id": "NJ_2c9240fe719137ca01720ba22d561a2b",
        "hdHdly": "3",
        "hdlc": "46.20"
    },
    {
        "mtmc": "苏州港太仓港区武港码头",
        "sdmc": "白茆沙水道",
        "x84": "121.16061794,31.68467547",
        "id": "NJ_2c9240fe719137ca01720ba39bcb1a2c",
        "mt_id": "NJ_2c9240fe719137ca01720ba39bcb1a2c",
        "hdHdly": "3",
        "hdlc": "47.20"
    },
    {
        "mtmc": "苏州港太仓港区武港码头公司小船装船码头",
        "sdmc": "白茆沙水道",
        "x84": "121.16012423,31.68222993",
        "id": "NJ_2c9240fe719137ca01720ba4ca0b1a2d",
        "mt_id": "NJ_2c9240fe719137ca01720ba4ca0b1a2d",
        "hdHdly": "3",
        "hdlc": "47.80"
    },
    {
        "mtmc": "太仓港区润禾杂货（重件）码头",
        "sdmc": "白茆沙水道",
        "x84": "121.15506842,31.68752335",
        "id": "NJ_2c9240fe719137ca01720ba703021a2e",
        "mt_id": "NJ_2c9240fe719137ca01720ba703021a2e",
        "hdHdly": "3",
        "hdlc": "48.50"
    },
    {
        "mtmc": "苏州港太仓港区万方国际通用码头",
        "sdmc": "白茆沙水道",
        "x84": "121.14866740,31.68862441",
        "id": "NJ_2c9240fe719137ca01720ba84d5d1a2f",
        "mt_id": "NJ_2c9240fe719137ca01720ba84d5d1a2f",
        "hdHdly": "3",
        "hdlc": "49.20"
    },
    {
        "mtmc": "万方（太仓）开发建设有限公司",
        "sdmc": "白茆沙水道",
        "x84": "121.14525737,31.69331252",
        "id": "NJ_2c9240fe719137ca01720ba98a041a30",
        "mt_id": "NJ_2c9240fe719137ca01720ba98a041a30",
        "hdHdly": "3",
        "hdlc": "49.23"
    },
    {
        "mtmc": "美锦码头",
        "sdmc": "白茆沙水道",
        "x84": "121.13842604,31.69715441",
        "id": "NJ_2c9240fe719137ca01720baabbf91a31",
        "mt_id": "NJ_2c9240fe719137ca01720baabbf91a31",
        "hdHdly": "3",
        "hdlc": "50.00"
    },
    {
        "mtmc": "盛泰码头",
        "sdmc": "永钢专用航道",
        "x84": "120.84218075,31.83554428",
        "id": "NJ_2c9240fe719137ca01720bc0b1841a34",
        "mt_id": "NJ_2c9240fe719137ca01720bc0b1841a34",
        "hdHdly": "3",
        "hdlc": "79.60"
    },
    {
        "mtmc": "宏泰码头",
        "sdmc": "永钢专用航道",
        "x84": "120.83838031,31.84221942",
        "id": "NJ_2c9240fe719137ca01720bc33b291a35",
        "mt_id": "NJ_2c9240fe719137ca01720bc33b291a35",
        "hdHdly": "3",
        "hdlc": "80.40"
    },
    {
        "mtmc": "长江航运公安局苏州分局张家港派出所40米趸船码头",
        "sdmc": "浏海沙水道",
        "x84": "120.70725856,31.99417345",
        "id": "NJ_2c9240fe719137ca01720bc44afa1a37",
        "mt_id": "NJ_2c9240fe719137ca01720bc44afa1a37",
        "hdHdly": "3",
        "hdlc": "108.20"
    },
    {
        "mtmc": "越洋码头",
        "sdmc": "浏海沙水道",
        "x84": "120.69772067,31.99482906",
        "id": "NJ_2c9240fe719137ca01720bc5fa221a38",
        "mt_id": "NJ_2c9240fe719137ca01720bc5fa221a38",
        "hdHdly": "3",
        "hdlc": "108.80"
    },
    {
        "mtmc": "中东码头",
        "sdmc": "浏海沙水道",
        "x84": "120.69574960,31.99488930",
        "id": "NJ_2c9240fe719137ca01720bc8782e1a3a",
        "mt_id": "NJ_2c9240fe719137ca01720bc8782e1a3a",
        "hdHdly": "3",
        "hdlc": "109.30"
    },
    {
        "mtmc": "张家港沙洲电力有限公司煤码头1",
        "sdmc": "浏海沙水道",
        "x84": "120.68110783,31.99378433",
        "id": "NJ_2c9240fe719137ca01720bcba9071a3c",
        "mt_id": "NJ_2c9240fe719137ca01720bcba9071a3c",
        "hdHdly": "3",
        "hdlc": "110.20"
    },
    {
        "mtmc": "张家港沙洲电力有限公司煤码头",
        "sdmc": "浏海沙水道",
        "x84": "120.68690869,31.99418538",
        "id": "NJ_2c9240fe719137ca01720bcccf651a3d",
        "mt_id": "NJ_2c9240fe719137ca01720bcccf651a3d",
        "hdHdly": "3",
        "hdlc": "110.20"
    },
    {
        "mtmc": "海力0号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.67639900,31.99342653",
        "id": "NJ_2c9240fe719137ca01720bd098901a3e",
        "mt_id": "NJ_2c9240fe719137ca01720bd098901a3e",
        "hdHdly": "3",
        "hdlc": "111.00"
    },
    {
        "mtmc": "奔辉码头",
        "sdmc": "浏海沙水道",
        "x84": "120.67250091,31.99325305",
        "id": "NJ_2c9240fe719137ca01720bd3ca0c1a3f",
        "mt_id": "NJ_2c9240fe719137ca01720bd3ca0c1a3f",
        "hdHdly": "3",
        "hdlc": "111.30"
    },
    {
        "mtmc": "海力1号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.66484665,31.99275227",
        "id": "NJ_2c9240fe719137ca01720bd53fd01a40",
        "mt_id": "NJ_2c9240fe719137ca01720bd53fd01a40",
        "hdHdly": "3",
        "hdlc": "111.90"
    },
    {
        "mtmc": "海力2号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.65294742,31.99021580",
        "id": "NJ_2c9240fe719137ca01720bd755661a41",
        "mt_id": "NJ_2c9240fe719137ca01720bd755661a41",
        "hdHdly": "3",
        "hdlc": "113.00"
    },
    {
        "mtmc": "海力3号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.64773543,31.98966633",
        "id": "NJ_2c9240fe719137ca01720bddbd7a1a42",
        "mt_id": "NJ_2c9240fe719137ca01720bddbd7a1a42",
        "hdHdly": "3",
        "hdlc": "113.80"
    },
    {
        "mtmc": "海力4号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.64178341,31.98939352",
        "id": "NJ_2c9240fe719137ca01720be070a81a43",
        "mt_id": "NJ_2c9240fe719137ca01720be070a81a43",
        "hdHdly": "3",
        "hdlc": "114.10"
    },
    {
        "mtmc": "海力5号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.63731602,31.98962474",
        "id": "NJ_2c9240fe719137ca01720be302131a44",
        "mt_id": "NJ_2c9240fe719137ca01720be302131a44",
        "hdHdly": "3",
        "hdlc": "114.60"
    },
    {
        "mtmc": "海力6号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.63073790,31.99018766",
        "id": "NJ_2c9240fe719137ca01720be4c1b91a45",
        "mt_id": "NJ_2c9240fe719137ca01720be4c1b91a45",
        "hdHdly": "3",
        "hdlc": "115.20"
    },
    {
        "mtmc": "海力7号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.62487464,31.98921384",
        "id": "NJ_2c9240fe719137ca01720be612fc1a46",
        "mt_id": "NJ_2c9240fe719137ca01720be612fc1a46",
        "hdHdly": "3",
        "hdlc": "115.90"
    },
    {
        "mtmc": "海力8号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.61858758,31.98997086",
        "id": "NJ_2c9240fe719137ca01720be7fe4a1a47",
        "mt_id": "NJ_2c9240fe719137ca01720be7fe4a1a47",
        "hdHdly": "3",
        "hdlc": "116.40"
    },
    {
        "mtmc": "海力9号码头",
        "sdmc": "浏海沙水道",
        "x84": "120.61075036,31.98888454",
        "id": "NJ_2c9240fe719137ca01720be999331a48",
        "mt_id": "NJ_2c9240fe719137ca01720be999331a48",
        "hdHdly": "3",
        "hdlc": "117.00"
    },
    {
        "mtmc": "长江航运公安局苏州分局警备码头",
        "sdmc": "浏海沙水道",
        "x84": "120.44923674,32.01132837",
        "id": "NJ_2c9240fe719137ca01720bec3a9e1a49",
        "mt_id": "NJ_2c9240fe719137ca01720bec3a9e1a49",
        "hdHdly": "3",
        "hdlc": "120.00"
    },
    {
        "mtmc": "浦项不锈钢码头",
        "sdmc": "大新专用航道",
        "x84": "120.57829968,31.99427081",
        "id": "NJ_2c9240fe719137ca01720bed8f5a1a4a",
        "mt_id": "NJ_2c9240fe719137ca01720bed8f5a1a4a",
        "hdHdly": "3",
        "hdlc": "120.10"
    },
    {
        "mtmc": "江苏海新船务重工有限公司码头",
        "sdmc": "白茆沙水道",
        "x84": "121.07337228,31.78349174",
        "id": "NJ_2c9240fe719137ca017210a247af1a92",
        "mt_id": "NJ_2c9240fe719137ca017210a247af1a92",
        "hdHdly": "3",
        "hdlc": "60.30"
    },
    {
        "mtmc": "江苏海隆重机有限公司港机码头",
        "sdmc": "白茆沙北水道",
        "x84": "121.11170407,31.78422953",
        "id": "NJ_2c9240fe719137ca017210a410c71a93",
        "mt_id": "NJ_2c9240fe719137ca017210a410c71a93",
        "hdHdly": "3",
        "hdlc": "60.00"
    },
    {
        "mtmc": "南通海门海螺水泥有限责任公司码头",
        "sdmc": "白茆沙北水道",
        "x84": "121.10391671,31.77876130",
        "id": "NJ_2c9240fe719137ca017210a5bf641a94",
        "mt_id": "NJ_2c9240fe719137ca017210a5bf641a94",
        "hdHdly": "3",
        "hdlc": "60.40"
    },
    {
        "mtmc": "南通中远钢结构有限公司通用码头",
        "sdmc": "白茆沙水道",
        "x84": "121.07975224,31.78832793",
        "id": "NJ_2c9240fe719137ca017210a74d441a95",
        "mt_id": "NJ_2c9240fe719137ca017210a74d441a95",
        "hdHdly": "3",
        "hdlc": "62.00"
    },
    {
        "mtmc": "招商局重工（江苏）有限公司1#码头",
        "sdmc": "白茆沙水道",
        "x84": "121.07887803,31.78305084",
        "id": "NJ_2c9240fe719137ca017210aa060e1a96",
        "mt_id": "NJ_2c9240fe719137ca017210aa060e1a96",
        "hdHdly": "3",
        "hdlc": "63.00"
    },
    {
        "mtmc": "招商局重工（江苏）有限公司2#码头",
        "sdmc": "白茆沙水道",
        "x84": "121.07714167,31.78626689",
        "id": "NJ_2c9240fe719137ca017210acc3d91a98",
        "mt_id": "NJ_2c9240fe719137ca017210acc3d91a98",
        "hdHdly": "3",
        "hdlc": "63.00"
    },
    {
        "mtmc": "招商局重工（江苏）有限公司3#码头",
        "sdmc": "白茆沙水道",
        "x84": "121.07975224,31.78832793",
        "id": "NJ_2c9240fe719137ca017210b393381a99",
        "mt_id": "NJ_2c9240fe719137ca017210b393381a99",
        "hdHdly": "3",
        "hdlc": "63.00"
    },
    {
        "mtmc": "南通港宝钢物流（江苏）有限公司通用码头",
        "sdmc": "白茆沙水道",
        "x84": "121.06349825,31.79523711",
        "id": "NJ_2c9240fe719137ca017210c54fe11aa0",
        "mt_id": "NJ_2c9240fe719137ca017210c54fe11aa0",
        "hdHdly": "3",
        "hdlc": "64.50"
    },
    {
        "mtmc": "南通振华重型装备制造新基地出运码头（东侧）",
        "sdmc": "白茆沙水道",
        "x84": "121.01809574,31.79668212",
        "id": "NJ_2c9240fe719137ca017210c892c21aa5",
        "mt_id": "NJ_2c9240fe719137ca017210c892c21aa5",
        "hdHdly": "3",
        "hdlc": "66.00"
    },
    {
        "mtmc": "南通振华重型装备制造新基地出运码头（南侧）",
        "sdmc": "白茆沙水道",
        "x84": "121.01810605,31.79665950",
        "id": "NJ_2c9240fe719137ca017210c9dff51aa6",
        "mt_id": "NJ_2c9240fe719137ca017210c9dff51aa6",
        "hdHdly": "3",
        "hdlc": "66.50"
    },
    {
        "mtmc": "南通一德码头",
        "sdmc": "营船港专用航道",
        "x84": "120.94469303,31.81517460",
        "id": "NJ_2c9240fe719137ca017210ccbc8a1aa7",
        "mt_id": "NJ_2c9240fe719137ca017210ccbc8a1aa7",
        "hdHdly": "3",
        "hdlc": "72.20"
    },
    {
        "mtmc": "南通汇丰石化码头",
        "sdmc": "营船港专用航道",
        "x84": "120.93864418,31.83006300",
        "id": "NJ_2c9240fe719137ca017210cdcd311aa8",
        "mt_id": "NJ_2c9240fe719137ca017210cdcd311aa8",
        "hdHdly": "3",
        "hdlc": "75.00"
    },
    {
        "mtmc": "南通江海码头",
        "sdmc": "营船港专用航道",
        "x84": "120.93785943,31.83349144",
        "id": "NJ_2c9240fe719137ca017210d288a11aa9",
        "mt_id": "NJ_2c9240fe719137ca017210d288a11aa9",
        "hdHdly": "3",
        "hdlc": "75.50"
    },
    {
        "mtmc": "南通嘉民千红码头",
        "sdmc": "营船港专用航道",
        "x84": "120.93754486,31.83625745",
        "id": "NJ_2c9240fe719137ca017210d4ba611aaa",
        "mt_id": "NJ_2c9240fe719137ca017210d4ba611aaa",
        "hdHdly": "3",
        "hdlc": "76.00"
    },
    {
        "mtmc": "南通宁汇码头",
        "sdmc": "营船港专用航道",
        "x84": "120.93715485,31.83900795",
        "id": "NJ_2c9240fe719137ca017210dbed801aab",
        "mt_id": "NJ_2c9240fe719137ca017210dbed801aab",
        "hdHdly": "3",
        "hdlc": "78.00"
    },
    {
        "mtmc": "南通江山化工码头",
        "sdmc": "营船港专用航道",
        "x84": "120.93370526,31.85107880",
        "id": "NJ_2c9240fe719137ca017210dd721e1aac",
        "mt_id": "NJ_2c9240fe719137ca017210dd721e1aac",
        "hdHdly": "3",
        "hdlc": "80.00"
    },
    {
        "mtmc": "惠生重工码头",
        "sdmc": "营船港专用航道",
        "x84": "120.93238385,31.85957719",
        "id": "NJ_2c9240fe719137ca017210df01c81aad",
        "mt_id": "NJ_2c9240fe719137ca017210df01c81aad",
        "hdHdly": "3",
        "hdlc": "80.50"
    },
    {
        "mtmc": "中天科技码头",
        "sdmc": "营船港专用航道",
        "x84": "120.92260057,31.88953938",
        "id": "NJ_2c9240fe719137ca017210e1699c1aae",
        "mt_id": "NJ_2c9240fe719137ca017210e1699c1aae",
        "hdHdly": "3",
        "hdlc": "83.80"
    },
    {
        "mtmc": "长江航运公安局南通派出所40米趸船码头",
        "sdmc": "营船港专用航道",
        "x84": "120.92163516,31.89141039",
        "id": "NJ_2c9240fe719137ca017210e2f1bf1aaf",
        "mt_id": "NJ_2c9240fe719137ca017210e2f1bf1aaf",
        "hdHdly": "3",
        "hdlc": "84.00"
    },
    {
        "mtmc": "长江引航中心南通引航交接基地趸船码头",
        "sdmc": "营船港专用航道",
        "x84": "120.92142241,31.89191152",
        "id": "NJ_2c9240fe719137ca017210e406501ab0",
        "mt_id": "NJ_2c9240fe719137ca017210e406501ab0",
        "hdHdly": "3",
        "hdlc": "84.05"
    },
    {
        "mtmc": "中集码头",
        "sdmc": "浏河水道",
        "x84": "120.91962436,31.89590091",
        "id": "NJ_2c9240fe719137ca017210e52c4b1ab1",
        "mt_id": "NJ_2c9240fe719137ca017210e52c4b1ab1",
        "hdHdly": "3",
        "hdlc": "84.20"
    },
    {
        "mtmc": "南通千象仓储码头",
        "sdmc": "营船港专用航道",
        "x84": "120.91806532,31.89916062",
        "id": "NJ_2c9240fe719137ca017210e67fc31ab2",
        "mt_id": "NJ_2c9240fe719137ca017210e67fc31ab2",
        "hdHdly": "3",
        "hdlc": "84.50"
    },
    {
        "mtmc": "申华化学",
        "sdmc": "营船港专用航道",
        "x84": "120.91743938,31.90036277",
        "id": "NJ_2c9240fe719137ca017210eb25051ab5",
        "mt_id": "NJ_2c9240fe719137ca017210eb25051ab5",
        "hdHdly": "3",
        "hdlc": "84.70"
    },
    {
        "mtmc": "华洋码头",
        "sdmc": "通州沙东水道",
        "x84": "120.89888288,31.90930254",
        "id": "NJ_2c9240fe719137ca017210ec6c4c1ab6",
        "mt_id": "NJ_2c9240fe719137ca017210ec6c4c1ab6",
        "hdHdly": "3",
        "hdlc": "85.00"
    },
    {
        "mtmc": "南通新大港储运码头",
        "sdmc": "通州沙东水道",
        "x84": "120.89401343,31.92286378",
        "id": "NJ_2c9240fe719137ca017210ed65701ab7",
        "mt_id": "NJ_2c9240fe719137ca017210ed65701ab7",
        "hdHdly": "3",
        "hdlc": "87.50"
    },
    {
        "mtmc": "南通港口集团有限公司码头",
        "sdmc": "南通水道",
        "x84": "120.84518707,31.98343414",
        "id": "NJ_2c9240fe719137ca017210eed1a21ab8",
        "mt_id": "NJ_2c9240fe719137ca017210eed1a21ab8",
        "hdHdly": "3",
        "hdlc": "94.80"
    },
    {
        "mtmc": "长江航运公安局南通分局警备码头",
        "sdmc": "南通水道",
        "x84": "120.84519988,31.98344659",
        "id": "NJ_2c9240fe719137ca017210f40c5b1abc",
        "mt_id": "NJ_2c9240fe719137ca017210f40c5b1abc",
        "hdHdly": "3",
        "hdlc": "95.00"
    },
    {
        "mtmc": "南通远洋船务工程有限公司码头",
        "sdmc": "南通水道",
        "x84": "120.82541893,32.00022886",
        "id": "NJ_2c9240fe719137ca017210fdd92b1abe",
        "mt_id": "NJ_2c9240fe719137ca017210fdd92b1abe",
        "hdHdly": "3",
        "hdlc": "97.50"
    },
    {
        "mtmc": "南通港务局集装箱码头",
        "sdmc": "南通水道",
        "x84": "120.81242905,32.00990993",
        "id": "NJ_2c9240fe719137ca01721100e6041ac0",
        "mt_id": "NJ_2c9240fe719137ca01721100e6041ac0",
        "hdHdly": "3",
        "hdlc": "99.60"
    },
    {
        "mtmc": "南通通吕码头",
        "sdmc": "南通水道",
        "x84": "120.80365645,32.01572990",
        "id": "NJ_2c9240fe719137ca0172110248ae1ac1",
        "mt_id": "NJ_2c9240fe719137ca0172110248ae1ac1",
        "hdHdly": "3",
        "hdlc": "100.70"
    },
    {
        "mtmc": "吉宝（南通）重工有限公司直立式驳岸码头",
        "sdmc": "南通水道",
        "x84": "120.80036678,32.01961936",
        "id": "NJ_2c9240fe719137ca01721103a8361ac2",
        "mt_id": "NJ_2c9240fe719137ca01721103a8361ac2",
        "hdHdly": "3",
        "hdlc": "101.60"
    },
    {
        "mtmc": "吉宝（南通）重工有限公司2#码头",
        "sdmc": "南通水道",
        "x84": "120.79789328,32.01914707",
        "id": "NJ_2c9240fe719137ca017211052c0e1ac3",
        "mt_id": "NJ_2c9240fe719137ca017211052c0e1ac3",
        "hdHdly": "3",
        "hdlc": "101.60"
    },
    {
        "mtmc": "南通天生港南通远洋渔业有限公司码头",
        "sdmc": "南通水道",
        "x84": "120.77820123,32.02574663",
        "id": "NJ_2c9240fe719137ca0172110633621ac4",
        "mt_id": "NJ_2c9240fe719137ca0172110633621ac4",
        "hdHdly": "3",
        "hdlc": "103.50"
    },
    {
        "mtmc": "江苏南通电厂“上大压小”新建项目配套码头",
        "sdmc": "南通水道",
        "x84": "120.77105764,32.01746245",
        "id": "NJ_2c9240fe719137ca01721108630a1ac5",
        "mt_id": "NJ_2c9240fe719137ca01721108630a1ac5",
        "hdHdly": "3",
        "hdlc": "104.00"
    },
    {
        "mtmc": "南通华能电厂输煤高架栈桥码头",
        "sdmc": "南通水道",
        "x84": "120.76531284,32.01741097",
        "id": "NJ_2c9240fe719137ca0172110bc6ed1ac7",
        "mt_id": "NJ_2c9240fe719137ca0172110bc6ed1ac7",
        "hdHdly": "3",
        "hdlc": "104.20"
    },
    {
        "mtmc": "江苏南通电厂“上大压小”二期工程码头",
        "sdmc": "南通水道",
        "x84": "120.75385770,32.03180328",
        "id": "NJ_2c9240fe719137ca01721114434c1ace",
        "mt_id": "NJ_2c9240fe719137ca01721114434c1ace",
        "hdHdly": "3",
        "hdlc": "106.00"
    },
    {
        "mtmc": "南通大冈船务有限公司公用码头",
        "sdmc": "南通水道",
        "x84": "120.73561360,32.04019026",
        "id": "NJ_2c9240fe719137ca01721118f09a1acf",
        "mt_id": "NJ_2c9240fe719137ca01721118f09a1acf",
        "hdHdly": "3",
        "hdlc": "107.00"
    },
    {
        "mtmc": "南通亚华集团造船项目通用码头",
        "sdmc": "南通水道",
        "x84": "120.72490017,32.04025949",
        "id": "NJ_2c9240fe719137ca0172111b77821ad2",
        "mt_id": "NJ_2c9240fe719137ca0172111b77821ad2",
        "hdHdly": "3",
        "hdlc": "107.50"
    },
    {
        "mtmc": "南通亚华集团造船项目通用船台",
        "sdmc": "南通水道",
        "x84": "120.72345696,32.04126858",
        "id": "NJ_2c9240fe719137ca0172112062df1ad4",
        "mt_id": "NJ_2c9240fe719137ca0172112062df1ad4",
        "hdHdly": "3",
        "hdlc": "107.60"
    },
    {
        "mtmc": "南通蛟龙重工海洋工程有限公司材料码头",
        "sdmc": "南通水道",
        "x84": "120.71275878,32.04107676",
        "id": "NJ_2c9240fe719137ca01721122ef941ad5",
        "mt_id": "NJ_2c9240fe719137ca01721122ef941ad5",
        "hdHdly": "3",
        "hdlc": "108.80"
    },
    {
        "mtmc": "南通蛟龙重工海洋工程有限公司长江侧码头",
        "sdmc": "南通水道",
        "x84": "120.71121238,32.04167612",
        "id": "NJ_2c9240fe719137ca01721124603f1ada",
        "mt_id": "NJ_2c9240fe719137ca01721124603f1ada",
        "hdHdly": "3",
        "hdlc": "109.00"
    },
    {
        "mtmc": "新时代船厂码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.42152818,32.05078761",
        "id": "NJ_2c9240fe719137ca017211dfe3af1b05",
        "mt_id": "NJ_2c9240fe719137ca017211dfe3af1b05",
        "hdHdly": "3",
        "hdlc": "136.20"
    },
    {
        "mtmc": "新世纪船厂码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.42114754,32.05058925",
        "id": "NJ_2c9240fe719137ca017211e0ed611b06",
        "mt_id": "NJ_2c9240fe719137ca017211e0ed611b06",
        "hdHdly": "3",
        "hdlc": "136.80"
    },
    {
        "mtmc": "新荣船厂码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.40355083,32.03978041",
        "id": "NJ_2c9240fe719137ca017211e213ed1b07",
        "mt_id": "NJ_2c9240fe719137ca017211e213ed1b07",
        "hdHdly": "3",
        "hdlc": "137.50"
    },
    {
        "mtmc": "国信煤码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.38631992,32.02688951",
        "id": "NJ_2c9240fe719137ca017211e333ce1b08",
        "mt_id": "NJ_2c9240fe719137ca017211e333ce1b08",
        "hdHdly": "3",
        "hdlc": "138.90"
    },
    {
        "mtmc": "国信重件码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.37705823,32.02122249",
        "id": "NJ_2c9240fe719137ca017211e446001b09",
        "mt_id": "NJ_2c9240fe719137ca017211e446001b09",
        "hdHdly": "3",
        "hdlc": "140.10"
    },
    {
        "mtmc": "靖江和尚港内港池",
        "sdmc": "福姜沙北水道",
        "x84": "120.37423830,32.02185221",
        "id": "NJ_2c9240fe719137ca017211e660e41b0c",
        "mt_id": "NJ_2c9240fe719137ca017211e660e41b0c",
        "hdHdly": "3",
        "hdlc": "140.20"
    },
    {
        "mtmc": "靖江六助港内港池（东侧）",
        "sdmc": "福姜沙北水道",
        "x84": "120.36419407,32.01412575",
        "id": "NJ_2c9240fe719137ca017211e827711b0d",
        "mt_id": "NJ_2c9240fe719137ca017211e827711b0d",
        "hdHdly": "3",
        "hdlc": "142.00"
    },
    {
        "mtmc": "靖江六助港内港池（西侧）",
        "sdmc": "福姜沙北水道",
        "x84": "120.36156308,32.01258214",
        "id": "NJ_2c9240fe719137ca017211e919c01b0e",
        "mt_id": "NJ_2c9240fe719137ca017211e919c01b0e",
        "hdHdly": "3",
        "hdlc": "142.00"
    },
    {
        "mtmc": "太和港务公用码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.34737821,32.00081685",
        "id": "NJ_2c9240fe719137ca017211ea246e1b0f",
        "mt_id": "NJ_2c9240fe719137ca017211ea246e1b0f",
        "hdHdly": "3",
        "hdlc": "144.90"
    },
    {
        "mtmc": "靖江苏通公用码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.33824450,31.99486239",
        "id": "NJ_2c9240fe719137ca017211eb57331b10",
        "mt_id": "NJ_2c9240fe719137ca017211eb57331b10",
        "hdHdly": "3",
        "hdlc": "145.80"
    },
    {
        "mtmc": "泰州靖江海事码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.32946305,31.98943843",
        "id": "NJ_2c9240fe719137ca017211efaa2f1b15",
        "mt_id": "NJ_2c9240fe719137ca017211efaa2f1b15",
        "hdHdly": "3",
        "hdlc": "147.00"
    },
    {
        "mtmc": "新港作业区公用码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.28064738,31.95780442",
        "id": "NJ_2c9240fe719137ca017211f0b2a71b17",
        "mt_id": "NJ_2c9240fe719137ca017211f0b2a71b17",
        "hdHdly": "3",
        "hdlc": "153.00"
    },
    {
        "mtmc": "通常港务码头",
        "sdmc": "营船港专用航道",
        "x84": "120.95027371,31.80881406",
        "id": "NJ_2c9240fe719137ca017211f1aa531b1a",
        "mt_id": "NJ_2c9240fe719137ca017211f1aa531b1a",
        "hdHdly": "3",
        "hdlc": "71.50"
    },
    {
        "mtmc": "长源钢铁码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.54665567,32.05773139",
        "id": "NJ_2c9240fe719137ca017211f2aabb1b1b",
        "mt_id": "NJ_2c9240fe719137ca017211f2aabb1b1b",
        "hdHdly": "3",
        "hdlc": "125.40"
    },
    {
        "mtmc": "启东海事浮码头",
        "sdmc": "长江北支水道",
        "x84": "121.81607651,31.68691088",
        "id": "NJ_2c9240fe719137ca017211f39fab1b1c",
        "mt_id": "NJ_2c9240fe719137ca017211f39fab1b1c",
        "hdHdly": "3",
        "hdlc": "6.20"
    },
    {
        "mtmc": "通海港口码头",
        "sdmc": "白茆沙水道",
        "x84": "121.01971696,31.78998594",
        "id": "NJ_2c9240fe719137ca017211f4c7361b1d",
        "mt_id": "NJ_2c9240fe719137ca017211f4c7361b1d",
        "hdHdly": "3",
        "hdlc": "66.00"
    },
    {
        "mtmc": "北支航道浮码头",
        "sdmc": "长江北支水道",
        "x84": "121.39760816,31.83870105",
        "id": "NJ_2c9240fe719137ca017211f5bd101b20",
        "mt_id": "NJ_2c9240fe719137ca017211f5bd101b20",
        "hdHdly": "3",
        "hdlc": "50.00"
    },
    {
        "mtmc": "上海振华启东海洋工程股份有限公司码头",
        "sdmc": "长江北支水道",
        "x84": "121.83977942,31.68092447",
        "id": "NJ_2c9240fe71913a480172107b1b271582",
        "mt_id": "NJ_2c9240fe71913a480172107b1b271582",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "江苏苏豪船舶重工码头",
        "sdmc": "仪征捷水道",
        "x84": "119.23687249,32.23457143",
        "id": "NJ_2c9240fe71913a4801722bbb3d8719e2",
        "mt_id": "NJ_2c9240fe71913a4801722bbb3d8719e2",
        "hdHdly": "3",
        "hdlc": "293.60"
    },
    {
        "mtmc": "扬子船业码头",
        "sdmc": "仪征捷水道",
        "x84": "119.24342023,32.23610054",
        "id": "NJ_2c9240fe71913a4801722bbf4bf019e6",
        "mt_id": "NJ_2c9240fe71913a4801722bbf4bf019e6",
        "hdHdly": "3",
        "hdlc": "291.70"
    },
    {
        "mtmc": "江苏苏港船舶有限公司码头",
        "sdmc": "仪征捷水道",
        "x84": "119.27010853,32.23949496",
        "id": "NJ_2c9240fe71913a4801722bc381e819e9",
        "mt_id": "NJ_2c9240fe71913a4801722bc381e819e9",
        "hdHdly": "3",
        "hdlc": "289.30"
    },
    {
        "mtmc": "万隆船业码头",
        "sdmc": "仪征捷水道",
        "x84": "119.27996739,32.24058859",
        "id": "NJ_2c9240fe71913a4801722bee6a7119f4",
        "mt_id": "NJ_2c9240fe71913a4801722bee6a7119f4",
        "hdHdly": "3",
        "hdlc": "288.80"
    },
    {
        "mtmc": "仪征舜天船厂码头",
        "sdmc": "仪征捷水道",
        "x84": "119.28992781,32.24093357",
        "id": "NJ_2c9240fe71913a4801722bf27baa19f7",
        "mt_id": "NJ_2c9240fe71913a4801722bf27baa19f7",
        "hdHdly": "3",
        "hdlc": "287.20"
    },
    {
        "mtmc": "国裕船厂舾装码头",
        "sdmc": "仪征捷水道",
        "x84": "119.29939136,32.24104286",
        "id": "NJ_2c9240fe71913a4801722bf4411219f8",
        "mt_id": "NJ_2c9240fe71913a4801722bf4411219f8",
        "hdHdly": "3",
        "hdlc": "286.70"
    },
    {
        "mtmc": "国裕船厂舾装码头",
        "sdmc": "仪征捷水道",
        "x84": "119.30361828,32.24052628",
        "id": "NJ_2c9240fe71913a4801722bf75acc19f9",
        "mt_id": "NJ_2c9240fe71913a4801722bf75acc19f9",
        "hdHdly": "3",
        "hdlc": "283.50"
    },
    {
        "mtmc": "国裕船厂岸壁式舾装码头",
        "sdmc": "仪征捷水道",
        "x84": "119.31107621,32.24078863",
        "id": "NJ_2c9240fe71913a4801722bf91b9219fa",
        "mt_id": "NJ_2c9240fe71913a4801722bf91b9219fa",
        "hdHdly": "3",
        "hdlc": "284.60"
    },
    {
        "mtmc": "亚龙打捞公司码头",
        "sdmc": "仪征捷水道",
        "x84": "119.32335966,32.23971040",
        "id": "NJ_2c9240fe71913a4801722bfb083b19fd",
        "mt_id": "NJ_2c9240fe71913a4801722bfb083b19fd",
        "hdHdly": "3",
        "hdlc": "284.20"
    },
    {
        "mtmc": "锦程新能源码头",
        "sdmc": "仪征捷水道",
        "x84": "119.32551566,32.23942435",
        "id": "NJ_2c9240fe71913a4801722bfcba2c19ff",
        "mt_id": "NJ_2c9240fe71913a4801722bfcba2c19ff",
        "hdHdly": "3",
        "hdlc": "284.00"
    },
    {
        "mtmc": "扬州海事处码头",
        "sdmc": "仪征捷水道",
        "x84": "119.35993939,32.23128921",
        "id": "NJ_2c9240fe71913a4801722c02219a1a07",
        "mt_id": "NJ_2c9240fe71913a4801722c02219a1a07",
        "hdHdly": "3",
        "hdlc": "281.20"
    },
    {
        "mtmc": "润华物流通用码头上海轮泊位",
        "sdmc": "泰兴水道",
        "x84": "119.93278329,32.03906834",
        "id": "NJ_2c9240fe7236336e01724e5e495505c3",
        "mt_id": "NJ_2c9240fe7236336e01724e5e495505c3",
        "hdHdly": "3",
        "hdlc": "188.40"
    },
    {
        "mtmc": "新韩通重工码头",
        "sdmc": "泰兴水道",
        "x84": "119.89714260,32.12686337",
        "id": "NJ_2c9240fe7236336e01724e5fdcac05c4",
        "mt_id": "NJ_2c9240fe7236336e01724e5fdcac05c4",
        "hdHdly": "3",
        "hdlc": "198.20"
    },
    {
        "mtmc": "长旺张启国装卸码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.80001460,32.16161969",
        "id": "NJ_2c9240fe7236336e01724e748ba705d9",
        "mt_id": "NJ_2c9240fe7236336e01724e748ba705d9",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "常州化工码头",
        "sdmc": "录安洲右汊",
        "x84": "119.97374234,31.97862496",
        "id": "NJ_2c9240fe7236336e01726ee705e11167",
        "mt_id": "NJ_2c9240fe7236336e01726ee705e11167",
        "hdHdly": "3",
        "hdlc": "186.00"
    },
    {
        "mtmc": "丹阳飞达码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.79616816,32.10848795",
        "id": "NJ_2c9240fe7236336e01726f0478401176",
        "mt_id": "NJ_2c9240fe7236336e01726f0478401176",
        "hdHdly": "3",
        "hdlc": "203.00"
    },
    {
        "mtmc": "长旺作业区码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.79831031,32.15566506",
        "id": "NJ_2c9240fe7236336e01726f10b9b11180",
        "mt_id": "NJ_2c9240fe7236336e01726f10b9b11180",
        "hdHdly": "3",
        "hdlc": "206.40"
    },
    {
        "mtmc": "池州港江口港区三期工程",
        "sdmc": "贵池水道",
        "x84": "117.53339492,30.73385251",
        "id": "NJ_2c9240fe7236336e017273230baa137f",
        "mt_id": "NJ_2c9240fe7236336e017273230baa137f",
        "hdHdly": "3",
        "hdlc": "574.40"
    },
    {
        "mtmc": "长峰码头",
        "sdmc": "东北横水道",
        "x84": "116.55194990,29.91283976",
        "id": "NJ_2c9240fe7236336e0172783813e214b6",
        "mt_id": "NJ_2c9240fe7236336e0172783813e214b6",
        "hdHdly": "3",
        "hdlc": "731.50"
    },
    {
        "mtmc": "康普力公用码头",
        "sdmc": "东北横水道",
        "x84": "116.55598713,29.92011541",
        "id": "NJ_2c9240fe7236336e0172790fd10c14ed",
        "mt_id": "NJ_2c9240fe7236336e0172790fd10c14ed",
        "hdHdly": "3",
        "hdlc": "730.50"
    },
    {
        "mtmc": "丹阳祥龙建材码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.87826202,32.07602923",
        "id": "NJ_2c9240fe7236336e01727913896314ee",
        "mt_id": "NJ_2c9240fe7236336e01727913896314ee",
        "hdHdly": "3",
        "hdlc": "195.08"
    },
    {
        "mtmc": "九江萍钢#3码头",
        "sdmc": "湖口水道",
        "x84": "116.27583852,29.78617507",
        "id": "NJ_2c9240fe7236336e017279237a4d14fe",
        "mt_id": "NJ_2c9240fe7236336e017279237a4d14fe",
        "hdHdly": "3",
        "hdlc": "760.50"
    },
    {
        "mtmc": "九江钢铁1号码头",
        "sdmc": "湖口水道",
        "x84": "116.269751,29.783113",
        "id": "NJ_2c9240fe7236336e017279263ad81501",
        "mt_id": "NJ_2c9240fe7236336e017279263ad81501",
        "hdHdly": "3",
        "hdlc": "762.00"
    },
    {
        "mtmc": "扬中市珩群码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.87400027,32.08654926",
        "id": "NJ_2c9240fe7236336e0172793180b21516",
        "mt_id": "NJ_2c9240fe7236336e0172793180b21516",
        "hdHdly": "3",
        "hdlc": "195.80"
    },
    {
        "mtmc": "神华九江电厂配套码头",
        "sdmc": "东北横水道",
        "x84": "116.36625432,29.84233596",
        "id": "NJ_2c9240fe7236336e01727974cd2f1523",
        "mt_id": "NJ_2c9240fe7236336e01727974cd2f1523",
        "hdHdly": "3",
        "hdlc": "750.00"
    },
    {
        "mtmc": "武穴港振航件杂货码头",
        "sdmc": "武穴水道",
        "x84": "115.53525762,29.84252389",
        "id": "NJ_2c9240fe7236336e01727d3329ce1637",
        "mt_id": "NJ_2c9240fe7236336e01727d3329ce1637",
        "hdHdly": "3",
        "hdlc": "841.40"
    },
    {
        "mtmc": "小池滨江综合码头",
        "sdmc": "九江大桥水道",
        "x84": "116.01902759,29.75875630",
        "id": "NJ_2c9240fe7236336e01727d447e041643",
        "mt_id": "NJ_2c9240fe7236336e01727d447e041643",
        "hdHdly": "3",
        "hdlc": "788.20"
    },
    {
        "mtmc": "力星物流散货码头",
        "sdmc": "张家洲南水道",
        "x84": "116.24717790,29.77284417",
        "id": "NJ_2c9240fe7236336e01727d6d6afb166f",
        "mt_id": "NJ_2c9240fe7236336e01727d6d6afb166f",
        "hdHdly": "3",
        "hdlc": "764.10"
    },
    {
        "mtmc": "安徽中洋船舶工业有限公司造船基地码头",
        "sdmc": "裕溪口水道",
        "x84": "118.31197504,31.49459551",
        "id": "NJ_2c9240fe7236336e01727e0214a4168e",
        "mt_id": "NJ_2c9240fe7236336e01727e0214a4168e",
        "hdHdly": "3",
        "hdlc": "426.00"
    },
    {
        "mtmc": "新兴际华铜陵东港现代物流有限公司码头",
        "sdmc": "荻港水道",
        "x84": "117.93637879,31.06173046",
        "id": "NJ_2c9240fe7236336e01727e0c64a71691",
        "mt_id": "NJ_2c9240fe7236336e01727e0c64a71691",
        "hdHdly": "3",
        "hdlc": "500.00"
    },
    {
        "mtmc": "九鼎物流码头",
        "sdmc": "九江水道",
        "x84": "115.91313044,29.71814550",
        "id": "NJ_2c9240fe7236336e01727e49467f16ab",
        "mt_id": "NJ_2c9240fe7236336e01727e49467f16ab",
        "hdHdly": "3",
        "hdlc": "799.70"
    },
    {
        "mtmc": "江新造船厂码头",
        "sdmc": "张家洲南水道",
        "x84": "116.24122375,29.76710883",
        "id": "NJ_2c9240fe7236336e017291a44da41b78",
        "mt_id": "NJ_2c9240fe7236336e017291a44da41b78",
        "hdHdly": "3",
        "hdlc": "765.00"
    },
    {
        "mtmc": "工业城公用港口一期码头",
        "sdmc": "武穴水道",
        "x84": "115.59899870,29.83469010",
        "id": "NJ_2c9240fe7236336e017291b5026a1b97",
        "mt_id": "NJ_2c9240fe7236336e017291b5026a1b97",
        "hdHdly": "3",
        "hdlc": "835.00"
    },
    {
        "mtmc": "江西亚东水泥专用码头技改工程",
        "sdmc": "武穴水道",
        "x84": "115.55854694,29.83328948",
        "id": "NJ_2c9240fe7236336e017291c35b7b1ba2",
        "mt_id": "NJ_2c9240fe7236336e017291c35b7b1ba2",
        "hdHdly": "3",
        "hdlc": "838.80"
    },
    {
        "mtmc": "九江仲元工贸码头",
        "sdmc": "张家洲南水道",
        "x84": "116.08799212,29.76114280",
        "id": "NJ_2c9240fe7236336e017292e02df81c02",
        "mt_id": "NJ_2c9240fe7236336e017292e02df81c02",
        "hdHdly": "3",
        "hdlc": "782.00"
    },
    {
        "mtmc": "江西铜业铅锌冶炼水运码头",
        "sdmc": "湖口水道",
        "x84": "116.26373397,29.78116464",
        "id": "NJ_2c9240fe7236336e0172931be7451c16",
        "mt_id": "NJ_2c9240fe7236336e0172931be7451c16",
        "hdHdly": "3",
        "hdlc": "762.10"
    },
    {
        "mtmc": "瑞昌港理文公用码头",
        "sdmc": "武穴水道",
        "x84": "115.60958732,29.83507265",
        "id": "NJ_2c9240fe7236336e017293367a0c1c27",
        "mt_id": "NJ_2c9240fe7236336e017293367a0c1c27",
        "hdHdly": "3",
        "hdlc": "833.40"
    },
    {
        "mtmc": "金砂湾港务#1码头",
        "sdmc": "湖口水道",
        "x84": "116.25753012,29.77824319",
        "id": "NJ_2c9240fe7236336e017296e7c3f91d6b",
        "mt_id": "NJ_2c9240fe7236336e017296e7c3f91d6b",
        "hdHdly": "3",
        "hdlc": "763.00"
    },
    {
        "mtmc": "赛得利散货码头",
        "sdmc": "湖口水道",
        "x84": "116.29771022,29.79670345",
        "id": "NJ_2c9240fe7236336e01729800e9571e5a",
        "mt_id": "NJ_2c9240fe7236336e01729800e9571e5a",
        "hdHdly": "3",
        "hdlc": "758.60"
    },
    {
        "mtmc": "华东船业码头",
        "sdmc": "湖口水道",
        "x84": "116.31420726,29.80880618",
        "id": "NJ_2c9240fe7236336e0172980a96ae1e6b",
        "mt_id": "NJ_2c9240fe7236336e0172980a96ae1e6b",
        "hdHdly": "3",
        "hdlc": "756.40"
    },
    {
        "mtmc": "安徽中桩物流园配套件杂货码头",
        "sdmc": "黑沙洲南水道",
        "x84": "118.07613948,31.20661001",
        "id": "NJ_2c9240fe7236337101723b229c760094",
        "mt_id": "NJ_2c9240fe7236337101723b229c760094",
        "hdHdly": "3",
        "hdlc": "477.00"
    },
    {
        "mtmc": "扬州二电厂1号煤码头",
        "sdmc": "焦山水道",
        "x84": "119.41394147,32.26093124",
        "id": "NJ_2c9240fe7236337101723b73d75600a6",
        "mt_id": "NJ_2c9240fe7236337101723b73d75600a6",
        "hdHdly": "3",
        "hdlc": "271.20"
    },
    {
        "mtmc": "扬州市港口污泥发电厂码头",
        "sdmc": "焦山水道",
        "x84": "119.41394147,32.26093124",
        "id": "NJ_2c9240fe7236337101723b75e05900a7",
        "mt_id": "NJ_2c9240fe7236337101723b75e05900a7",
        "hdHdly": "3",
        "hdlc": "271.20"
    },
    {
        "mtmc": "扬州港区#3泊位",
        "sdmc": "焦山水道",
        "x84": "119.43173153,32.26896625",
        "id": "NJ_2c9240fe7236337101723b78082a00a8",
        "mt_id": "NJ_2c9240fe7236337101723b78082a00a8",
        "hdHdly": "3",
        "hdlc": "269.70"
    },
    {
        "mtmc": "中央储备扬州直属库码头",
        "sdmc": "焦山水道",
        "x84": "119.43452378,32.27225223",
        "id": "NJ_2c9240fe7236337101723b89586f00ab",
        "mt_id": "NJ_2c9240fe7236337101723b89586f00ab",
        "hdHdly": "3",
        "hdlc": "269.30"
    },
    {
        "mtmc": "扬州港区#1～#2泊位",
        "sdmc": "焦山水道",
        "x84": "119.43664486,32.27081889",
        "id": "NJ_2c9240fe7236337101723b8db74000ac",
        "mt_id": "NJ_2c9240fe7236337101723b8db74000ac",
        "hdHdly": "3",
        "hdlc": "269.10"
    },
    {
        "mtmc": "扬州港务局码头",
        "sdmc": "焦山水道",
        "x84": "119.44434145,32.27232291",
        "id": "NJ_2c9240fe7236337101723b90f29a00ad",
        "mt_id": "NJ_2c9240fe7236337101723b90f29a00ad",
        "hdHdly": "3",
        "hdlc": "268.60"
    },
    {
        "mtmc": "泰兴太平洋液化气码头",
        "sdmc": "泰兴水道",
        "x84": "119.92059062,32.11962076",
        "id": "NJ_2c9240fe7236337101724ab4949d0360",
        "mt_id": "NJ_2c9240fe7236337101724ab4949d0360",
        "hdHdly": "3",
        "hdlc": "197.70"
    },
    {
        "mtmc": "常州电厂码头",
        "sdmc": "泰兴水道",
        "x84": "120.00426661,31.97052024",
        "id": "NJ_2c9240fe7236337101724ab94b770361",
        "mt_id": "NJ_2c9240fe7236337101724ab94b770361",
        "hdHdly": "3",
        "hdlc": "178.20"
    },
    {
        "mtmc": "常州港码头",
        "sdmc": "泰兴水道",
        "x84": "119.99968564,31.97413665",
        "id": "NJ_2c9240fe7236337101724abc0e250362",
        "mt_id": "NJ_2c9240fe7236337101724abc0e250362",
        "hdHdly": "3",
        "hdlc": "178.50"
    },
    {
        "mtmc": "常州录安洲化工码头",
        "sdmc": "泰兴水道",
        "x84": "119.98896329,31.98407568",
        "id": "NJ_2c9240fe7236337101724acde1360363",
        "mt_id": "NJ_2c9240fe7236337101724acde1360363",
        "hdHdly": "3",
        "hdlc": "180.00"
    },
    {
        "mtmc": "常州港录安洲港区码头(一期)（现：常州港中天码头）",
        "sdmc": "泰兴水道",
        "x84": "119.97587497,31.99586843",
        "id": "NJ_2c9240fe7236337101724ada32f9036a",
        "mt_id": "NJ_2c9240fe7236337101724ada32f9036a",
        "hdHdly": "3",
        "hdlc": "181.40"
    },
    {
        "mtmc": "铁本码头",
        "sdmc": "泰兴水道",
        "x84": "119.94106238,32.02307410",
        "id": "NJ_2c9240fe7236337101724adf5873036d",
        "mt_id": "NJ_2c9240fe7236337101724adf5873036d",
        "hdHdly": "3",
        "hdlc": "186.40"
    },
    {
        "mtmc": "润华物流通用码头下海轮泊位",
        "sdmc": "泰兴水道",
        "x84": "119.93476611,32.03490295",
        "id": "NJ_2c9240fe7236337101724b0c76590374",
        "mt_id": "NJ_2c9240fe7236337101724b0c76590374",
        "hdHdly": "3",
        "hdlc": "187.80"
    },
    {
        "mtmc": "江苏镇江发电有限公司码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.27260528,32.19114751",
        "id": "NJ_2c9240fe7236337101724fbad55e07fc",
        "mt_id": "NJ_2c9240fe7236337101724fbad55e07fc",
        "hdHdly": "3",
        "hdlc": "287.70"
    },
    {
        "mtmc": "镇江港高资港区焦化散杂码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.28145262,32.18850101",
        "id": "NJ_2c9240fe7236337101724fbd0b6d07ff",
        "mt_id": "NJ_2c9240fe7236337101724fbd0b6d07ff",
        "hdHdly": "3",
        "hdlc": "286.80"
    },
    {
        "mtmc": "镇江李长荣石化码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.28615731,32.18715780",
        "id": "NJ_2c9240fe7236337101724fbfe3ab0817",
        "mt_id": "NJ_2c9240fe7236337101724fbfe3ab0817",
        "hdHdly": "3",
        "hdlc": "286.50"
    },
    {
        "mtmc": "南京港机重工制造有限公司码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.10024891,32.24081560",
        "id": "NJ_2c9240fe7236337101724fc1df20082f",
        "mt_id": "NJ_2c9240fe7236337101724fc1df20082f",
        "hdHdly": "3",
        "hdlc": "306.00"
    },
    {
        "mtmc": "金海宏业码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.28971108,32.18697943",
        "id": "NJ_2c9240fe7236337101724fc71eb60847",
        "mt_id": "NJ_2c9240fe7236337101724fc71eb60847",
        "hdHdly": "3",
        "hdlc": "286.10"
    },
    {
        "mtmc": "镇江二重码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.30212113,32.18698272",
        "id": "NJ_2c9240fe7236337101724fc953ef084a",
        "mt_id": "NJ_2c9240fe7236337101724fc953ef084a",
        "hdHdly": "3",
        "hdlc": "284.30"
    },
    {
        "mtmc": "高资海事处浮码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.30150327,32.18692793",
        "id": "NJ_2c9240fe7236337101724fcb4e800862",
        "mt_id": "NJ_2c9240fe7236337101724fcb4e800862",
        "hdHdly": "3",
        "hdlc": "285.50"
    },
    {
        "mtmc": "船港物流码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.31887045,32.18721033",
        "id": "NJ_2c9240fe7236337101724fcd85770869",
        "mt_id": "NJ_2c9240fe7236337101724fcd85770869",
        "hdHdly": "3",
        "hdlc": "283.40"
    },
    {
        "mtmc": "镇江德龙港务公司码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.33380903,32.18907726",
        "id": "NJ_2c9240fe7236337101724fd7cbda086c",
        "mt_id": "NJ_2c9240fe7236337101724fd7cbda086c",
        "hdHdly": "3",
        "hdlc": "282.40"
    },
    {
        "mtmc": "镇江惠龙长江港务公司码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.34863347,32.19344015",
        "id": "NJ_2c9240fe7236337101724fd9a295086d",
        "mt_id": "NJ_2c9240fe7236337101724fd9a295086d",
        "hdHdly": "3",
        "hdlc": "280.60"
    },
    {
        "mtmc": "镇江船厂码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.37351782,32.20987700",
        "id": "NJ_2c9240fe7236337101724fe273e7086e",
        "mt_id": "NJ_2c9240fe7236337101724fe273e7086e",
        "hdHdly": "3",
        "hdlc": "278.10"
    },
    {
        "mtmc": "思瀚码头",
        "sdmc": "炮子洲右汊",
        "x84": "119.89485226,32.05437339",
        "id": "NJ_2c9240fe72363371017255248b190a45",
        "mt_id": "NJ_2c9240fe72363371017255248b190a45",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "大路预制厂码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.74035042,32.22502785",
        "id": "NJ_2c9240fe72363371017255453d200a8d",
        "mt_id": "NJ_2c9240fe72363371017255453d200a8d",
        "hdHdly": "3",
        "hdlc": "220.50"
    },
    {
        "mtmc": "丹徒水利治江码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.74887880,32.21943594",
        "id": "NJ_2c9240fe723633710172554f33630a98",
        "mt_id": "NJ_2c9240fe723633710172554f33630a98",
        "hdHdly": "3",
        "hdlc": "220.30"
    },
    {
        "mtmc": "弋江港航处执法基地",
        "sdmc": "芜湖水道",
        "x84": "118.35695616,31.31426612",
        "id": "NJ_2c9240fe742948cb017447ac205e0992",
        "mt_id": "NJ_2c9240fe742948cb017447ac205e0992",
        "hdHdly": "3",
        "hdlc": "446.30"
    },
    {
        "mtmc": "枞阳县老洲头港港务公司码头",
        "sdmc": "大通水道（下段）",
        "x84": "117.65670592,30.79106586",
        "id": "NJ_2c9240fe74521f7b017456de08170109",
        "mt_id": "NJ_2c9240fe74521f7b017456de08170109",
        "hdHdly": "3",
        "hdlc": "560.50"
    },
    {
        "mtmc": "铜陵五松海事执法码头",
        "sdmc": "成德洲东港水道",
        "x84": "117.77436203,30.95941561",
        "id": "NJ_2c9240fe74521f7b0174661a1625063a",
        "mt_id": "NJ_2c9240fe74521f7b0174661a1625063a",
        "hdHdly": "3",
        "hdlc": "538.90"
    },
    {
        "mtmc": "安庆双河执法码头",
        "sdmc": "官洲水道",
        "x84": "116.88619277,30.37343915",
        "id": "NJ_2c9240fe74521f7b0174663bb6420653",
        "mt_id": "NJ_2c9240fe74521f7b0174663bb6420653",
        "hdHdly": "3",
        "hdlc": "662.90"
    },
    {
        "mtmc": "七公司码头（惠宁公司）宁港囤65-6",
        "sdmc": "草鞋峡水道",
        "x84": "118.87388048,32.17576190",
        "id": "NJ_2c9240fe74521f7b01746685e9b90661",
        "mt_id": "NJ_2c9240fe74521f7b01746685e9b90661",
        "hdHdly": "3",
        "hdlc": "330.10"
    },
    {
        "mtmc": "栖霞派出所水上警务室",
        "sdmc": "草鞋峡水道",
        "x84": "118.87313091,32.17550274",
        "id": "NJ_2c9240fe74521f7b0174668cb76c0667",
        "mt_id": "NJ_2c9240fe74521f7b0174668cb76c0667",
        "hdHdly": "3",
        "hdlc": "330.20"
    },
    {
        "mtmc": "南京港新生圩港区码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.85057273,32.16511014",
        "id": "NJ_2c9240fe74521f7b01746693aa2d066a",
        "mt_id": "NJ_2c9240fe74521f7b01746693aa2d066a",
        "hdHdly": "3",
        "hdlc": "330.30"
    },
    {
        "mtmc": "宁港囤65-13",
        "sdmc": "草鞋峡水道",
        "x84": "118.84946610,32.16459511",
        "id": "NJ_2c9240fe74521f7b01746697f52d066c",
        "mt_id": "NJ_2c9240fe74521f7b01746697f52d066c",
        "hdHdly": "3",
        "hdlc": "332.70"
    },
    {
        "mtmc": "武警边防码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.84887660,32.16426512",
        "id": "NJ_2c9240fe74521f7b01746699e45b066d",
        "mt_id": "NJ_2c9240fe74521f7b01746699e45b066d",
        "hdHdly": "3",
        "hdlc": "332.80"
    },
    {
        "mtmc": "海事码头（苏海事趸28）",
        "sdmc": "草鞋峡水道",
        "x84": "118.84231168,32.16030973",
        "id": "NJ_2c9240fe74521f7b01746751072606ab",
        "mt_id": "NJ_2c9240fe74521f7b01746751072606ab",
        "hdHdly": "3",
        "hdlc": "333.60"
    },
    {
        "mtmc": "桥管局码头（二桥处宁道囤23）",
        "sdmc": "草鞋峡水道",
        "x84": "118.84001899,32.15887301",
        "id": "NJ_2c9240fe74521f7b017467541c3606ac",
        "mt_id": "NJ_2c9240fe74521f7b017467541c3606ac",
        "hdHdly": "3",
        "hdlc": "333.80"
    },
    {
        "mtmc": "扬中综合码头",
        "sdmc": "口岸直水道",
        "x84": "119.88120869,32.18828952",
        "id": "NJ_2c9240fe74521f7b01746b44079e0a1c",
        "mt_id": "NJ_2c9240fe74521f7b01746b44079e0a1c",
        "hdHdly": "3",
        "hdlc": "206.00"
    },
    {
        "mtmc": "新韩通重工码头",
        "sdmc": "口岸直水道",
        "x84": "119.89714261,32.12685436",
        "id": "NJ_2c9240fe74521f7b01746b47946e0a1f",
        "mt_id": "NJ_2c9240fe74521f7b01746b47946e0a1f",
        "hdHdly": "3",
        "hdlc": "199.00"
    },
    {
        "mtmc": "润祥码头",
        "sdmc": "口岸直水道",
        "x84": "119.67739008,32.24778500",
        "id": "NJ_2c9240fe74521f7b01746b4b7c4f0a20",
        "mt_id": "NJ_2c9240fe74521f7b01746b4b7c4f0a20",
        "hdHdly": "3",
        "hdlc": "239.30"
    },
    {
        "mtmc": "扬州远洋国际码头",
        "sdmc": "口岸直水道",
        "x84": "119.72696741,32.31833158",
        "id": "NJ_2c9240fe74521f7b01746b4dceac0a21",
        "mt_id": "NJ_2c9240fe74521f7b01746b4dceac0a21",
        "hdHdly": "3",
        "hdlc": "230.60"
    },
    {
        "mtmc": "江都泰富码头",
        "sdmc": "口岸直水道",
        "x84": "119.73686632,32.32519717",
        "id": "NJ_2c9240fe74521f7b01746b534c310a23",
        "mt_id": "NJ_2c9240fe74521f7b01746b534c310a23",
        "hdHdly": "3",
        "hdlc": "229.50"
    },
    {
        "mtmc": "中航鼎衡码头",
        "sdmc": "口岸直水道",
        "x84": "119.78656212,32.32869095",
        "id": "NJ_2c9240fe74521f7b01746b56a1b60a24",
        "mt_id": "NJ_2c9240fe74521f7b01746b56a1b60a24",
        "hdHdly": "3",
        "hdlc": "225.10"
    },
    {
        "mtmc": "扬州海昌(江都海昌)港务码头",
        "sdmc": "口岸直水道",
        "x84": "119.80738017,32.32321465",
        "id": "NJ_2c9240fe74521f7b01746b5903550a25",
        "mt_id": "NJ_2c9240fe74521f7b01746b5903550a25",
        "hdHdly": "3",
        "hdlc": "223.60"
    },
    {
        "mtmc": "扬州海螺(江都海螺)水泥码头",
        "sdmc": "口岸直水道",
        "x84": "119.81747923,32.31836007",
        "id": "NJ_2c9240fe74521f7b01746b5ef36a0a26",
        "mt_id": "NJ_2c9240fe74521f7b01746b5ef36a0a26",
        "hdHdly": "3",
        "hdlc": "222.60"
    },
    {
        "mtmc": "泰州杨湾海螺水泥码头",
        "sdmc": "口岸直水道",
        "x84": "119.82831426,32.31121516",
        "id": "NJ_2c9240fe74521f7b01746b6103300a27",
        "mt_id": "NJ_2c9240fe74521f7b01746b6103300a27",
        "hdHdly": "3",
        "hdlc": "221.60"
    },
    {
        "mtmc": "泰州杨湾港区万吨码头",
        "sdmc": "口岸直水道",
        "x84": "119.83176088,32.30792830",
        "id": "NJ_2c9240fe74521f7b01746b629a6a0a28",
        "mt_id": "NJ_2c9240fe74521f7b01746b629a6a0a28",
        "hdHdly": "3",
        "hdlc": "220.80"
    },
    {
        "mtmc": "海泰石化码头",
        "sdmc": "口岸直水道",
        "x84": "119.84776568,32.29367340",
        "id": "NJ_2c9240fe74521f7b01746b64c8280a29",
        "mt_id": "NJ_2c9240fe74521f7b01746b64c8280a29",
        "hdHdly": "3",
        "hdlc": "218.60"
    },
    {
        "mtmc": "镇江船厂船舶舾装码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.37584675,32.21209788",
        "id": "NJ_2c9240fe7452204601746b4189dc1263",
        "mt_id": "NJ_2c9240fe7452204601746b4189dc1263",
        "hdHdly": "3",
        "hdlc": "278.00"
    },
    {
        "mtmc": "镇江中船码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.35678324,32.19631752",
        "id": "NJ_2c9240fe7452204601746b4f90901266",
        "mt_id": "NJ_2c9240fe7452204601746b4f90901266",
        "hdHdly": "3",
        "hdlc": "280.20"
    },
    {
        "mtmc": "江苏镇江发电有限公司煤码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.27832503,32.18931364",
        "id": "NJ_2c9240fe7452204601746b6adc301276",
        "mt_id": "NJ_2c9240fe7452204601746b6adc301276",
        "hdHdly": "3",
        "hdlc": "287.40"
    },
    {
        "mtmc": "船山矿码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.31059274,32.18669829",
        "id": "NJ_2c9240fe7452204601746b72285b127a",
        "mt_id": "NJ_2c9240fe7452204601746b72285b127a",
        "hdHdly": "3",
        "hdlc": "284.50"
    },
    {
        "mtmc": "中盐码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.29943866,32.18651070",
        "id": "NJ_2c9240fe7452204601746b76b96d127b",
        "mt_id": "NJ_2c9240fe7452204601746b76b96d127b",
        "hdHdly": "3",
        "hdlc": "285.30"
    },
    {
        "mtmc": "句容台泥码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.25860791,32.19792183",
        "id": "NJ_2c9240fe7452204601746b7b77e81283",
        "mt_id": "NJ_2c9240fe7452204601746b7b77e81283",
        "hdHdly": "3",
        "hdlc": "289.00"
    },
    {
        "mtmc": "建华管桩码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.25622167,32.19890656",
        "id": "NJ_2c9240fe7452204601746b82f69d128a",
        "mt_id": "NJ_2c9240fe7452204601746b82f69d128a",
        "hdHdly": "3",
        "hdlc": "289.80"
    },
    {
        "mtmc": "句容电厂码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.24920655,32.20337392",
        "id": "NJ_2c9240fe7452204601746b8c1788128b",
        "mt_id": "NJ_2c9240fe7452204601746b8c1788128b",
        "hdHdly": "3",
        "hdlc": "290.30"
    },
    {
        "mtmc": "华电句容煤炭储运码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.24317695,32.20677138",
        "id": "NJ_2c9240fe7452204601746bb63172129d",
        "mt_id": "NJ_2c9240fe7452204601746bb63172129d",
        "hdHdly": "3",
        "hdlc": "291.00"
    },
    {
        "mtmc": "句容华港港务有限公司长江趸船码头",
        "sdmc": "仪征水道(下段)",
        "x84": "119.23382604,32.21178455",
        "id": "NJ_2c9240fe7452204601746bca2bd712a0",
        "mt_id": "NJ_2c9240fe7452204601746bca2bd712a0",
        "hdHdly": "3",
        "hdlc": "292.00"
    },
    {
        "mtmc": "泰州高港作业区沥青码头",
        "sdmc": "口岸直水道",
        "x84": "119.84994442,32.29229629",
        "id": "NJ_2c9240fe746ce54c01747b1aa80210f7",
        "mt_id": "NJ_2c9240fe746ce54c01747b1aa80210f7",
        "hdHdly": "3",
        "hdlc": "218.30"
    },
    {
        "mtmc": "高港港区码头群",
        "sdmc": "口岸直水道",
        "x84": "119.85711998,32.28639793",
        "id": "NJ_2c9240fe746ce54c01747b2416cf10fe",
        "mt_id": "NJ_2c9240fe746ce54c01747b2416cf10fe",
        "hdHdly": "3",
        "hdlc": "217.40"
    },
    {
        "mtmc": "江苏苏洋船舶工程有限公司船厂配套码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.89710263,32.05892044",
        "id": "NJ_2c9240fe746ce85101749adebf1e2c0c",
        "mt_id": "NJ_2c9240fe746ce85101749adebf1e2c0c",
        "hdHdly": "3",
        "hdlc": "192.00"
    },
    {
        "mtmc": "轮渡码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.81387969,32.15062323",
        "id": "NJ_2c9240fe74a058e70174b48e4b600e10",
        "mt_id": "NJ_2c9240fe74a058e70174b48e4b600e10",
        "hdHdly": "3",
        "hdlc": "336.20"
    },
    {
        "mtmc": "宁港囤90-5",
        "sdmc": "草鞋峡水道",
        "x84": "118.78650054,32.13392511",
        "id": "NJ_2c9240fe74a058e70174b49b21360e15",
        "mt_id": "NJ_2c9240fe74a058e70174b49b21360e15",
        "hdHdly": "3",
        "hdlc": "339.50"
    },
    {
        "mtmc": "边防码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.78297187,32.13071715",
        "id": "NJ_2c9240fe74a058e70174b4a19aaf0e16",
        "mt_id": "NJ_2c9240fe74a058e70174b4a19aaf0e16",
        "hdHdly": "3",
        "hdlc": "340.30"
    },
    {
        "mtmc": "上元门港区港口执法基地码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.78256404,32.13030745",
        "id": "NJ_2c9240fe74a058e70174b4a393a10e17",
        "mt_id": "NJ_2c9240fe74a058e70174b4a393a10e17",
        "hdHdly": "3",
        "hdlc": "340.40"
    },
    {
        "mtmc": "五马渡旅游码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.78094409,32.12940816",
        "id": "NJ_2c9240fe74a058e70174b4a5a03a0e18",
        "mt_id": "NJ_2c9240fe74a058e70174b4a5a03a0e18",
        "hdHdly": "3",
        "hdlc": "340.50"
    },
    {
        "mtmc": "长燃#3码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.77728300,32.12741610",
        "id": "NJ_2c9240fe74a058e70174b4ab3dab0e19",
        "mt_id": "NJ_2c9240fe74a058e70174b4ab3dab0e19",
        "hdHdly": "3",
        "hdlc": "341.00"
    },
    {
        "mtmc": "长燃#2码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.77643117,32.12692995",
        "id": "NJ_2c9240fe74a058e70174b4b3cf380e1a",
        "mt_id": "NJ_2c9240fe74a058e70174b4b3cf380e1a",
        "hdHdly": "3",
        "hdlc": "341.10"
    },
    {
        "mtmc": "长燃#1码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.77534542,32.12650466",
        "id": "NJ_2c9240fe74a058e70174b4b568950e1b",
        "mt_id": "NJ_2c9240fe74a058e70174b4b568950e1b",
        "hdHdly": "3",
        "hdlc": "341.20"
    },
    {
        "mtmc": "宁港一公司#14码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.76944017,32.12436439",
        "id": "NJ_2c9240fe74a058e70174b4be581f0e1d",
        "mt_id": "NJ_2c9240fe74a058e70174b4be581f0e1d",
        "hdHdly": "3",
        "hdlc": "341.60"
    },
    {
        "mtmc": "金陵船厂#3码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.75389733,32.11878392",
        "id": "NJ_2c9240fe74a058e70174b4c0c7c80e20",
        "mt_id": "NJ_2c9240fe74a058e70174b4c0c7c80e20",
        "hdHdly": "3",
        "hdlc": "343.10"
    },
    {
        "mtmc": "金陵船厂#1码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.75058945,32.11660521",
        "id": "NJ_2c9240fe74a058e70174b4c1f3680e21",
        "mt_id": "NJ_2c9240fe74a058e70174b4c1f3680e21",
        "hdHdly": "3",
        "hdlc": "343.70"
    },
    {
        "mtmc": "中国长江航运集团金陵船厂码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.22780368,32.23347206",
        "id": "NJ_2c9240fe74a058e70174b4c958b80e23",
        "mt_id": "NJ_2c9240fe74a058e70174b4c958b80e23",
        "hdHdly": "3",
        "hdlc": "294.00"
    },
    {
        "mtmc": "中国长江航运集团金陵船厂码头#2",
        "sdmc": "仪征水道(上段)",
        "x84": "119.21963943,32.23412524",
        "id": "NJ_2c9240fe74a058e70174b4cb5c3a0e24",
        "mt_id": "NJ_2c9240fe74a058e70174b4cb5c3a0e24",
        "hdHdly": "3",
        "hdlc": "294.50"
    },
    {
        "mtmc": "中国长江航运集团金陵船厂码头#3",
        "sdmc": "仪征水道(上段)",
        "x84": "119.21877385,32.23485032",
        "id": "NJ_2c9240fe74a058e70174b4cd5a3b0e25",
        "mt_id": "NJ_2c9240fe74a058e70174b4cd5a3b0e25",
        "hdHdly": "3",
        "hdlc": "294.70"
    },
    {
        "mtmc": "环球造船有限公司码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.21573617,32.23523728",
        "id": "NJ_2c9240fe74a058e70174b4d1321f0e27",
        "mt_id": "NJ_2c9240fe74a058e70174b4d1321f0e27",
        "hdHdly": "3",
        "hdlc": "295.20"
    },
    {
        "mtmc": "海螺水泥专用码头",
        "sdmc": "大新专用航道",
        "x84": "120.57275593,31.99437493",
        "id": "NJ_2c9240fe74a058e70174b4da457e0e2a",
        "mt_id": "NJ_2c9240fe74a058e70174b4da457e0e2a",
        "hdHdly": "3",
        "hdlc": "120.70"
    },
    {
        "mtmc": "环球造船有限公司码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.20892208,32.23677286",
        "id": "NJ_2c9240fe74a058e70174b4dae8560e2b",
        "mt_id": "NJ_2c9240fe74a058e70174b4dae8560e2b",
        "hdHdly": "3",
        "hdlc": "295.10"
    },
    {
        "mtmc": "扬州港仪征港区泗源沟作业区仪征港务公用码头一期工程",
        "sdmc": "仪征水道(上段)",
        "x84": "119.20228520,32.23864284",
        "id": "NJ_2c9240fe74a058e70174b4ddd02c0e2c",
        "mt_id": "NJ_2c9240fe74a058e70174b4ddd02c0e2c",
        "hdHdly": "3",
        "hdlc": "296.00"
    },
    {
        "mtmc": "泗源沟口头无名氏码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.18058984,32.24549253",
        "id": "NJ_2c9240fe74a058e70174b4df9f6d0e2f",
        "mt_id": "NJ_2c9240fe74a058e70174b4df9f6d0e2f",
        "hdHdly": "3",
        "hdlc": "298.60"
    },
    {
        "mtmc": "仪征瑞达码头（宁工囤6）",
        "sdmc": "仪征水道(上段)",
        "x84": "119.16867415,32.24895854",
        "id": "NJ_2c9240fe74a058e70174b4ec31350e33",
        "mt_id": "NJ_2c9240fe74a058e70174b4ec31350e33",
        "hdHdly": "3",
        "hdlc": "299.80"
    },
    {
        "mtmc": "扬州港仪征港区公共液体化工专用码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.14863240,32.25093509",
        "id": "NJ_2c9240fe74a058e70174b4ee84f90e34",
        "mt_id": "NJ_2c9240fe74a058e70174b4ee84f90e34",
        "hdHdly": "3",
        "hdlc": "301.30"
    },
    {
        "mtmc": "扬州港仪征港区公共液体化工专用码头（二期）",
        "sdmc": "仪征水道(上段)",
        "x84": "119.14315439,32.25120462",
        "id": "NJ_2c9240fe74a058e70174b4f007440e35",
        "mt_id": "NJ_2c9240fe74a058e70174b4f007440e35",
        "hdHdly": "3",
        "hdlc": "302.00"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.14117884,32.25139860",
        "id": "NJ_2c9240fe74a058e70174b4f2163f0e70",
        "mt_id": "NJ_2c9240fe74a058e70174b4f2163f0e70",
        "hdHdly": "3",
        "hdlc": "302.30"
    },
    {
        "mtmc": "扬州港仪征港区南京港股份有限公司610、611码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.12729128,32.25221414",
        "id": "NJ_2c9240fe74a058e70174b50b66e90fa8",
        "mt_id": "NJ_2c9240fe74a058e70174b50b66e90fa8",
        "hdHdly": "3",
        "hdlc": "303.60"
    },
    {
        "mtmc": "中化扬州石化码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.12092214,32.25175940",
        "id": "NJ_2c9240fe74a058e70174b50eaada0faa",
        "mt_id": "NJ_2c9240fe74a058e70174b50eaada0faa",
        "hdHdly": "3",
        "hdlc": "304.20"
    },
    {
        "mtmc": "中化扬州石化码头",
        "sdmc": "仪征水道(上段)",
        "x84": "119.11934566,32.25164024",
        "id": "NJ_2c9240fe74a058e70174b51042a10fab",
        "mt_id": "NJ_2c9240fe74a058e70174b51042a10fab",
        "hdHdly": "3",
        "hdlc": "304.40"
    },
    {
        "mtmc": "紫金山船厂码头#1",
        "sdmc": "仪征水道(上段)",
        "x84": "119.11309606,32.25081670",
        "id": "NJ_2c9240fe74a058e70174b51ba7520faf",
        "mt_id": "NJ_2c9240fe74a058e70174b51ba7520faf",
        "hdHdly": "3",
        "hdlc": "305.00"
    },
    {
        "mtmc": "紫金山船厂码头#2",
        "sdmc": "仪征水道(上段)",
        "x84": "119.11111548,32.25043306",
        "id": "NJ_2c9240fe74a058e70174b51d23340fb0",
        "mt_id": "NJ_2c9240fe74a058e70174b51d23340fb0",
        "hdHdly": "3",
        "hdlc": "305.10"
    },
    {
        "mtmc": "紫金山船厂码头#3",
        "sdmc": "仪征水道(上段)",
        "x84": "119.10803019,32.25016783",
        "id": "NJ_2c9240fe74a058e70174b51e484b0fb1",
        "mt_id": "NJ_2c9240fe74a058e70174b51e484b0fb1",
        "hdHdly": "3",
        "hdlc": "305.20"
    },
    {
        "mtmc": "宁港6公司自备取水口囤船",
        "sdmc": "仪征水道(上段)",
        "x84": "119.10641792,32.25010232",
        "id": "NJ_2c9240fe74a058e70174b51fbaa80fb2",
        "mt_id": "NJ_2c9240fe74a058e70174b51fbaa80fb2",
        "hdHdly": "3",
        "hdlc": "305.60"
    },
    {
        "mtmc": "六石码头",
        "sdmc": "龙潭水道",
        "x84": "118.97705410,32.18884842",
        "id": "NJ_2c9240fe74a058e70174b521cb580fb3",
        "mt_id": "NJ_2c9240fe74a058e70174b521cb580fb3",
        "hdHdly": "3",
        "hdlc": "320.00"
    },
    {
        "mtmc": "双龙码头",
        "sdmc": "龙潭水道",
        "x84": "118.97489562,32.18937197",
        "id": "NJ_2c9240fe74a058e70174b52467310fb4",
        "mt_id": "NJ_2c9240fe74a058e70174b52467310fb4",
        "hdHdly": "3",
        "hdlc": "321.60"
    },
    {
        "mtmc": "清江码头",
        "sdmc": "龙潭水道",
        "x84": "118.90679325,32.18524847",
        "id": "NJ_2c9240fe74a058e70174b5261ea60fb6",
        "mt_id": "NJ_2c9240fe74a058e70174b5261ea60fb6",
        "hdHdly": "3",
        "hdlc": "326.40"
    },
    {
        "mtmc": "西坝八期#9泊位",
        "sdmc": "龙潭水道",
        "x84": "118.90373503,32.18561875",
        "id": "NJ_2c9240fe74a058e70174b527c5030fb8",
        "mt_id": "NJ_2c9240fe74a058e70174b527c5030fb8",
        "hdHdly": "3",
        "hdlc": "328.00"
    },
    {
        "mtmc": "西坝三期+起步工程码头",
        "sdmc": "龙潭水道",
        "x84": "118.89837160,32.18633859",
        "id": "NJ_2c9240fe74a058e70174b529a2ad0fbb",
        "mt_id": "NJ_2c9240fe74a058e70174b529a2ad0fbb",
        "hdHdly": "3",
        "hdlc": "328.00"
    },
    {
        "mtmc": "南京化学工业园西坝港区五期工程+二期工程",
        "sdmc": "龙潭水道",
        "x84": "118.88045267,32.18538763",
        "id": "NJ_2c9240fe74a058e70174b52c18dd0fbe",
        "mt_id": "NJ_2c9240fe74a058e70174b52c18dd0fbe",
        "hdHdly": "3",
        "hdlc": "328.10"
    },
    {
        "mtmc": "武家嘴船厂码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.85623126,32.17886772",
        "id": "NJ_2c9240fe74a058e70174b52efccb0fbf",
        "mt_id": "NJ_2c9240fe74a058e70174b52efccb0fbf",
        "hdHdly": "3",
        "hdlc": "331.40"
    },
    {
        "mtmc": "宁港09-6（港务局）",
        "sdmc": "草鞋峡水道",
        "x84": "118.84937152,32.17211466",
        "id": "NJ_2c9240fe74a058e70174b530b3b20fc0",
        "mt_id": "NJ_2c9240fe74a058e70174b530b3b20fc0",
        "hdHdly": "3",
        "hdlc": "332.70"
    },
    {
        "mtmc": "蓝昇船厂A",
        "sdmc": "草鞋峡水道",
        "x84": "118.84344659,32.17032027",
        "id": "NJ_2c9240fe74a058e70174b53662990fc3",
        "mt_id": "NJ_2c9240fe74a058e70174b53662990fc3",
        "hdHdly": "3",
        "hdlc": "333.20"
    },
    {
        "mtmc": "蓝昇船厂B",
        "sdmc": "草鞋峡水道",
        "x84": "118.84294974,32.17020751",
        "id": "NJ_2c9240fe74a058e70174b543f3910fcc",
        "mt_id": "NJ_2c9240fe74a058e70174b543f3910fcc",
        "hdHdly": "3",
        "hdlc": "333.30"
    },
    {
        "mtmc": "川田修船厂码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.83746798,32.16692905",
        "id": "NJ_2c9240fe74a058e70174b5455bfb0fcd",
        "mt_id": "NJ_2c9240fe74a058e70174b5455bfb0fcd",
        "hdHdly": "3",
        "hdlc": "333.90"
    },
    {
        "mtmc": "轮渡公司码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.82355950,32.16525846",
        "id": "NJ_2c9240fe74a058e70174b56bee8311ce",
        "mt_id": "NJ_2c9240fe74a058e70174b56bee8311ce",
        "hdHdly": "3",
        "hdlc": "335.20"
    },
    {
        "mtmc": "中国水政（新增）",
        "sdmc": "仪征水道(上段)",
        "x84": "119.17555900,32.24681242",
        "id": "NJ_2c9240fe74a058e70174b8d1ce9c13f0",
        "mt_id": "NJ_2c9240fe74a058e70174b8d1ce9c13f0",
        "hdHdly": "3",
        "hdlc": "299.80"
    },
    {
        "mtmc": "南京水警07趸新增",
        "sdmc": "龙潭水道",
        "x84": "118.93111308,32.16930635",
        "id": "NJ_2c9240fe74a058e70174bab542d917ca",
        "mt_id": "NJ_2c9240fe74a058e70174bab542d917ca",
        "hdHdly": "3",
        "hdlc": "324.00"
    },
    {
        "mtmc": "无名氏码头",
        "sdmc": "草鞋峡水道",
        "x84": "118.84743932,32.16307077",
        "id": "NJ_2c9240fe74a058e70174bb3ab70c1840",
        "mt_id": "NJ_2c9240fe74a058e70174bb3ab70c1840",
        "hdHdly": "3",
        "hdlc": "332.70"
    },
    {
        "mtmc": "彭泽县装卸运输公司码头",
        "sdmc": "马当南水道",
        "x84": "116.557316,29.927897",
        "id": "NJ_2c9240fe74a058e70174c9fca34a2cb6",
        "mt_id": "NJ_2c9240fe74a058e70174c9fca34a2cb6",
        "hdHdly": "3",
        "hdlc": "729.70"
    },
    {
        "mtmc": "乾丰物流码头",
        "sdmc": "东北横水道",
        "x84": "116.55459202,29.91713374",
        "id": "NJ_2c9240fe74a058e70174ca019a2a2cb9",
        "mt_id": "NJ_2c9240fe74a058e70174ca019a2a2cb9",
        "hdHdly": "3",
        "hdlc": "730.90"
    },
    {
        "mtmc": "朝阳机械厂码头",
        "sdmc": "东北横水道",
        "x84": "116.53404549,29.89515944",
        "id": "NJ_2c9240fe74a058e70174ca10285e2cbc",
        "mt_id": "NJ_2c9240fe74a058e70174ca10285e2cbc",
        "hdHdly": "3",
        "hdlc": "733.70"
    },
    {
        "mtmc": "逸达船厂码头",
        "sdmc": "东北横水道",
        "x84": "116.42660548,29.86705918",
        "id": "NJ_2c9240fe74a058e70174ca130ce92cbd",
        "mt_id": "NJ_2c9240fe74a058e70174ca130ce92cbd",
        "hdHdly": "3",
        "hdlc": "744.00"
    },
    {
        "mtmc": "泰子装卸码头",
        "sdmc": "东北横水道",
        "x84": "116.42171373,29.86637928",
        "id": "NJ_2c9240fe74a058e70174ca150e662cbe",
        "mt_id": "NJ_2c9240fe74a058e70174ca150e662cbe",
        "hdHdly": "3",
        "hdlc": "744.40"
    },
    {
        "mtmc": "惠民码头",
        "sdmc": "东北横水道",
        "x84": "116.40628036,29.86134912",
        "id": "NJ_2c9240fe74a058e70174ca195b8a2cc0",
        "mt_id": "NJ_2c9240fe74a058e70174ca195b8a2cc0",
        "hdHdly": "3",
        "hdlc": "746.00"
    },
    {
        "mtmc": "晨光码头",
        "sdmc": "东北横水道",
        "x84": "116.38237729,29.84884443",
        "id": "NJ_2c9240fe74a058e70174ca287c882cc7",
        "mt_id": "NJ_2c9240fe74a058e70174ca287c882cc7",
        "hdHdly": "3",
        "hdlc": "748.80"
    },
    {
        "mtmc": "南通港天生港区横港沙作业区化工码头",
        "sdmc": "浏海沙水道",
        "x84": "120.67056264,32.00470856",
        "id": "NJ_2c9240fe74a058e70174d26345603540",
        "mt_id": "NJ_2c9240fe74a058e70174d26345603540",
        "hdHdly": "3",
        "hdlc": "111.60"
    },
    {
        "mtmc": "鑫海煤炭码头",
        "sdmc": "白茆沙水道",
        "x84": "121.13234342,31.70083738",
        "id": "NJ_2c9240fe74a058e70174d777d8b93b76",
        "mt_id": "NJ_2c9240fe74a058e70174d777d8b93b76",
        "hdHdly": "3",
        "hdlc": "51.50"
    },
    {
        "mtmc": "三角形码头",
        "sdmc": "九江水道",
        "x84": "115.94346185,29.71516175",
        "id": "NJ_2c9240fe74a058e70174d78679763ed9",
        "mt_id": "NJ_2c9240fe74a058e70174d78679763ed9",
        "hdHdly": "3",
        "hdlc": "797.00"
    },
    {
        "mtmc": "四方物流码头",
        "sdmc": "九江水道",
        "x84": "115.94189263,29.71501390",
        "id": "NJ_2c9240fe74a058e70174d78aa2bb3eda",
        "mt_id": "NJ_2c9240fe74a058e70174d78aa2bb3eda",
        "hdHdly": "3",
        "hdlc": "797.00"
    },
    {
        "mtmc": "水泥船厂码头",
        "sdmc": "九江水道",
        "x84": "115.93147954,29.71467765",
        "id": "NJ_2c9240fe74a058e70174d78e554d3edb",
        "mt_id": "NJ_2c9240fe74a058e70174d78e554d3edb",
        "hdHdly": "3",
        "hdlc": "798.00"
    },
    {
        "mtmc": "214散货码头",
        "sdmc": "九江水道",
        "x84": "115.92494030,29.71544557",
        "id": "NJ_2c9240fe74a058e70174d792e80c3edd",
        "mt_id": "NJ_2c9240fe74a058e70174d792e80c3edd",
        "hdHdly": "3",
        "hdlc": "798.70"
    },
    {
        "mtmc": "砂石码头",
        "sdmc": "九江水道",
        "x84": "115.92176138,29.71604213",
        "id": "NJ_2c9240fe74a058e70174d7977af73edf",
        "mt_id": "NJ_2c9240fe74a058e70174d7977af73edf",
        "hdHdly": "3",
        "hdlc": "799.00"
    },
    {
        "mtmc": "远洋码头",
        "sdmc": "九江水道",
        "x84": "115.92015540,29.71643496",
        "id": "NJ_2c9240fe74a058e70174d7990df33ee0",
        "mt_id": "NJ_2c9240fe74a058e70174d7990df33ee0",
        "hdHdly": "3",
        "hdlc": "799.10"
    },
    {
        "mtmc": "航运公司码头",
        "sdmc": "九江水道",
        "x84": "115.91622923,29.71732297",
        "id": "NJ_2c9240fe74a058e70174d79cd5b53ee2",
        "mt_id": "NJ_2c9240fe74a058e70174d79cd5b53ee2",
        "hdHdly": "3",
        "hdlc": "799.50"
    },
    {
        "mtmc": "顺鑫码头",
        "sdmc": "九江水道",
        "x84": "115.91204242,29.71841620",
        "id": "NJ_2c9240fe74a058e70174d7a079073ee5",
        "mt_id": "NJ_2c9240fe74a058e70174d7a079073ee5",
        "hdHdly": "3",
        "hdlc": "800.00"
    },
    {
        "mtmc": "南通中远重工有限公司通用码头",
        "sdmc": "白茆沙北水道",
        "x84": "121.08632974,31.78239766",
        "id": "NJ_2c9240fe74a058e70174d7a206a23ee6",
        "mt_id": "NJ_2c9240fe74a058e70174d7a206a23ee6",
        "hdHdly": "3",
        "hdlc": "63.40"
    },
    {
        "mtmc": "港区海事执法大队码头",
        "sdmc": "九江水道",
        "x84": "115.90227106,29.72081618",
        "id": "NJ_2c9240fe74a058e70174d7a2171d3ee7",
        "mt_id": "NJ_2c9240fe74a058e70174d7a2171d3ee7",
        "hdHdly": "3",
        "hdlc": "800.90"
    },
    {
        "mtmc": "水警码头",
        "sdmc": "九江水道",
        "x84": "115.79882186,29.76428911",
        "id": "NJ_2c9240fe74a058e70174d7bc12663ee8",
        "mt_id": "NJ_2c9240fe74a058e70174d7bc12663ee8",
        "hdHdly": "3",
        "hdlc": "811.90"
    },
    {
        "mtmc": "龙翔码头",
        "sdmc": "九江水道",
        "x84": "115.79380354,29.76721113",
        "id": "NJ_2c9240fe74a058e70174d7bde1df3ee9",
        "mt_id": "NJ_2c9240fe74a058e70174d7bde1df3ee9",
        "hdHdly": "3",
        "hdlc": "812.40"
    },
    {
        "mtmc": "柴桑水政码头",
        "sdmc": "新洲水道",
        "x84": "115.77177860,29.77844615",
        "id": "NJ_2c9240fe74a058e70174d7c1a06c3eeb",
        "mt_id": "NJ_2c9240fe74a058e70174d7c1a06c3eeb",
        "hdHdly": "3",
        "hdlc": "815.10"
    },
    {
        "mtmc": "徐湾码头",
        "sdmc": "新洲水道",
        "x84": "115.76995934,29.77924106",
        "id": "NJ_2c9240fe74a058e70174d7c4cb373eec",
        "mt_id": "NJ_2c9240fe74a058e70174d7c4cb373eec",
        "hdHdly": "3",
        "hdlc": "815.30"
    },
    {
        "mtmc": "小池检查站码头",
        "sdmc": "新洲水道",
        "x84": "115.78314324,29.79205477",
        "id": "NJ_2c9240fe74a058e70174d7ca328d3eed",
        "mt_id": "NJ_2c9240fe74a058e70174d7ca328d3eed",
        "hdHdly": "3",
        "hdlc": "815.20"
    },
    {
        "mtmc": "武穴港航码头",
        "sdmc": "武穴水道",
        "x84": "115.55247816,29.84305473",
        "id": "NJ_2c9240fe74a058e70174d7d771143ef9",
        "mt_id": "NJ_2c9240fe74a058e70174d7d771143ef9",
        "hdHdly": "3",
        "hdlc": "839.80"
    },
    {
        "mtmc": "中长燃八里江码头",
        "sdmc": "张家洲南水道",
        "x84": "116.24019196,29.77503238",
        "id": "NJ_2c9240fe74a058e70174d7dbee0b3efa",
        "mt_id": "NJ_2c9240fe74a058e70174d7dbee0b3efa",
        "hdHdly": "3",
        "hdlc": "764.50"
    },
    {
        "mtmc": "中铁重件码头",
        "sdmc": "九江大桥水道",
        "x84": "116.05253854,29.75689724",
        "id": "NJ_2c9240fe74a058e70174d7e09bfc3efb",
        "mt_id": "NJ_2c9240fe74a058e70174d7e09bfc3efb",
        "hdHdly": "3",
        "hdlc": "785.40"
    },
    {
        "mtmc": "武穴海事处码头",
        "sdmc": "武穴水道",
        "x84": "115.55334673,29.84309131",
        "id": "NJ_2c9240fe74a058e70174d8aa43c44035",
        "mt_id": "NJ_2c9240fe74a058e70174d8aa43c44035",
        "hdHdly": "3",
        "hdlc": "839.70"
    },
    {
        "mtmc": "小孤山客运码头",
        "sdmc": "东北横水道",
        "x84": "116.54885075,29.92955427",
        "id": "NJ_2c9240fe74a058e70174d8ae98184036",
        "mt_id": "NJ_2c9240fe74a058e70174d8ae98184036",
        "hdHdly": "3",
        "hdlc": "729.50"
    },
    {
        "mtmc": "周家湾海事救助基地码头",
        "sdmc": "东北横水道",
        "x84": "116.47615786,29.90951104",
        "id": "NJ_2c9240fe74a058e70174d8bfce0d404b",
        "mt_id": "NJ_2c9240fe74a058e70174d8bfce0d404b",
        "hdHdly": "3",
        "hdlc": "738.80"
    },
    {
        "mtmc": "恒运砂石码头",
        "sdmc": "湖口水道",
        "x84": "116.30815610,29.80577963",
        "id": "NJ_2c9240fe74a058e70174d903eb514089",
        "mt_id": "NJ_2c9240fe74a058e70174d903eb514089",
        "hdHdly": "3",
        "hdlc": "757.20"
    },
    {
        "mtmc": "江海#4泵船",
        "sdmc": "九江水道",
        "x84": "115.95034956,29.71664184",
        "id": "NJ_2c9240fe74a058e70174d91ec77d4092",
        "mt_id": "NJ_2c9240fe74a058e70174d91ec77d4092",
        "hdHdly": "3",
        "hdlc": "796.60"
    },
    {
        "mtmc": "建陶码头",
        "sdmc": "九江水道",
        "x84": "115.94794952,29.71589210",
        "id": "NJ_2c9240fe74a058e70174d92069764093",
        "mt_id": "NJ_2c9240fe74a058e70174d92069764093",
        "hdHdly": "3",
        "hdlc": "796.60"
    },
    {
        "mtmc": "海后码头",
        "sdmc": "九江水道",
        "x84": "115.94535881,29.71559183",
        "id": "NJ_2c9240fe74a058e70174d9229d0a4094",
        "mt_id": "NJ_2c9240fe74a058e70174d9229d0a4094",
        "hdHdly": "3",
        "hdlc": "796.80"
    },
    {
        "mtmc": "鑫丰码头",
        "sdmc": "九江水道",
        "x84": "115.90997038,29.71889528",
        "id": "NJ_2c9240fe74a058e70174d925209f4095",
        "mt_id": "NJ_2c9240fe74a058e70174d925209f4095",
        "hdHdly": "3",
        "hdlc": "800.20"
    },
    {
        "mtmc": "盛华物流码头",
        "sdmc": "口岸直水道",
        "x84": "119.69211100,32.24755958",
        "id": "NJ_2c9240fe74a059c30174b99b02b70cb2",
        "mt_id": "NJ_2c9240fe74a059c30174b99b02b70cb2",
        "hdHdly": "3",
        "hdlc": "238.00"
    },
    {
        "mtmc": "暨阳汽渡码头",
        "sdmc": "江阴水道(上段)",
        "x84": "120.13694533,31.92825910",
        "id": "NJ_2c9240fe74a059c30174b9ddd8ad0df4",
        "mt_id": "NJ_2c9240fe74a059c30174b9ddd8ad0df4",
        "hdHdly": "3",
        "hdlc": "165.90"
    },
    {
        "mtmc": "少专船厂浮船坞",
        "sdmc": "武穴水道",
        "x84": "115.54017768,29.84225179",
        "id": "NJ_2c9240fe74a059c30174bf345aec1022",
        "mt_id": "NJ_2c9240fe74a059c30174bf345aec1022",
        "hdHdly": "3",
        "hdlc": "841.00"
    },
    {
        "mtmc": "武穴港航码头",
        "sdmc": "武穴水道",
        "x84": "115.55247231,29.84346056",
        "id": "NJ_2c9240fe74a059c30174bf390a821023",
        "mt_id": "NJ_2c9240fe74a059c30174bf390a821023",
        "hdHdly": "3",
        "hdlc": "839.80"
    },
    {
        "mtmc": "打捞公司装卸码头",
        "sdmc": "武穴水道",
        "x84": "115.55611293,29.84283297",
        "id": "NJ_2c9240fe74a059c30174bf3d85f91024",
        "mt_id": "NJ_2c9240fe74a059c30174bf3d85f91024",
        "hdHdly": "3",
        "hdlc": "839.40"
    },
    {
        "mtmc": "港务码头",
        "sdmc": "武穴水道",
        "x84": "115.55742516,29.84295555",
        "id": "NJ_2c9240fe74a059c30174bf40543c1026",
        "mt_id": "NJ_2c9240fe74a059c30174bf40543c1026",
        "hdHdly": "3",
        "hdlc": "839.30"
    },
    {
        "mtmc": "恒发船厂码头",
        "sdmc": "武穴水道",
        "x84": "115.59737892,29.84620943",
        "id": "NJ_2c9240fe74a059c30174bf4696f61027",
        "mt_id": "NJ_2c9240fe74a059c30174bf4696f61027",
        "hdHdly": "3",
        "hdlc": "835.40"
    },
    {
        "mtmc": "鄂东造船厂码头",
        "sdmc": "武穴水道",
        "x84": "115.56848751,29.84353601",
        "id": "NJ_2c9240fe74a059c30174bf517acf1028",
        "mt_id": "NJ_2c9240fe74a059c30174bf517acf1028",
        "hdHdly": "3",
        "hdlc": "838.00"
    },
    {
        "mtmc": "河道局码头",
        "sdmc": "武穴水道",
        "x84": "115.58672748,29.83478480",
        "id": "NJ_2c9240fe74a059c30174bf54bf3c1029",
        "mt_id": "NJ_2c9240fe74a059c30174bf54bf3c1029",
        "hdHdly": "3",
        "hdlc": "836.70"
    },
    {
        "mtmc": "长江港务码头整合工程",
        "sdmc": "武穴水道",
        "x84": "115.55198068,29.83379489",
        "id": "NJ_2c9240fe74a059c30174bf5e8d02102b",
        "mt_id": "NJ_2c9240fe74a059c30174bf5e8d02102b",
        "hdHdly": "3",
        "hdlc": "840.00"
    },
    {
        "mtmc": "武山铜矿码头",
        "sdmc": "武穴水道",
        "x84": "115.57929779,29.83489447",
        "id": "NJ_2c9240fe74a059c30174bf618ec9102e",
        "mt_id": "NJ_2c9240fe74a059c30174bf618ec9102e",
        "hdHdly": "3",
        "hdlc": "837.10"
    },
    {
        "mtmc": "城子镇海事救援基地",
        "sdmc": "新洲水道",
        "x84": "115.69317771,29.83930917",
        "id": "NJ_2c9240fe74a059c30174bf645d2c102f",
        "mt_id": "NJ_2c9240fe74a059c30174bf645d2c102f",
        "hdHdly": "3",
        "hdlc": "825.40"
    },
    {
        "mtmc": "中铁九桥码头",
        "sdmc": "九江大桥水道",
        "x84": "116.050841,29.756195",
        "id": "NJ_2c9240fe74a059c30174bf76f5501036",
        "mt_id": "NJ_2c9240fe74a059c30174bf76f5501036",
        "hdHdly": "3",
        "hdlc": "785.60"
    },
    {
        "mtmc": "马鞍山港快捷物流码头",
        "sdmc": "江心洲水道",
        "x84": "118.44593171,31.70447421",
        "id": "NJ_2c9240fe74a059c30174c3af30c816f6",
        "mt_id": "NJ_2c9240fe74a059c30174c3af30c816f6",
        "hdHdly": "3",
        "hdlc": "399.90"
    },
    {
        "mtmc": "江新造船厂舾装码头",
        "sdmc": "张家洲南水道",
        "x84": "116.23955310,29.76515971",
        "id": "NJ_2c9240fe74a059c30174c47134bc1a6d",
        "mt_id": "NJ_2c9240fe74a059c30174c47134bc1a6d",
        "hdHdly": "3",
        "hdlc": "765.30"
    },
    {
        "mtmc": "湖口海事码头",
        "sdmc": "张家洲南水道",
        "x84": "116.22351730,29.75298733",
        "id": "NJ_2c9240fe74a059c30174c476fd731ac2",
        "mt_id": "NJ_2c9240fe74a059c30174c476fd731ac2",
        "hdHdly": "3",
        "hdlc": "767.60"
    },
    {
        "mtmc": "正天码头",
        "sdmc": "湖口水道",
        "x84": "116.31878968,29.81033642",
        "id": "NJ_2c9240fe74a059c30174c49067531b7e",
        "mt_id": "NJ_2c9240fe74a059c30174c49067531b7e",
        "hdHdly": "3",
        "hdlc": "755.80"
    },
    {
        "mtmc": "鑫汇码头",
        "sdmc": "张家洲南水道",
        "x84": "116.24605031,29.77152970",
        "id": "NJ_2c9240fe74a059c30174d3a27a6825d5",
        "mt_id": "NJ_2c9240fe74a059c30174d3a27a6825d5",
        "hdHdly": "3",
        "hdlc": "764.30"
    },
    {
        "mtmc": "长宏码头",
        "sdmc": "湖口水道",
        "x84": "116.25484341,29.77658624",
        "id": "NJ_2c9240fe74a059c30174d3a4a6af25d8",
        "mt_id": "NJ_2c9240fe74a059c30174d3a4a6af25d8",
        "hdHdly": "3",
        "hdlc": "763.20"
    },
    {
        "mtmc": "柁矶码头",
        "sdmc": "张家洲南水道",
        "x84": "116.24484685,29.77068387",
        "id": "NJ_2c9240fe74a059c30174d3a931c525d9",
        "mt_id": "NJ_2c9240fe74a059c30174d3a931c525d9",
        "hdHdly": "3",
        "hdlc": "764.50"
    },
    {
        "mtmc": "长顺码头",
        "sdmc": "张家洲南水道",
        "x84": "116.24341554,29.76989085",
        "id": "NJ_2c9240fe74a059c30174d3b55ea9266a",
        "mt_id": "NJ_2c9240fe74a059c30174d3b55ea9266a",
        "hdHdly": "3",
        "hdlc": "764.70"
    },
    {
        "mtmc": "九江船厂码头",
        "sdmc": "张家洲南水道",
        "x84": "116.22009905,29.74983691",
        "id": "NJ_2c9240fe74a059c30174d3b80020266b",
        "mt_id": "NJ_2c9240fe74a059c30174d3b80020266b",
        "hdHdly": "3",
        "hdlc": "767.80"
    },
    {
        "mtmc": "港口局 码头",
        "sdmc": "张家洲南水道",
        "x84": "116.21873145,29.74889065",
        "id": "NJ_2c9240fe74a059c30174d3bea54c268b",
        "mt_id": "NJ_2c9240fe74a059c30174d3bea54c268b",
        "hdHdly": "3",
        "hdlc": "768.00"
    },
    {
        "mtmc": "湖口轮渡码头",
        "sdmc": "张家洲南水道",
        "x84": "116.21531300,29.74580326",
        "id": "NJ_2c9240fe74a059c30174d3c050d9268c",
        "mt_id": "NJ_2c9240fe74a059c30174d3c050d9268c",
        "hdHdly": "3",
        "hdlc": "768.40"
    },
    {
        "mtmc": "林政码头",
        "sdmc": "张家洲南水道",
        "x84": "116.21386540,29.74452269",
        "id": "NJ_2c9240fe74a059c30174d3c24c5b268d",
        "mt_id": "NJ_2c9240fe74a059c30174d3c24c5b268d",
        "hdHdly": "3",
        "hdlc": "768.60"
    },
    {
        "mtmc": "新港汽渡码头",
        "sdmc": "张家洲南水道",
        "x84": "116.09924676,29.75933460",
        "id": "NJ_2c9240fe74a059c30174d3c7baa22693",
        "mt_id": "NJ_2c9240fe74a059c30174d3c7baa22693",
        "hdHdly": "3",
        "hdlc": "780.30"
    },
    {
        "mtmc": "预制厂码头",
        "sdmc": "张家洲南水道",
        "x84": "116.09048910,29.76170125",
        "id": "NJ_2c9240fe74a059c30174d3ca4d8426d5",
        "mt_id": "NJ_2c9240fe74a059c30174d3ca4d8426d5",
        "hdHdly": "3",
        "hdlc": "781.20"
    },
    {
        "mtmc": "粮库码头",
        "sdmc": "张家洲南水道",
        "x84": "116.07324637,29.76028250",
        "id": "NJ_2c9240fe74a059c30174d3d0926826dc",
        "mt_id": "NJ_2c9240fe74a059c30174d3d0926826dc",
        "hdHdly": "3",
        "hdlc": "783.20"
    },
    {
        "mtmc": "新港海事码头",
        "sdmc": "张家洲南水道",
        "x84": "116.06916346,29.76018165",
        "id": "NJ_2c9240fe74a059c30174d3d3169e26eb",
        "mt_id": "NJ_2c9240fe74a059c30174d3d3169e26eb",
        "hdHdly": "3",
        "hdlc": "783.60"
    },
    {
        "mtmc": "新立基码头",
        "sdmc": "张家洲南水道",
        "x84": "116.06569834,29.76035566",
        "id": "NJ_2c9240fe74a059c30174d3d7de822731",
        "mt_id": "NJ_2c9240fe74a059c30174d3d7de822731",
        "hdHdly": "3",
        "hdlc": "784.00"
    },
    {
        "mtmc": "水警码头",
        "sdmc": "九江大桥水道",
        "x84": "116.00235637,29.74014640",
        "id": "NJ_2c9240fe74a059c30174d3e09fd5273a",
        "mt_id": "NJ_2c9240fe74a059c30174d3e09fd5273a",
        "hdHdly": "3",
        "hdlc": "790.60"
    },
    {
        "mtmc": "水上消防码头",
        "sdmc": "九江大桥水道",
        "x84": "115.99719748,29.73712151",
        "id": "NJ_2c9240fe74a059c30174d3e9512d273d",
        "mt_id": "NJ_2c9240fe74a059c30174d3e9512d273d",
        "hdHdly": "3",
        "hdlc": "791.20"
    },
    {
        "mtmc": "渔政码头",
        "sdmc": "九江大桥水道",
        "x84": "115.99595164,29.73663396",
        "id": "NJ_2c9240fe74a059c30174d3eb2b9b273e",
        "mt_id": "NJ_2c9240fe74a059c30174d3eb2b9b273e",
        "hdHdly": "3",
        "hdlc": "791.40"
    },
    {
        "mtmc": "安信航运公司码头",
        "sdmc": "九江大桥水道",
        "x84": "115.98014682,29.72954902",
        "id": "NJ_2c9240fe74a059c30174d3ed74c52742",
        "mt_id": "NJ_2c9240fe74a059c30174d3ed74c52742",
        "hdHdly": "3",
        "hdlc": "793.10"
    },
    {
        "mtmc": "河道局防汛码头",
        "sdmc": "九江水道",
        "x84": "115.95679932,29.72051763",
        "id": "NJ_2c9240fe74a059c30174d3efb7172767",
        "mt_id": "NJ_2c9240fe74a059c30174d3efb7172767",
        "hdHdly": "3",
        "hdlc": "795.70"
    },
    {
        "mtmc": "长江芜湖LNG码头",
        "sdmc": "白茆水道",
        "x84": "118.10239024,31.21639856",
        "id": "NJ_2c9240fe74a059c30174d90ba7262f04",
        "mt_id": "NJ_2c9240fe74a059c30174d90ba7262f04",
        "hdHdly": "3",
        "hdlc": "473.90"
    },
    {
        "mtmc": "东华能源码头",
        "sdmc": "福姜沙南水道",
        "x84": "120.45552387,32.00035594",
        "id": "NJ_2c9240fe74d92b09017511b780ae233b",
        "mt_id": "NJ_2c9240fe74d92b09017511b780ae233b",
        "hdHdly": "3",
        "hdlc": "125.00"
    },
    {
        "mtmc": "长江南京航道处（肉联厂）",
        "sdmc": "南京大桥水道",
        "x84": "118.73959688,32.10851599",
        "id": "NJ_2c9240fe75f456090176072eb24d0871",
        "mt_id": "NJ_2c9240fe75f456090176072eb24d0871",
        "hdHdly": "3",
        "hdlc": "345.00"
    },
    {
        "mtmc": "大桥四处码头",
        "sdmc": "南京大桥水道",
        "x84": "118.73154140,32.11568533",
        "id": "NJ_2c9240fe75f4560901760743c2380898",
        "mt_id": "NJ_2c9240fe75f4560901760743c2380898",
        "hdHdly": "3",
        "hdlc": "345.00"
    },
    {
        "mtmc": "中铁大桥码头",
        "sdmc": "九江大桥水道",
        "x84": "116.048403,29.755432",
        "id": "NJ_2c9240fe75f4560901760d81bb5a0d5e",
        "mt_id": "NJ_2c9240fe75f4560901760d81bb5a0d5e",
        "hdHdly": "3",
        "hdlc": "785.90"
    },
    {
        "mtmc": "江苏常熟发电煤码头",
        "sdmc": "白茆沙水道",
        "x84": "120.96349851,31.76902735",
        "id": "NJ_2c9240fe75f458f8017618bdae411112",
        "mt_id": "NJ_2c9240fe75f458f8017618bdae411112",
        "hdHdly": "3",
        "hdlc": "70.30"
    },
    {
        "mtmc": "常熟电厂码头",
        "sdmc": "白茆沙水道",
        "x84": "120.96015557,31.77165171",
        "id": "NJ_2c9240fe75f458f8017618c55dab1113",
        "mt_id": "NJ_2c9240fe75f458f8017618c55dab1113",
        "hdHdly": "3",
        "hdlc": "70.50"
    },
    {
        "mtmc": "镇江港高桥港区LNG 加注站及接收站",
        "sdmc": "口岸直水道",
        "x84": "119.67785529,32.25126709",
        "id": "NJ_2c9240fe7727b1af017741ba66cd08f9",
        "mt_id": "NJ_2c9240fe7727b1af017741ba66cd08f9",
        "hdHdly": "3",
        "hdlc": "238.50"
    },
    {
        "mtmc": "大津清洁能源装备产业园码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.91012124,32.05495336",
        "id": "NJ_2c9240fe7727b1af017741eb8cbd0951",
        "mt_id": "NJ_2c9240fe7727b1af017741eb8cbd0951",
        "hdHdly": "3",
        "hdlc": "191.00"
    },
    {
        "mtmc": "江苏世通船舶重工有限公司成品库外接码头",
        "sdmc": "丹徒直水道",
        "x84": "119.60962803,32.25707128",
        "id": "NJ_2c9240fe7727b1af017742c40c52096f",
        "mt_id": "NJ_2c9240fe7727b1af017742c40c52096f",
        "hdHdly": "3",
        "hdlc": "252.00"
    },
    {
        "mtmc": "九江萍钢#5码头",
        "sdmc": "湖口水道",
        "x84": "116.28146814,29.78855128",
        "id": "NJ_2c9240fe7727b1af0177481def500bcb",
        "mt_id": "NJ_2c9240fe7727b1af0177481def500bcb",
        "hdHdly": "3",
        "hdlc": "760.20"
    },
    {
        "mtmc": "九江萍钢#4码头",
        "sdmc": "湖口水道",
        "x84": "116.28021021,29.78802121",
        "id": "NJ_2c9240fe7727b1af01774829b82b0bdd",
        "mt_id": "NJ_2c9240fe7727b1af01774829b82b0bdd",
        "hdHdly": "3",
        "hdlc": "761.00"
    },
    {
        "mtmc": "湖口洗舱站",
        "sdmc": "湖口水道",
        "x84": "116.327474,29.815722",
        "id": "NJ_2c9240fe7727b1e301773cd30b9304e0",
        "mt_id": "NJ_2c9240fe7727b1e301773cd30b9304e0",
        "hdHdly": "3",
        "hdlc": "755.20"
    },
    {
        "mtmc": "招商燃气码头",
        "sdmc": "张家洲南水道",
        "x84": "116.06702020,29.76051835",
        "id": "NJ_2c9240fe7727b1e301774bb73edc0c46",
        "mt_id": "NJ_2c9240fe7727b1e301774bb73edc0c46",
        "hdHdly": "3",
        "hdlc": "783.80"
    },
    {
        "mtmc": "安徽华庆石化码头一期工程",
        "sdmc": "太平府水道（下段）",
        "x84": "118.46000436,31.57708594",
        "id": "NJ_2c9240fe7acd698b017ad17156f80010",
        "mt_id": "NJ_2c9240fe7acd698b017ad17156f80010",
        "hdHdly": "3",
        "hdlc": "414.80"
    },
    {
        "mtmc": "马鞍山市中庆石化码头",
        "sdmc": "太平府水道（下段）",
        "x84": "118.46212443,31.58199432",
        "id": "NJ_2c9240fe7b3318b7017b52e774640652",
        "mt_id": "NJ_2c9240fe7b3318b7017b52e774640652",
        "hdHdly": "3",
        "hdlc": "413.60"
    },
    {
        "mtmc": "铜陵国电码头",
        "sdmc": "荻港水道",
        "x84": "117.95535067,31.07098025",
        "id": "NJ_2c9240fe7b9a69ce017ba04c264a037f",
        "mt_id": "NJ_2c9240fe7b9a69ce017ba04c264a037f",
        "hdHdly": "3",
        "hdlc": "497.00"
    },
    {
        "mtmc": "金江物流货运码头",
        "sdmc": "西华水道",
        "x84": "118.34994543,31.42206973",
        "id": "NJ_2c9240fe7c97499b017d07dc21ae223a",
        "mt_id": "NJ_2c9240fe7c97499b017d07dc21ae223a",
        "hdHdly": "3",
        "hdlc": "433.80"
    },
    {
        "mtmc": "皖江散货砂石中心码头",
        "sdmc": "江心洲水道",
        "x84": "118.39786150,31.68027496",
        "id": "NJ_2c9240fe7d4b67c4017d79e9eaf20d4f",
        "mt_id": "NJ_2c9240fe7d4b67c4017d79e9eaf20d4f",
        "hdHdly": "3",
        "hdlc": "403.50"
    },
    {
        "mtmc": "朱家桥外贸综合物流园一期码头",
        "sdmc": "西华水道",
        "x84": "118.34704703,31.40468646",
        "id": "NJ_2c9240fe7d4b67c4017d7a10ed390d58",
        "mt_id": "NJ_2c9240fe7d4b67c4017d7a10ed390d58",
        "hdHdly": "3",
        "hdlc": "436.40"
    },
    {
        "mtmc": "船舶LNG加注站",
        "sdmc": "东北横水道",
        "x84": "116.35206754,29.83274986",
        "id": "NJ_2c9240fe7e053110017e6c51a8e428cd",
        "mt_id": "NJ_2c9240fe7e053110017e6c51a8e428cd",
        "hdHdly": "3",
        "hdlc": "753.20"
    },
    {
        "mtmc": "九江石化89万芳烃码头",
        "sdmc": "张家洲南水道",
        "x84": "116.06274414,29.76006412",
        "id": "NJ_2c9240fe7e053110017e7155fb7d12a3",
        "mt_id": "NJ_2c9240fe7e053110017e7155fb7d12a3",
        "hdHdly": "3",
        "hdlc": "784.30"
    },
    {
        "mtmc": "池州江东码头",
        "sdmc": "大通水道（下段）",
        "x84": "117.73579753,30.80503682",
        "id": "NJ_2c9240fe7e296a06017e2d8b71c547ee",
        "mt_id": "NJ_2c9240fe7e296a06017e2d8b71c547ee",
        "hdHdly": "3",
        "hdlc": "554.00"
    },
    {
        "mtmc": "苏海事趸202",
        "sdmc": "太平洲捷水道",
        "x84": "119.74372819,32.23065890",
        "id": "NJ_2c9240fe7e296a06017e6c285bb3005f",
        "mt_id": "NJ_2c9240fe7e296a06017e6c285bb3005f",
        "hdHdly": "3",
        "hdlc": "242.20"
    },
    {
        "mtmc": "镇边趸01",
        "sdmc": "太平洲捷水道",
        "x84": "119.74271939,32.22346053",
        "id": "NJ_2c9240fe7e296a06017e6c2e76bf01ca",
        "mt_id": "NJ_2c9240fe7e296a06017e6c2e76bf01ca",
        "hdHdly": "3",
        "hdlc": "241.90"
    },
    {
        "mtmc": "扬中市金标装卸码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.76111242,32.22033140",
        "id": "NJ_2c9240fe7e296a06017e6c3543aa0285",
        "mt_id": "NJ_2c9240fe7e296a06017e6c3543aa0285",
        "hdHdly": "3",
        "hdlc": "217.70"
    },
    {
        "mtmc": "南江码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.81442700,32.21130397",
        "id": "NJ_2c9240fe7e296a06017e6c3942fc02a7",
        "mt_id": "NJ_2c9240fe7e296a06017e6c3942fc02a7",
        "hdHdly": "3",
        "hdlc": "211.40"
    },
    {
        "mtmc": "采砂管理局浮码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.78363872,32.20935210",
        "id": "NJ_2c9240fe7e296a06017e6c3ebdd002d1",
        "mt_id": "NJ_2c9240fe7e296a06017e6c3ebdd002d1",
        "hdHdly": "3",
        "hdlc": "215.20"
    },
    {
        "mtmc": "扬中二桥海事码头",
        "sdmc": "太平洲捷水道",
        "x84": "119.90146675,32.05783272",
        "id": "NJ_2c9240fe7e296a06017e6c41ae8102ed",
        "mt_id": "NJ_2c9240fe7e296a06017e6c41ae8102ed",
        "hdHdly": "3",
        "hdlc": "192.70"
    },
    {
        "mtmc": "栈桥码头",
        "sdmc": "录安洲右汊",
        "x84": "119.95373550,31.99790935",
        "id": "NJ_2c9240fe7e296a06017e6c44135c0303",
        "mt_id": "NJ_2c9240fe7e296a06017e6c44135c0303",
        "hdHdly": "3",
        "hdlc": "184.00"
    },
    {
        "mtmc": "常州海事",
        "sdmc": "录安洲右汊",
        "x84": "119.95505936,31.99583562",
        "id": "NJ_2c9240fe7e296a06017e6c4800870327",
        "mt_id": "NJ_2c9240fe7e296a06017e6c4800870327",
        "hdHdly": "3",
        "hdlc": "183.70"
    },
    {
        "mtmc": "德胜港公安浮码头",
        "sdmc": "录安洲右汊",
        "x84": "119.95293279,31.99487890",
        "id": "NJ_2c9240fe7e296a06017e6c49d797033d",
        "mt_id": "NJ_2c9240fe7e296a06017e6c49d797033d",
        "hdHdly": "3",
        "hdlc": "183.80"
    },
    {
        "mtmc": "德胜港海事浮码头",
        "sdmc": "录安洲右汊",
        "x84": "119.95367408,31.99383305",
        "id": "NJ_2c9240fe7e296a06017e6c4b753f0359",
        "mt_id": "NJ_2c9240fe7e296a06017e6c4b753f0359",
        "hdHdly": "3",
        "hdlc": "183.70"
    },
    {
        "mtmc": "常州海事码头（录安洲尾一侧）",
        "sdmc": "录安洲右汊",
        "x84": "119.98310615,31.97804942",
        "id": "NJ_2c9240fe7e296a06017e6c4d78f003b4",
        "mt_id": "NJ_2c9240fe7e296a06017e6c4d78f003b4",
        "hdHdly": "3",
        "hdlc": "180.40"
    },
    {
        "mtmc": "常州海事码头（常州一侧）",
        "sdmc": "录安洲右汊",
        "x84": "119.99512591,31.97043000",
        "id": "NJ_2c9240fe7e296a06017e6c4f004403c9",
        "mt_id": "NJ_2c9240fe7e296a06017e6c4f004403c9",
        "hdHdly": "3",
        "hdlc": "179.00"
    },
    {
        "mtmc": "南京地铁4号线江心洲站结构及盾构土外运临时浮趸船码头",
        "sdmc": "南京水道",
        "x84": "118.71059746,32.06047789",
        "id": "NJ_2c9240fe7e296a06017e6fe7f76a2234",
        "mt_id": "NJ_2c9240fe7e296a06017e6fe7f76a2234",
        "hdHdly": "3",
        "hdlc": "351.10"
    },
    {
        "mtmc": "芜湖东汇码头",
        "sdmc": "白茆水道",
        "x84": "118.17773806,31.26320445",
        "id": "NJ_2c9240fe7e296a06017e767c774b5df1",
        "mt_id": "NJ_2c9240fe7e296a06017e767c774b5df1",
        "hdHdly": "3",
        "hdlc": "465.30"
    },
    {
        "mtmc": "芜湖荻港海螺码头",
        "sdmc": "荻港水道",
        "x84": "117.99871164,31.10438287",
        "id": "NJ_2c9240fe7e296a06017e7682175c5e9f",
        "mt_id": "NJ_2c9240fe7e296a06017e7682175c5e9f",
        "hdHdly": "3",
        "hdlc": "497.80"
    },
    {
        "mtmc": "深国际物流中心码头",
        "sdmc": "福姜沙北水道",
        "x84": "120.37004457,32.01609285",
        "id": "NJ_2c9240fe7e296a06017e89df3bc20ee4",
        "mt_id": "NJ_2c9240fe7e296a06017e89df3bc20ee4",
        "hdHdly": "3",
        "hdlc": "141.00"
    },
    {
        "mtmc": "芜湖金江物流有限公司货运码头",
        "sdmc": "西华水道",
        "x84": "118.34994543,31.42206973",
        "id": "NJ_2c9240fe7ea6ca26017ee6c78f3e51d3",
        "mt_id": "NJ_2c9240fe7ea6ca26017ee6c78f3e51d3",
        "hdHdly": "3",
        "hdlc": "433.80"
    },
    {
        "mtmc": "王家巷渡轮码头",
        "sdmc": "汉口水道",
        "x84": "114.289586,30.571260",
        "id": "WH_00e66bcc9f1c41e2bb30b05b00407dec",
        "mt_id": "WH_00e66bcc9f1c41e2bb30b05b00407dec",
        "hdHdly": "2",
        "hdlc": "0.50"
    },
    {
        "mtmc": "荆州港嘉瑞化工码头",
        "sdmc": "马家寨水道",
        "x84": "112.337528,30.070674",
        "id": "WH_016ea6f40e344e97857fa3506218a259",
        "mt_id": "WH_016ea6f40e344e97857fa3506218a259",
        "hdHdly": "2",
        "hdlc": "435.00"
    },
    {
        "mtmc": "城陵矶海事执法大队码头",
        "sdmc": "洞庭湖口",
        "x84": "113.141869,29.438041",
        "id": "WH_01ad3ddc68c54b1ea7bf3ff4a8892f3a",
        "mt_id": "WH_01ad3ddc68c54b1ea7bf3ff4a8892f3a",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "腾达码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.751651,30.603692",
        "id": "WH_01bba392f62a4ff98ef8ad5544364be2",
        "mt_id": "WH_01bba392f62a4ff98ef8ad5544364be2",
        "hdHdly": "3",
        "hdlc": "984.40"
    },
    {
        "mtmc": "城陵矶海事处码头",
        "sdmc": "洞庭湖口",
        "x84": "113.133593,29.425023",
        "id": "WH_0231e82cd6074e518f48ab37cf3ea76e",
        "mt_id": "WH_0231e82cd6074e518f48ab37cf3ea76e",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "港务局十码头",
        "sdmc": "洞庭湖口",
        "x84": "113.143122,29.44494",
        "id": "WH_0264d44d3022432cbdc3f2c771a11788",
        "mt_id": "WH_0264d44d3022432cbdc3f2c771a11788",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "长江公安巡航救助基地",
        "sdmc": "陡湖堤水道",
        "x84": "112.209503,30.083277",
        "id": "WH_02b4609712b1429e93dbf93e58163d6d",
        "mt_id": "WH_02b4609712b1429e93dbf93e58163d6d",
        "hdHdly": "2",
        "hdlc": "447.20"
    },
    {
        "mtmc": "蕲州龙全建材货运码头",
        "sdmc": "搁排矶水道",
        "x84": "115.358835,30.004645",
        "id": "WH_0359306ae7894f10a94b313dea020c51",
        "mt_id": "WH_0359306ae7894f10a94b313dea020c51",
        "hdHdly": "3",
        "hdlc": "869.10"
    },
    {
        "mtmc": "鹏鑫水上基地",
        "sdmc": "罗湖洲水道",
        "x84": "114.830437,30.560577",
        "id": "WH_03e6cadf66b84573b156abf900cd975c",
        "mt_id": "WH_03e6cadf66b84573b156abf900cd975c",
        "hdHdly": "3",
        "hdlc": "966.00"
    },
    {
        "mtmc": "冶钢码头",
        "sdmc": "黄石水道",
        "x84": "115.141684,30.206766",
        "id": "WH_04bc5512e10b4791bd8ce5596dacd607",
        "mt_id": "WH_04bc5512e10b4791bd8ce5596dacd607",
        "hdHdly": "3",
        "hdlc": "905.42"
    },
    {
        "mtmc": "永春码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.464827,29.843382",
        "id": "WH_04dc6465331d4278a4c5038f8be22803",
        "mt_id": "WH_04dc6465331d4278a4c5038f8be22803",
        "hdHdly": "3",
        "hdlc": "847.90"
    },
    {
        "mtmc": "城港白尾生活码头",
        "sdmc": "仙峰水道",
        "x84": "113.200326,29.506578",
        "id": "WH_052a510b069c4af9892bbaa4a599567f",
        "mt_id": "WH_052a510b069c4af9892bbaa4a599567f",
        "hdHdly": "2",
        "hdlc": "222.20"
    },
    {
        "mtmc": "商品汽车滚装码头",
        "sdmc": "水洪口水道",
        "x84": "113.935129,30.303503",
        "id": "WH_056e1baa23714800a26c5fed53ab5e21",
        "mt_id": "WH_056e1baa23714800a26c5fed53ab5e21",
        "hdHdly": "2",
        "hdlc": "62.60"
    },
    {
        "mtmc": "阳新娲石水泥厂码头",
        "sdmc": "搁排矶水道",
        "x84": "115.401617,29.910357",
        "id": "WH_0577995b970e4b7e8eabed1d55c3e511",
        "mt_id": "WH_0577995b970e4b7e8eabed1d55c3e511",
        "hdHdly": "3",
        "hdlc": "857.43"
    },
    {
        "mtmc": "昌森伟业码头",
        "sdmc": "黄石水道",
        "x84": "115.072303,30.274077",
        "id": "WH_05a57564b5f6474aaa0957e06340f2fe",
        "mt_id": "WH_05a57564b5f6474aaa0957e06340f2fe",
        "hdHdly": "3",
        "hdlc": "917.20"
    },
    {
        "mtmc": "石首航道处码头",
        "sdmc": "石首水道",
        "x84": "112.406479,29.738667",
        "id": "WH_05fd9446882c4c54a51437138480ac00",
        "mt_id": "WH_05fd9446882c4c54a51437138480ac00",
        "hdHdly": "2",
        "hdlc": "387.00"
    },
    {
        "mtmc": "水文码头",
        "sdmc": "汉口水道",
        "x84": "114.326982,30.628273",
        "id": "WH_0652da12ef0e45678a6f46841f034334",
        "mt_id": "WH_0652da12ef0e45678a6f46841f034334",
        "hdHdly": "3",
        "hdlc": "1036.70"
    },
    {
        "mtmc": "阳新港区富池作业区综合码头",
        "sdmc": "搁排矶水道",
        "x84": "115.395903,29.949751",
        "id": "WH_06b7d14afcad4287aba1d0567bf2283c",
        "mt_id": "WH_06b7d14afcad4287aba1d0567bf2283c",
        "hdHdly": "3",
        "hdlc": "861.00"
    },
    {
        "mtmc": "管线队基地码头",
        "sdmc": "武桥水道-上",
        "x84": "114.279668,30.520792",
        "id": "WH_06fc6d2de2014cf0bf0448f76ff28f62",
        "mt_id": "WH_06fc6d2de2014cf0bf0448f76ff28f62",
        "hdHdly": "2",
        "hdlc": "5.50"
    },
    {
        "mtmc": "城港塘湾生活码头",
        "sdmc": "杨林岩水道",
        "x84": "113.27204,29.590474",
        "id": "WH_06fe0cb3530442ebb64017868c7c047b",
        "mt_id": "WH_06fe0cb3530442ebb64017868c7c047b",
        "hdHdly": "2",
        "hdlc": "210.20"
    },
    {
        "mtmc": "长江蕲州航道处码头",
        "sdmc": "蕲春水道",
        "x84": "115.331278,30.063611",
        "id": "WH_073e7ee1a5b84ea99fbf5043b9eab605",
        "mt_id": "WH_073e7ee1a5b84ea99fbf5043b9eab605",
        "hdHdly": "3",
        "hdlc": "877.48"
    },
    {
        "mtmc": "临时散装码头",
        "sdmc": "煤炭洲水道",
        "x84": "114.042754,30.228378",
        "id": "WH_09125e4cd58f47e39b59cbb2bc3c27da",
        "mt_id": "WH_09125e4cd58f47e39b59cbb2bc3c27da",
        "hdHdly": "2",
        "hdlc": "52.60"
    },
    {
        "mtmc": "魏宏码头",
        "sdmc": "太平口水道-下",
        "x84": "112.143782,30.309359",
        "id": "WH_095709c0cc7d476080d18bb627faaa2e",
        "mt_id": "WH_095709c0cc7d476080d18bb627faaa2e",
        "hdHdly": "2",
        "hdlc": "488.70"
    },
    {
        "mtmc": "钟家湾码头",
        "sdmc": "沙洲水道",
        "x84": "114.839463,30.554476",
        "id": "WH_098de86583724908b0f489dccba6497c",
        "mt_id": "WH_098de86583724908b0f489dccba6497c",
        "hdHdly": "3",
        "hdlc": "965.30"
    },
    {
        "mtmc": "武汉新港化工新城石化码头",
        "sdmc": "阳逻水道",
        "x84": "114.567007,30.6001",
        "id": "WH_0b949c83280a4ba6ae28914e7ce476af",
        "mt_id": "WH_0b949c83280a4ba6ae28914e7ce476af",
        "hdHdly": "3",
        "hdlc": "1006.50"
    },
    {
        "mtmc": "港通物流码头",
        "sdmc": "邓家口水道",
        "x84": "114.085968,30.310689",
        "id": "WH_0c32711c69944778a34ae06f7b583007",
        "mt_id": "WH_0c32711c69944778a34ae06f7b583007",
        "hdHdly": "2",
        "hdlc": "35.40"
    },
    {
        "mtmc": "石首汽渡",
        "sdmc": "石首水道",
        "x84": "112.39354,29.761825",
        "id": "WH_0cecfbd4c71442a0abc7b29531654ad7",
        "mt_id": "WH_0cecfbd4c71442a0abc7b29531654ad7",
        "hdHdly": "2",
        "hdlc": "388.00"
    },
    {
        "mtmc": "江洲航道基地",
        "sdmc": "窑集佬水道",
        "x84": "112.842898,29.768919",
        "id": "WH_0d1db51288214d9f927a5af44359dc69",
        "mt_id": "WH_0d1db51288214d9f927a5af44359dc69",
        "hdHdly": "2",
        "hdlc": "320.20"
    },
    {
        "mtmc": "华海船厂黄州船厂舾装码头",
        "sdmc": "沙洲水道",
        "x84": "114.835619,30.505938",
        "id": "WH_0d9c391497b945d697448b970c2f58b2",
        "mt_id": "WH_0d9c391497b945d697448b970c2f58b2",
        "hdHdly": "3",
        "hdlc": "960.00"
    },
    {
        "mtmc": "船舶污染物接收转运码头",
        "sdmc": "蕲春水道",
        "x84": "115.300528,30.094043",
        "id": "WH_0df4ca5d7346459c8961126b5ebb7d1b",
        "mt_id": "WH_0df4ca5d7346459c8961126b5ebb7d1b",
        "hdHdly": "3",
        "hdlc": "882.00"
    },
    {
        "mtmc": "粮食现代物流（武汉）基地工程",
        "sdmc": "阳逻水道",
        "x84": "114.568998,30.620792",
        "id": "WH_0e30c60af5c0440b91524655215ff3cc",
        "mt_id": "WH_0e30c60af5c0440b91524655215ff3cc",
        "hdHdly": "3",
        "hdlc": "1008.30"
    },
    {
        "mtmc": "鄂祥港泵#3码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.452750,29.876665",
        "id": "WH_0f38b0021e5b4e5a81327ecef9d8d722",
        "mt_id": "WH_0f38b0021e5b4e5a81327ecef9d8d722",
        "hdHdly": "3",
        "hdlc": "851.36"
    },
    {
        "mtmc": "长江公安江陵派出所",
        "sdmc": "郝穴水道",
        "x84": "112.351571,30.067361",
        "id": "WH_0f69a7860dad44ac8793ccf72199328e",
        "mt_id": "WH_0f69a7860dad44ac8793ccf72199328e",
        "hdHdly": "2",
        "hdlc": "433.90"
    },
    {
        "mtmc": "沙码头",
        "sdmc": "沙洲水道",
        "x84": "114.821573,30.463336",
        "id": "WH_0fb9b18ffcfb411a8e94adcd0d00fc3d",
        "mt_id": "WH_0fb9b18ffcfb411a8e94adcd0d00fc3d",
        "hdHdly": "3",
        "hdlc": "955.30"
    },
    {
        "mtmc": "浠水港航码头",
        "sdmc": "戴家洲水道",
        "x84": "115.113404,30.342603",
        "id": "WH_10bf5ea75c714caebb09e2619b6cdc3d",
        "mt_id": "WH_10bf5ea75c714caebb09e2619b6cdc3d",
        "hdHdly": "3",
        "hdlc": "924.70"
    },
    {
        "mtmc": "蕲州水利码头",
        "sdmc": "蕲春水道",
        "x84": "115.331623,30.065292",
        "id": "WH_1101db3c0051446e90e60380268d8500",
        "mt_id": "WH_1101db3c0051446e90e60380268d8500",
        "hdHdly": "3",
        "hdlc": "877.70"
    },
    {
        "mtmc": "阳新县梓港码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.439415,29.867041",
        "id": "WH_124bee25adf847069e79c46bc8224dfe",
        "mt_id": "WH_124bee25adf847069e79c46bc8224dfe",
        "hdHdly": "3",
        "hdlc": "851.20"
    },
    {
        "mtmc": "汉纸码头",
        "sdmc": "邓家口水道",
        "x84": "114.019845,30.260281",
        "id": "WH_12748bc854bb46e1875dba4c211a6b59",
        "mt_id": "WH_12748bc854bb46e1875dba4c211a6b59",
        "hdHdly": "2",
        "hdlc": "52.60"
    },
    {
        "mtmc": "华粮储运码头",
        "sdmc": "洞庭湖口",
        "x84": "113.141701,29.43508",
        "id": "WH_12b0a08e57864ea0b2e1d126f1c7014c",
        "mt_id": "WH_12b0a08e57864ea0b2e1d126f1c7014c",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "阳逻警备基地码头",
        "sdmc": "阳逻水道",
        "x84": "114.577437,30.577117",
        "id": "WH_12c54cd4c01a4a49b1c77a4019b921d0",
        "mt_id": "WH_12c54cd4c01a4a49b1c77a4019b921d0",
        "hdHdly": "3",
        "hdlc": "1004.20"
    },
    {
        "mtmc": "港口集团9号码头",
        "sdmc": "黄石水道",
        "x84": "115.090451,30.217384",
        "id": "WH_12d601f2f53c4d839c0230360e2d5a1c",
        "mt_id": "WH_12d601f2f53c4d839c0230360e2d5a1c",
        "hdHdly": "3",
        "hdlc": "910.30"
    },
    {
        "mtmc": "公安航道处码头",
        "sdmc": "陡湖堤水道",
        "x84": "112.215735,30.07524",
        "id": "WH_13d4869e2d7b47779d9385b09b681cd0",
        "mt_id": "WH_13d4869e2d7b47779d9385b09b681cd0",
        "hdHdly": "2",
        "hdlc": "446.60"
    },
    {
        "mtmc": "长江新滩海事码头",
        "sdmc": "簰洲水道",
        "x84": "113.926106,30.205354",
        "id": "WH_1481507e07a4474a94e808bc10d7df1a",
        "mt_id": "WH_1481507e07a4474a94e808bc10d7df1a",
        "hdHdly": "2",
        "hdlc": "86.10"
    },
    {
        "mtmc": "黄冈二水厂抽水码头",
        "sdmc": "沙洲水道",
        "x84": "114.839213,30.469628",
        "id": "WH_14b07aad01774777a3c9067493446ab1",
        "mt_id": "WH_14b07aad01774777a3c9067493446ab1",
        "hdHdly": "3",
        "hdlc": "957.06"
    },
    {
        "mtmc": "新冶钢16#码头",
        "sdmc": "黄石水道",
        "x84": "115.154903,30.209813",
        "id": "WH_14f49f6625144c008f65841c99de5b6d",
        "mt_id": "WH_14f49f6625144c008f65841c99de5b6d",
        "hdHdly": "3",
        "hdlc": "904.00"
    },
    {
        "mtmc": "邓家口散装码头",
        "sdmc": "邓家口水道",
        "x84": "113.959056,30.307129",
        "id": "WH_15440fb3a40545c4af2543667fee8291",
        "mt_id": "WH_15440fb3a40545c4af2543667fee8291",
        "hdHdly": "2",
        "hdlc": "60.30"
    },
    {
        "mtmc": "公安县盛埠建材有限责任公司码头",
        "sdmc": "马家寨水道",
        "x84": "112.269843,30.06595",
        "id": "WH_16198be86c7e4347bc6fffa9b011f41d",
        "mt_id": "WH_16198be86c7e4347bc6fffa9b011f41d",
        "hdHdly": "2",
        "hdlc": "442.00"
    },
    {
        "mtmc": "联谊沙码头",
        "sdmc": "沌口水道-上（停用）",
        "x84": "114.170640,30.421507",
        "id": "WH_1662d25b50e341ffb6c2055be371946c",
        "mt_id": "WH_1662d25b50e341ffb6c2055be371946c",
        "hdHdly": "2",
        "hdlc": "20.60"
    },
    {
        "mtmc": "抬船路2号码头",
        "sdmc": "白沙洲水道",
        "x84": "114.224226,30.483649",
        "id": "WH_17116ef9443e46a7813bb415b402c743",
        "mt_id": "WH_17116ef9443e46a7813bb415b402c743",
        "hdHdly": "2",
        "hdlc": "11.90"
    },
    {
        "mtmc": "荆州航道测绘中心码头",
        "sdmc": "太平口水道-下",
        "x84": "112.249407,30.299775",
        "id": "WH_1719733ebf684986b11d18af8498b000",
        "mt_id": "WH_1719733ebf684986b11d18af8498b000",
        "hdHdly": "2",
        "hdlc": "477.50"
    },
    {
        "mtmc": "嘉鱼葛洲坝水泥有限责任公司配套码头工程",
        "sdmc": "龙口水道",
        "x84": "113.811591,29.922741",
        "id": "WH_173968ac75e940a0b01cec61b24736ca",
        "mt_id": "WH_173968ac75e940a0b01cec61b24736ca",
        "hdHdly": "2",
        "hdlc": "141.90"
    },
    {
        "mtmc": "中石化黄州码头",
        "sdmc": "巴河水道",
        "x84": "114.948488,30.414484",
        "id": "WH_174d5d85dd4e43bc883ae2569104704c",
        "mt_id": "WH_174d5d85dd4e43bc883ae2569104704c",
        "hdHdly": "3",
        "hdlc": "941.00"
    },
    {
        "mtmc": "中华路码头改造（含中华路码头和红巷码头）",
        "sdmc": "武桥水道-下",
        "x84": "114.288661,30.553606",
        "id": "WH_1757cbdbbf23490c8dc23f6dc00948fe",
        "mt_id": "WH_1757cbdbbf23490c8dc23f6dc00948fe",
        "hdHdly": "2",
        "hdlc": "2.00"
    },
    {
        "mtmc": "沙隆达电煤码头",
        "sdmc": "太平口水道-下",
        "x84": "112.289615,30.252201",
        "id": "WH_177420340f5e4967bd94357b0fcfe5ff",
        "mt_id": "WH_177420340f5e4967bd94357b0fcfe5ff",
        "hdHdly": "2",
        "hdlc": "471.10"
    },
    {
        "mtmc": "黄石港棋盘洲港区（一期）10号泊位",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.262897,30.151189",
        "id": "WH_17d66fdbe43847e0887ec14ee4ff3e21",
        "mt_id": "WH_17d66fdbe43847e0887ec14ee4ff3e21",
        "hdHdly": "3",
        "hdlc": "889.20"
    },
    {
        "mtmc": "船厂码头",
        "sdmc": "戴家洲水道",
        "x84": "115.113616,30.333807",
        "id": "WH_18f6aef7631f4205b7a6d267126462b3",
        "mt_id": "WH_18f6aef7631f4205b7a6d267126462b3",
        "hdHdly": "3",
        "hdlc": "924.00"
    },
    {
        "mtmc": "嘉通物流码头",
        "sdmc": "龙口水道",
        "x84": "113.815027,29.923675",
        "id": "WH_1920196660394f7c9cafc6dfb57e5402",
        "mt_id": "WH_1920196660394f7c9cafc6dfb57e5402",
        "hdHdly": "2",
        "hdlc": "142.20"
    },
    {
        "mtmc": "黄州唐家渡浮吊码头",
        "sdmc": "沙洲水道",
        "x84": "114.837398,30.531191",
        "id": "WH_19c9d570d94b485aa7660addb0696930",
        "mt_id": "WH_19c9d570d94b485aa7660addb0696930",
        "hdHdly": "3",
        "hdlc": "962.60"
    },
    {
        "mtmc": "鄂祥港泵#9码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.447989,29.881696",
        "id": "WH_1c11e9b80518476ba448cc8a2585df42",
        "mt_id": "WH_1c11e9b80518476ba448cc8a2585df42",
        "hdHdly": "3",
        "hdlc": "852.11"
    },
    {
        "mtmc": "石首市工业码头#1—#4泊位",
        "sdmc": "石首水道",
        "x84": "112.420105,29.745703",
        "id": "WH_1c14f4399a944e60b46af0f91556b161",
        "mt_id": "WH_1c14f4399a944e60b46af0f91556b161",
        "hdHdly": "2",
        "hdlc": "384.10"
    },
    {
        "mtmc": "散货码头",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.167591,30.212574",
        "id": "WH_1cdb1781ed8b400b9b98f1d874ab8a2b",
        "mt_id": "WH_1cdb1781ed8b400b9b98f1d874ab8a2b",
        "hdHdly": "3",
        "hdlc": "902.48"
    },
    {
        "mtmc": "洪湖航道处石码头维护基地码头",
        "sdmc": "石头关水道",
        "x84": "113.544187,29.846323",
        "id": "WH_1f22f6ffdde045a782db261d7a7d93b1",
        "mt_id": "WH_1f22f6ffdde045a782db261d7a7d93b1",
        "hdHdly": "2",
        "hdlc": "170.20"
    },
    {
        "mtmc": "和润物流码头",
        "sdmc": "簰洲水道",
        "x84": "113.871018,30.231588",
        "id": "WH_1fc1b21a18f645ceb9f63baabecf7479",
        "mt_id": "WH_1fc1b21a18f645ceb9f63baabecf7479",
        "hdHdly": "2",
        "hdlc": "73.50"
    },
    {
        "mtmc": "松滋水政大队",
        "sdmc": "涴市水道",
        "x84": "112.010168,30.272591",
        "id": "WH_201913dce5c24cdbb24e4417a082bb47",
        "mt_id": "WH_201913dce5c24cdbb24e4417a082bb47",
        "hdHdly": "2",
        "hdlc": "502.00"
    },
    {
        "mtmc": "祥云综合码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.432794,29.899773",
        "id": "WH_206725d33ea04923a200ea2f079e5ada",
        "mt_id": "WH_206725d33ea04923a200ea2f079e5ada",
        "hdHdly": "3",
        "hdlc": "854.67"
    },
    {
        "mtmc": "蕲春利强船舶修造码头",
        "sdmc": "蕲春水道",
        "x84": "115.272143,30.15275",
        "id": "WH_20dca382172d42a08fd47fcd0cd34236",
        "mt_id": "WH_20dca382172d42a08fd47fcd0cd34236",
        "hdHdly": "3",
        "hdlc": "888.60"
    },
    {
        "mtmc": "前进修船码头",
        "sdmc": "巴河水道",
        "x84": "114.938111,30.415479",
        "id": "WH_20e00e3aa31044fe9322c83e60496134",
        "mt_id": "WH_20e00e3aa31044fe9322c83e60496134",
        "hdHdly": "3",
        "hdlc": "942.02"
    },
    {
        "mtmc": "黄冈大桥巡航救助基地",
        "sdmc": "沙洲水道",
        "x84": "114.838204,30.535281",
        "id": "WH_21602d142a834692b0e8c2f9b6ceac9d",
        "mt_id": "WH_21602d142a834692b0e8c2f9b6ceac9d",
        "hdHdly": "3",
        "hdlc": "963.35"
    },
    {
        "mtmc": "富池海事码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.437401,29.869491",
        "id": "WH_24527f3a0b274d7ea506ed253f14f459",
        "mt_id": "WH_24527f3a0b274d7ea506ed253f14f459",
        "hdHdly": "3",
        "hdlc": "851.51"
    },
    {
        "mtmc": "棋盘州华新水泥厂",
        "sdmc": "蕲春水道",
        "x84": "115.287382,30.121081",
        "id": "WH_246131ad15094168b95f1c6c0ee7da89",
        "mt_id": "WH_246131ad15094168b95f1c6c0ee7da89",
        "hdHdly": "2",
        "hdlc": "885.00"
    },
    {
        "mtmc": "白螺长江船舶污水垃圾接收设施码头",
        "sdmc": "螺山水道-上",
        "x84": "113.268446,29.600377",
        "id": "WH_24cca146cccc46d899fb2e4c21662cbd",
        "mt_id": "WH_24cca146cccc46d899fb2e4c21662cbd",
        "hdHdly": "2",
        "hdlc": "209.80"
    },
    {
        "mtmc": "荆岳大桥航道维护基地",
        "sdmc": "道仁矶水道",
        "x84": "113.22086,29.537463",
        "id": "WH_25bee9fd2abd401ab20b5d92ba3bff12",
        "mt_id": "WH_25bee9fd2abd401ab20b5d92ba3bff12",
        "hdHdly": "2",
        "hdlc": "218.30"
    },
    {
        "mtmc": "青山船厂清浚码头",
        "sdmc": "青山夹水道",
        "x84": "114.435416,30.665184",
        "id": "WH_26c00aa3937140ccb271f24f7d9931b9",
        "mt_id": "WH_26c00aa3937140ccb271f24f7d9931b9",
        "hdHdly": "3",
        "hdlc": "1025.10"
    },
    {
        "mtmc": "港发#1-#4码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.474977,29.858179",
        "id": "WH_2752168bfff1438192a49bd7dacd7c7a",
        "mt_id": "WH_2752168bfff1438192a49bd7dacd7c7a",
        "hdHdly": "3",
        "hdlc": "848.00"
    },
    {
        "mtmc": "葛化取水趸（原葛化盐趸）",
        "sdmc": "牧鹅洲水道",
        "x84": "114.606694,30.554560",
        "id": "WH_2807f803e4bb41d4b0da418eedf3de05",
        "mt_id": "WH_2807f803e4bb41d4b0da418eedf3de05",
        "hdHdly": "3",
        "hdlc": "999.70"
    },
    {
        "mtmc": "港兴建材码头",
        "sdmc": "马家咀水道",
        "x84": "112.255579,30.202502",
        "id": "WH_283ad4b7cebd4a718b33dfbce15f15e2",
        "mt_id": "WH_283ad4b7cebd4a718b33dfbce15f15e2",
        "hdHdly": "2",
        "hdlc": "464.50"
    },
    {
        "mtmc": "民本矿业1号码头",
        "sdmc": "搁排矶水道",
        "x84": "115.407247,29.947774",
        "id": "WH_286e8fa7ec6845a284ddfbaba3b43c88",
        "mt_id": "WH_286e8fa7ec6845a284ddfbaba3b43c88",
        "hdHdly": "3",
        "hdlc": "861.40"
    },
    {
        "mtmc": "月亮湾汽渡3号码头        月亮湾轮渡码头",
        "sdmc": "汉口水道",
        "x84": "114.315793,30.590185",
        "id": "WH_28acd3d9f66e4246819801ee0db5954c",
        "mt_id": "WH_28acd3d9f66e4246819801ee0db5954c",
        "hdHdly": "3",
        "hdlc": "1040.50"
    },
    {
        "mtmc": "散花水厂取水码头",
        "sdmc": "黄石水道",
        "x84": "115.078358,30.263738",
        "id": "WH_29460dd54f2948a0895d6ea833f189c7",
        "mt_id": "WH_29460dd54f2948a0895d6ea833f189c7",
        "hdHdly": "3",
        "hdlc": "916.00"
    },
    {
        "mtmc": "塔市驿高桩深水码头",
        "sdmc": "塔市驿水道-下",
        "x84": "112.795104,29.736721",
        "id": "WH_294c11c071634ad5adae27352c93ca4a",
        "mt_id": "WH_294c11c071634ad5adae27352c93ca4a",
        "hdHdly": "2",
        "hdlc": "326.50"
    },
    {
        "mtmc": "李埠综合码头",
        "sdmc": "太平口水道-下",
        "x84": "112.147173,30.310110",
        "id": "WH_294d4845124b49fca0703f5898e8e134",
        "mt_id": "WH_294d4845124b49fca0703f5898e8e134",
        "hdHdly": "2",
        "hdlc": "487.00"
    },
    {
        "mtmc": "朱家湾码头",
        "sdmc": "马家寨水道",
        "x84": "112.253093,30.061050",
        "id": "WH_29882376d13044e783daed077ab6cb50",
        "mt_id": "WH_29882376d13044e783daed077ab6cb50",
        "hdHdly": "2",
        "hdlc": "443.00"
    },
    {
        "mtmc": "监利水文站码头",
        "sdmc": "监利水道",
        "x84": "112.889159,29.806102",
        "id": "WH_29da23c45fb14911912f2d65d6b8955c",
        "mt_id": "WH_29da23c45fb14911912f2d65d6b8955c",
        "hdHdly": "2",
        "hdlc": "313.80"
    },
    {
        "mtmc": "方家夹人渡右岸",
        "sdmc": "莱家铺水道",
        "x84": "112.674281,29.761554",
        "id": "WH_29f2897b06954fe382a672469189570c",
        "mt_id": "WH_29f2897b06954fe382a672469189570c",
        "hdHdly": "2",
        "hdlc": "340.00"
    },
    {
        "mtmc": "青山材料处码头",
        "sdmc": "青山夹水道",
        "x84": "114.421944,30.660439",
        "id": "WH_2a1526bfb3954632ac9f6a9205605a90",
        "mt_id": "WH_2a1526bfb3954632ac9f6a9205605a90",
        "hdHdly": "3",
        "hdlc": "1026.80"
    },
    {
        "mtmc": "抽水码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.864633,30.628543",
        "id": "WH_2a1a5efad5a042c9a39b5bc60de2ccbf",
        "mt_id": "WH_2a1a5efad5a042c9a39b5bc60de2ccbf",
        "hdHdly": "3",
        "hdlc": "973.15"
    },
    {
        "mtmc": "鄂州轮渡码头",
        "sdmc": "沙洲水道",
        "x84": "114.880206,30.412551",
        "id": "WH_2aac883e785a4019b5bbe30ec0b820a7",
        "mt_id": "WH_2aac883e785a4019b5bbe30ec0b820a7",
        "hdHdly": "3",
        "hdlc": "947.30"
    },
    {
        "mtmc": "黄石海事码头",
        "sdmc": "黄石水道",
        "x84": "115.094302,30.214519",
        "id": "WH_2af24d7c584149078ae66e21d3d54e69",
        "mt_id": "WH_2af24d7c584149078ae66e21d3d54e69",
        "hdHdly": "3",
        "hdlc": "909.80"
    },
    {
        "mtmc": "盐卡一二三期集装箱散货码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.282346,30.274491",
        "id": "WH_2c3a1dc237ba407b9ff64170448ef7a2",
        "mt_id": "WH_2c3a1dc237ba407b9ff64170448ef7a2",
        "hdHdly": "2",
        "hdlc": "471.80"
    },
    {
        "mtmc": "华电码头",
        "sdmc": "马家寨水道",
        "x84": "112.305348,30.075608",
        "id": "WH_2c4b2302a3094f3482304e1f171fefe9",
        "mt_id": "WH_2c4b2302a3094f3482304e1f171fefe9",
        "hdHdly": "2",
        "hdlc": "438.20"
    },
    {
        "mtmc": "江陵水上综合执法码头",
        "sdmc": "郝穴水道",
        "x84": "112.353106,30.067013",
        "id": "WH_2cbc1d8444064c48aa543feb285317a4",
        "mt_id": "WH_2cbc1d8444064c48aa543feb285317a4",
        "hdHdly": "2",
        "hdlc": "433.70"
    },
    {
        "mtmc": "渔政码头",
        "sdmc": "蕲春水道",
        "x84": "115.331409,30.064022",
        "id": "WH_2ce6e6ec62884c6c842d82d8ba4cb623",
        "mt_id": "WH_2ce6e6ec62884c6c842d82d8ba4cb623",
        "hdHdly": "3",
        "hdlc": "877.55"
    },
    {
        "mtmc": "鄂州北门海事码头",
        "sdmc": "沙洲水道",
        "x84": "114.879217,30.413165",
        "id": "WH_2cf65c688ac24a17be6219609a15c328",
        "mt_id": "WH_2cf65c688ac24a17be6219609a15c328",
        "hdHdly": "3",
        "hdlc": "948.00"
    },
    {
        "mtmc": "航道综合码头",
        "sdmc": "阳逻水道",
        "x84": "114.577109,30.601973",
        "id": "WH_2d5b4344a542475d9015f17b39d8e03f",
        "mt_id": "WH_2d5b4344a542475d9015f17b39d8e03f",
        "hdHdly": "3",
        "hdlc": "1006.30"
    },
    {
        "mtmc": "华新骨料#3码头",
        "sdmc": "搁排矶水道",
        "x84": "115.383933,29.958942",
        "id": "WH_2dce116d5ebf447389e1b30c43aeda0a",
        "mt_id": "WH_2dce116d5ebf447389e1b30c43aeda0a",
        "hdHdly": "3",
        "hdlc": "863.30"
    },
    {
        "mtmc": "江陵航道处码头",
        "sdmc": "郝穴水道",
        "x84": "112.402465,30.029806",
        "id": "WH_2e0a6ef7fa6d487ca4e7f7033da84215",
        "mt_id": "WH_2e0a6ef7fa6d487ca4e7f7033da84215",
        "hdHdly": "2",
        "hdlc": "425.10"
    },
    {
        "mtmc": "建材码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.752721,30.604322",
        "id": "WH_2e59e2cde34a4fa48537974fb345f1b6",
        "mt_id": "WH_2e59e2cde34a4fa48537974fb345f1b6",
        "hdHdly": "3",
        "hdlc": "984.20"
    },
    {
        "mtmc": "楚江物流码头",
        "sdmc": "沙洲水道",
        "x84": "114.835664,30.516121",
        "id": "WH_2ed4deda7afd4c3eb2d791da47c072f8",
        "mt_id": "WH_2ed4deda7afd4c3eb2d791da47c072f8",
        "hdHdly": "3",
        "hdlc": "961.00"
    },
    {
        "mtmc": "建材码头",
        "sdmc": "沙洲水道",
        "x84": "114.821941,30.461295",
        "id": "WH_2eefa594bcaa413ab1b535a52f7dae75",
        "mt_id": "WH_2eefa594bcaa413ab1b535a52f7dae75",
        "hdHdly": "3",
        "hdlc": "955.20"
    },
    {
        "mtmc": "湖北星丰码头",
        "sdmc": "戴家洲水道",
        "x84": "115.037131,30.391432",
        "id": "WH_2f77e04624434473b79c333bb7d4e144",
        "mt_id": "WH_2f77e04624434473b79c333bb7d4e144",
        "hdHdly": "3",
        "hdlc": "931.77"
    },
    {
        "mtmc": "天兴洲海事码头",
        "sdmc": "青山夹水道",
        "x84": "114.397299,30.653400",
        "id": "WH_302a506269d2436386a14e54d7c9027a",
        "mt_id": "WH_302a506269d2436386a14e54d7c9027a",
        "hdHdly": "3",
        "hdlc": "1029.60"
    },
    {
        "mtmc": "鄂祥港泵#4码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.452168,29.877239",
        "id": "WH_304cb66e05af4784aa32c5fda7284b26",
        "mt_id": "WH_304cb66e05af4784aa32c5fda7284b26",
        "hdHdly": "3",
        "hdlc": "851.43"
    },
    {
        "mtmc": "阖翔沙码头",
        "sdmc": "沌口水道-上（停用）",
        "x84": "114.170198,30.422425",
        "id": "WH_30778362b5694de6a3ec1e0ecacbb8c7",
        "mt_id": "WH_30778362b5694de6a3ec1e0ecacbb8c7",
        "hdHdly": "2",
        "hdlc": "20.70"
    },
    {
        "mtmc": "小湾矶散货码头",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.235194,30.192874",
        "id": "WH_30ff3c00e3144808a904a229658b602d",
        "mt_id": "WH_30ff3c00e3144808a904a229658b602d",
        "hdHdly": "3",
        "hdlc": "894.59"
    },
    {
        "mtmc": "武汉市渔政专用码头（长渔趸1、2号）",
        "sdmc": "汉口水道",
        "x84": "114.320781,30.618145",
        "id": "WH_32a717c996104d2a86f79c27bd4fd0fd",
        "mt_id": "WH_32a717c996104d2a86f79c27bd4fd0fd",
        "hdHdly": "3",
        "hdlc": "1037.80"
    },
    {
        "mtmc": "港务局一码头趸船",
        "sdmc": "阳逻水道",
        "x84": "114.550961,30.654965",
        "id": "WH_3525c6a4a712491fba2813f39e584866",
        "mt_id": "WH_3525c6a4a712491fba2813f39e584866",
        "hdHdly": "3",
        "hdlc": "1012.50"
    },
    {
        "mtmc": "阳逻航道处趸船",
        "sdmc": "阳逻水道",
        "x84": "114.549564,30.657037",
        "id": "WH_35fbd74afbc642b3b04de4b06fdde2d9",
        "mt_id": "WH_35fbd74afbc642b3b04de4b06fdde2d9",
        "hdHdly": "3",
        "hdlc": "1012.90"
    },
    {
        "mtmc": "汇鑫码头",
        "sdmc": "沙洲水道",
        "x84": "114.829178,30.544489",
        "id": "WH_360d2819d4fc4d2dbace71aef8b6b9fe",
        "mt_id": "WH_360d2819d4fc4d2dbace71aef8b6b9fe",
        "hdHdly": "3",
        "hdlc": "963.80"
    },
    {
        "mtmc": "货运码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.441939,29.889256",
        "id": "WH_3687b8727b974a4a8559f2b8c17355e7",
        "mt_id": "WH_3687b8727b974a4a8559f2b8c17355e7",
        "hdHdly": "3",
        "hdlc": "853.14"
    },
    {
        "mtmc": "棋盘洲车渡码头",
        "sdmc": "蕲春水道",
        "x84": "115.299929,30.099000",
        "id": "WH_36a276cf75844709849142d53df7f880",
        "mt_id": "WH_36a276cf75844709849142d53df7f880",
        "hdHdly": "3",
        "hdlc": "884.20"
    },
    {
        "mtmc": "开发区工业综合码头",
        "sdmc": "瓦口子水道-下",
        "x84": "112.284166,30.230421",
        "id": "WH_382cc0e35b0c48159397b93fda74a44d",
        "mt_id": "WH_382cc0e35b0c48159397b93fda74a44d",
        "hdHdly": "2",
        "hdlc": "468.60"
    },
    {
        "mtmc": "李埠过驳基地办公码头",
        "sdmc": "涴市水道",
        "x84": "112.000412,30.283441",
        "id": "WH_386f9fd10d464082a4b87b43ac26c74f",
        "mt_id": "WH_386f9fd10d464082a4b87b43ac26c74f",
        "hdHdly": "2",
        "hdlc": "504.00"
    },
    {
        "mtmc": "调关巡航救助基地",
        "sdmc": "调关水道",
        "x84": "112.636095,29.700957",
        "id": "WH_3917f551e64944c6919a2adddc131236",
        "mt_id": "WH_3917f551e64944c6919a2adddc131236",
        "hdHdly": "2",
        "hdlc": "347.50"
    },
    {
        "mtmc": "公安水政监察大队 ",
        "sdmc": "陡湖堤水道",
        "x84": "112.213058,30.077346",
        "id": "WH_3922aeb7a8d64f318f3a9c61130b2b49",
        "mt_id": "WH_3922aeb7a8d64f318f3a9c61130b2b49",
        "hdHdly": "2",
        "hdlc": "446.80"
    },
    {
        "mtmc": "荆州地方海事码头",
        "sdmc": "太平口水道-上",
        "x84": "112.106506,30.300827",
        "id": "WH_3945327e08814079b150d30567424ad4",
        "mt_id": "WH_3945327e08814079b150d30567424ad4",
        "hdHdly": "2",
        "hdlc": "492.20"
    },
    {
        "mtmc": "港口集团11号码头",
        "sdmc": "黄石水道",
        "x84": "115.092193,30.216188",
        "id": "WH_3949bc01e9d94b2996d7a2e48e37ef8d",
        "mt_id": "WH_3949bc01e9d94b2996d7a2e48e37ef8d",
        "hdHdly": "3",
        "hdlc": "909.90"
    },
    {
        "mtmc": "武船码头4号",
        "sdmc": "武桥水道-上",
        "x84": "114.283779,30.535222",
        "id": "WH_3a2ba29ea62f47d4a0b39753979ef223",
        "mt_id": "WH_3a2ba29ea62f47d4a0b39753979ef223",
        "hdHdly": "2",
        "hdlc": "4.10"
    },
    {
        "mtmc": "鄂祥港泵#11码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.446442,29.883569",
        "id": "WH_3b3354bbcae8440ebf5f7f1a7fb07aa3",
        "mt_id": "WH_3b3354bbcae8440ebf5f7f1a7fb07aa3",
        "hdHdly": "3",
        "hdlc": "852.37"
    },
    {
        "mtmc": "金口重件码头",
        "sdmc": "金口水道",
        "x84": "114.155592,30.381274",
        "id": "WH_3b4a3b4254e04e92b971af479b9b9ed6",
        "mt_id": "WH_3b4a3b4254e04e92b971af479b9b9ed6",
        "hdHdly": "2",
        "hdlc": "25.00"
    },
    {
        "mtmc": "新冶钢码头",
        "sdmc": "黄石水道",
        "x84": "115.144146,30.20668",
        "id": "WH_3b98020425c142619f94a795a07752ab",
        "mt_id": "WH_3b98020425c142619f94a795a07752ab",
        "hdHdly": "3",
        "hdlc": "904.70"
    },
    {
        "mtmc": "电建建材产业园码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.475463,29.84127",
        "id": "WH_3bb96d42297145d1b6dd5f0ed6e85e49",
        "mt_id": "WH_3bb96d42297145d1b6dd5f0ed6e85e49",
        "hdHdly": "3",
        "hdlc": "846.60"
    },
    {
        "mtmc": "浠水水利码头",
        "sdmc": "黄石水道",
        "x84": "115.079815,30.270563",
        "id": "WH_3bbf4e76ad884070b595d05763ae55e6",
        "mt_id": "WH_3bbf4e76ad884070b595d05763ae55e6",
        "hdHdly": "3",
        "hdlc": "0.00"
    },
    {
        "mtmc": "鄂州水务",
        "sdmc": "沙洲水道",
        "x84": "114.902132,30.408955",
        "id": "WH_3c004f7b3cae46bea68824c84c99a67e",
        "mt_id": "WH_3c004f7b3cae46bea68824c84c99a67e",
        "hdHdly": "3",
        "hdlc": "945.60"
    },
    {
        "mtmc": "长江武汉处机动船队码头",
        "sdmc": "武桥水道-上",
        "x84": "114.285649,30.544471",
        "id": "WH_3c43f51a12e4423380dcd50f096c54df",
        "mt_id": "WH_3c43f51a12e4423380dcd50f096c54df",
        "hdHdly": "2",
        "hdlc": "3.00"
    },
    {
        "mtmc": "中长燃绿色航运综合服务区",
        "sdmc": "白沙洲水道",
        "x84": "114.247121,30.510024",
        "id": "WH_3ca7d7c3c25d42b5991df9db4c3a6a31",
        "mt_id": "WH_3ca7d7c3c25d42b5991df9db4c3a6a31",
        "hdHdly": "2",
        "hdlc": "8.00"
    },
    {
        "mtmc": "新华联富润码头",
        "sdmc": "道仁矶水道",
        "x84": "113.218815,29.535069",
        "id": "WH_3de33bd164544ee1af587f3f45840f82",
        "mt_id": "WH_3de33bd164544ee1af587f3f45840f82",
        "hdHdly": "2",
        "hdlc": "218.80"
    },
    {
        "mtmc": "鄂州港航码头",
        "sdmc": "沙洲水道",
        "x84": "114.900450,30.409373",
        "id": "WH_3e06efeba00f432a9d4be8acf7256918",
        "mt_id": "WH_3e06efeba00f432a9d4be8acf7256918",
        "hdHdly": "3",
        "hdlc": "945.60"
    },
    {
        "mtmc": "黄石外贸码头",
        "sdmc": "黄石水道",
        "x84": "115.088127,30.219550",
        "id": "WH_3e1accb5cebe46cda750e89e81a15c50",
        "mt_id": "WH_3e1accb5cebe46cda750e89e81a15c50",
        "hdHdly": "3",
        "hdlc": "910.50"
    },
    {
        "mtmc": "马家寨临时砂石集并点（临时性）",
        "sdmc": "陡湖堤水道",
        "x84": "112.214970,30.091923",
        "id": "WH_3e970d560e6f45f88c64f1d66d799da9",
        "mt_id": "WH_3e970d560e6f45f88c64f1d66d799da9",
        "hdHdly": "2",
        "hdlc": "448.10"
    },
    {
        "mtmc": "石首市地方海事码头",
        "sdmc": "石首水道",
        "x84": "112.407504,29.738950",
        "id": "WH_3ea2005751b14b0fb0c907246fe0c86a",
        "mt_id": "WH_3ea2005751b14b0fb0c907246fe0c86a",
        "hdHdly": "2",
        "hdlc": "386.10"
    },
    {
        "mtmc": "半壁山世发搬运站码头",
        "sdmc": "搁排矶水道",
        "x84": "115.412118,29.902495",
        "id": "WH_3f2a76760e1c4941ab7fbd77607cf75c",
        "mt_id": "WH_3f2a76760e1c4941ab7fbd77607cf75c",
        "hdHdly": "3",
        "hdlc": "856.00"
    },
    {
        "mtmc": "余家冲恒鹏物流码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.433896,29.897921",
        "id": "WH_417920f46f0e4f568a34a691e65df774",
        "mt_id": "WH_417920f46f0e4f568a34a691e65df774",
        "hdHdly": "3",
        "hdlc": "854.00"
    },
    {
        "mtmc": "煤码头",
        "sdmc": "沙洲水道",
        "x84": "114.821374,30.464492",
        "id": "WH_41f6f275e02146318617fb5f3bbf5a90",
        "mt_id": "WH_41f6f275e02146318617fb5f3bbf5a90",
        "hdHdly": "3",
        "hdlc": "955.40"
    },
    {
        "mtmc": "鄂交基地",
        "sdmc": "巴河水道",
        "x84": "114.941671,30.401857",
        "id": "WH_42c4e4f2db484383802109f368820235",
        "mt_id": "WH_42c4e4f2db484383802109f368820235",
        "hdHdly": "3",
        "hdlc": "942.00"
    },
    {
        "mtmc": "石首人渡",
        "sdmc": "石首水道",
        "x84": "112.392165,29.747588",
        "id": "WH_431a356d0b7d43ed99b2ec148aef553c",
        "mt_id": "WH_431a356d0b7d43ed99b2ec148aef553c",
        "hdHdly": "2",
        "hdlc": "389.00"
    },
    {
        "mtmc": "荆轮码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.288989,30.257678",
        "id": "WH_43dab36875c34231899e8cf5e58ba26e",
        "mt_id": "WH_43dab36875c34231899e8cf5e58ba26e",
        "hdHdly": "2",
        "hdlc": "471.50"
    },
    {
        "mtmc": "岳阳招商燃气码头",
        "sdmc": "道仁矶水道",
        "x84": "113.210909,29.520607",
        "id": "WH_44587d6acb7447058328a45983d2fd45",
        "mt_id": "WH_44587d6acb7447058328a45983d2fd45",
        "hdHdly": "2",
        "hdlc": "220.50"
    },
    {
        "mtmc": "华新水泥#3泊位",
        "sdmc": "黄石水道",
        "x84": "115.080284,30.226073",
        "id": "WH_452703bb0c464977a3df13a14c3573a4",
        "mt_id": "WH_452703bb0c464977a3df13a14c3573a4",
        "hdHdly": "3",
        "hdlc": "911.70"
    },
    {
        "mtmc": "长江黄冈海事处码头",
        "sdmc": "巴河水道",
        "x84": "114.921832,30.416289",
        "id": "WH_459baed6da0b48e7a7048e2cadfc9898",
        "mt_id": "WH_459baed6da0b48e7a7048e2cadfc9898",
        "hdHdly": "3",
        "hdlc": "943.60"
    },
    {
        "mtmc": "长江武汉航道处测绘中心码头",
        "sdmc": "武桥水道-上",
        "x84": "114.285778,30.545125",
        "id": "WH_45b36316777a4e38873c7a430703cd21",
        "mt_id": "WH_45b36316777a4e38873c7a430703cd21",
        "hdHdly": "2",
        "hdlc": "2.80"
    },
    {
        "mtmc": "长江海事陆城巡航救助基地",
        "sdmc": "螺山水道-上",
        "x84": "113.275027,29.594333",
        "id": "WH_461e19a49cec48ff8b12f9f407473314",
        "mt_id": "WH_461e19a49cec48ff8b12f9f407473314",
        "hdHdly": "2",
        "hdlc": "199.80"
    },
    {
        "mtmc": "公安县综合执法大队",
        "sdmc": "陡湖堤水道",
        "x84": "112.208752,30.084575",
        "id": "WH_463d40b026964bcc9b49268cc0b979f6",
        "mt_id": "WH_463d40b026964bcc9b49268cc0b979f6",
        "hdHdly": "2",
        "hdlc": "447.50"
    },
    {
        "mtmc": "晴川轮渡码头",
        "sdmc": "武桥水道-下",
        "x84": "114.280531,30.557420",
        "id": "WH_4691d40502564cbd86da55556413d7dc",
        "mt_id": "WH_4691d40502564cbd86da55556413d7dc",
        "hdHdly": "2",
        "hdlc": "2.20"
    },
    {
        "mtmc": "黄石大桥航道维护基地",
        "sdmc": "黄石水道",
        "x84": "115.103172,30.210765",
        "id": "WH_46c5d7c34f7b4b4f8040035641426453",
        "mt_id": "WH_46c5d7c34f7b4b4f8040035641426453",
        "hdHdly": "3",
        "hdlc": "908.80"
    },
    {
        "mtmc": "金口航道码头",
        "sdmc": "金口水道",
        "x84": "114.1428,30.3695",
        "id": "WH_46e3dfa5139b46ab94ecb91c4c5f7fa3",
        "mt_id": "WH_46e3dfa5139b46ab94ecb91c4c5f7fa3",
        "hdHdly": "2",
        "hdlc": "27.00"
    },
    {
        "mtmc": "装卸码头",
        "sdmc": "沙洲水道",
        "x84": "114.82112,30.467669",
        "id": "WH_473ded6e500b46d1b871edf034bc85fa",
        "mt_id": "WH_473ded6e500b46d1b871edf034bc85fa",
        "hdHdly": "3",
        "hdlc": "955.80"
    },
    {
        "mtmc": "中石化阳逻码头趸船",
        "sdmc": "阳逻水道",
        "x84": "114.553762,30.650362",
        "id": "WH_484be74a462d433e9dd16129a2c5b01f",
        "mt_id": "WH_484be74a462d433e9dd16129a2c5b01f",
        "hdHdly": "3",
        "hdlc": "1012.10"
    },
    {
        "mtmc": "港务局十一码头（煤码头）",
        "sdmc": "洞庭湖口",
        "x84": "113.143525,29.446143",
        "id": "WH_485ac007408c44118600c65269fe0598",
        "mt_id": "WH_485ac007408c44118600c65269fe0598",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "鄂囤沙码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.631804,30.553869",
        "id": "WH_48b0bab007fc48eb850306ea5a1554ca",
        "mt_id": "WH_48b0bab007fc48eb850306ea5a1554ca",
        "hdHdly": "3",
        "hdlc": "999.40"
    },
    {
        "mtmc": "超凡物流码头",
        "sdmc": "沙洲水道",
        "x84": "114.825694,30.439669",
        "id": "WH_49b2372809c64e5cbb03181699b12b78",
        "mt_id": "WH_49b2372809c64e5cbb03181699b12b78",
        "hdHdly": "3",
        "hdlc": "953.10"
    },
    {
        "mtmc": "华新水泥厂码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.438527,29.892441",
        "id": "WH_49da9957ff45456392afd832cab79c46",
        "mt_id": "WH_49da9957ff45456392afd832cab79c46",
        "hdHdly": "3",
        "hdlc": "852.80"
    },
    {
        "mtmc": "荆州旺港码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.279709,30.277975",
        "id": "WH_4a8afee1b3f84dfebc92a2e21e39f84c",
        "mt_id": "WH_4a8afee1b3f84dfebc92a2e21e39f84c",
        "hdHdly": "2",
        "hdlc": "474.00"
    },
    {
        "mtmc": "西塞山水利码头",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.166435,30.211241",
        "id": "WH_4c548177c0d54055be188a1d21ad45c7",
        "mt_id": "WH_4c548177c0d54055be188a1d21ad45c7",
        "hdHdly": "3",
        "hdlc": "902.61"
    },
    {
        "mtmc": "80万吨乙烯气码头",
        "sdmc": "阳逻水道",
        "x84": "114.574213,30.583661",
        "id": "WH_4c7b9d193b414dbb9198fa07e2d403a0",
        "mt_id": "WH_4c7b9d193b414dbb9198fa07e2d403a0",
        "hdHdly": "3",
        "hdlc": "1004.20"
    },
    {
        "mtmc": "大沙航道处",
        "sdmc": "嘉鱼水道",
        "x84": "113.866812,30.006172",
        "id": "WH_4caa67cb55634568b7e154a3aee2547f",
        "mt_id": "WH_4caa67cb55634568b7e154a3aee2547f",
        "hdHdly": "2",
        "hdlc": "131.60"
    },
    {
        "mtmc": "武汉长江公安警备码头",
        "sdmc": "汉口水道",
        "x84": "114.290312,30.572635",
        "id": "WH_4d0d62dd25a04d4597becc9da5ab4093",
        "mt_id": "WH_4d0d62dd25a04d4597becc9da5ab4093",
        "hdHdly": "2",
        "hdlc": "1043.30"
    },
    {
        "mtmc": "武汉水务执法码头",
        "sdmc": "汉口水道",
        "x84": "114.311240,30.604268",
        "id": "WH_4d246f6b0e8b405e84e70761d8a3f893",
        "mt_id": "WH_4d246f6b0e8b405e84e70761d8a3f893",
        "hdHdly": "3",
        "hdlc": "1039.00"
    },
    {
        "mtmc": "华新水泥厂3号码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.436488,29.893888",
        "id": "WH_4d3d6b0482714815b601ae3bafedfbc2",
        "mt_id": "WH_4d3d6b0482714815b601ae3bafedfbc2",
        "hdHdly": "3",
        "hdlc": "853.40"
    },
    {
        "mtmc": "船厂货运码头",
        "sdmc": "青山夹水道",
        "x84": "114.434195,30.665117",
        "id": "WH_4d45ba7a3328467ab7c548f0f3b8224c",
        "mt_id": "WH_4d45ba7a3328467ab7c548f0f3b8224c",
        "hdHdly": "3",
        "hdlc": "1025.60"
    },
    {
        "mtmc": "煤卸货码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.760340,30.607859",
        "id": "WH_4dcc62d1aa284c1fa6ad406f0b4f8747",
        "mt_id": "WH_4dcc62d1aa284c1fa6ad406f0b4f8747",
        "hdHdly": "3",
        "hdlc": "983.50"
    },
    {
        "mtmc": "港通物流浮吊1号码头",
        "sdmc": "邓家口水道",
        "x84": "114.020863,30.258720",
        "id": "WH_4df16d7f63584c5db709a5293021767d",
        "mt_id": "WH_4df16d7f63584c5db709a5293021767d",
        "hdHdly": "2",
        "hdlc": "52.60"
    },
    {
        "mtmc": "鄂州捷达码头",
        "sdmc": "巴河水道",
        "x84": "114.943181,30.401187",
        "id": "WH_4e74328b5d2b4bcb8333a66722bb3eb2",
        "mt_id": "WH_4e74328b5d2b4bcb8333a66722bb3eb2",
        "hdHdly": "3",
        "hdlc": "941.35"
    },
    {
        "mtmc": "阳逻海事码头趸船",
        "sdmc": "阳逻水道",
        "x84": "114.549957,30.656449",
        "id": "WH_4ea11de0ebdd4bab93b9630681165ff3",
        "mt_id": "WH_4ea11de0ebdd4bab93b9630681165ff3",
        "hdHdly": "3",
        "hdlc": "1012.90"
    },
    {
        "mtmc": "武船码头6号",
        "sdmc": "武桥水道-上",
        "x84": "114.285059,30.532197",
        "id": "WH_4f1919136b354e85972dd50c23906ca4",
        "mt_id": "WH_4f1919136b354e85972dd50c23906ca4",
        "hdHdly": "2",
        "hdlc": "4.30"
    },
    {
        "mtmc": "监利新洲码头",
        "sdmc": "大马洲水道",
        "x84": "112.931932,29.730734",
        "id": "WH_4f92735569274e459de9727b04fecfb9",
        "mt_id": "WH_4f92735569274e459de9727b04fecfb9",
        "hdHdly": "2",
        "hdlc": "302.80"
    },
    {
        "mtmc": "鄂祥港泵#7码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.449946,29.879600",
        "id": "WH_5012b0be4fbe4b3a970023080f0a5b44",
        "mt_id": "WH_5012b0be4fbe4b3a970023080f0a5b44",
        "hdHdly": "3",
        "hdlc": "851.76"
    },
    {
        "mtmc": "鄂州水上公安码头",
        "sdmc": "沙洲水道",
        "x84": "114.879636,30.412789",
        "id": "WH_507708a221fa4093a60330c1c7f0abfa",
        "mt_id": "WH_507708a221fa4093a60330c1c7f0abfa",
        "hdHdly": "3",
        "hdlc": "947.50"
    },
    {
        "mtmc": "荆州港旅游码头",
        "sdmc": "太平口水道-下",
        "x84": "112.240916,30.304209",
        "id": "WH_50a66e21a1a44e6a8034462f8af5c85d",
        "mt_id": "WH_50a66e21a1a44e6a8034462f8af5c85d",
        "hdHdly": "2",
        "hdlc": "480.30"
    },
    {
        "mtmc": "鑫港物流#1码头",
        "sdmc": "巴河水道",
        "x84": "114.955745,30.399191",
        "id": "WH_50b0d9a2aa6e492cb72f4d59f92996dd",
        "mt_id": "WH_50b0d9a2aa6e492cb72f4d59f92996dd",
        "hdHdly": "3",
        "hdlc": "940.00"
    },
    {
        "mtmc": "花港装卸码头",
        "sdmc": "阳逻水道",
        "x84": "114.621746,30.551648",
        "id": "WH_50d2dda0d6ec42259e0af52d10567b36",
        "mt_id": "WH_50d2dda0d6ec42259e0af52d10567b36",
        "hdHdly": "3",
        "hdlc": "1010.20"
    },
    {
        "mtmc": "华新水泥#1泊位",
        "sdmc": "黄石水道",
        "x84": "115.078571,30.228027",
        "id": "WH_515bb4eb5c54416d8bae695fbd6d88ca",
        "mt_id": "WH_515bb4eb5c54416d8bae695fbd6d88ca",
        "hdHdly": "3",
        "hdlc": "912.00"
    },
    {
        "mtmc": "管窑综合码头",
        "sdmc": "蕲春水道",
        "x84": "115.282247,30.142685",
        "id": "WH_51b03d27bbc14fc795694d65fe89532f",
        "mt_id": "WH_51b03d27bbc14fc795694d65fe89532f",
        "hdHdly": "2",
        "hdlc": "887.20"
    },
    {
        "mtmc": "黄冈亚东#2码头改扩建工程",
        "sdmc": "搁排矶水道",
        "x84": "115.415228,29.910886",
        "id": "WH_51ca86a69fec4233a363438116b92190",
        "mt_id": "WH_51ca86a69fec4233a363438116b92190",
        "hdHdly": "3",
        "hdlc": "856.51"
    },
    {
        "mtmc": "港务局二码头趸船",
        "sdmc": "阳逻水道",
        "x84": "114.551260,30.654396",
        "id": "WH_51d9c90a052a4b6d8b7e9f145f1e1cf3",
        "mt_id": "WH_51d9c90a052a4b6d8b7e9f145f1e1cf3",
        "hdHdly": "3",
        "hdlc": "1012.60"
    },
    {
        "mtmc": "长江航道测量中心码头",
        "sdmc": "汉口水道",
        "x84": "114.320394,30.61703",
        "id": "WH_539017de82b34274b56b6dacd9c17805",
        "mt_id": "WH_539017de82b34274b56b6dacd9c17805",
        "hdHdly": "3",
        "hdlc": "1037.80"
    },
    {
        "mtmc": "青山轮渡码头",
        "sdmc": "青山夹水道",
        "x84": "114.418021,30.659537",
        "id": "WH_53e914bc9e104986abb4b06aee24610f",
        "mt_id": "WH_53e914bc9e104986abb4b06aee24610f",
        "hdHdly": "3",
        "hdlc": "1027.20"
    },
    {
        "mtmc": "港鑫物流码头",
        "sdmc": "巴河水道",
        "x84": "114.955286,30.399097",
        "id": "WH_54347d6a70ce4f06b67715cf73ea1771",
        "mt_id": "WH_54347d6a70ce4f06b67715cf73ea1771",
        "hdHdly": "3",
        "hdlc": "940.00"
    },
    {
        "mtmc": "潘家湾港区通用码头",
        "sdmc": "汉金关水道",
        "x84": "114.091077,30.109650",
        "id": "WH_54625f21738743ee98276f0a5cd77849",
        "mt_id": "WH_54625f21738743ee98276f0a5cd77849",
        "hdHdly": "2",
        "hdlc": "106.20"
    },
    {
        "mtmc": "白浒山#1沙码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.632759,30.554301",
        "id": "WH_5506654c64564c81a275e33fc2f3510b",
        "mt_id": "WH_5506654c64564c81a275e33fc2f3510b",
        "hdHdly": "3",
        "hdlc": "996.70"
    },
    {
        "mtmc": "黄石渔政码头",
        "sdmc": "黄石水道",
        "x84": "115.098396,30.211909",
        "id": "WH_553a30e8da014b7cb993e6cb54da3c4a",
        "mt_id": "WH_553a30e8da014b7cb993e6cb54da3c4a",
        "hdHdly": "3",
        "hdlc": "908.90"
    },
    {
        "mtmc": "长江公安警备基地",
        "sdmc": "洞庭湖口",
        "x84": "113.134135,29.425559",
        "id": "WH_56a41a0ba67b44fe90cfc8071679e042",
        "mt_id": "WH_56a41a0ba67b44fe90cfc8071679e042",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "唐家渡焦煤码头",
        "sdmc": "沙洲水道",
        "x84": "114.837632,30.531749",
        "id": "WH_57af488389b44225b8bd8f1b191fe0a8",
        "mt_id": "WH_57af488389b44225b8bd8f1b191fe0a8",
        "hdHdly": "3",
        "hdlc": "962.90"
    },
    {
        "mtmc": "荆江分洪区码头",
        "sdmc": "马家寨水道",
        "x84": "112.201205,30.095458",
        "id": "WH_581d4f6dfe094bc29830fe10a9a5a563",
        "mt_id": "WH_581d4f6dfe094bc29830fe10a9a5a563",
        "hdHdly": "2",
        "hdlc": "441.30"
    },
    {
        "mtmc": "华新水泥厂青山码头",
        "sdmc": "青山夹水道",
        "x84": "114.41506,30.658786",
        "id": "WH_5b5cc5a5feb146bb9a2f25dd3a49fbbe",
        "mt_id": "WH_5b5cc5a5feb146bb9a2f25dd3a49fbbe",
        "hdHdly": "3",
        "hdlc": "1027.70"
    },
    {
        "mtmc": "鑫港物流#3码头",
        "sdmc": "巴河水道",
        "x84": "114.958019,30.398719",
        "id": "WH_5b6e797fc9c1465db6e53538f004961f",
        "mt_id": "WH_5b6e797fc9c1465db6e53538f004961f",
        "hdHdly": "3",
        "hdlc": "940.00"
    },
    {
        "mtmc": "华特沥青码头",
        "sdmc": "阳逻水道",
        "x84": "114.568907,30.621280",
        "id": "WH_5bbf380f8bb84164b96d85aba867a3c9",
        "mt_id": "WH_5bbf380f8bb84164b96d85aba867a3c9",
        "hdHdly": "3",
        "hdlc": "1008.10"
    },
    {
        "mtmc": "棋盘洲作业区三期工程",
        "sdmc": "蕲春水道",
        "x84": "115.283557,30.126852",
        "id": "WH_5c1ca8a2020a459c9ac3a67e742a058e",
        "mt_id": "WH_5c1ca8a2020a459c9ac3a67e742a058e",
        "hdHdly": "3",
        "hdlc": "883.00"
    },
    {
        "mtmc": "武汉港化学品船舶洗舱站",
        "sdmc": "阳逻水道",
        "x84": "114.567757,30.597517",
        "id": "WH_5ca32f86fa1d4c71937e6e7b251bdc63",
        "mt_id": "WH_5ca32f86fa1d4c71937e6e7b251bdc63",
        "hdHdly": "3",
        "hdlc": "1005.70"
    },
    {
        "mtmc": "富江公用码头",
        "sdmc": "搁排矶水道",
        "x84": "115.407561,29.904593",
        "id": "WH_5cba68e1d68e4e51810a516b902a5b2d",
        "mt_id": "WH_5cba68e1d68e4e51810a516b902a5b2d",
        "hdHdly": "3",
        "hdlc": "856.68"
    },
    {
        "mtmc": "武船码头1号",
        "sdmc": "武桥水道-上",
        "x84": "114.283959,30.539439",
        "id": "WH_5d26b8c04e9c484bba5b1902fe731925",
        "mt_id": "WH_5d26b8c04e9c484bba5b1902fe731925",
        "hdHdly": "2",
        "hdlc": "3.70"
    },
    {
        "mtmc": "公安人渡码头",
        "sdmc": "陡湖堤水道",
        "x84": "112.231601,30.06385",
        "id": "WH_5d84748071794cfc839b2ef02a0ac2f8",
        "mt_id": "WH_5d84748071794cfc839b2ef02a0ac2f8",
        "hdHdly": "2",
        "hdlc": "445.20"
    },
    {
        "mtmc": "丝毛径码头",
        "sdmc": null,
        "x84": "",
        "id": "WH_5f187abd6b624e879b86f6dea513241a",
        "mt_id": "WH_5f187abd6b624e879b86f6dea513241a",
        "hdHdly": "3",
        "hdlc": "899.74"
    },
    {
        "mtmc": "鄂祥港泵#2码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.453333,29.876198",
        "id": "WH_5fc964aadfba4a78ab8294d15e2e3eb5",
        "mt_id": "WH_5fc964aadfba4a78ab8294d15e2e3eb5",
        "hdHdly": "3",
        "hdlc": "851.29"
    },
    {
        "mtmc": "鄂钢码头二期",
        "sdmc": "沙洲水道",
        "x84": "114.82354,30.504954",
        "id": "WH_5ff0a2d9064f4fa5ba0e64d95d79bd9d",
        "mt_id": "WH_5ff0a2d9064f4fa5ba0e64d95d79bd9d",
        "hdHdly": "3",
        "hdlc": "959.50"
    },
    {
        "mtmc": "华新水泥厂码头",
        "sdmc": "仙峰水道",
        "x84": "113.195416,29.500189",
        "id": "WH_60311462ac194aacadbe9e0d1b47676b",
        "mt_id": "WH_60311462ac194aacadbe9e0d1b47676b",
        "hdHdly": "2",
        "hdlc": "223.20"
    },
    {
        "mtmc": "黄州港高桩码头",
        "sdmc": "巴河水道",
        "x84": "114.962696,30.414079",
        "id": "WH_608608a9d0404066873a975357633c91",
        "mt_id": "WH_608608a9d0404066873a975357633c91",
        "hdHdly": "3",
        "hdlc": "942.00"
    },
    {
        "mtmc": "沙市热电厂码头",
        "sdmc": "太平口水道-下",
        "x84": "112.278334,30.279454",
        "id": "WH_6111e765e6344b5483f06bd295e12894",
        "mt_id": "WH_6111e765e6344b5483f06bd295e12894",
        "hdHdly": "2",
        "hdlc": "473.80"
    },
    {
        "mtmc": "荆州四码头",
        "sdmc": "太平口水道-下",
        "x84": "112.247666,30.300698",
        "id": "WH_61b62a3f20c842fcb9a90fd31a8e798f",
        "mt_id": "WH_61b62a3f20c842fcb9a90fd31a8e798f",
        "hdHdly": "2",
        "hdlc": "478.00"
    },
    {
        "mtmc": "中东化工有限公司码头",
        "sdmc": "阳逻水道",
        "x84": "114.565894,30.627624",
        "id": "WH_6261bfc3d4a84597af680eb68632c3a2",
        "mt_id": "WH_6261bfc3d4a84597af680eb68632c3a2",
        "hdHdly": "3",
        "hdlc": "999.20"
    },
    {
        "mtmc": "荆江门一期工程码头",
        "sdmc": "反咀水道",
        "x84": "112.952497,29.466427",
        "id": "WH_62d1a950f2a2462a83b6214907550fec",
        "mt_id": "WH_62d1a950f2a2462a83b6214907550fec",
        "hdHdly": "2",
        "hdlc": "271.50"
    },
    {
        "mtmc": "二航局生产基地码头",
        "sdmc": "湖广水道",
        "x84": "114.69132,30.587741",
        "id": "WH_631478a269734ac0aa717f03b8720fb3",
        "mt_id": "WH_631478a269734ac0aa717f03b8720fb3",
        "hdHdly": "3",
        "hdlc": "990.10"
    },
    {
        "mtmc": "中长燃码头",
        "sdmc": "巴河水道",
        "x84": "114.986662,30.414598",
        "id": "WH_6333541bf55949639cafe820e788e8af",
        "mt_id": "WH_6333541bf55949639cafe820e788e8af",
        "hdHdly": "3",
        "hdlc": "935.71"
    },
    {
        "mtmc": "百盛过驳基地临时码头、地方海事临时码头",
        "sdmc": "仙峰水道",
        "x84": "113.190716,29.492032",
        "id": "WH_638aaa6b2aee42058787e4416704c8df",
        "mt_id": "WH_638aaa6b2aee42058787e4416704c8df",
        "hdHdly": "2",
        "hdlc": "224.50"
    },
    {
        "mtmc": "水政码头",
        "sdmc": "阳逻水道",
        "x84": "114.527543,30.684549",
        "id": "WH_63d80403a3164549b0d971225e9d4f64",
        "mt_id": "WH_63d80403a3164549b0d971225e9d4f64",
        "hdHdly": "3",
        "hdlc": "1018.00"
    },
    {
        "mtmc": "田家镇#1码头",
        "sdmc": "搁排矶水道",
        "x84": "115.411874,29.916011",
        "id": "WH_640c96ad2a9f42bca56985e72f0abc55",
        "mt_id": "WH_640c96ad2a9f42bca56985e72f0abc55",
        "hdHdly": "3",
        "hdlc": "857.29"
    },
    {
        "mtmc": "调关航道处码头",
        "sdmc": "调关水道",
        "x84": "112.635724,29.70065",
        "id": "WH_64bbd7144e094d0aa7781642a5c9c5ab",
        "mt_id": "WH_64bbd7144e094d0aa7781642a5c9c5ab",
        "hdHdly": "2",
        "hdlc": "347.70"
    },
    {
        "mtmc": "中华人民共和国岳阳洪湖海事处",
        "sdmc": "界牌水道",
        "x84": "113.455569,29.797163",
        "id": "WH_6631130045704d0682d402b9be963d73",
        "mt_id": "WH_6631130045704d0682d402b9be963d73",
        "hdHdly": "2",
        "hdlc": "180.50"
    },
    {
        "mtmc": "富安码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.288637,30.260306",
        "id": "WH_674a2edde9074943a5bc6015938ea609",
        "mt_id": "WH_674a2edde9074943a5bc6015938ea609",
        "hdHdly": "2",
        "hdlc": "471.80"
    },
    {
        "mtmc": "兆基码头",
        "sdmc": "白沙洲水道",
        "x84": "114.237485,30.498128",
        "id": "WH_68745798f62e421d864a72411bf8cd97",
        "mt_id": "WH_68745798f62e421d864a72411bf8cd97",
        "hdHdly": "2",
        "hdlc": "9.70"
    },
    {
        "mtmc": "武汉城管码头",
        "sdmc": "汉口水道",
        "x84": "114.312761,30.606022",
        "id": "WH_68d7cffa30844112a22200864f335f17",
        "mt_id": "WH_68d7cffa30844112a22200864f335f17",
        "hdHdly": "3",
        "hdlc": "1038.80"
    },
    {
        "mtmc": "黄冈轮渡码头",
        "sdmc": "沙洲水道",
        "x84": "114.885796,30.421921",
        "id": "WH_69320831adfc4072b75ddde00be50a2e",
        "mt_id": "WH_69320831adfc4072b75ddde00be50a2e",
        "hdHdly": "3",
        "hdlc": "947.00"
    },
    {
        "mtmc": "武汉工程局码头",
        "sdmc": "汉口水道",
        "x84": "114.319193,30.615292",
        "id": "WH_693d559f27b04ed4aaa18758f55c535c",
        "mt_id": "WH_693d559f27b04ed4aaa18758f55c535c",
        "hdHdly": "3",
        "hdlc": "1038.10"
    },
    {
        "mtmc": "金口地方海事码头",
        "sdmc": "金口水道",
        "x84": "114.124145,30.337077",
        "id": "WH_69d2fa7e5af245e3941e8746d5b2ccdc",
        "mt_id": "WH_69d2fa7e5af245e3941e8746d5b2ccdc",
        "hdHdly": "2",
        "hdlc": "31.00"
    },
    {
        "mtmc": "长风船舶维修浮码头",
        "sdmc": "洞庭湖口",
        "x84": "113.130478,29.421701",
        "id": "WH_6a4706dbcaae4f6292cb4c02b18054e4",
        "mt_id": "WH_6a4706dbcaae4f6292cb4c02b18054e4",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "振荣水泥码头",
        "sdmc": "瓦口子水道-下",
        "x84": "112.282607,30.226593",
        "id": "WH_6a47f168587f4194b3ea9b43a001ab06",
        "mt_id": "WH_6a47f168587f4194b3ea9b43a001ab06",
        "hdHdly": "2",
        "hdlc": "467.80"
    },
    {
        "mtmc": "阳逻国际集装箱三期",
        "sdmc": "阳逻水道",
        "x84": "114.548516,30.658042",
        "id": "WH_6ae1d73178ec4087aad5046feb311619",
        "mt_id": "WH_6ae1d73178ec4087aad5046feb311619",
        "hdHdly": "3",
        "hdlc": "1013.50"
    },
    {
        "mtmc": "洪山水务码头",
        "sdmc": "沌口水道-上（停用）",
        "x84": "114.211373,30.450218",
        "id": "WH_6b34de0f0c61458aa495f815c25b449e",
        "mt_id": "WH_6b34de0f0c61458aa495f815c25b449e",
        "hdHdly": "2",
        "hdlc": "15.70"
    },
    {
        "mtmc": "国强通用码头",
        "sdmc": "马家寨水道",
        "x84": "112.297269,30.076106",
        "id": "WH_6b828e8945ce45839302b1afa5832ad8",
        "mt_id": "WH_6b828e8945ce45839302b1afa5832ad8",
        "hdHdly": "2",
        "hdlc": "438.90"
    },
    {
        "mtmc": "富池装卸码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.444494,29.857507",
        "id": "WH_6bd10eaa0dd5419583ee9222695e9db7",
        "mt_id": "WH_6bd10eaa0dd5419583ee9222695e9db7",
        "hdHdly": "3",
        "hdlc": "850.06"
    },
    {
        "mtmc": "长江潘家湾海事巡航救助基地",
        "sdmc": "汉金关水道",
        "x84": "114.081763,30.088322",
        "id": "WH_6bdd9218d6f74b869e2a4c7b96b35d21",
        "mt_id": "WH_6bdd9218d6f74b869e2a4c7b96b35d21",
        "hdHdly": "2",
        "hdlc": "108.60"
    },
    {
        "mtmc": "安吉物流码头（一、二期）",
        "sdmc": "金口水道",
        "x84": "114.16054,30.386617",
        "id": "WH_6c29f1d15e7e4a4eae9bbd5c45b2fd80",
        "mt_id": "WH_6c29f1d15e7e4a4eae9bbd5c45b2fd80",
        "hdHdly": "2",
        "hdlc": "24.50"
    },
    {
        "mtmc": "白沙洲轮渡临时停靠码头",
        "sdmc": "白沙洲水道",
        "x84": "114.271950,30.511745",
        "id": "WH_6c2c0e9213354076aa01fd190c05faf5",
        "mt_id": "WH_6c2c0e9213354076aa01fd190c05faf5",
        "hdHdly": "2",
        "hdlc": "5.90"
    },
    {
        "mtmc": "武汉关轮渡码头",
        "sdmc": "武桥水道-下",
        "x84": "114.292886,30.576692",
        "id": "WH_6d206bdd0abb423088468657e22386d3",
        "mt_id": "WH_6d206bdd0abb423088468657e22386d3",
        "hdHdly": "3",
        "hdlc": "1043.10"
    },
    {
        "mtmc": "民本矿业3号码头",
        "sdmc": "搁排矶水道",
        "x84": "115.405524,29.949713",
        "id": "WH_6d24e5acbfbd4b4395648db706290f6a",
        "mt_id": "WH_6d24e5acbfbd4b4395648db706290f6a",
        "hdHdly": "3",
        "hdlc": "861.60"
    },
    {
        "mtmc": "三江港综合码头",
        "sdmc": "沙洲水道",
        "x84": "114.824901,30.514756",
        "id": "WH_6d362beae6e841e09b64d0b051a7419a",
        "mt_id": "WH_6d362beae6e841e09b64d0b051a7419a",
        "hdHdly": "3",
        "hdlc": "961.00"
    },
    {
        "mtmc": "华新骨料#1码头",
        "sdmc": "搁排矶水道",
        "x84": "115.385659,29.957997",
        "id": "WH_705a6e430ef14d10ab6f8e8353d74123",
        "mt_id": "WH_705a6e430ef14d10ab6f8e8353d74123",
        "hdHdly": "3",
        "hdlc": "864.74"
    },
    {
        "mtmc": "东船重工",
        "sdmc": "大埠街水道-下",
        "x84": "111.938194,30.353218",
        "id": "WH_70cf4f8ddcea4630bc2d37ad1cf7589d",
        "mt_id": "WH_70cf4f8ddcea4630bc2d37ad1cf7589d",
        "hdHdly": "2",
        "hdlc": "514.70"
    },
    {
        "mtmc": "红灯码头",
        "sdmc": "蕲春水道",
        "x84": "115.301978,30.119969",
        "id": "WH_721e73279b1e4a2f8e9c58f19d0c748c",
        "mt_id": "WH_721e73279b1e4a2f8e9c58f19d0c748c",
        "hdHdly": "3",
        "hdlc": "884.13"
    },
    {
        "mtmc": "荆州市水政执法支队码头",
        "sdmc": "太平口水道-下",
        "x84": "112.249824,30.299390",
        "id": "WH_72b85894e65e432e9110aaa6b8e5c9ce",
        "mt_id": "WH_72b85894e65e432e9110aaa6b8e5c9ce",
        "hdHdly": "2",
        "hdlc": "477.20"
    },
    {
        "mtmc": "武汉金诚通达装卸有限公司码头",
        "sdmc": "白沙洲水道",
        "x84": "114.218841,30.477319",
        "id": "WH_72babf3ab56547fbb09093c7d9935c45",
        "mt_id": "WH_72babf3ab56547fbb09093c7d9935c45",
        "hdHdly": "2",
        "hdlc": "12.80"
    },
    {
        "mtmc": "华隆沥青码头",
        "sdmc": "邓家口水道",
        "x84": "113.977702,30.298159",
        "id": "WH_7331774e265549628fab38c1d6b32236",
        "mt_id": "WH_7331774e265549628fab38c1d6b32236",
        "hdHdly": "2",
        "hdlc": "58.00"
    },
    {
        "mtmc": "五福旅游码头",
        "sdmc": "汉口水道",
        "x84": "114.307584,30.597353",
        "id": "WH_737b9ffa0d674d7dbf50b151dfbb56d6",
        "mt_id": "WH_737b9ffa0d674d7dbf50b151dfbb56d6",
        "hdHdly": "3",
        "hdlc": "1039.80"
    },
    {
        "mtmc": "武船码头",
        "sdmc": "阳逻水道",
        "x84": "114.559253,30.640697",
        "id": "WH_73ecae730b124f10947f92c4278cea06",
        "mt_id": "WH_73ecae730b124f10947f92c4278cea06",
        "hdHdly": "3",
        "hdlc": "1011.20"
    },
    {
        "mtmc": "强楚船业有限公司",
        "sdmc": "湖广水道",
        "x84": "114.757666,30.606219",
        "id": "WH_744deed305cb4fe3b71604a3028c060a",
        "mt_id": "WH_744deed305cb4fe3b71604a3028c060a",
        "hdHdly": "3",
        "hdlc": "984.00"
    },
    {
        "mtmc": "南河口人渡右岸",
        "sdmc": "莱家铺水道",
        "x84": "112.654241,29.753387",
        "id": "WH_763604bb6338440cbddb6ca58a9e2b9c",
        "mt_id": "WH_763604bb6338440cbddb6ca58a9e2b9c",
        "hdHdly": "2",
        "hdlc": "343.00"
    },
    {
        "mtmc": "华容海事处码头",
        "sdmc": "熊家洲水道",
        "x84": "112.97611,29.484292",
        "id": "WH_77679935b5294a068bea5b88c38fdb4e",
        "mt_id": "WH_77679935b5294a068bea5b88c38fdb4e",
        "hdHdly": "2",
        "hdlc": "268.70"
    },
    {
        "mtmc": "娲石水泥厂（原阳逻水泥厂1#、2#码头）",
        "sdmc": "阳逻水道",
        "x84": "114.565669,30.627725",
        "id": "WH_77acd4e954364a7595c5b43f7023341d",
        "mt_id": "WH_77acd4e954364a7595c5b43f7023341d",
        "hdHdly": "3",
        "hdlc": "999.50"
    },
    {
        "mtmc": "天兴洲左岸汽渡1号码头",
        "sdmc": "青山夹水道",
        "x84": "114.427374,30.673313",
        "id": "WH_791beb052f4e4e9f9f85a40f6524a363",
        "mt_id": "WH_791beb052f4e4e9f9f85a40f6524a363",
        "hdHdly": "3",
        "hdlc": "1025.90"
    },
    {
        "mtmc": "武桥海事码头",
        "sdmc": "武桥水道-上",
        "x84": "114.286089,30.545629",
        "id": "WH_7a8efecdc8b348c180cdd4ecb1c359ca",
        "mt_id": "WH_7a8efecdc8b348c180cdd4ecb1c359ca",
        "hdHdly": "2",
        "hdlc": "2.70"
    },
    {
        "mtmc": "黄冈三水厂抽水码头",
        "sdmc": "沙洲水道",
        "x84": "114.917591,30.416262",
        "id": "WH_7a95fde2a8434ec9a3c24352e8f1985c",
        "mt_id": "WH_7a95fde2a8434ec9a3c24352e8f1985c",
        "hdHdly": "3",
        "hdlc": "944.32"
    },
    {
        "mtmc": "临港新城综合码头",
        "sdmc": "沙洲水道",
        "x84": "114.836411,30.523107",
        "id": "WH_7b26a226726d44e8b9c45ba5747b120e",
        "mt_id": "WH_7b26a226726d44e8b9c45ba5747b120e",
        "hdHdly": "3",
        "hdlc": "961.95"
    },
    {
        "mtmc": "权顺散货码头",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.220162,30.211773",
        "id": "WH_7b3169e0c13b4bf7ac649fed8739da4c",
        "mt_id": "WH_7b3169e0c13b4bf7ac649fed8739da4c",
        "hdHdly": "3",
        "hdlc": "897.00"
    },
    {
        "mtmc": "汉华船厂",
        "sdmc": "金口水道",
        "x84": "114.116061,30.355681",
        "id": "WH_7b42602ced3e4ac7a1efd376eef8261c",
        "mt_id": "WH_7b42602ced3e4ac7a1efd376eef8261c",
        "hdHdly": "2",
        "hdlc": "29.60"
    },
    {
        "mtmc": "武汉应急抢险打捞基地",
        "sdmc": "阳逻水道",
        "x84": "114.529049,30.683289",
        "id": "WH_7b53bbd2faa5460c89f7453724eb0b27",
        "mt_id": "WH_7b53bbd2faa5460c89f7453724eb0b27",
        "hdHdly": "3",
        "hdlc": "1016.60"
    },
    {
        "mtmc": "兰溪海事码头",
        "sdmc": "戴家洲水道",
        "x84": "115.112718,30.349996",
        "id": "WH_7b60fe96af834235bca753152a712682",
        "mt_id": "WH_7b60fe96af834235bca753152a712682",
        "hdHdly": "3",
        "hdlc": "930.80"
    },
    {
        "mtmc": "金九龙2号码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.286194,30.267427",
        "id": "WH_7bae828d32d042568de718742a31eaf3",
        "mt_id": "WH_7bae828d32d042568de718742a31eaf3",
        "hdHdly": "2",
        "hdlc": "471.90"
    },
    {
        "mtmc": "公安县长江砂石杨厂集并点",
        "sdmc": "马家寨水道",
        "x84": "112.287759,30.068454",
        "id": "WH_7bf2e49b73ad4dcf942266732421a81e",
        "mt_id": "WH_7bf2e49b73ad4dcf942266732421a81e",
        "hdHdly": "2",
        "hdlc": "438.90"
    },
    {
        "mtmc": "白沙洲海事码头",
        "sdmc": "白沙洲水道",
        "x84": "114.229043,30.489539",
        "id": "WH_7c16941b7ea74e6690996761b290bed4",
        "mt_id": "WH_7c16941b7ea74e6690996761b290bed4",
        "hdHdly": "2",
        "hdlc": "10.90"
    },
    {
        "mtmc": "黄石港航码头",
        "sdmc": "黄石水道",
        "x84": "115.100742,30.21129",
        "id": "WH_7c9f848cb0d24390a989f86ab2861ba7",
        "mt_id": "WH_7c9f848cb0d24390a989f86ab2861ba7",
        "hdHdly": "3",
        "hdlc": "908.90"
    },
    {
        "mtmc": "华新水泥#2泊位",
        "sdmc": "黄石水道",
        "x84": "115.079291,30.227425",
        "id": "WH_7cb391c53e434c8aa680fa42f0b6573f",
        "mt_id": "WH_7cb391c53e434c8aa680fa42f0b6573f",
        "hdHdly": "3",
        "hdlc": "912.00"
    },
    {
        "mtmc": "黄颡口砂石集并中心火山作业点码头",
        "sdmc": "蕲春水道",
        "x84": "115.326502,30.019479",
        "id": "WH_7cf612f4c430487c9dcade1182f76f80",
        "mt_id": "WH_7cf612f4c430487c9dcade1182f76f80",
        "hdHdly": "3",
        "hdlc": "872.00"
    },
    {
        "mtmc": "华新水泥厂#1码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.436684,29.893832",
        "id": "WH_7cff0b6272bb4bd5a8d813786c31e3fe",
        "mt_id": "WH_7cff0b6272bb4bd5a8d813786c31e3fe",
        "hdHdly": "3",
        "hdlc": "853.00"
    },
    {
        "mtmc": "沙市港航处码头",
        "sdmc": "太平口水道-下",
        "x84": "112.24767,30.300706",
        "id": "WH_7dac79af151342efa868a72c29199a2b",
        "mt_id": "WH_7dac79af151342efa868a72c29199a2b",
        "hdHdly": "2",
        "hdlc": "477.90"
    },
    {
        "mtmc": "华新阳新水泥有限公司码头",
        "sdmc": "蕲春水道",
        "x84": "115.287382,30.121081",
        "id": "WH_7ddbf4febde541e48bd37263e82ce1c6",
        "mt_id": "WH_7ddbf4febde541e48bd37263e82ce1c6",
        "hdHdly": "3",
        "hdlc": "885.00"
    },
    {
        "mtmc": "长江武汉航道处大桥航道维护基地",
        "sdmc": "武桥水道-上",
        "x84": "114.285826,30.545125",
        "id": "WH_7e13e725a1584b2383825992e2f35f30",
        "mt_id": "WH_7e13e725a1584b2383825992e2f35f30",
        "hdHdly": "2",
        "hdlc": "2.90"
    },
    {
        "mtmc": "金誉欣码头",
        "sdmc": "蕲春水道",
        "x84": "115.325343,30.020428",
        "id": "WH_7f0f4349da624a99b288a13118442b7b",
        "mt_id": "WH_7f0f4349da624a99b288a13118442b7b",
        "hdHdly": "3",
        "hdlc": "872.50"
    },
    {
        "mtmc": "南河口人渡左岸",
        "sdmc": "莱家铺水道",
        "x84": "112.656818,29.767918",
        "id": "WH_7fc188c397c24a06bfe175821f677750",
        "mt_id": "WH_7fc188c397c24a06bfe175821f677750",
        "hdHdly": "2",
        "hdlc": "343.00"
    },
    {
        "mtmc": "黄州港新港港区国盛综合码头",
        "sdmc": "巴河水道",
        "x84": "114.971958,30.414726",
        "id": "WH_80a207477e82473ebee0dfb03768a522",
        "mt_id": "WH_80a207477e82473ebee0dfb03768a522",
        "hdHdly": "3",
        "hdlc": "938.50"
    },
    {
        "mtmc": "江陵巡航救助基地",
        "sdmc": "郝穴水道",
        "x84": "112.402297,30.029968",
        "id": "WH_80c7aa574db249ec9a785e88e1554485",
        "mt_id": "WH_80c7aa574db249ec9a785e88e1554485",
        "hdHdly": "2",
        "hdlc": "427.70"
    },
    {
        "mtmc": "沌口码头",
        "sdmc": "沌口水道-上（停用）",
        "x84": "114.217569,30.476203",
        "id": "WH_80f4e4a9f58a46fea8abf5b76a9a93c0",
        "mt_id": "WH_80f4e4a9f58a46fea8abf5b76a9a93c0",
        "hdHdly": "2",
        "hdlc": "15.10"
    },
    {
        "mtmc": "鄂州大通物流股份有限公司",
        "sdmc": "巴河水道",
        "x84": "114.941467,30.401898",
        "id": "WH_81f82574a7c2403f92a8d0a8b1aeaa83",
        "mt_id": "WH_81f82574a7c2403f92a8d0a8b1aeaa83",
        "hdHdly": "3",
        "hdlc": "941.60"
    },
    {
        "mtmc": "青山海事处",
        "sdmc": "阳逻水道",
        "x84": "114.467564,30.675749",
        "id": "WH_827cab54c59044b7b7adfe4ad7da2971",
        "mt_id": "WH_827cab54c59044b7b7adfe4ad7da2971",
        "hdHdly": "3",
        "hdlc": "1021.70"
    },
    {
        "mtmc": "宇丰码头",
        "sdmc": "金口水道",
        "x84": "114.089318,30.324497",
        "id": "WH_8320d76ec18741f5b8efb7b00c9505c4",
        "mt_id": "WH_8320d76ec18741f5b8efb7b00c9505c4",
        "hdHdly": "2",
        "hdlc": "31.70"
    },
    {
        "mtmc": "建强港务有限公司码头",
        "sdmc": "郝穴水道",
        "x84": "112.375654,30.058678",
        "id": "WH_837aad535cf948d2959f39c720a22b7c",
        "mt_id": "WH_837aad535cf948d2959f39c720a22b7c",
        "hdHdly": "2",
        "hdlc": "431.50"
    },
    {
        "mtmc": "恒阳石化码头",
        "sdmc": "仙峰水道",
        "x84": "113.200326,29.506578",
        "id": "WH_83f5f9cec790405b86c6684756b591ab",
        "mt_id": "WH_83f5f9cec790405b86c6684756b591ab",
        "hdHdly": "2",
        "hdlc": "222.20"
    },
    {
        "mtmc": "荆桥航道维护基地码头",
        "sdmc": "太平口水道-下",
        "x84": "112.202617,30.320074",
        "id": "WH_8482b975864249c59b7f653c363a9c2d",
        "mt_id": "WH_8482b975864249c59b7f653c363a9c2d",
        "hdHdly": "2",
        "hdlc": "482.00"
    },
    {
        "mtmc": "兴润能源码头",
        "sdmc": "马家寨水道",
        "x84": "112.301873,30.07628",
        "id": "WH_84f00ad1e7b145dca1a35709513f1680",
        "mt_id": "WH_84f00ad1e7b145dca1a35709513f1680",
        "hdHdly": "2",
        "hdlc": "438.50"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "汉口水道",
        "x84": "114.319755,30.616268",
        "id": "WH_8527c2327c814828b3bd48fd40e51326",
        "mt_id": "WH_8527c2327c814828b3bd48fd40e51326",
        "hdHdly": "3",
        "hdlc": "1038.00"
    },
    {
        "mtmc": "天兴洲航道维护基地",
        "sdmc": "青山夹水道",
        "x84": "114.375906,30.643655",
        "id": "WH_8601fc2a40a3429988eef278719f18cf",
        "mt_id": "WH_8601fc2a40a3429988eef278719f18cf",
        "hdHdly": "3",
        "hdlc": "1032.50"
    },
    {
        "mtmc": "散花轮渡码头",
        "sdmc": "黄石水道",
        "x84": "115.132962,30.214432",
        "id": "WH_86f4fc0f46c44448932e7e4897832257",
        "mt_id": "WH_86f4fc0f46c44448932e7e4897832257",
        "hdHdly": "3",
        "hdlc": "906.00"
    },
    {
        "mtmc": "鑫港物流#2码头",
        "sdmc": "巴河水道",
        "x84": "114.956839,30.398762",
        "id": "WH_8773a965897e4436b69017ba6291d5c3",
        "mt_id": "WH_8773a965897e4436b69017ba6291d5c3",
        "hdHdly": "3",
        "hdlc": "940.00"
    },
    {
        "mtmc": "沙码头",
        "sdmc": "沙洲水道",
        "x84": "114.821315,30.466152",
        "id": "WH_87a60c1351a746f6b8543fb623c474c6",
        "mt_id": "WH_87a60c1351a746f6b8543fb623c474c6",
        "hdHdly": "3",
        "hdlc": "955.70"
    },
    {
        "mtmc": "武警码头",
        "sdmc": "武桥水道-上",
        "x84": "114.286706,30.547292",
        "id": "WH_87c1158334e94a4d9e2cc15b69110852",
        "mt_id": "WH_87c1158334e94a4d9e2cc15b69110852",
        "hdHdly": "2",
        "hdlc": "2.70"
    },
    {
        "mtmc": "长利玻璃厂码头",
        "sdmc": "簰洲水道",
        "x84": "113.862891,30.181515",
        "id": "WH_88b1cab3cdcd439ebcecfcc6f34df01a",
        "mt_id": "WH_88b1cab3cdcd439ebcecfcc6f34df01a",
        "hdHdly": "2",
        "hdlc": "79.30"
    },
    {
        "mtmc": "长江公安公安派出所码头",
        "sdmc": "陡湖堤水道",
        "x84": "112.214059,30.076373",
        "id": "WH_89f3309ea6b84441b8063ae42e5859df",
        "mt_id": "WH_89f3309ea6b84441b8063ae42e5859df",
        "hdHdly": "2",
        "hdlc": "446.70"
    },
    {
        "mtmc": "武船双柳基地码头",
        "sdmc": "湖广水道",
        "x84": "114.669929,30.582311",
        "id": "WH_8a4ae5bc169d43c38d605cfeb3d141ad",
        "mt_id": "WH_8a4ae5bc169d43c38d605cfeb3d141ad",
        "hdHdly": "3",
        "hdlc": "991.20"
    },
    {
        "mtmc": "燕矶船厂#1码头",
        "sdmc": "戴家洲水道",
        "x84": "115.018595,30.399939",
        "id": "WH_8a908b897121422790594d857a797042",
        "mt_id": "WH_8a908b897121422790594d857a797042",
        "hdHdly": "3",
        "hdlc": "934.11"
    },
    {
        "mtmc": "永发危化码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.289181,30.247219",
        "id": "WH_8b76ec6a17964a28a47166ce4b93ec51",
        "mt_id": "WH_8b76ec6a17964a28a47166ce4b93ec51",
        "hdHdly": "2",
        "hdlc": "470.10"
    },
    {
        "mtmc": "黄冈晨鸣港务综合码头",
        "sdmc": "巴河水道",
        "x84": "114.975307,30.414530",
        "id": "WH_8bf36702b75546ff93608688f8de84e0",
        "mt_id": "WH_8bf36702b75546ff93608688f8de84e0",
        "hdHdly": "3",
        "hdlc": "937.90"
    },
    {
        "mtmc": "青山客运总站码头",
        "sdmc": "青山夹水道",
        "x84": "114.420871,30.660135",
        "id": "WH_8c4835acc2074e5c8c0616d0995945e8",
        "mt_id": "WH_8c4835acc2074e5c8c0616d0995945e8",
        "hdHdly": "3",
        "hdlc": "1027.00"
    },
    {
        "mtmc": "海波重科码头",
        "sdmc": "煤炭洲水道",
        "x84": "114.036773,30.231497",
        "id": "WH_8d1d46c6fdc14cb581e39b0a4f7131b4",
        "mt_id": "WH_8d1d46c6fdc14cb581e39b0a4f7131b4",
        "hdHdly": "2",
        "hdlc": "49.00"
    },
    {
        "mtmc": "青山镇码头",
        "sdmc": "青山夹水道",
        "x84": "114.41689,30.659152",
        "id": "WH_8d5be77c65634962b5484033b7a1249a",
        "mt_id": "WH_8d5be77c65634962b5484033b7a1249a",
        "hdHdly": "3",
        "hdlc": "1027.70"
    },
    {
        "mtmc": "阳逻港江海港物流码头",
        "sdmc": "阳逻水道",
        "x84": "114.520616,30.687025",
        "id": "WH_8d84f1090ed9438daf1fde55abb42a67",
        "mt_id": "WH_8d84f1090ed9438daf1fde55abb42a67",
        "hdHdly": "3",
        "hdlc": "1016.80"
    },
    {
        "mtmc": "砂石码头",
        "sdmc": null,
        "x84": "",
        "id": "WH_8d959598aca74c60bae42bc7eef29802",
        "mt_id": "WH_8d959598aca74c60bae42bc7eef29802",
        "hdHdly": "3",
        "hdlc": "901.76"
    },
    {
        "mtmc": "港务局外贸码头",
        "sdmc": "洞庭湖口",
        "x84": "113.142568,29.442185",
        "id": "WH_8e097aef07fc4de8a845a07b8f3fb8c6",
        "mt_id": "WH_8e097aef07fc4de8a845a07b8f3fb8c6",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "武汉海事巡航救助基地码头",
        "sdmc": "汉口水道",
        "x84": "114.297950,30.582483",
        "id": "WH_90bb2e0d447c4585a1760bc60bec9918",
        "mt_id": "WH_90bb2e0d447c4585a1760bc60bec9918",
        "hdHdly": "3",
        "hdlc": "1041.70"
    },
    {
        "mtmc": "华新水泥厂2号码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.435768,29.894879",
        "id": "WH_9130bcd8c4a44f638d35bbc4a4c4edfa",
        "mt_id": "WH_9130bcd8c4a44f638d35bbc4a4c4edfa",
        "hdHdly": "3",
        "hdlc": "853.30"
    },
    {
        "mtmc": "新冶钢滑道",
        "sdmc": "黄石水道",
        "x84": "115.154026,30.209585",
        "id": "WH_91d5d593615d44eda7919a03dcf5cbfc",
        "mt_id": "WH_91d5d593615d44eda7919a03dcf5cbfc",
        "hdHdly": "3",
        "hdlc": "903.81"
    },
    {
        "mtmc": "长航公安公务码头",
        "sdmc": "道仁矶水道",
        "x84": "113.221643,29.538893",
        "id": "WH_927fdf021cd84e1a8f46f181564d96e7",
        "mt_id": "WH_927fdf021cd84e1a8f46f181564d96e7",
        "hdHdly": "2",
        "hdlc": "218.10"
    },
    {
        "mtmc": "螺山水文码头",
        "sdmc": "螺山水道-上",
        "x84": "113.319111,29.663588",
        "id": "WH_93cace8219f6445280a974be4981cfb6",
        "mt_id": "WH_93cace8219f6445280a974be4981cfb6",
        "hdHdly": "2",
        "hdlc": "200.70"
    },
    {
        "mtmc": "武汉江盛码头",
        "sdmc": "沌口水道",
        "x84": "114.209399,30.466225",
        "id": "WH_94ddeb2e8f0b461e982e58bd37f11705",
        "mt_id": "WH_94ddeb2e8f0b461e982e58bd37f11705",
        "hdHdly": "2",
        "hdlc": "15.00"
    },
    {
        "mtmc": "君山LNG加注站码头",
        "sdmc": "铁铺水道",
        "x84": "112.9123,29.581418",
        "id": "WH_95b6b814a6c4414e96dedb10fb72dc3c",
        "mt_id": "WH_95b6b814a6c4414e96dedb10fb72dc3c",
        "hdHdly": "2",
        "hdlc": "285.00"
    },
    {
        "mtmc": "散货码头",
        "sdmc": "戴家洲水道",
        "x84": "115.113716,30.336151",
        "id": "WH_962cafe7440d4fb9a41edaf92f3c4248",
        "mt_id": "WH_962cafe7440d4fb9a41edaf92f3c4248",
        "hdHdly": "3",
        "hdlc": "924.00"
    },
    {
        "mtmc": "红钢城1-2号吊车码头",
        "sdmc": "青山夹水道",
        "x84": "114.391284,30.651379",
        "id": "WH_96db518cbebf4e60b75f726e42dc8f3f",
        "mt_id": "WH_96db518cbebf4e60b75f726e42dc8f3f",
        "hdHdly": "3",
        "hdlc": "1030.60"
    },
    {
        "mtmc": "古长堤人渡",
        "sdmc": "藕池口水道",
        "x84": "112.389949,29.798223",
        "id": "WH_9719e6a9cb83408d99ade626a51c6057",
        "mt_id": "WH_9719e6a9cb83408d99ade626a51c6057",
        "hdHdly": "2",
        "hdlc": "400.10"
    },
    {
        "mtmc": "黄石上窑轮渡码头",
        "sdmc": "黄石水道",
        "x84": "115.095553,30.213883",
        "id": "WH_978b5704c78c46cc80555c822aa3450a",
        "mt_id": "WH_978b5704c78c46cc80555c822aa3450a",
        "hdHdly": "3",
        "hdlc": "909.71"
    },
    {
        "mtmc": "散货码头",
        "sdmc": null,
        "x84": "",
        "id": "WH_981a4a1d28424dafae6009ba51e77151",
        "mt_id": "WH_981a4a1d28424dafae6009ba51e77151",
        "hdHdly": "3",
        "hdlc": "902.32"
    },
    {
        "mtmc": "城陵矶国际集装箱码头",
        "sdmc": "仙峰水道",
        "x84": "113.170188,29.470227",
        "id": "WH_983ceda7123e4cd9af5b878b4b5698e0",
        "mt_id": "WH_983ceda7123e4cd9af5b878b4b5698e0",
        "hdHdly": "2",
        "hdlc": "226.50"
    },
    {
        "mtmc": "白浒山海事码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.632422,30.553851",
        "id": "WH_988c9a1c15e848c8a32dda2d07fe0e90",
        "mt_id": "WH_988c9a1c15e848c8a32dda2d07fe0e90",
        "hdHdly": "3",
        "hdlc": "996.80"
    },
    {
        "mtmc": "四维码头",
        "sdmc": "汉口水道",
        "x84": "114.317775,30.614777",
        "id": "WH_98cccd4f5f03479d80969a8f6c17d5b8",
        "mt_id": "WH_98cccd4f5f03479d80969a8f6c17d5b8",
        "hdHdly": "3",
        "hdlc": "1038.40"
    },
    {
        "mtmc": "荆州长航公安码头",
        "sdmc": "太平口水道-下",
        "x84": "112.247814,30.300631",
        "id": "WH_992717642c3547e6b9e17d0b285a7bb0",
        "mt_id": "WH_992717642c3547e6b9e17d0b285a7bb0",
        "hdHdly": "2",
        "hdlc": "477.70"
    },
    {
        "mtmc": "国博中心客运旅游码头",
        "sdmc": "白沙洲水道",
        "x84": "114.244391,30.506252",
        "id": "WH_99c00ac87b04482da77232fbf5fbc592",
        "mt_id": "WH_99c00ac87b04482da77232fbf5fbc592",
        "hdHdly": "2",
        "hdlc": "8.60"
    },
    {
        "mtmc": "黄石港棋盘洲港区综合码头",
        "sdmc": "蕲春水道",
        "x84": "115.267753,30.146236",
        "id": "WH_9b412f657d924bb3a3efdaa4e529cd31",
        "mt_id": "WH_9b412f657d924bb3a3efdaa4e529cd31",
        "hdHdly": "3",
        "hdlc": "887.64"
    },
    {
        "mtmc": "黄颡口轮渡码头",
        "sdmc": "搁排矶水道",
        "x84": "115.329852,30.015839",
        "id": "WH_9c270fa4c94a4fe59fcb496b317833fc",
        "mt_id": "WH_9c270fa4c94a4fe59fcb496b317833fc",
        "hdHdly": "3",
        "hdlc": "871.95"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "蕲春水道",
        "x84": "115.261259,30.152197",
        "id": "WH_9c38b80486f84381be47d14763c031c4",
        "mt_id": "WH_9c38b80486f84381be47d14763c031c4",
        "hdHdly": "3",
        "hdlc": "889.41"
    },
    {
        "mtmc": "八里汽渡路",
        "sdmc": "蕲春水道",
        "x84": "115.309071,30.110274",
        "id": "WH_9d25d78239f844c5905159e89bd07a2e",
        "mt_id": "WH_9d25d78239f844c5905159e89bd07a2e",
        "hdHdly": "2",
        "hdlc": "882.86"
    },
    {
        "mtmc": "调关汽渡左岸",
        "sdmc": "调关水道",
        "x84": "112.628574,29.705721",
        "id": "WH_9d2f629a756149b6b9189803e7a36fde",
        "mt_id": "WH_9d2f629a756149b6b9189803e7a36fde",
        "hdHdly": "2",
        "hdlc": "351.50"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "武桥水道-下",
        "x84": "114.282452,30.560274",
        "id": "WH_9d6b1f6e55334935812f5c5a696e0f82",
        "mt_id": "WH_9d6b1f6e55334935812f5c5a696e0f82",
        "hdHdly": "2",
        "hdlc": "1.70"
    },
    {
        "mtmc": "岳化码头",
        "sdmc": "道仁矶水道",
        "x84": "113.209542,29.518965",
        "id": "WH_9d920ff3d9e440cdb2264bfbdc4f0a9a",
        "mt_id": "WH_9d920ff3d9e440cdb2264bfbdc4f0a9a",
        "hdHdly": "2",
        "hdlc": "221.20"
    },
    {
        "mtmc": "石首市长江船舶污染物接受转运码头",
        "sdmc": "石首水道",
        "x84": "112.429145,29.751862",
        "id": "WH_9eb8a2f0897d4e0ea6296bb41a039090",
        "mt_id": "WH_9eb8a2f0897d4e0ea6296bb41a039090",
        "hdHdly": "2",
        "hdlc": "382.70"
    },
    {
        "mtmc": "湖北誉江船舶制造有限公司码头",
        "sdmc": "马家寨水道",
        "x84": "112.312861,30.068164",
        "id": "WH_9ec6b3cfd81345e0a8fd545a5189cb20",
        "mt_id": "WH_9ec6b3cfd81345e0a8fd545a5189cb20",
        "hdHdly": "2",
        "hdlc": "437.50"
    },
    {
        "mtmc": "团风新港一期码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.837940,30.595553",
        "id": "WH_9f2472d0dd704c1e9ed24ec55da7613a",
        "mt_id": "WH_9f2472d0dd704c1e9ed24ec55da7613a",
        "hdHdly": "3",
        "hdlc": "970.30"
    },
    {
        "mtmc": "平湖门水厂浮码头",
        "sdmc": "武桥水道-上",
        "x84": "114.284099,30.540818",
        "id": "WH_9f53b965e63e47a39a2b95652ad3567f",
        "mt_id": "WH_9f53b965e63e47a39a2b95652ad3567f",
        "hdHdly": "2",
        "hdlc": "3.50"
    },
    {
        "mtmc": "富强散货码头",
        "sdmc": "搁排矶水道",
        "x84": "115.403802,29.951732",
        "id": "WH_9f6a78c7b6804e70a155da6c1b69510e",
        "mt_id": "WH_9f6a78c7b6804e70a155da6c1b69510e",
        "hdHdly": "3",
        "hdlc": "862.00"
    },
    {
        "mtmc": "铸钱炉码头",
        "sdmc": "搁排矶水道",
        "x84": "115.379877,29.973562",
        "id": "WH_a057e9130a1648c18b2f320a1593c82d",
        "mt_id": "WH_a057e9130a1648c18b2f320a1593c82d",
        "hdHdly": "3",
        "hdlc": "865.00"
    },
    {
        "mtmc": "纱帽海事码头",
        "sdmc": "金口水道",
        "x84": "114.086232,30.314487",
        "id": "WH_a0c7db01e3f64bc59111341c109cb26d",
        "mt_id": "WH_a0c7db01e3f64bc59111341c109cb26d",
        "hdHdly": "2",
        "hdlc": "34.90"
    },
    {
        "mtmc": "长江石矶头巡航救助基地",
        "sdmc": "嘉鱼水道",
        "x84": "113.825373,29.929814",
        "id": "WH_a0d02b093af145ddbe2f1ebd2570d78f",
        "mt_id": "WH_a0d02b093af145ddbe2f1ebd2570d78f",
        "hdHdly": "2",
        "hdlc": "140.70"
    },
    {
        "mtmc": "长航公安码头",
        "sdmc": "青山夹水道",
        "x84": "114.374448,30.643011",
        "id": "WH_a0d5d570a2c04170ad1bbc527e512884",
        "mt_id": "WH_a0d5d570a2c04170ad1bbc527e512884",
        "hdHdly": "3",
        "hdlc": "1032.60"
    },
    {
        "mtmc": "阳新娲石码头",
        "sdmc": "搁排矶水道",
        "x84": "115.404542,29.907059",
        "id": "WH_a1a159551aa04719baf66c9c07ff6cec",
        "mt_id": "WH_a1a159551aa04719baf66c9c07ff6cec",
        "hdHdly": "3",
        "hdlc": "857.00"
    },
    {
        "mtmc": "绣林巡航救助基地",
        "sdmc": "石首水道",
        "x84": "112.406911,29.738771",
        "id": "WH_a2314b1947be4a8a90f01d30f0dee86b",
        "mt_id": "WH_a2314b1947be4a8a90f01d30f0dee86b",
        "hdHdly": "2",
        "hdlc": "386.20"
    },
    {
        "mtmc": "左岭卸煤码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.632383,30.554158",
        "id": "WH_a2b310aaf74d4b7c87c3cb6602d03275",
        "mt_id": "WH_a2b310aaf74d4b7c87c3cb6602d03275",
        "hdHdly": "3",
        "hdlc": "996.40"
    },
    {
        "mtmc": "和顺汽渡泊位",
        "sdmc": "太平口水道-下",
        "x84": "112.242941,30.303284",
        "id": "WH_a2d651d8256a40d7ba7b202cad085f16",
        "mt_id": "WH_a2d651d8256a40d7ba7b202cad085f16",
        "hdHdly": "2",
        "hdlc": "478.50"
    },
    {
        "mtmc": "金堡村散货码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.454846,29.849256",
        "id": "WH_a42b264d5b27486c9e981e682d30525d",
        "mt_id": "WH_a42b264d5b27486c9e981e682d30525d",
        "hdHdly": "3",
        "hdlc": "849.00"
    },
    {
        "mtmc": "涴市航道维护基地",
        "sdmc": "涴市水道",
        "x84": "112.009318,30.272667",
        "id": "WH_a57e49246e874cf89eba73eef08d2188",
        "mt_id": "WH_a57e49246e874cf89eba73eef08d2188",
        "hdHdly": "2",
        "hdlc": "502.10"
    },
    {
        "mtmc": "武钢1-2号码头",
        "sdmc": "青山夹水道",
        "x84": "114.446909,30.669923",
        "id": "WH_a5d8ae150cb54576a2048dda16547616",
        "mt_id": "WH_a5d8ae150cb54576a2048dda16547616",
        "hdHdly": "3",
        "hdlc": "1023.30"
    },
    {
        "mtmc": "武汉明达码头",
        "sdmc": "沌口水道",
        "x84": "114.211384,30.468757",
        "id": "WH_a6cd21d5f5654b3199540b1b399d6121",
        "mt_id": "WH_a6cd21d5f5654b3199540b1b399d6121",
        "hdHdly": "2",
        "hdlc": "14.50"
    },
    {
        "mtmc": "沙市港口巡航救助基地",
        "sdmc": "太平口水道-下",
        "x84": "112.246151,30.301664",
        "id": "WH_a7030b1c23394f4a8a8eab181de2929b",
        "mt_id": "WH_a7030b1c23394f4a8a8eab181de2929b",
        "hdHdly": "2",
        "hdlc": "478.10"
    },
    {
        "mtmc": "砂石码头",
        "sdmc": null,
        "x84": "",
        "id": "WH_a77a13df0bb5441eb39f741502bec39a",
        "mt_id": "WH_a77a13df0bb5441eb39f741502bec39a",
        "hdHdly": "3",
        "hdlc": "901.96"
    },
    {
        "mtmc": "花山码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.578235,30.576419",
        "id": "WH_a7e71e696efb401c88d754c5002ad142",
        "mt_id": "WH_a7e71e696efb401c88d754c5002ad142",
        "hdHdly": "3",
        "hdlc": "1003.30"
    },
    {
        "mtmc": "中山舰轮渡旅游码头",
        "sdmc": "金口水道",
        "x84": "114.124558,30.348830",
        "id": "WH_a89dc52ff6ed43528094d98510612099",
        "mt_id": "WH_a89dc52ff6ed43528094d98510612099",
        "hdHdly": "2",
        "hdlc": "29.70"
    },
    {
        "mtmc": "武船双柳基地工作船、材料（重件）码头",
        "sdmc": "湖广水道",
        "x84": "114.662787,30.580466",
        "id": "WH_a8f483d574164c0f9e31feffc0b4c721",
        "mt_id": "WH_a8f483d574164c0f9e31feffc0b4c721",
        "hdHdly": "3",
        "hdlc": "994.00"
    },
    {
        "mtmc": "长岭石化厂#1-4码头",
        "sdmc": "螺山水道-上",
        "x84": "113.282696,29.60161",
        "id": "WH_a9b5b8106936476b8a0060863c45e6a2",
        "mt_id": "WH_a9b5b8106936476b8a0060863c45e6a2",
        "hdHdly": "2",
        "hdlc": "209.00"
    },
    {
        "mtmc": "调关汽渡右岸",
        "sdmc": "调关水道",
        "x84": "112.633951,29.699237",
        "id": "WH_aa9b149ae8fb4e0fbe47c95587242025",
        "mt_id": "WH_aa9b149ae8fb4e0fbe47c95587242025",
        "hdHdly": "2",
        "hdlc": "351.50"
    },
    {
        "mtmc": "港务局四码头（客运码头）",
        "sdmc": "洞庭湖口",
        "x84": "113.141825,29.43868",
        "id": "WH_aac8ced43624419da842ccfe67551c98",
        "mt_id": "WH_aac8ced43624419da842ccfe67551c98",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "葛化物流码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.606746,30.554542",
        "id": "WH_ab122e640b4c4f3581b97ec258375996",
        "mt_id": "WH_ab122e640b4c4f3581b97ec258375996",
        "hdHdly": "3",
        "hdlc": "999.60"
    },
    {
        "mtmc": "远大医药化工码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.444639,29.858983",
        "id": "WH_ab5dcb5060ee4914a05f79cbb971922c",
        "mt_id": "WH_ab5dcb5060ee4914a05f79cbb971922c",
        "hdHdly": "3",
        "hdlc": "850.20"
    },
    {
        "mtmc": "中远海运集装箱码头",
        "sdmc": "阳逻水道",
        "x84": "114.521521,30.686593",
        "id": "WH_ab9a4ceb8d744070965f3dfdde5ab7ac",
        "mt_id": "WH_ab9a4ceb8d744070965f3dfdde5ab7ac",
        "hdHdly": "3",
        "hdlc": "1016.70"
    },
    {
        "mtmc": "荆州港江陵港区郝穴作业区江陵县长江船舶污染物接收转运码头",
        "sdmc": "郝穴水道",
        "x84": "112.354646,30.066213",
        "id": "WH_abea42e0dbea425190deba4e91aa2759",
        "mt_id": "WH_abea42e0dbea425190deba4e91aa2759",
        "hdHdly": "2",
        "hdlc": "433.60"
    },
    {
        "mtmc": "散货码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.434714,29.897949",
        "id": "WH_ac9dc4d22dcf46b5bd67bbc0890835fc",
        "mt_id": "WH_ac9dc4d22dcf46b5bd67bbc0890835fc",
        "hdHdly": "3",
        "hdlc": "854.31"
    },
    {
        "mtmc": "杨泗港集装箱码头",
        "sdmc": "白沙洲水道",
        "x84": "114.262771,30.528517",
        "id": "WH_accfedbcf7d4471bb6799c8beb061754",
        "mt_id": "WH_accfedbcf7d4471bb6799c8beb061754",
        "hdHdly": "2",
        "hdlc": "5.70"
    },
    {
        "mtmc": "团风港航码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.833211,30.600977",
        "id": "WH_ad23fea3b99d49d898b71e46c1921ab3",
        "mt_id": "WH_ad23fea3b99d49d898b71e46c1921ab3",
        "hdHdly": "3",
        "hdlc": "974.50"
    },
    {
        "mtmc": "调关人渡",
        "sdmc": "调关水道",
        "x84": "112.633223,29.698672",
        "id": "WH_ad2ca21f0d3c4201a51308bba6ae8a4a",
        "mt_id": "WH_ad2ca21f0d3c4201a51308bba6ae8a4a",
        "hdHdly": "2",
        "hdlc": "351.50"
    },
    {
        "mtmc": "黄州昌顺码头",
        "sdmc": "巴河水道",
        "x84": "114.964850,30.414707",
        "id": "WH_b00a6ff2987c454ba5646417fba70943",
        "mt_id": "WH_b00a6ff2987c454ba5646417fba70943",
        "hdHdly": "3",
        "hdlc": "939.44"
    },
    {
        "mtmc": "水泽贸易趸船",
        "sdmc": "大埠街水道-下",
        "x84": "111.932548,30.358931",
        "id": "WH_b043c90bec5e4219a1adda5d53f25c58",
        "mt_id": "WH_b043c90bec5e4219a1adda5d53f25c58",
        "hdHdly": "2",
        "hdlc": "515.20"
    },
    {
        "mtmc": "江陵跃进综合码头",
        "sdmc": "瓦口子水道-下",
        "x84": "112.277967,30.220835",
        "id": "WH_b0922ddec28c465bb70058f21a13dea5",
        "mt_id": "WH_b0922ddec28c465bb70058f21a13dea5",
        "hdHdly": "2",
        "hdlc": "467.50"
    },
    {
        "mtmc": "江汉运河航道管理处码头",
        "sdmc": "太平口水道-上",
        "x84": "112.107292,30.301332",
        "id": "WH_b134756ae63149769e6d9bd8bdf2c416",
        "mt_id": "WH_b134756ae63149769e6d9bd8bdf2c416",
        "hdHdly": "2",
        "hdlc": "492.10"
    },
    {
        "mtmc": "武汉关航道码头（21码头）",
        "sdmc": "武桥水道-下",
        "x84": "114.293813,30.578063",
        "id": "WH_b1711c93843343a9a31d6b81cbc653d7",
        "mt_id": "WH_b1711c93843343a9a31d6b81cbc653d7",
        "hdHdly": "3",
        "hdlc": "1043.00"
    },
    {
        "mtmc": "鄂祥港泵#10码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.447543,29.882377",
        "id": "WH_b2e42e195d824f7a8458000f074c13c2",
        "mt_id": "WH_b2e42e195d824f7a8458000f074c13c2",
        "hdHdly": "3",
        "hdlc": "852.19"
    },
    {
        "mtmc": "武汉水上公安局码头",
        "sdmc": "汉口水道",
        "x84": "114.313490,30.606945",
        "id": "WH_b34c9077acca486fb954c9cb87fbf84a",
        "mt_id": "WH_b34c9077acca486fb954c9cb87fbf84a",
        "hdHdly": "3",
        "hdlc": "1038.60"
    },
    {
        "mtmc": "五丈港港区金航码头",
        "sdmc": "巴河水道",
        "x84": "114.947145,30.40045",
        "id": "WH_b4f95dd2fb0648549a560cd9e79453b6",
        "mt_id": "WH_b4f95dd2fb0648549a560cd9e79453b6",
        "hdHdly": "3",
        "hdlc": "941.00"
    },
    {
        "mtmc": "武汉港务局武昌外贸码头",
        "sdmc": "青山夹水道",
        "x84": "114.458072,30.673882",
        "id": "WH_b62eff5b16204cc3851df67dcdc6b676",
        "mt_id": "WH_b62eff5b16204cc3851df67dcdc6b676",
        "hdHdly": "3",
        "hdlc": "1022.10"
    },
    {
        "mtmc": "鄂祥港泵#8码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.449488,29.880128",
        "id": "WH_b6591de619bb42afa3c073fd25d85375",
        "mt_id": "WH_b6591de619bb42afa3c073fd25d85375",
        "hdHdly": "3",
        "hdlc": "851.88"
    },
    {
        "mtmc": "水政码头",
        "sdmc": "湖广水道",
        "x84": "114.69998,30.590307",
        "id": "WH_b693913d9579418bba539768b98b4772",
        "mt_id": "WH_b693913d9579418bba539768b98b4772",
        "hdHdly": "3",
        "hdlc": "989.00"
    },
    {
        "mtmc": "湖北亚东原料专用码头",
        "sdmc": "阳逻水道",
        "x84": "114.540604,30.674239",
        "id": "WH_b76d306fd1804acd9a07848c7f616ec1",
        "mt_id": "WH_b76d306fd1804acd9a07848c7f616ec1",
        "hdHdly": "3",
        "hdlc": "1015.00"
    },
    {
        "mtmc": "阳新港区船舶污染物集中接收码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.495067,29.839074",
        "id": "WH_b77abe0c558945eda0bae0e8f242e1bd",
        "mt_id": "WH_b77abe0c558945eda0bae0e8f242e1bd",
        "hdHdly": "3",
        "hdlc": "845.30"
    },
    {
        "mtmc": "黄冈航道处码头",
        "sdmc": "巴河水道",
        "x84": "114.921483,30.415044",
        "id": "WH_b7b1fb64a0b346a5b70ab6c611bdffd2",
        "mt_id": "WH_b7b1fb64a0b346a5b70ab6c611bdffd2",
        "hdHdly": "3",
        "hdlc": "943.70"
    },
    {
        "mtmc": "高桩码头",
        "sdmc": "巴河水道",
        "x84": "114.959635,30.41504",
        "id": "WH_b7ef2205a4f74a4fb3afef569be4e343",
        "mt_id": "WH_b7ef2205a4f74a4fb3afef569be4e343",
        "hdHdly": "3",
        "hdlc": "939.80"
    },
    {
        "mtmc": "城陵矶航道维护基地",
        "sdmc": "洞庭湖口",
        "x84": "113.142113,29.43843",
        "id": "WH_b858175df72f48f0976e0a03de5d1c68",
        "mt_id": "WH_b858175df72f48f0976e0a03de5d1c68",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "抬船路1号码头",
        "sdmc": "白沙洲水道",
        "x84": "114.226380,30.486288",
        "id": "WH_b90353bc17e842679b58373b28d61346",
        "mt_id": "WH_b90353bc17e842679b58373b28d61346",
        "hdHdly": "2",
        "hdlc": "11.60"
    },
    {
        "mtmc": "宜昌鑫汇船舶修造有限公司",
        "sdmc": "大埠街水道-下",
        "x84": "111.935887,30.354693",
        "id": "WH_b9f986bd1d5f46498d6ca7417b3e83ea",
        "mt_id": "WH_b9f986bd1d5f46498d6ca7417b3e83ea",
        "hdHdly": "2",
        "hdlc": "514.70"
    },
    {
        "mtmc": "鄂钢码头一期",
        "sdmc": "沙洲水道",
        "x84": "114.824157,30.510182",
        "id": "WH_ba324f4ccb054e99871dabaed8313fcb",
        "mt_id": "WH_ba324f4ccb054e99871dabaed8313fcb",
        "hdHdly": "3",
        "hdlc": "960.37"
    },
    {
        "mtmc": "联谊兴盛码头",
        "sdmc": "邓家口水道",
        "x84": "114.000632,30.281861",
        "id": "WH_ba4e768c857047ef9dd4c3b2177dd15c",
        "mt_id": "WH_ba4e768c857047ef9dd4c3b2177dd15c",
        "hdHdly": "2",
        "hdlc": "55.40"
    },
    {
        "mtmc": "鄂州海事码头",
        "sdmc": "沙洲水道",
        "x84": "114.879387,30.41289",
        "id": "WH_ba7a64146863451eb9ead8d9a6129d86",
        "mt_id": "WH_ba7a64146863451eb9ead8d9a6129d86",
        "hdHdly": "3",
        "hdlc": "947.40"
    },
    {
        "mtmc": "岳纸#1码头",
        "sdmc": "观音洲水道",
        "x84": "113.147043,29.449431",
        "id": "WH_bae5c5f57b5740ceb03f200e31e9e5b5",
        "mt_id": "WH_bae5c5f57b5740ceb03f200e31e9e5b5",
        "hdHdly": "2",
        "hdlc": "231.00"
    },
    {
        "mtmc": "华新水泥厂5、6号码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.441026,29.889807",
        "id": "WH_bbcc524bcdef49e58535aeb7fe5fa52c",
        "mt_id": "WH_bbcc524bcdef49e58535aeb7fe5fa52c",
        "hdHdly": "3",
        "hdlc": "853.80"
    },
    {
        "mtmc": "80万吨乙烯煤码头",
        "sdmc": "阳逻水道",
        "x84": "114.538517,30.659257",
        "id": "WH_bc69a20da15e4a4aa2f85fb3f791c13d",
        "mt_id": "WH_bc69a20da15e4a4aa2f85fb3f791c13d",
        "hdHdly": "3",
        "hdlc": "1013.20"
    },
    {
        "mtmc": "高桩码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.429858,29.901492",
        "id": "WH_bd9a20f5000940cbb25966dee661d2ef",
        "mt_id": "WH_bd9a20f5000940cbb25966dee661d2ef",
        "hdHdly": "3",
        "hdlc": "854.84"
    },
    {
        "mtmc": "邓家口散装水泥码头",
        "sdmc": "邓家口水道",
        "x84": "113.999686,30.282351",
        "id": "WH_be67549bd9524e65ad267340aefd6f9f",
        "mt_id": "WH_be67549bd9524e65ad267340aefd6f9f",
        "hdHdly": "2",
        "hdlc": "55.50"
    },
    {
        "mtmc": "长江赤壁地方海事趸船12080（赤壁旅游码头趸船）",
        "sdmc": "石头关水道",
        "x84": "113.619262,29.886206",
        "id": "WH_be68c16574df42c7bf12aa19b688170c",
        "mt_id": "WH_be68c16574df42c7bf12aa19b688170c",
        "hdHdly": "2",
        "hdlc": "161.80"
    },
    {
        "mtmc": "港航码头（船舶交易服务中心）",
        "sdmc": "邓家口水道-上（停用）",
        "x84": "113.956248,30.307173",
        "id": "WH_bea00bd137e744ddb8424ae08ffad7e8",
        "mt_id": "WH_bea00bd137e744ddb8424ae08ffad7e8",
        "hdHdly": "2",
        "hdlc": "60.60"
    },
    {
        "mtmc": "粤汉码头（改建）",
        "sdmc": "汉口水道",
        "x84": "114.302762,30.590322",
        "id": "WH_bf16023cbe4640db89ae402b19f4b56a",
        "mt_id": "WH_bf16023cbe4640db89ae402b19f4b56a",
        "hdHdly": "3",
        "hdlc": "1040.50"
    },
    {
        "mtmc": "取水码头",
        "sdmc": "搁排矶水道",
        "x84": "115.374409,29.988782",
        "id": "WH_bfcc43d162484c6dbce8f6f741f46a19",
        "mt_id": "WH_bfcc43d162484c6dbce8f6f741f46a19",
        "hdHdly": "3",
        "hdlc": "866.70"
    },
    {
        "mtmc": "石首市水政码头",
        "sdmc": "石首水道",
        "x84": "112.408044,29.739036",
        "id": "WH_c0ebcc4849204dc2aa0a7f7c3ce22fb8",
        "mt_id": "WH_c0ebcc4849204dc2aa0a7f7c3ce22fb8",
        "hdHdly": "2",
        "hdlc": "386.10"
    },
    {
        "mtmc": "监利海事码头",
        "sdmc": "窑集佬水道",
        "x84": "112.842315,29.768400",
        "id": "WH_c160a7c6c7714ce4b32b1b11e1705462",
        "mt_id": "WH_c160a7c6c7714ce4b32b1b11e1705462",
        "hdHdly": "2",
        "hdlc": "320.20"
    },
    {
        "mtmc": "三和管桩码头",
        "sdmc": "沙洲水道",
        "x84": "114.827242,30.527892",
        "id": "WH_c1b01cc306c442b5a464db78a621e1b9",
        "mt_id": "WH_c1b01cc306c442b5a464db78a621e1b9",
        "hdHdly": "3",
        "hdlc": "962.37"
    },
    {
        "mtmc": "船厂上交通码头",
        "sdmc": "青山夹水道",
        "x84": "114.434519,30.665107",
        "id": "WH_c251e208491f4aaaa0ee23e7231c6c52",
        "mt_id": "WH_c251e208491f4aaaa0ee23e7231c6c52",
        "hdHdly": "3",
        "hdlc": "1025.50"
    },
    {
        "mtmc": "荆江水文码头",
        "sdmc": "太平口水道-下",
        "x84": "112.228965,30.314686",
        "id": "WH_c25e4fdbf530473089de68fd683c0574",
        "mt_id": "WH_c25e4fdbf530473089de68fd683c0574",
        "hdHdly": "2",
        "hdlc": "480.10"
    },
    {
        "mtmc": "浩川趸1",
        "sdmc": "马家咀水道",
        "x84": "112.239816,30.187804",
        "id": "WH_c26ebd85a7b1476ebc2b43316dc00f0e",
        "mt_id": "WH_c26ebd85a7b1476ebc2b43316dc00f0e",
        "hdHdly": "2",
        "hdlc": "462.00"
    },
    {
        "mtmc": "光大船厂码头",
        "sdmc": "沙洲水道",
        "x84": "114.822099,30.485949",
        "id": "WH_c326030c90e6432398649e7bedfd63b8",
        "mt_id": "WH_c326030c90e6432398649e7bedfd63b8",
        "hdHdly": "3",
        "hdlc": "957.55"
    },
    {
        "mtmc": "巴陵石化#1煤码头",
        "sdmc": "洞庭湖口",
        "x84": "113.128099,29.420377",
        "id": "WH_c424310b53ee42e9ae1395c3d8e8cbdf",
        "mt_id": "WH_c424310b53ee42e9ae1395c3d8e8cbdf",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "亿钧（玻璃厂）码头",
        "sdmc": "太平口水道-下",
        "x84": "112.285594,30.268695",
        "id": "WH_c469de8d78d741d886addd2189a8b4d1",
        "mt_id": "WH_c469de8d78d741d886addd2189a8b4d1",
        "hdHdly": "2",
        "hdlc": "472.60"
    },
    {
        "mtmc": "红巷码头",
        "sdmc": "武桥水道-下",
        "x84": "114.290649,30.556217",
        "id": "WH_c638e54013a34616a2bc6d617579f702",
        "mt_id": "WH_c638e54013a34616a2bc6d617579f702",
        "hdHdly": "2",
        "hdlc": "2.00"
    },
    {
        "mtmc": "黄冈水利码头",
        "sdmc": "巴河水道",
        "x84": "114.922762,30.414999",
        "id": "WH_c650e18e875f4f298bbc13e61e9737d2",
        "mt_id": "WH_c650e18e875f4f298bbc13e61e9737d2",
        "hdHdly": "3",
        "hdlc": "943.70"
    },
    {
        "mtmc": "华新水泥厂#2码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.436182,29.894938",
        "id": "WH_c87fccc41a2943a9954d99937d484c64",
        "mt_id": "WH_c87fccc41a2943a9954d99937d484c64",
        "hdHdly": "3",
        "hdlc": "853.10"
    },
    {
        "mtmc": "宏达恒信码头",
        "sdmc": "湖广水道",
        "x84": "114.696875,30.589115",
        "id": "WH_c92d6c104d29452c91ac9105b605bbf4",
        "mt_id": "WH_c92d6c104d29452c91ac9105b605bbf4",
        "hdHdly": "3",
        "hdlc": "989.60"
    },
    {
        "mtmc": "调关装卸码头",
        "sdmc": "调关水道",
        "x84": "112.630291,29.697107",
        "id": "WH_ca67648d2ccf4626b48f37867152076c",
        "mt_id": "WH_ca67648d2ccf4626b48f37867152076c",
        "hdHdly": "2",
        "hdlc": "351.50"
    },
    {
        "mtmc": "黄石分局警备基地",
        "sdmc": "蕲春水道",
        "x84": "115.300002,30.100788",
        "id": "WH_cb2572f41cec412c810d7d0414f95f37",
        "mt_id": "WH_cb2572f41cec412c810d7d0414f95f37",
        "hdHdly": "3",
        "hdlc": "882.30"
    },
    {
        "mtmc": "岳阳市华能电厂专用码头",
        "sdmc": "仙峰水道",
        "x84": "113.162982,29.465283",
        "id": "WH_cb2ab61b96e542fdafffd371277189e4",
        "mt_id": "WH_cb2ab61b96e542fdafffd371277189e4",
        "hdHdly": "2",
        "hdlc": "228.00"
    },
    {
        "mtmc": "港务局十三码头",
        "sdmc": "洞庭湖口",
        "x84": "113.145426,29.447726",
        "id": "WH_cb33e97c8bfb42258010ddfd3b0f58c4",
        "mt_id": "WH_cb33e97c8bfb42258010ddfd3b0f58c4",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "嘉鱼海事码头",
        "sdmc": "嘉鱼水道",
        "x84": "110.832047,29.938392",
        "id": "WH_ccde64cfee3c4f2688ab895914d58b24",
        "mt_id": "WH_ccde64cfee3c4f2688ab895914d58b24",
        "hdHdly": "2",
        "hdlc": "139.00"
    },
    {
        "mtmc": "鄂州电厂码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.634757,30.555500",
        "id": "WH_cd1b4827bc284d2f87a4f72be707561d",
        "mt_id": "WH_cd1b4827bc284d2f87a4f72be707561d",
        "hdHdly": "3",
        "hdlc": "996.30"
    },
    {
        "mtmc": "章华港人渡",
        "sdmc": "塔市驿水道-上",
        "x84": "112.750133,29.729035",
        "id": "WH_cd9b371243294a988b36f5fac17b00b0",
        "mt_id": "WH_cd9b371243294a988b36f5fac17b00b0",
        "hdHdly": "2",
        "hdlc": "331.00"
    },
    {
        "mtmc": "煤码头",
        "sdmc": "沙洲水道",
        "x84": "114.821308,30.465268",
        "id": "WH_ce656fd51cbf416fb55168ebc5599c46",
        "mt_id": "WH_ce656fd51cbf416fb55168ebc5599c46",
        "hdHdly": "3",
        "hdlc": "955.50"
    },
    {
        "mtmc": "泰华化工码头",
        "sdmc": "蕲春水道",
        "x84": "115.290485,30.116249",
        "id": "WH_cf5f361236f9430ba43960896d651842",
        "mt_id": "WH_cf5f361236f9430ba43960896d651842",
        "hdHdly": "3",
        "hdlc": "885.00"
    },
    {
        "mtmc": "水政码头",
        "sdmc": "太平口水道-下",
        "x84": "112.230614,30.313337",
        "id": "WH_d11546ff5bea4c33bf277b31106a268d",
        "mt_id": "WH_d11546ff5bea4c33bf277b31106a268d",
        "hdHdly": "2",
        "hdlc": "480.00"
    },
    {
        "mtmc": "洪山粮库码头",
        "sdmc": "沌口水道-上（停用）",
        "x84": "114.20394,30.446308",
        "id": "WH_d16a64231f6d4343b875177b58246fcc",
        "mt_id": "WH_d16a64231f6d4343b875177b58246fcc",
        "hdHdly": "2",
        "hdlc": "16.70"
    },
    {
        "mtmc": "鄂祥港泵#1码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.453664,29.875544",
        "id": "WH_d1d6a24127fd41ff8e9bd15956f0bae0",
        "mt_id": "WH_d1d6a24127fd41ff8e9bd15956f0bae0",
        "hdHdly": "3",
        "hdlc": "851.21"
    },
    {
        "mtmc": "长江海事赤壁巡航救助基地趸船",
        "sdmc": "陆溪口水道",
        "x84": "113.625516,29.894036",
        "id": "WH_d2d1aa1911b249aca05ab14488d32594",
        "mt_id": "WH_d2d1aa1911b249aca05ab14488d32594",
        "hdHdly": "2",
        "hdlc": "160.80"
    },
    {
        "mtmc": "华新水泥厂4号码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.438131,29.892247",
        "id": "WH_d42d6d4292a744208d869654a83e2dc2",
        "mt_id": "WH_d42d6d4292a744208d869654a83e2dc2",
        "hdHdly": "3",
        "hdlc": "853.60"
    },
    {
        "mtmc": "黄石新港有色化工码头",
        "sdmc": "蕲春水道",
        "x84": "115.295815,30.108235",
        "id": "WH_d47fec7adde44889b17477935d8dc4d4",
        "mt_id": "WH_d47fec7adde44889b17477935d8dc4d4",
        "hdHdly": "3",
        "hdlc": "883.43"
    },
    {
        "mtmc": "武船码头5号",
        "sdmc": "武桥水道-上",
        "x84": "114.284125,30.534632",
        "id": "WH_d4ee36c9d75c4e10b3bdc606124990bc",
        "mt_id": "WH_d4ee36c9d75c4e10b3bdc606124990bc",
        "hdHdly": "2",
        "hdlc": "4.20"
    },
    {
        "mtmc": "黄石水上公安码头",
        "sdmc": "黄石水道",
        "x84": "115.105907,30.210111",
        "id": "WH_d593c9eabf6c492b8aa974fb53840941",
        "mt_id": "WH_d593c9eabf6c492b8aa974fb53840941",
        "hdHdly": "3",
        "hdlc": "908.58"
    },
    {
        "mtmc": "白沙洲港口码头",
        "sdmc": "白沙洲水道",
        "x84": "114.276707,30.516350",
        "id": "WH_d5aa78691e994b9ca766c58647c04aa2",
        "mt_id": "WH_d5aa78691e994b9ca766c58647c04aa2",
        "hdHdly": "2",
        "hdlc": "5.90"
    },
    {
        "mtmc": "武船码头3号",
        "sdmc": "武桥水道-上",
        "x84": "114.283788,30.539224",
        "id": "WH_d668c3d9bbc84d0eab15f68ed162d4ab",
        "mt_id": "WH_d668c3d9bbc84d0eab15f68ed162d4ab",
        "hdHdly": "2",
        "hdlc": "3.80"
    },
    {
        "mtmc": "长江泵250码头",
        "sdmc": "阳逻水道",
        "x84": "114.482563,30.678527",
        "id": "WH_d6a8da82e3364e6593759f4b08db98bf",
        "mt_id": "WH_d6a8da82e3364e6593759f4b08db98bf",
        "hdHdly": "3",
        "hdlc": "1012.80"
    },
    {
        "mtmc": "长江簰洲航道处",
        "sdmc": "簰洲水道",
        "x84": "113.924445,30.204775",
        "id": "WH_d6abed982d2441cc8c7eb360df53cad8",
        "mt_id": "WH_d6abed982d2441cc8c7eb360df53cad8",
        "hdHdly": "2",
        "hdlc": "86.00"
    },
    {
        "mtmc": "邓家口海事码头",
        "sdmc": "邓家口水道",
        "x84": "114.007542,30.276756",
        "id": "WH_d75f1851457d475690218404d5623acb",
        "mt_id": "WH_d75f1851457d475690218404d5623acb",
        "hdHdly": "2",
        "hdlc": "58.10"
    },
    {
        "mtmc": "武汉新洲砂石集并中心(振新码头）",
        "sdmc": "湖广水道",
        "x84": "114.747989,30.61433",
        "id": "WH_d77574dd865b4823abc598e8410a8c66",
        "mt_id": "WH_d77574dd865b4823abc598e8410a8c66",
        "hdHdly": "3",
        "hdlc": "984.26"
    },
    {
        "mtmc": "二航水工码头",
        "sdmc": "邓家口水道",
        "x84": "114.017305,30.264330",
        "id": "WH_d778fba0317b4ad7bb466313b1631d17",
        "mt_id": "WH_d778fba0317b4ad7bb466313b1631d17",
        "hdHdly": "2",
        "hdlc": "53.10"
    },
    {
        "mtmc": "白浒山#2沙码头",
        "sdmc": "牧鹅洲水道",
        "x84": "114.632382,30.554077",
        "id": "WH_d926339ddbff466ea9df4578887c6d1b",
        "mt_id": "WH_d926339ddbff466ea9df4578887c6d1b",
        "hdHdly": "3",
        "hdlc": "996.70"
    },
    {
        "mtmc": "华新煤码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.443166,29.888241",
        "id": "WH_d967f1d6064241f1beb749000bed0d1c",
        "mt_id": "WH_d967f1d6064241f1beb749000bed0d1c",
        "hdHdly": "3",
        "hdlc": "852.96"
    },
    {
        "mtmc": "港务局三码头趸船",
        "sdmc": "阳逻水道",
        "x84": "114.551441,30.653259",
        "id": "WH_d9be73c9dbeb48d8995ed8248f85ae26",
        "mt_id": "WH_d9be73c9dbeb48d8995ed8248f85ae26",
        "hdHdly": "3",
        "hdlc": "1012.70"
    },
    {
        "mtmc": "五丈港港区多用途码头",
        "sdmc": "巴河水道",
        "x84": "114.932143,30.404442",
        "id": "WH_d9cc9774e6a447b286b7e58950d13299",
        "mt_id": "WH_d9cc9774e6a447b286b7e58950d13299",
        "hdHdly": "3",
        "hdlc": "942.30"
    },
    {
        "mtmc": "长江海事荆州大桥巡航救助基地",
        "sdmc": "太平口水道-下",
        "x84": "112.202598,30.32008",
        "id": "WH_da081c87331e4200bdb32e540c2681a9",
        "mt_id": "WH_da081c87331e4200bdb32e540c2681a9",
        "hdHdly": "2",
        "hdlc": "482.00"
    },
    {
        "mtmc": "马口工业园综合码头",
        "sdmc": "搁排矶水道",
        "x84": "115.4049,29.950232",
        "id": "WH_da45dd75101740e0953f7ee311fb1fc3",
        "mt_id": "WH_da45dd75101740e0953f7ee311fb1fc3",
        "hdHdly": "3",
        "hdlc": "857.00"
    },
    {
        "mtmc": "监利公务码头下段",
        "sdmc": "大马洲水道",
        "x84": "112.934661,29.766893",
        "id": "WH_db7233886572481abfc1f5d11a24e582",
        "mt_id": "WH_db7233886572481abfc1f5d11a24e582",
        "hdHdly": "2",
        "hdlc": "303.70"
    },
    {
        "mtmc": "江润造船厂",
        "sdmc": "巴河水道",
        "x84": "114.944527,30.414919",
        "id": "WH_dbd4c01bc265424785344864c2defc09",
        "mt_id": "WH_dbd4c01bc265424785344864c2defc09",
        "hdHdly": "3",
        "hdlc": "941.20"
    },
    {
        "mtmc": "西塞山电厂煤码头",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.170327,30.213710",
        "id": "WH_dbf2017b41f846b797c7d29ea074c667",
        "mt_id": "WH_dbf2017b41f846b797c7d29ea074c667",
        "hdHdly": "3",
        "hdlc": "902.15"
    },
    {
        "mtmc": "铁铺航道处码头",
        "sdmc": "熊家洲水道",
        "x84": "112.976093,29.483886",
        "id": "WH_dd0cf2bf1ab14098b1584f6b97d20e96",
        "mt_id": "WH_dd0cf2bf1ab14098b1584f6b97d20e96",
        "hdHdly": "2",
        "hdlc": "268.80"
    },
    {
        "mtmc": "富池远东物流码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.450953,29.851978",
        "id": "WH_dd26cd4323a047e6a86ffe8398c810c4",
        "mt_id": "WH_dd26cd4323a047e6a86ffe8398c810c4",
        "hdHdly": "3",
        "hdlc": "849.30"
    },
    {
        "mtmc": "华新水泥工作船",
        "sdmc": "鲤鱼山水道",
        "x84": "115.437313,29.892905",
        "id": "WH_ddb0c1b4bbb54ff69af12ee9a461a748",
        "mt_id": "WH_ddb0c1b4bbb54ff69af12ee9a461a748",
        "hdHdly": "3",
        "hdlc": "653.50"
    },
    {
        "mtmc": "轮渡公司码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.435485,29.871505",
        "id": "WH_df392e0ab9334fa28c631336754c4a60",
        "mt_id": "WH_df392e0ab9334fa28c631336754c4a60",
        "hdHdly": "3",
        "hdlc": "851.80"
    },
    {
        "mtmc": "方家夹人渡左岸",
        "sdmc": "莱家铺水道",
        "x84": "112.679624,29.765809",
        "id": "WH_df77cd170bdd4572a32654a334f2b38a",
        "mt_id": "WH_df77cd170bdd4572a32654a334f2b38a",
        "hdHdly": "2",
        "hdlc": "340.00"
    },
    {
        "mtmc": "禹杰综合码头",
        "sdmc": "沙洲水道",
        "x84": "114.83756,30.527032",
        "id": "WH_df93d7093cbf4b47828f8caaeae6c802",
        "mt_id": "WH_df93d7093cbf4b47828f8caaeae6c802",
        "hdHdly": "3",
        "hdlc": "962.28"
    },
    {
        "mtmc": "湖北煤投荆州煤炭铁水储备基地码头",
        "sdmc": "马家寨水道",
        "x84": "112.312788,30.074978",
        "id": "WH_dfadc42f40d14a6b8be2fb20768cbef3",
        "mt_id": "WH_dfadc42f40d14a6b8be2fb20768cbef3",
        "hdHdly": "2",
        "hdlc": "437.00"
    },
    {
        "mtmc": "中国外运白浒山港",
        "sdmc": "牧鹅洲水道",
        "x84": "114.604739,30.555264",
        "id": "WH_dfca707c12934621b4dfd0bbae2fcbb7",
        "mt_id": "WH_dfca707c12934621b4dfd0bbae2fcbb7",
        "hdHdly": "3",
        "hdlc": "1000.00"
    },
    {
        "mtmc": "黄冈市防汛抢险应急与河道综合执法基地",
        "sdmc": "巴河水道",
        "x84": "114.921932,30.415910",
        "id": "WH_e04a23d772e94c989bbf06337a3473f8",
        "mt_id": "WH_e04a23d772e94c989bbf06337a3473f8",
        "hdHdly": "3",
        "hdlc": "943.00"
    },
    {
        "mtmc": "云溪LNG加注站码头",
        "sdmc": "道仁矶水道",
        "x84": "113.211777,29.521713",
        "id": "WH_e0702420dac443199c3ac19b95bd0c91",
        "mt_id": "WH_e0702420dac443199c3ac19b95bd0c91",
        "hdHdly": "2",
        "hdlc": "220.30"
    },
    {
        "mtmc": "散货码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.434031,29.898651",
        "id": "WH_e260514c3ab74cf48e15c624bcd9b214",
        "mt_id": "WH_e260514c3ab74cf48e15c624bcd9b214",
        "hdHdly": "3",
        "hdlc": "854.41"
    },
    {
        "mtmc": "金水作业区砂石集散中心码头",
        "sdmc": "金口水道",
        "x84": "114.091711,30.279113",
        "id": "WH_e2ecf6bf92374fc5be0cf8cff710fbf7",
        "mt_id": "WH_e2ecf6bf92374fc5be0cf8cff710fbf7",
        "hdHdly": "2",
        "hdlc": "38.00"
    },
    {
        "mtmc": "阳逻轮渡客运码头",
        "sdmc": "阳逻水道",
        "x84": "114.567465,30.624443",
        "id": "WH_e346bc5be78f4b6ba2c16a13bc060de7",
        "mt_id": "WH_e346bc5be78f4b6ba2c16a13bc060de7",
        "hdHdly": "3",
        "hdlc": "998.90"
    },
    {
        "mtmc": "武汉外贸码头",
        "sdmc": "青山夹水道",
        "x84": "114.455449,30.673013",
        "id": "WH_e374509cd37c458c8fd8b760ffd983e2",
        "mt_id": "WH_e374509cd37c458c8fd8b760ffd983e2",
        "hdHdly": "3",
        "hdlc": "1022.40"
    },
    {
        "mtmc": "华容铁水联运煤炭储备一期码头",
        "sdmc": "大马洲水道",
        "x84": "112.919734,29.686561",
        "id": "WH_e3ce49457b0f4fdf846d37fccf139ed6",
        "mt_id": "WH_e3ce49457b0f4fdf846d37fccf139ed6",
        "hdHdly": "2",
        "hdlc": "297.50"
    },
    {
        "mtmc": "长江航运公安局武汉分局洪湖派出所",
        "sdmc": "界牌水道",
        "x84": "113.454848,29.796636",
        "id": "WH_e46ce12c7ff14f11aba1f58d30bb417d",
        "mt_id": "WH_e46ce12c7ff14f11aba1f58d30bb417d",
        "hdHdly": "2",
        "hdlc": "180.67"
    },
    {
        "mtmc": "监利港区白螺物流港一期工程码头",
        "sdmc": "螺山水道-上",
        "x84": "113.2861,29.620617",
        "id": "WH_e4aca9455ad74f9c8eb4bda47dc2e64a",
        "mt_id": "WH_e4aca9455ad74f9c8eb4bda47dc2e64a",
        "hdHdly": "2",
        "hdlc": "206.70"
    },
    {
        "mtmc": "咸宁核电厂大件专用码头",
        "sdmc": "汉金关水道-上（停用）",
        "x84": "114.086926,30.099331",
        "id": "WH_e4c6dc94900b4a008e9de26467c9b3b9",
        "mt_id": "WH_e4c6dc94900b4a008e9de26467c9b3b9",
        "hdHdly": "2",
        "hdlc": "107.30"
    },
    {
        "mtmc": "田家镇海事码头",
        "sdmc": "搁排矶水道",
        "x84": "115.413304,29.913894",
        "id": "WH_e641e8ccca6a4a2eae03b8a878591111",
        "mt_id": "WH_e641e8ccca6a4a2eae03b8a878591111",
        "hdHdly": "3",
        "hdlc": "857.02"
    },
    {
        "mtmc": "燕矶船厂#2码头",
        "sdmc": "戴家洲水道",
        "x84": "115.021798,30.398760",
        "id": "WH_e64db00b26c04c1aa03204372f5334a5",
        "mt_id": "WH_e64db00b26c04c1aa03204372f5334a5",
        "hdHdly": "3",
        "hdlc": "933.80"
    },
    {
        "mtmc": "二码头客运码头",
        "sdmc": "太平口水道-下",
        "x84": "112.242365,30.303623",
        "id": "WH_e6c89e7fb0714fb58ba145af4a137b59",
        "mt_id": "WH_e6c89e7fb0714fb58ba145af4a137b59",
        "hdHdly": "2",
        "hdlc": "478.50"
    },
    {
        "mtmc": "阳逻一、二期集装箱码头",
        "sdmc": "阳逻水道",
        "x84": "114.545371,30.666507",
        "id": "WH_e6ca40f276b740abae032d2f259d3b2a",
        "mt_id": "WH_e6ca40f276b740abae032d2f259d3b2a",
        "hdHdly": "3",
        "hdlc": "1014.10"
    },
    {
        "mtmc": "武汉抬船路散装水泥码头",
        "sdmc": "白沙洲水道",
        "x84": "114.220310,30.479518",
        "id": "WH_e70d460da54244aa8047b635c211324f",
        "mt_id": "WH_e70d460da54244aa8047b635c211324f",
        "hdHdly": "2",
        "hdlc": "12.50"
    },
    {
        "mtmc": "黄家山砂石集并点码头",
        "sdmc": "搁排矶水道",
        "x84": "115.378896,29.974545",
        "id": "WH_e9c19ad55a764469b517d96a3cba29ab",
        "mt_id": "WH_e9c19ad55a764469b517d96a3cba29ab",
        "hdHdly": "3",
        "hdlc": "865.00"
    },
    {
        "mtmc": "散货码头",
        "sdmc": "牯牛沙水道-上",
        "x84": "115.168964,30.213504",
        "id": "WH_e9f4390a8f62426db3ab907370adf7ee",
        "mt_id": "WH_e9f4390a8f62426db3ab907370adf7ee",
        "hdHdly": "3",
        "hdlc": "902.32"
    },
    {
        "mtmc": "金澳石化码头",
        "sdmc": "螺山水道-上",
        "x84": "113.284108,29.618036",
        "id": "WH_eb1bfb7d6ca942f09ff32b86326d03b0",
        "mt_id": "WH_eb1bfb7d6ca942f09ff32b86326d03b0",
        "hdHdly": "2",
        "hdlc": "207.20"
    },
    {
        "mtmc": "武汉航道船厂",
        "sdmc": "邓家口水道",
        "x84": "114.007639,30.276947",
        "id": "WH_eb3b0263d8e644ecbec32be10f95a976",
        "mt_id": "WH_eb3b0263d8e644ecbec32be10f95a976",
        "hdHdly": "2",
        "hdlc": "55.00"
    },
    {
        "mtmc": "武钢球团矿码头",
        "sdmc": "巴河水道",
        "x84": "114.935643,30.40375",
        "id": "WH_eb70f132ad54439f98b63ea8d6effc38",
        "mt_id": "WH_eb70f132ad54439f98b63ea8d6effc38",
        "hdHdly": "3",
        "hdlc": "942.00"
    },
    {
        "mtmc": "洪湖港区综合码头污水收集点趸船",
        "sdmc": "石头关水道",
        "x84": "113.538551,29.842268",
        "id": "WH_ec05580f7415460683e9b16cb32c2bca",
        "mt_id": "WH_ec05580f7415460683e9b16cb32c2bca",
        "hdHdly": "2",
        "hdlc": "170.80"
    },
    {
        "mtmc": "长江公安码头",
        "sdmc": "邓家口水道",
        "x84": "114.006783,30.276456",
        "id": "WH_ec2899d252904c698d54dae6c6322ce1",
        "mt_id": "WH_ec2899d252904c698d54dae6c6322ce1",
        "hdHdly": "2",
        "hdlc": "54.70"
    },
    {
        "mtmc": "团风水厂码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.828853,30.604373",
        "id": "WH_ec43422cb9724dcc8576c4bb539c2f63",
        "mt_id": "WH_ec43422cb9724dcc8576c4bb539c2f63",
        "hdHdly": "3",
        "hdlc": "975.00"
    },
    {
        "mtmc": "长江武汉救捞局码头",
        "sdmc": "汉口水道",
        "x84": "114.320073,30.616579",
        "id": "WH_ec5e0350701f4b398554e5d0ac6cb3d8",
        "mt_id": "WH_ec5e0350701f4b398554e5d0ac6cb3d8",
        "hdHdly": "3",
        "hdlc": "1037.90"
    },
    {
        "mtmc": "烟家铺汽渡码头",
        "sdmc": "窑集佬水道",
        "x84": "112.852937,29.790755",
        "id": "WH_ec9893c1419546e99fee58ef55661a0b",
        "mt_id": "WH_ec9893c1419546e99fee58ef55661a0b",
        "hdHdly": "2",
        "hdlc": "317.50"
    },
    {
        "mtmc": "民娃船业",
        "sdmc": "大埠街水道-下",
        "x84": "111.937051,30.353513",
        "id": "WH_ecd2424e7efc4608b6e144d05cd2d796",
        "mt_id": "WH_ecd2424e7efc4608b6e144d05cd2d796",
        "hdHdly": "2",
        "hdlc": "514.50"
    },
    {
        "mtmc": "监利污染物转移码头",
        "sdmc": "大马洲水道",
        "x84": "112.93286,29.76319",
        "id": "WH_ed640ac87ae744cab9376d1b619f9d15",
        "mt_id": "WH_ed640ac87ae744cab9376d1b619f9d15",
        "hdHdly": "2",
        "hdlc": "306.50"
    },
    {
        "mtmc": "华新骨料#2码头",
        "sdmc": "搁排矶水道",
        "x84": "115.384631,29.95853",
        "id": "WH_ee6531bed6ac4c6cbb5e677488cd7317",
        "mt_id": "WH_ee6531bed6ac4c6cbb5e677488cd7317",
        "hdHdly": "3",
        "hdlc": "864.86"
    },
    {
        "mtmc": "80万吨乙烯重件码头",
        "sdmc": "阳逻水道",
        "x84": "114.571151,30.589574",
        "id": "WH_eec6f517d68f4ea4bc17f2a34ba61d5b",
        "mt_id": "WH_eec6f517d68f4ea4bc17f2a34ba61d5b",
        "hdHdly": "3",
        "hdlc": "1005.10"
    },
    {
        "mtmc": "晨鸣港务码头",
        "sdmc": "巴河水道",
        "x84": "114.975307,30.414530",
        "id": "WH_ef1431927b29499abb11d3c64578f8de",
        "mt_id": "WH_ef1431927b29499abb11d3c64578f8de",
        "hdHdly": "3",
        "hdlc": "938.23"
    },
    {
        "mtmc": "港发码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.760359,30.607854",
        "id": "WH_ef7d337dbee54f25ab86fd1daa4b4cc0",
        "mt_id": "WH_ef7d337dbee54f25ab86fd1daa4b4cc0",
        "hdHdly": "3",
        "hdlc": "983.50"
    },
    {
        "mtmc": "中长燃码头",
        "sdmc": "巴河水道",
        "x84": "114.986662,30.414598",
        "id": "WH_efac32d2271943569d013844c75b07be",
        "mt_id": "WH_efac32d2271943569d013844c75b07be",
        "hdHdly": "3",
        "hdlc": "935.60"
    },
    {
        "mtmc": "监利公务码头上段",
        "sdmc": "大马洲水道",
        "x84": "112.935087,29.768656",
        "id": "WH_efe5030f772146d18d1e65e9c3b63f70",
        "mt_id": "WH_efe5030f772146d18d1e65e9c3b63f70",
        "hdHdly": "2",
        "hdlc": "303.70"
    },
    {
        "mtmc": "金鸡码头",
        "sdmc": "搁排矶水道",
        "x84": "115.405423,29.906427",
        "id": "WH_f0667e8cd5044e95a37beda25e78e8b0",
        "mt_id": "WH_f0667e8cd5044e95a37beda25e78e8b0",
        "hdHdly": "3",
        "hdlc": "857.00"
    },
    {
        "mtmc": "华新水泥厂1号码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.435093,29.896222",
        "id": "WH_f07ff45d129a4c009a54645bb78fcfc0",
        "mt_id": "WH_f07ff45d129a4c009a54645bb78fcfc0",
        "hdHdly": "3",
        "hdlc": "853.20"
    },
    {
        "mtmc": "武钢工业港码头",
        "sdmc": "青山夹水道",
        "x84": "114.459155,30.674193",
        "id": "WH_f0cb8739a2fc44e894de8d34dd0ebfcd",
        "mt_id": "WH_f0cb8739a2fc44e894de8d34dd0ebfcd",
        "hdHdly": "3",
        "hdlc": "1022.70"
    },
    {
        "mtmc": "蕲州海事码头",
        "sdmc": "蕲春水道",
        "x84": "115.331500,30.064572",
        "id": "WH_f0e2b01608514f7ab3f452267f62d2a0",
        "mt_id": "WH_f0e2b01608514f7ab3f452267f62d2a0",
        "hdHdly": "3",
        "hdlc": "877.61"
    },
    {
        "mtmc": "民本矿业2号码头",
        "sdmc": "搁排矶水道",
        "x84": "115.406493,29.948657",
        "id": "WH_f1cd419d6eb04187b65c967a5f0209a8",
        "mt_id": "WH_f1cd419d6eb04187b65c967a5f0209a8",
        "hdHdly": "3",
        "hdlc": "861.50"
    },
    {
        "mtmc": "荆州港公安港区船舶污染物接收站码头",
        "sdmc": "马家寨水道",
        "x84": "112.276881,30.067604",
        "id": "WH_f325ef0e5e934db08286b54784dfaccd",
        "mt_id": "WH_f325ef0e5e934db08286b54784dfaccd",
        "hdHdly": "2",
        "hdlc": "441.00"
    },
    {
        "mtmc": "杨叶横堤码头",
        "sdmc": "黄石水道",
        "x84": "115.073212,30.276487",
        "id": "WH_f3c05c38238f47d78b815f0bcc10bfad",
        "mt_id": "WH_f3c05c38238f47d78b815f0bcc10bfad",
        "hdHdly": "3",
        "hdlc": "917.50"
    },
    {
        "mtmc": "散货码头",
        "sdmc": null,
        "x84": "",
        "id": "WH_f3c2f57bbc9f4098b67d4c74f91c993d",
        "mt_id": "WH_f3c2f57bbc9f4098b67d4c74f91c993d",
        "hdHdly": "3",
        "hdlc": "902.48"
    },
    {
        "mtmc": "华新水泥厂7号码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.441281,29.889552",
        "id": "WH_f5942f8dfd8b48cea2d717516c505193",
        "mt_id": "WH_f5942f8dfd8b48cea2d717516c505193",
        "hdHdly": "3",
        "hdlc": "854.10"
    },
    {
        "mtmc": "汉南港码头2#-4#泊位",
        "sdmc": "水洪口水道",
        "x84": "113.936397,30.304063",
        "id": "WH_f5e1fe5f3d0b4108b0db40485b007acd",
        "mt_id": "WH_f5e1fe5f3d0b4108b0db40485b007acd",
        "hdHdly": "2",
        "hdlc": "62.30"
    },
    {
        "mtmc": "岳纸煤码头",
        "sdmc": "仙峰水道",
        "x84": "113.151446,29.452851",
        "id": "WH_f61978c4890d40008c2b01244e651abd",
        "mt_id": "WH_f61978c4890d40008c2b01244e651abd",
        "hdHdly": "2",
        "hdlc": "228.80"
    },
    {
        "mtmc": "福兴建材码头",
        "sdmc": "瓦口子水道-下",
        "x84": "112.287760,30.238610",
        "id": "WH_f6bccd6d4e72462c9707c2cf279b512c",
        "mt_id": "WH_f6bccd6d4e72462c9707c2cf279b512c",
        "hdHdly": "2",
        "hdlc": "469.25"
    },
    {
        "mtmc": "荆岳大桥巡航救助基地",
        "sdmc": "道仁矶水道",
        "x84": "113.221883,29.538596",
        "id": "WH_f6f0cef57d46464894053ccb804af9dd",
        "mt_id": "WH_f6f0cef57d46464894053ccb804af9dd",
        "hdHdly": "2",
        "hdlc": "218.00"
    },
    {
        "mtmc": "洪湖市地方海事码头",
        "sdmc": "新堤水道",
        "x84": "113.456609,29.798060",
        "id": "WH_f78d67dfbfe14fc890b415bf36e90666",
        "mt_id": "WH_f78d67dfbfe14fc890b415bf36e90666",
        "hdHdly": "2",
        "hdlc": "180.40"
    },
    {
        "mtmc": "富地富江LNG加注码头",
        "sdmc": "沙洲水道",
        "x84": "114.82104,30.472991",
        "id": "WH_f7fe088da7f14fdd972c71355a26bc6d",
        "mt_id": "WH_f7fe088da7f14fdd972c71355a26bc6d",
        "hdHdly": "3",
        "hdlc": "956.40"
    },
    {
        "mtmc": "鑫源石化码头（原鄂石趸1）号",
        "sdmc": "阳逻水道",
        "x84": "114.561502,30.635825",
        "id": "WH_f8d0c48e32814fb8b1ab1fb5b9ea7186",
        "mt_id": "WH_f8d0c48e32814fb8b1ab1fb5b9ea7186",
        "hdHdly": "3",
        "hdlc": "1010.30"
    },
    {
        "mtmc": "巴河巡航救助码头",
        "sdmc": "巴河水道",
        "x84": "114.921555,30.415043",
        "id": "WH_f975e2b64cf140abbbc951e6f668ea9d",
        "mt_id": "WH_f975e2b64cf140abbbc951e6f668ea9d",
        "hdHdly": "3",
        "hdlc": "943.84"
    },
    {
        "mtmc": "岳阳铁水集运煤炭储备基地配套码头一期工程",
        "sdmc": "杨林岩水道",
        "x84": "113.263374,29.571075",
        "id": "WH_f9c8de4575f6484bbe7d6c342ecc92b9",
        "mt_id": "WH_f9c8de4575f6484bbe7d6c342ecc92b9",
        "hdHdly": "2",
        "hdlc": "213.00"
    },
    {
        "mtmc": "中国供销江陵综合码头",
        "sdmc": "郝穴水道",
        "x84": "112.37256,30.059982",
        "id": "WH_f9d45da14f8643199cb2a4f942b78d17",
        "mt_id": "WH_f9d45da14f8643199cb2a4f942b78d17",
        "hdHdly": "2",
        "hdlc": "431.70"
    },
    {
        "mtmc": "阳新水利防汛码头",
        "sdmc": "鲤鱼山水道",
        "x84": "115.435685,29.871007",
        "id": "WH_f9ed2a55ba0a4d8db6f3b192e7c1785c",
        "mt_id": "WH_f9ed2a55ba0a4d8db6f3b192e7c1785c",
        "hdHdly": "3",
        "hdlc": "851.73"
    },
    {
        "mtmc": "长江岳阳航道处综合码头（测绘中心）",
        "sdmc": "洞庭湖口",
        "x84": "113.137846,29.428768",
        "id": "WH_fb37cb40c7f14f00ad0323706bb4ac56",
        "mt_id": "WH_fb37cb40c7f14f00ad0323706bb4ac56",
        "hdHdly": "2",
        "hdlc": "230.00"
    },
    {
        "mtmc": "江北船厂",
        "sdmc": "巴河水道",
        "x84": "114.950819,30.414458",
        "id": "WH_fb582c6b09c549198e23b9872952dd3f",
        "mt_id": "WH_fb582c6b09c549198e23b9872952dd3f",
        "hdHdly": "3",
        "hdlc": "940.70"
    },
    {
        "mtmc": "武钢港口作业码头",
        "sdmc": "青山夹水道",
        "x84": "114.445922,30.669107",
        "id": "WH_fb5832409a004f8aababfd2fff1c3a35",
        "mt_id": "WH_fb5832409a004f8aababfd2fff1c3a35",
        "hdHdly": "3",
        "hdlc": "1023.80"
    },
    {
        "mtmc": "卸货码头",
        "sdmc": "罗湖洲水道",
        "x84": "114.748107,30.614496",
        "id": "WH_fbec427d5bd24158abc3dd8ae20e9b0a",
        "mt_id": "WH_fbec427d5bd24158abc3dd8ae20e9b0a",
        "hdHdly": "3",
        "hdlc": "984.26"
    },
    {
        "mtmc": "洪湖市新堤港区综合码头",
        "sdmc": "石头关水道",
        "x84": "113.534984,29.840355",
        "id": "WH_fc51536def75482c919b99d14da35b2f",
        "mt_id": "WH_fc51536def75482c919b99d14da35b2f",
        "hdHdly": "2",
        "hdlc": "171.00"
    },
    {
        "mtmc": "华能阳逻电厂码头",
        "sdmc": "阳逻水道",
        "x84": "114.536638,30.677927",
        "id": "WH_fde73b27968b4574a2b3fbe0aa7fe8c5",
        "mt_id": "WH_fde73b27968b4574a2b3fbe0aa7fe8c5",
        "hdHdly": "3",
        "hdlc": "1015.60"
    },
    {
        "mtmc": "沙市钢管厂码头",
        "sdmc": "瓦口子水道-上",
        "x84": "112.280517,30.277034",
        "id": "WH_fe07b30f8ec44514b0dde7357f1de602",
        "mt_id": "WH_fe07b30f8ec44514b0dde7357f1de602",
        "hdHdly": "2",
        "hdlc": "473.90"
    },
    {
        "mtmc": "长利玻璃码头",
        "sdmc": "金口水道",
        "x84": "114.099264,30.340754",
        "id": "WH_fe5db8a59e634af090eef0e26c22df34",
        "mt_id": "WH_fe5db8a59e634af090eef0e26c22df34",
        "hdHdly": "2",
        "hdlc": "31.80"
    },
    {
        "mtmc": "川渝码头",
        "sdmc": "沙洲水道",
        "x84": "114.821649,30.461234",
        "id": "WH_ff23ee92f59448e295634f7aacd9fcc4",
        "mt_id": "WH_ff23ee92f59448e295634f7aacd9fcc4",
        "hdHdly": "3",
        "hdlc": "955.10"
    },
    {
        "mtmc": "南溪区红光码头",
        "sdmc": "筲箕背水道",
        "x84": "",
        "id": "YB_ff8080816d6640fb016d66ed26c40031",
        "mt_id": "YB_ff8080816d6640fb016d66ed26c40031",
        "hdHdly": "1",
        "hdlc": "1011.20"
    },
    {
        "mtmc": "长宁香炉滩码头",
        "sdmc": "香炉滩水道",
        "x84": "105.019652,28.746156",
        "id": "YB_ff8080816d6640fb016d66f64ad60038",
        "mt_id": "YB_ff8080816d6640fb016d66f64ad60038",
        "hdHdly": "1",
        "hdlc": "981.10"
    },
    {
        "mtmc": "宜宾港重大件码头",
        "sdmc": "李庄水道",
        "x84": "104.758764,28.788109",
        "id": "YB_ff8080816df66302016dfc8d95fc0046",
        "mt_id": "YB_ff8080816df66302016dfc8d95fc0046",
        "hdHdly": "1",
        "hdlc": "1028.00"
    },
    {
        "mtmc": "宜宾港滚装码头",
        "sdmc": "李庄水道",
        "x84": "104.758167,28.786719",
        "id": "YB_ff8080816df66302016dfc8fe5250047",
        "mt_id": "YB_ff8080816df66302016dfc8fe5250047",
        "hdHdly": "1",
        "hdlc": "1027.80"
    },
    {
        "mtmc": "宜宾港志城作业区散货泊位",
        "sdmc": "李庄水道",
        "x84": "104.772873,28.797086",
        "id": "YB_ff8080816df66302016dfc9305d20048",
        "mt_id": "YB_ff8080816df66302016dfc9305d20048",
        "hdHdly": "1",
        "hdlc": "1026.90"
    },
    {
        "mtmc": "宜昌港秭归港区王家嘴砂石集并中心码头",
        "sdmc": "牛口水道",
        "x84": "110.457766,31.023538",
        "id": "YC_8a8080fa742443d30174427d02a90002",
        "mt_id": "YC_8a8080fa742443d30174427d02a90002",
        "hdHdly": "1",
        "hdlc": "107.50"
    },
    {
        "mtmc": "宜都市红花套砂石集并中心码头工程",
        "sdmc": "云池水道",
        "x84": "111.410530319,30.510849816",
        "id": "YC_8a8080fa742443d30174439706e80003",
        "mt_id": "YC_8a8080fa742443d30174439706e80003",
        "hdHdly": "2",
        "hdlc": "604.00"
    },
    {
        "mtmc": "宜昌港秭归港区三峡库区秭归县水运应用LNG项目码头",
        "sdmc": "归州水道",
        "x84": "110.686098,31.003334",
        "id": "YC_8a8080fa77195724017e6baaa3380849",
        "mt_id": "YC_8a8080fa77195724017e6baaa3380849",
        "hdHdly": "1",
        "hdlc": "86.00"
    },
    {
        "mtmc": "长江秭归航道处香溪长江码头",
        "sdmc": "归州水道",
        "x84": "110.74912,30.957077",
        "id": "YC_8a8080fa77195724017e6c70b22f084a",
        "mt_id": "YC_8a8080fa77195724017e6c70b22f084a",
        "hdHdly": "1",
        "hdlc": "78.00"
    },
    {
        "mtmc": "归州客运码头",
        "sdmc": "归州水道",
        "x84": "110.712099,30.9789",
        "id": "YC_8a8080fa77195724017e6c7eb2c1084b",
        "mt_id": "YC_8a8080fa77195724017e6c7eb2c1084b",
        "hdHdly": "1",
        "hdlc": "82.00"
    },
    {
        "mtmc": "荆州港松滋港区松滋丽源码头",
        "sdmc": "关洲水道",
        "x84": "111.619179925,30.301410395",
        "id": "YC_8a8080fa77195724017e7534ecfa0882",
        "mt_id": "YC_8a8080fa77195724017e7534ecfa0882",
        "hdHdly": "2",
        "hdlc": "555.20"
    },
    {
        "mtmc": "姚家港煤炭专用码头",
        "sdmc": "芦家河水道",
        "x84": ",",
        "id": "YC_8a8080fa77195724017e756bd23a0883",
        "mt_id": "YC_8a8080fa77195724017e756bd23a0883",
        "hdHdly": "2",
        "hdlc": "545.20"
    },
    {
        "mtmc": "巴东港主城港区船舶污染物接收转运码头",
        "sdmc": "巴东水道",
        "x84": "110.384106,31.047084",
        "id": "YC_8a8080fa77195724017e7a947c4b088c",
        "mt_id": "YC_8a8080fa77195724017e7a947c4b088c",
        "hdHdly": "1",
        "hdlc": "116.00"
    },
    {
        "mtmc": "巴东县地方海事、水利局码头",
        "sdmc": "巴东水道",
        "x84": "110.336090,31.049143",
        "id": "YC_8a8080fa77195724017e7a9e41c1088d",
        "mt_id": "YC_8a8080fa77195724017e7a9e41c1088d",
        "hdHdly": "1",
        "hdlc": "121.70"
    },
    {
        "mtmc": "荣成配套专用煤炭码头",
        "sdmc": "关洲水道",
        "x84": "111.58987,30.26368",
        "id": "YC_ff8080816d5d80bc016d623304810028",
        "mt_id": "YC_ff8080816d5d80bc016d623304810028",
        "hdHdly": "2",
        "hdlc": "560.00"
    },
    {
        "mtmc": "荆州港松滋港区车阳河综合码头",
        "sdmc": "关洲水道",
        "x84": "111.5821,30.2582",
        "id": "YC_ff8080816d5d80bc016d6236a6b10031",
        "mt_id": "YC_ff8080816d5d80bc016d6236a6b10031",
        "hdHdly": "2",
        "hdlc": "561.00"
    },
    {
        "mtmc": "荆州港松滋港区车阳河综合码头二期",
        "sdmc": "关洲水道",
        "x84": "111.575758,30.2566",
        "id": "YC_ff8080816d5d80bc016d6242e941003e",
        "mt_id": "YC_ff8080816d5d80bc016d6242e941003e",
        "hdHdly": "2",
        "hdlc": "562.00"
    },
    {
        "mtmc": "飞强物流码头",
        "sdmc": "关洲水道",
        "x84": "111.556528,30.257168",
        "id": "YC_ff8080816d5d80bc016d624a2bad004a",
        "mt_id": "YC_ff8080816d5d80bc016d624a2bad004a",
        "hdHdly": "2",
        "hdlc": "564.00"
    },
    {
        "mtmc": "宜都市装卸运输总公司危化码头",
        "sdmc": "关洲水道",
        "x84": "111.550882,30.260277",
        "id": "YC_ff8080816d5d80bc016d62585d060057",
        "mt_id": "YC_ff8080816d5d80bc016d62585d060057",
        "hdHdly": "2",
        "hdlc": "564.90"
    },
    {
        "mtmc": "宜昌港宜都港区石鼓作业区综合码头",
        "sdmc": "关洲水道",
        "x84": "111.537917,30.270398",
        "id": "YC_ff8080816d5d80bc016d625e48ea0058",
        "mt_id": "YC_ff8080816d5d80bc016d625e48ea0058",
        "hdHdly": "2",
        "hdlc": "566.00"
    },
    {
        "mtmc": "枝城港18码头",
        "sdmc": "枝城水道",
        "x84": "111.53701,30.271818",
        "id": "YC_ff8080816d5d80bc016d6260887b0059",
        "mt_id": "YC_ff8080816d5d80bc016d6260887b0059",
        "hdHdly": "2",
        "hdlc": "566.20"
    },
    {
        "mtmc": "枝城港17码头",
        "sdmc": "枝城水道",
        "x84": "111.534693,30.273794",
        "id": "YC_ff8080816d5d80bc016d62625942005a",
        "mt_id": "YC_ff8080816d5d80bc016d62625942005a",
        "hdHdly": "2",
        "hdlc": "566.50"
    },
    {
        "mtmc": "枝城港17附码头",
        "sdmc": "枝城水道",
        "x84": "111.533874,30.274302",
        "id": "YC_ff8080816d5d80bc016d6265dcc6005b",
        "mt_id": "YC_ff8080816d5d80bc016d6265dcc6005b",
        "hdHdly": "2",
        "hdlc": "566.70"
    },
    {
        "mtmc": "枝城港16附码头",
        "sdmc": "枝城水道",
        "x84": "111.531755,30.276422",
        "id": "YC_ff8080816d5d80bc016d62683c34005c",
        "mt_id": "YC_ff8080816d5d80bc016d62683c34005c",
        "hdHdly": "2",
        "hdlc": "566.90"
    },
    {
        "mtmc": "枝城港15附码头",
        "sdmc": "枝城水道",
        "x84": "111.530221,30.277059",
        "id": "YC_ff8080816d5d80bc016d626a7a22005d",
        "mt_id": "YC_ff8080816d5d80bc016d626a7a22005d",
        "hdHdly": "2",
        "hdlc": "567.00"
    },
    {
        "mtmc": "枝城港15码头",
        "sdmc": "枝城水道",
        "x84": "111.529341,30.277694",
        "id": "YC_ff8080816d5d80bc016d626cd367005e",
        "mt_id": "YC_ff8080816d5d80bc016d626cd367005e",
        "hdHdly": "2",
        "hdlc": "567.20"
    },
    {
        "mtmc": "枝城港13码头",
        "sdmc": "枝城水道",
        "x84": "111.527477,30.279064",
        "id": "YC_ff8080816d5d80bc016d626ed735005f",
        "mt_id": "YC_ff8080816d5d80bc016d626ed735005f",
        "hdHdly": "2",
        "hdlc": "567.40"
    },
    {
        "mtmc": "枝城港12码头",
        "sdmc": "枝城水道",
        "x84": "111.526007,30.280062",
        "id": "YC_ff8080816d5d80bc016d62714d050060",
        "mt_id": "YC_ff8080816d5d80bc016d62714d050060",
        "hdHdly": "2",
        "hdlc": "567.60"
    },
    {
        "mtmc": "枝城航发砂石集并中心",
        "sdmc": "枝城水道",
        "x84": "111.548977279,30.261804145",
        "id": "YC_ff8080816d5d80bc016d6273b30b0061",
        "mt_id": "YC_ff8080816d5d80bc016d6273b30b0061",
        "hdHdly": "2",
        "hdlc": "567.60"
    },
    {
        "mtmc": "枝城港5码头",
        "sdmc": "枝城水道",
        "x84": "111.5139,30.2906",
        "id": "YC_ff8080816d5d80bc016d62764ed40062",
        "mt_id": "YC_ff8080816d5d80bc016d62764ed40062",
        "hdHdly": "2",
        "hdlc": "569.00"
    },
    {
        "mtmc": "宜总装趸7号",
        "sdmc": "枝城水道",
        "x84": "111.512587,30.291833",
        "id": "YC_ff8080816d5d80bc016d627874f70063",
        "mt_id": "YC_ff8080816d5d80bc016d627874f70063",
        "hdHdly": "2",
        "hdlc": "569.40"
    },
    {
        "mtmc": "沙坨皮带机码头",
        "sdmc": "枝城水道",
        "x84": "111.5114,30.2929",
        "id": "YC_ff8080816d5d80bc016d627abcce0064",
        "mt_id": "YC_ff8080816d5d80bc016d627abcce0064",
        "hdHdly": "2",
        "hdlc": "569.50"
    },
    {
        "mtmc": "枝城杂件码头",
        "sdmc": "枝城水道",
        "x84": "111.5107,30.2938",
        "id": "YC_ff8080816d5d80bc016d627ccf8b0065",
        "mt_id": "YC_ff8080816d5d80bc016d627ccf8b0065",
        "hdHdly": "2",
        "hdlc": "569.60"
    },
    {
        "mtmc": "宜总装趸13号",
        "sdmc": "枝城水道",
        "x84": "111.5101,30.2945",
        "id": "YC_ff8080816d5d80bc016d627f3a570066",
        "mt_id": "YC_ff8080816d5d80bc016d627f3a570066",
        "hdHdly": "2",
        "hdlc": "569.80"
    },
    {
        "mtmc": "宜总装趸9号",
        "sdmc": "枝城水道",
        "x84": "111.509538,30.295083",
        "id": "YC_ff8080816d5d80bc016d628129210067",
        "mt_id": "YC_ff8080816d5d80bc016d628129210067",
        "hdHdly": "2",
        "hdlc": "569.90"
    },
    {
        "mtmc": "航道码头",
        "sdmc": "枝城水道",
        "x84": "111.50911,30.29557",
        "id": "YC_ff8080816d5d80bc016d6284583b0068",
        "mt_id": "YC_ff8080816d5d80bc016d6284583b0068",
        "hdHdly": "2",
        "hdlc": "570.00"
    },
    {
        "mtmc": "秭归县香溪汽车轮渡北岸",
        "sdmc": "归州水道",
        "x84": "110.753953,30.966296",
        "id": "YC_ff8080816d5d80bc016d65cfac0c0078",
        "mt_id": "YC_ff8080816d5d80bc016d65cfac0c0078",
        "hdHdly": "1",
        "hdlc": "77.60"
    },
    {
        "mtmc": "秭归县香溪汽车轮渡南岸",
        "sdmc": "归州水道",
        "x84": "110.752207,30.957543",
        "id": "YC_ff8080816d5d80bc016d65e18f210079",
        "mt_id": "YC_ff8080816d5d80bc016d65e18f210079",
        "hdHdly": "1",
        "hdlc": "77.50"
    },
    {
        "mtmc": "秭归县屈原镇汽车轮渡北岸",
        "sdmc": "青滩水道",
        "x84": "110.821924,30.928326",
        "id": "YC_ff8080816d5d80bc016d65e6451c007a",
        "mt_id": "YC_ff8080816d5d80bc016d65e6451c007a",
        "hdHdly": "1",
        "hdlc": "70.00"
    },
    {
        "mtmc": "水文码头",
        "sdmc": "枝城水道",
        "x84": "111.5087,30.2957",
        "id": "YC_ff8080816d5d80bc016d65e6d208007b",
        "mt_id": "YC_ff8080816d5d80bc016d65e6d208007b",
        "hdHdly": "2",
        "hdlc": "570.10"
    },
    {
        "mtmc": "枝城3码头",
        "sdmc": "枝城水道",
        "x84": "111.506966,30.298332",
        "id": "YC_ff8080816d5d80bc016d65e8f49a007c",
        "mt_id": "YC_ff8080816d5d80bc016d65e8f49a007c",
        "hdHdly": "2",
        "hdlc": "570.20"
    },
    {
        "mtmc": "枝城海事码头",
        "sdmc": "枝城水道",
        "x84": "111.506089,30.299661",
        "id": "YC_ff8080816d5d80bc016d65ea9638007d",
        "mt_id": "YC_ff8080816d5d80bc016d65ea9638007d",
        "hdHdly": "2",
        "hdlc": "570.60"
    },
    {
        "mtmc": "白水港1号泊位",
        "sdmc": "枝城水道",
        "x84": "111.496354,30.311028",
        "id": "YC_ff8080816d5d80bc016d65ec64ff007e",
        "mt_id": "YC_ff8080816d5d80bc016d65ec64ff007e",
        "hdHdly": "2",
        "hdlc": "571.80"
    },
    {
        "mtmc": "秭归县屈原镇汽车轮渡南岸",
        "sdmc": "青滩水道",
        "x84": "110.809782,30.925801",
        "id": "YC_ff8080816d5d80bc016d65edcf19007f",
        "mt_id": "YC_ff8080816d5d80bc016d65edcf19007f",
        "hdHdly": "1",
        "hdlc": "70.80"
    },
    {
        "mtmc": "华新水泥厂码头",
        "sdmc": "龙窝水道",
        "x84": "111.495347,30.321739",
        "id": "YC_ff8080816d5d80bc016d65eefd420080",
        "mt_id": "YC_ff8080816d5d80bc016d65eefd420080",
        "hdHdly": "2",
        "hdlc": "572.00"
    },
    {
        "mtmc": "东阳光火力发电楼子河码头",
        "sdmc": "龙窝水道",
        "x84": "111.494626,30.32847667666",
        "id": "YC_ff8080816d5d80bc016d65f142cc0081",
        "mt_id": "YC_ff8080816d5d80bc016d65f142cc0081",
        "hdHdly": "2",
        "hdlc": "576.00"
    },
    {
        "mtmc": "宏顺码头",
        "sdmc": "龙窝水道",
        "x84": "111.5101,30.3631",
        "id": "YC_ff8080816d5d80bc016d65f34a0e0082",
        "mt_id": "YC_ff8080816d5d80bc016d65f34a0e0082",
        "hdHdly": "2",
        "hdlc": "577.50"
    },
    {
        "mtmc": "祥和码头",
        "sdmc": "龙窝水道",
        "x84": "111.5116,30.3651",
        "id": "YC_ff8080816d5d80bc016d65f53f610083",
        "mt_id": "YC_ff8080816d5d80bc016d65f53f610083",
        "hdHdly": "2",
        "hdlc": "578.00"
    },
    {
        "mtmc": "四海船务码头",
        "sdmc": "龙窝水道",
        "x84": "111.5155,30.4047",
        "id": "YC_ff8080816d5d80bc016d65f705e30084",
        "mt_id": "YC_ff8080816d5d80bc016d65f705e30084",
        "hdHdly": "2",
        "hdlc": "583.00"
    },
    {
        "mtmc": "满胜贵码头",
        "sdmc": "龙窝水道",
        "x84": "111.5132,30.3676",
        "id": "YC_ff8080816d5d80bc016d65fe711b0085",
        "mt_id": "YC_ff8080816d5d80bc016d65fe711b0085",
        "hdHdly": "2",
        "hdlc": "578.30"
    },
    {
        "mtmc": "孙家河海事码头",
        "sdmc": "白洋水道",
        "x84": "111.50612,30.410892",
        "id": "YC_ff8080816d5d80bc016d6600657c0086",
        "mt_id": "YC_ff8080816d5d80bc016d6600657c0086",
        "hdHdly": "2",
        "hdlc": "584.20"
    },
    {
        "mtmc": "孙家河煤炭经营站码头",
        "sdmc": "白洋水道",
        "x84": "111.501858,30.411694",
        "id": "YC_ff8080816d5d80bc016d6602b6b20087",
        "mt_id": "YC_ff8080816d5d80bc016d6602b6b20087",
        "hdHdly": "2",
        "hdlc": "584.50"
    },
    {
        "mtmc": "孙家河汽渡码头",
        "sdmc": "白洋水道",
        "x84": "111.499397,30.410873",
        "id": "YC_ff8080816d5d80bc016d6605217a0088",
        "mt_id": "YC_ff8080816d5d80bc016d6605217a0088",
        "hdHdly": "2",
        "hdlc": "585.10"
    },
    {
        "mtmc": "驿马冲砂石集并中心码头",
        "sdmc": "白洋水道",
        "x84": "111.4916,30.4094",
        "id": "YC_ff8080816d5d80bc016d66095fc00089",
        "mt_id": "YC_ff8080816d5d80bc016d66095fc00089",
        "hdHdly": "2",
        "hdlc": "586.50"
    },
    {
        "mtmc": "祥印码头",
        "sdmc": "宜都水道（宜都）",
        "x84": "111.4315,30.4160",
        "id": "YC_ff8080816d5d80bc016d66111807008b",
        "mt_id": "YC_ff8080816d5d80bc016d66111807008b",
        "hdHdly": "2",
        "hdlc": "592.10"
    },
    {
        "mtmc": "中光货场码头",
        "sdmc": "宜都水道（宜都）",
        "x84": "111.4301,30.4196",
        "id": "YC_ff8080816d5d80bc016d66264ade008d",
        "mt_id": "YC_ff8080816d5d80bc016d66264ade008d",
        "hdHdly": "2",
        "hdlc": "593.00"
    },
    {
        "mtmc": "白洋汽渡",
        "sdmc": "白洋水道",
        "x84": "111.508722,30.418838",
        "id": "YC_ff8080816d5d80bc016d662f40a7008e",
        "mt_id": "YC_ff8080816d5d80bc016d662f40a7008e",
        "hdHdly": "2",
        "hdlc": "584.30"
    },
    {
        "mtmc": "山移港埠码头",
        "sdmc": "白洋水道",
        "x84": "111.503412,30.420501",
        "id": "YC_ff8080816d5d80bc016d663383cc008f",
        "mt_id": "YC_ff8080816d5d80bc016d663383cc008f",
        "hdHdly": "2",
        "hdlc": "585.20"
    },
    {
        "mtmc": "宜昌港白洋作业区综合码头",
        "sdmc": "白洋水道",
        "x84": "111.477357,30.41782",
        "id": "YC_ff8080816d5d80bc016d6638036f0090",
        "mt_id": "YC_ff8080816d5d80bc016d6638036f0090",
        "hdHdly": "2",
        "hdlc": "587.00"
    },
    {
        "mtmc": "云池深水港14号码头",
        "sdmc": "云池水道",
        "x84": "111.450126,30.467440",
        "id": "YC_ff8080816d5d80bc016d667dc8ef00e0",
        "mt_id": "YC_ff8080816d5d80bc016d667dc8ef00e0",
        "hdHdly": "2",
        "hdlc": "598.20"
    },
    {
        "mtmc": "宜昌港务集团云池深水码头",
        "sdmc": "云池水道",
        "x84": "111.448652,30.47245",
        "id": "YC_ff8080816d5d80bc016d6686f83800e4",
        "mt_id": "YC_ff8080816d5d80bc016d6686f83800e4",
        "hdHdly": "2",
        "hdlc": "598.60"
    },
    {
        "mtmc": "云池深水港6#码头",
        "sdmc": "云池水道",
        "x84": "111.447528,30.474880",
        "id": "YC_ff8080816d5d80bc016d6689b41900e6",
        "mt_id": "YC_ff8080816d5d80bc016d6689b41900e6",
        "hdHdly": "2",
        "hdlc": "599.20"
    },
    {
        "mtmc": "宜化港趸1号",
        "sdmc": "云池水道",
        "x84": "111.444695,30.481691",
        "id": "YC_ff8080816d5d80bc016d66c7e10200ed",
        "mt_id": "YC_ff8080816d5d80bc016d66c7e10200ed",
        "hdHdly": "2",
        "hdlc": "599.80"
    },
    {
        "mtmc": "大江码头",
        "sdmc": "云池水道",
        "x84": "111.444428,30.482531",
        "id": "YC_ff8080816d5d80bc016d66d0631100ef",
        "mt_id": "YC_ff8080816d5d80bc016d66d0631100ef",
        "hdHdly": "2",
        "hdlc": "599.90"
    },
    {
        "mtmc": "西蓝水上加气站码头",
        "sdmc": "云池水道",
        "x84": "111.42043,30.498973",
        "id": "YC_ff8080816d5d80bc016d66d2e99e00f1",
        "mt_id": "YC_ff8080816d5d80bc016d66d2e99e00f1",
        "hdHdly": "2",
        "hdlc": "602.50"
    },
    {
        "mtmc": "葛缘码头",
        "sdmc": "云池水道",
        "x84": "111.417296,30.501843",
        "id": "YC_ff8080816d5d80bc016d66d7e8d900f2",
        "mt_id": "YC_ff8080816d5d80bc016d66d7e8d900f2",
        "hdHdly": "2",
        "hdlc": "603.10"
    },
    {
        "mtmc": "宏拓码头",
        "sdmc": "云池水道",
        "x84": "111.414168,30.505826",
        "id": "YC_ff8080816d5d80bc016d66dae56600f3",
        "mt_id": "YC_ff8080816d5d80bc016d66dae56600f3",
        "hdHdly": "2",
        "hdlc": "603.40"
    },
    {
        "mtmc": "三峡全通专用码头",
        "sdmc": "云池水道",
        "x84": "111.420414,30.512378",
        "id": "YC_ff8080816d5d80bc016d66de0af700f5",
        "mt_id": "YC_ff8080816d5d80bc016d66de0af700f5",
        "hdHdly": "2",
        "hdlc": "603.60"
    },
    {
        "mtmc": "鑫通码头",
        "sdmc": "云池水道",
        "x84": "111.415820,30.503754",
        "id": "YC_ff8080816d670f70016d672b3fa50002",
        "mt_id": "YC_ff8080816d670f70016d672b3fa50002",
        "hdHdly": "2",
        "hdlc": "605.00"
    },
    {
        "mtmc": "宜昌港宜都港区红花套作业区综合码头",
        "sdmc": "古老背水道",
        "x84": "111.40726,30.518337",
        "id": "YC_ff8080816d670f70016d672f829f0005",
        "mt_id": "YC_ff8080816d670f70016d672f829f0005",
        "hdHdly": "2",
        "hdlc": "605.00"
    },
    {
        "mtmc": "宜都市农洋公贸有限公司码头",
        "sdmc": "古老背水道",
        "x84": "111.403724,30.527548",
        "id": "YC_ff8080816d670f70016d6733ffa30007",
        "mt_id": "YC_ff8080816d670f70016d6733ffa30007",
        "hdHdly": "2",
        "hdlc": "605.50"
    },
    {
        "mtmc": "红港砂石场码头",
        "sdmc": "古老背水道",
        "x84": "111.413072,30.525291",
        "id": "YC_ff8080816d670f70016d674901bc000e",
        "mt_id": "YC_ff8080816d670f70016d674901bc000e",
        "hdHdly": "2",
        "hdlc": "605.60"
    },
    {
        "mtmc": "古老背客渡码头",
        "sdmc": "古老背水道",
        "x84": "111.410246,30.530470",
        "id": "YC_ff8080816d670f70016d674c9429000f",
        "mt_id": "YC_ff8080816d670f70016d674c9429000f",
        "hdHdly": "2",
        "hdlc": "606.10"
    },
    {
        "mtmc": "海汇综合一期码头",
        "sdmc": "古老背水道",
        "x84": "111.407766,30.542502",
        "id": "YC_ff8080816d670f70016d675075940011",
        "mt_id": "YC_ff8080816d670f70016d675075940011",
        "hdHdly": "2",
        "hdlc": "607.00"
    },
    {
        "mtmc": "海汇综合二期码头",
        "sdmc": "古老背水道",
        "x84": "111.405986,30.545903",
        "id": "YC_ff8080816d670f70016d67543a530013",
        "mt_id": "YC_ff8080816d670f70016d67543a530013",
        "hdHdly": "2",
        "hdlc": "607.50"
    },
    {
        "mtmc": "宝塔号水厂趸船",
        "sdmc": "古老背水道",
        "x84": "111.405162,30.54747",
        "id": "YC_ff8080816d670f70016d6757eb9b0015",
        "mt_id": "YC_ff8080816d670f70016d6757eb9b0015",
        "hdHdly": "2",
        "hdlc": "608.10"
    },
    {
        "mtmc": "通达码头",
        "sdmc": "古老背水道",
        "x84": "111.404728,30.548778",
        "id": "YC_ff8080816d670f70016d675baffa0016",
        "mt_id": "YC_ff8080816d670f70016d675baffa0016",
        "hdHdly": "2",
        "hdlc": "608.40"
    },
    {
        "mtmc": "兴发宜趸02",
        "sdmc": "古老背水道",
        "x84": "111.404589,30.550087",
        "id": "YC_ff8080816d670f70016d6760deb20018",
        "mt_id": "YC_ff8080816d670f70016d6760deb20018",
        "hdHdly": "2",
        "hdlc": "608.50"
    },
    {
        "mtmc": "兴发宜趸01",
        "sdmc": "古老背水道",
        "x84": "111.401255,30.555474",
        "id": "YC_ff8080816d670f70016d6764f3c6001a",
        "mt_id": "YC_ff8080816d670f70016d6764f3c6001a",
        "hdHdly": "2",
        "hdlc": "608.60"
    },
    {
        "mtmc": "宜都顺发物流有限公司码头",
        "sdmc": "古老背水道",
        "x84": "111.392764,30.549711",
        "id": "YC_ff8080816d670f70016d676af512001c",
        "mt_id": "YC_ff8080816d670f70016d676af512001c",
        "hdHdly": "2",
        "hdlc": "608.80"
    },
    {
        "mtmc": "兴发宜趸03",
        "sdmc": "古老背水道",
        "x84": "111.40038,30.558255",
        "id": "YC_ff8080816d670f70016d676e57ae001d",
        "mt_id": "YC_ff8080816d670f70016d676e57ae001d",
        "hdHdly": "2",
        "hdlc": "609.50"
    },
    {
        "mtmc": "葛洲坝机械有限公司码头",
        "sdmc": "虎牙峡水道",
        "x84": "111.398513,30.56305",
        "id": "YC_ff8080816d670f70016d67747c7b001f",
        "mt_id": "YC_ff8080816d670f70016d67747c7b001f",
        "hdHdly": "2",
        "hdlc": "610.10"
    },
    {
        "mtmc": "七星台作业区综合码头",
        "sdmc": "大埠街水道",
        "x84": "111.907109,30.396949",
        "id": "YC_ff8080816d670f70016d677527170020",
        "mt_id": "YC_ff8080816d670f70016d677527170020",
        "hdHdly": "2",
        "hdlc": "519.70"
    },
    {
        "mtmc": "鸿胜达货运码头",
        "sdmc": "大埠街水道",
        "x84": "111.896243,30.405984",
        "id": "YC_ff8080816d670f70016d6776bc180021",
        "mt_id": "YC_ff8080816d670f70016d6776bc180021",
        "hdHdly": "2",
        "hdlc": "521.00"
    },
    {
        "mtmc": "七星台海事码头　",
        "sdmc": "大埠街水道",
        "x84": "111.887457,30.411263",
        "id": "YC_ff8080816d670f70016d6778d9280023",
        "mt_id": "YC_ff8080816d670f70016d6778d9280023",
        "hdHdly": "2",
        "hdlc": "522.20"
    },
    {
        "mtmc": "旱雨码头",
        "sdmc": "江口水道",
        "x84": "111.836122,30.429323",
        "id": "YC_ff8080816d670f70016d677a5f340024",
        "mt_id": "YC_ff8080816d670f70016d677a5f340024",
        "hdHdly": "2",
        "hdlc": "526.90"
    },
    {
        "mtmc": "长江宜昌航道船舶基地管理处码头",
        "sdmc": "古老背水道",
        "x84": "111.390093,30.555664",
        "id": "YC_ff8080816d670f70016d677ec4ce0026",
        "mt_id": "YC_ff8080816d670f70016d677ec4ce0026",
        "hdHdly": "2",
        "hdlc": "610.00"
    },
    {
        "mtmc": "聚鑫码头",
        "sdmc": "江口水道",
        "x84": "111.836978,30.429751",
        "id": "YC_ff8080816d67bb7d016d683b6a320008",
        "mt_id": "YC_ff8080816d67bb7d016d683b6a320008",
        "hdHdly": "2",
        "hdlc": "526.80"
    },
    {
        "mtmc": "恒力水泥码头",
        "sdmc": "江口水道",
        "x84": "111.860474,30.431221",
        "id": "YC_ff8080816d67bb7d016d683cfdb00009",
        "mt_id": "YC_ff8080816d67bb7d016d683cfdb00009",
        "hdHdly": "2",
        "hdlc": "524.80"
    },
    {
        "mtmc": "宝筏寺汽渡码头",
        "sdmc": "刘巷水道",
        "x84": "111.788967,30.418872",
        "id": "YC_ff8080816d67bb7d016d68410205000b",
        "mt_id": "YC_ff8080816d67bb7d016d68410205000b",
        "hdHdly": "2",
        "hdlc": "531.90"
    },
    {
        "mtmc": "宏盛货运码头",
        "sdmc": "刘巷水道",
        "x84": "111.777204,30.420085",
        "id": "YC_ff8080816d67bb7d016d68427fe1000c",
        "mt_id": "YC_ff8080816d67bb7d016d68427fe1000c",
        "hdHdly": "2",
        "hdlc": "533.00"
    },
    {
        "mtmc": "飞鹰客渡码头",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.75839,30.421723",
        "id": "YC_ff8080816d67bb7d016d684421d8000d",
        "mt_id": "YC_ff8080816d67bb7d016d684421d8000d",
        "hdHdly": "2",
        "hdlc": "534.80"
    },
    {
        "mtmc": "省化码头（长枝趸B002）",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.764315,30.421542",
        "id": "YC_ff8080816d67bb7d016d6845f3a2000e",
        "mt_id": "YC_ff8080816d67bb7d016d6845f3a2000e",
        "hdHdly": "2",
        "hdlc": "534.40"
    },
    {
        "mtmc": "省化码头（长枝趸B003）",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.768852,30.421537",
        "id": "YC_ff8080816d67bb7d016d684730be000f",
        "mt_id": "YC_ff8080816d67bb7d016d684730be000f",
        "hdHdly": "2",
        "hdlc": "533.80"
    },
    {
        "mtmc": "省化码头（长枝趸B004）",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.764286,30.421572",
        "id": "YC_ff8080816d67bb7d016d6848c6930010",
        "mt_id": "YC_ff8080816d67bb7d016d6848c6930010",
        "hdHdly": "2",
        "hdlc": "534.60"
    },
    {
        "mtmc": "滕家河散货码头（马家店散货码头）",
        "sdmc": "刘巷水道",
        "x84": "111.774662,30.420679",
        "id": "YC_ff8080816d67bb7d016d684a80250011",
        "mt_id": "YC_ff8080816d67bb7d016d684a80250011",
        "hdHdly": "2",
        "hdlc": "533.30"
    },
    {
        "mtmc": "省化码头（长枝趸B001）",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.765442,30.421567",
        "id": "YC_ff8080816d67bb7d016d684bdcd80012",
        "mt_id": "YC_ff8080816d67bb7d016d684bdcd80012",
        "hdHdly": "2",
        "hdlc": "534.30"
    },
    {
        "mtmc": "长航公安码头",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.745978,30.420889",
        "id": "YC_ff8080816d67bb7d016d684d093b0013",
        "mt_id": "YC_ff8080816d67bb7d016d684d093b0013",
        "hdHdly": "2",
        "hdlc": "535.90"
    },
    {
        "mtmc": "枝江水政码头",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.732808,30.418197",
        "id": "YC_ff8080816d67bb7d016d684f04980014",
        "mt_id": "YC_ff8080816d67bb7d016d684f04980014",
        "hdHdly": "2",
        "hdlc": "536.70"
    },
    {
        "mtmc": "舟江鱼舫码头",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.744984,30.420752",
        "id": "YC_ff8080816d67bb7d016d685049140015",
        "mt_id": "YC_ff8080816d67bb7d016d685049140015",
        "hdHdly": "2",
        "hdlc": "536.10"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.741696,30.420331",
        "id": "YC_ff8080816d67bb7d016d6851a43c0016",
        "mt_id": "YC_ff8080816d67bb7d016d6851a43c0016",
        "hdHdly": "2",
        "hdlc": "536.40"
    },
    {
        "mtmc": "航道码头",
        "sdmc": "枝江水道（枝江）",
        "x84": "111.745839,30.420903",
        "id": "YC_ff8080816d67bb7d016d6852e3010017",
        "mt_id": "YC_ff8080816d67bb7d016d6852e3010017",
        "hdHdly": "2",
        "hdlc": "536.00"
    },
    {
        "mtmc": "姚家港综合码头",
        "sdmc": "芦家河水道",
        "x84": "111.644056,30.375909",
        "id": "YC_ff8080816d67bb7d016d6854de0f0018",
        "mt_id": "YC_ff8080816d67bb7d016d6854de0f0018",
        "hdHdly": "2",
        "hdlc": "546.40"
    },
    {
        "mtmc": "三宁化工码头4号趸",
        "sdmc": "芦家河水道",
        "x84": "111.639526,30.371699",
        "id": "YC_ff8080816d67bb7d016d6857d475001a",
        "mt_id": "YC_ff8080816d67bb7d016d6857d475001a",
        "hdHdly": "2",
        "hdlc": "547.40"
    },
    {
        "mtmc": "兴港装卸码头二码头",
        "sdmc": "芦家河水道",
        "x84": "111.640912,30.372958",
        "id": "YC_ff8080816d67bb7d016d685a42f1001b",
        "mt_id": "YC_ff8080816d67bb7d016d685a42f1001b",
        "hdHdly": "2",
        "hdlc": "547.20"
    },
    {
        "mtmc": "姚港航道码头",
        "sdmc": "芦家河水道",
        "x84": "111.640111,30.372131",
        "id": "YC_ff8080816d67bb7d016d685baffb001c",
        "mt_id": "YC_ff8080816d67bb7d016d685baffb001c",
        "hdHdly": "2",
        "hdlc": "547.30"
    },
    {
        "mtmc": "三宁危化码头",
        "sdmc": "芦家河水道",
        "x84": "111.635463,30.367284",
        "id": "YC_ff8080816d67bb7d016d685e6baf001e",
        "mt_id": "YC_ff8080816d67bb7d016d685e6baf001e",
        "hdHdly": "2",
        "hdlc": "547.80"
    },
    {
        "mtmc": "三宁化工码头3号趸",
        "sdmc": "芦家河水道",
        "x84": "111.638519,30.370641",
        "id": "YC_ff8080816d67bb7d016d685fff8a001f",
        "mt_id": "YC_ff8080816d67bb7d016d685fff8a001f",
        "hdHdly": "2",
        "hdlc": "547.50"
    },
    {
        "mtmc": "姚家港作业区临时重件码头",
        "sdmc": "芦家河水道",
        "x84": "111.627476,30.361038",
        "id": "YC_ff8080816d67bb7d016d686140bc0020",
        "mt_id": "YC_ff8080816d67bb7d016d686140bc0020",
        "hdHdly": "2",
        "hdlc": "549.10"
    },
    {
        "mtmc": "毛家场码头",
        "sdmc": "芦家河水道",
        "x84": "111.626203,30.359041",
        "id": "YC_ff8080816d67bb7d016d686273880021",
        "mt_id": "YC_ff8080816d67bb7d016d686273880021",
        "hdHdly": "2",
        "hdlc": "549.20"
    },
    {
        "mtmc": "松滋海事码头",
        "sdmc": "芦家河水道",
        "x84": "111.621737,30.305776",
        "id": "YC_ff8080816d67bb7d016d68644e8a0022",
        "mt_id": "YC_ff8080816d67bb7d016d68644e8a0022",
        "hdHdly": "2",
        "hdlc": "554.80"
    },
    {
        "mtmc": "宜昌航道工程局白沙脑码头",
        "sdmc": "白沙脑水道",
        "x84": "111.340629,30.642935",
        "id": "YC_ff8080816d67bb7d016d6b20ea3d0039",
        "mt_id": "YC_ff8080816d67bb7d016d6b20ea3d0039",
        "hdHdly": "1",
        "hdlc": "620.40"
    },
    {
        "mtmc": "长航公安码头",
        "sdmc": "虎牙峡水道",
        "x84": "111.38608,30.597761",
        "id": "YC_ff8080816d67bb7d016d6b25c173003b",
        "mt_id": "YC_ff8080816d67bb7d016d6b25c173003b",
        "hdHdly": "1",
        "hdlc": "613.90"
    },
    {
        "mtmc": "宜港集箱4025-2",
        "sdmc": "白沙脑水道",
        "x84": "111.310869,30.673164",
        "id": "YC_ff8080816d67bb7d016d6b2c00b2003e",
        "mt_id": "YC_ff8080816d67bb7d016d6b2c00b2003e",
        "hdHdly": "2",
        "hdlc": "625.00"
    },
    {
        "mtmc": "宜航趸3码头",
        "sdmc": "宜昌水道（中游）",
        "x84": "111.309933,30.674384",
        "id": "YC_ff8080816d67bb7d016d6b2fb5840040",
        "mt_id": "YC_ff8080816d67bb7d016d6b2fb5840040",
        "hdHdly": "2",
        "hdlc": "625.20"
    },
    {
        "mtmc": "艾家河海事执法大队码头",
        "sdmc": "白沙脑水道",
        "x84": "111.377854,30.605775",
        "id": "YC_ff8080816d67bb7d016d6b3186430041",
        "mt_id": "YC_ff8080816d67bb7d016d6b3186430041",
        "hdHdly": "1",
        "hdlc": "615.10"
    },
    {
        "mtmc": "宜昌港十码头",
        "sdmc": "宜昌水道（中游）",
        "x84": "111.303605,30.679576",
        "id": "YC_ff8080816d67bb7d016d6b3b6c650045",
        "mt_id": "YC_ff8080816d67bb7d016d6b3b6c650045",
        "hdHdly": "2",
        "hdlc": "625.80"
    },
    {
        "mtmc": "宜昌港六码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.301158,30.681314",
        "id": "YC_ff8080816d67bb7d016d6b4073ab0048",
        "mt_id": "YC_ff8080816d67bb7d016d6b4073ab0048",
        "hdHdly": "1",
        "hdlc": "0.20"
    },
    {
        "mtmc": "宜昌樱桃园码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.26664,30.694775",
        "id": "YC_ff8080816d67bb7d016d6b43eefa0049",
        "mt_id": "YC_ff8080816d67bb7d016d6b43eefa0049",
        "hdHdly": "1",
        "hdlc": "3.80"
    },
    {
        "mtmc": "水文码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.279253,30.692728",
        "id": "YC_ff8080816d67bb7d016d6b456d87004a",
        "mt_id": "YC_ff8080816d67bb7d016d6b456d87004a",
        "hdHdly": "1",
        "hdlc": "2.60"
    },
    {
        "mtmc": "海事码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.281124,30.691429",
        "id": "YC_ff8080816d67bb7d016d6b48a3f9004c",
        "mt_id": "YC_ff8080816d67bb7d016d6b48a3f9004c",
        "hdHdly": "1",
        "hdlc": "2.30"
    },
    {
        "mtmc": "中水门过闸登记站码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.282432,30.690659",
        "id": "YC_ff8080816d67bb7d016d6b4aa7e6004d",
        "mt_id": "YC_ff8080816d67bb7d016d6b4aa7e6004d",
        "hdHdly": "1",
        "hdlc": "2.10"
    },
    {
        "mtmc": "宜昌航道局一马路码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.284053,30.689745",
        "id": "YC_ff8080816d67bb7d016d6b4c8a35004f",
        "mt_id": "YC_ff8080816d67bb7d016d6b4c8a35004f",
        "hdHdly": "1",
        "hdlc": "2.00"
    },
    {
        "mtmc": "宜昌航道工程局一马路码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.284060,30.689747",
        "id": "YC_ff8080816d67bb7d016d6b4e07c80051",
        "mt_id": "YC_ff8080816d67bb7d016d6b4e07c80051",
        "hdHdly": "1",
        "hdlc": "1.90"
    },
    {
        "mtmc": "宜昌港五码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.298792,30.682429",
        "id": "YC_ff8080816d67bb7d016d6b4e33c70052",
        "mt_id": "YC_ff8080816d67bb7d016d6b4e33c70052",
        "hdHdly": "1",
        "hdlc": "0.40"
    },
    {
        "mtmc": "宜昌市渔政处码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.284891,30.689303",
        "id": "YC_ff8080816d67bb7d016d6b4fbaa40053",
        "mt_id": "YC_ff8080816d67bb7d016d6b4fbaa40053",
        "hdHdly": "1",
        "hdlc": "1.80"
    },
    {
        "mtmc": "宜昌港三码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.297094,30.683335",
        "id": "YC_ff8080816d67bb7d016d6b5133130054",
        "mt_id": "YC_ff8080816d67bb7d016d6b5133130054",
        "hdHdly": "1",
        "hdlc": "0.60"
    },
    {
        "mtmc": "长航公安",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.285602,30.688913",
        "id": "YC_ff8080816d67bb7d016d6b522e390055",
        "mt_id": "YC_ff8080816d67bb7d016d6b522e390055",
        "hdHdly": "1",
        "hdlc": "1.70"
    },
    {
        "mtmc": "长江委趸船",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.294823,30.684864",
        "id": "YC_ff8080816d67bb7d016d6b53c1f20056",
        "mt_id": "YC_ff8080816d67bb7d016d6b53c1f20056",
        "hdHdly": "1",
        "hdlc": "0.80"
    },
    {
        "mtmc": "宜昌港二码头",
        "sdmc": "宜昌水道（上游）",
        "x84": "111.295729,30.684095",
        "id": "YC_ff8080816d67bb7d016d6b54e86f0057",
        "mt_id": "YC_ff8080816d67bb7d016d6b54e86f0057",
        "hdHdly": "1",
        "hdlc": "0.70"
    },
    {
        "mtmc": "巴东长渡河旅游码头",
        "sdmc": "巴东水道",
        "x84": "110.31527,31.033478",
        "id": "YC_ff8080816d67bb7d016d6b5cab650058",
        "mt_id": "YC_ff8080816d67bb7d016d6b5cab650058",
        "hdHdly": "1",
        "hdlc": "123.00"
    },
    {
        "mtmc": "巴东港宝塔河港区泓宇物流综合码头",
        "sdmc": "巴东水道",
        "x84": "110.410764,31.029219",
        "id": "YC_ff8080816d67bb7d016d6b6aafce005b",
        "mt_id": "YC_ff8080816d67bb7d016d6b6aafce005b",
        "hdHdly": "1",
        "hdlc": "114.30"
    },
    {
        "mtmc": "巴东头道角码头",
        "sdmc": "巴东水道",
        "x84": "110.432367,31.026991",
        "id": "YC_ff8080816d67bb7d016d6b6cab63005c",
        "mt_id": "YC_ff8080816d67bb7d016d6b6cab63005c",
        "hdHdly": "1",
        "hdlc": "111.30"
    },
    {
        "mtmc": "宜昌港枝江港区姚家港作业区毛家场砂石集并点码头工程",
        "sdmc": "芦家河水道",
        "x84": "111.626815,30.359464",
        "id": "YC_ff80808173dc01050173dc9944710001",
        "mt_id": "YC_ff80808173dc01050173dc9944710001",
        "hdHdly": "2",
        "hdlc": "549.20"
    },
    {
        "mtmc": "宜昌港宜都港区洋溪作业区临港物流园综合码头一期工程",
        "sdmc": "关洲水道",
        "x84": "111.539970677,30.268979555",
        "id": "YC_ff80808174671fbc01748b7fb0120008",
        "mt_id": "YC_ff80808174671fbc01748b7fb0120008",
        "hdHdly": "2",
        "hdlc": "565.50"
    },
    {
        "mtmc": "巴东鑫盛船厂码头",
        "sdmc": "巴东水道",
        "x84": "110.310370,31.039791",
        "id": "YC_ff80808174af61790174c3b62f33002f",
        "mt_id": "YC_ff80808174af61790174c3b62f33002f",
        "hdHdly": "1",
        "hdlc": "123.80"
    },
    {
        "mtmc": "长江海事局郭家坝巡航救助基地",
        "sdmc": "归州水道",
        "x84": "110.747496,30.957796",
        "id": "YC_ff8080817ce97ca4017e6c35598802a2",
        "mt_id": "YC_ff8080817ce97ca4017e6c35598802a2",
        "hdHdly": "1",
        "hdlc": "78.30"
    },
    {
        "mtmc": "姚家港作业区水上洗舱站",
        "sdmc": "芦家河水道",
        "x84": "111.650551,30.380464",
        "id": "YC_ff8080817ce97ca4017e757c536c02b0",
        "mt_id": "YC_ff8080817ce97ca4017e757c536c02b0",
        "hdHdly": "2",
        "hdlc": "545.65"
    },
    {
        "mtmc": "宜昌港宜都港区枝城作业区铁水联运码头一期工程",
        "sdmc": "枝城水道",
        "x84": "111.529384274,30.277981298",
        "id": "YC_ff8080817ce97ca4017e75bb182002b2",
        "mt_id": "YC_ff8080817ce97ca4017e75bb182002b2",
        "hdHdly": "2",
        "hdlc": "567.20"
    },
    {
        "mtmc": "宜昌港宜都港区和盛散货码头改造工程",
        "sdmc": "龙窝水道",
        "x84": " 111.496316181,30.314042876",
        "id": "YC_ff8080817ce97ca4017e75cde3b202b4",
        "mt_id": "YC_ff8080817ce97ca4017e75cde3b202b4",
        "hdHdly": "2",
        "hdlc": "572.30"
    },
    {
        "mtmc": "巴东县城应急备用水源",
        "sdmc": "巴东水道",
        "x84": "110.308744,31.032045",
        "id": "YC_ff8080817ce97ca4017e7a9bd47f02b6",
        "mt_id": "YC_ff8080817ce97ca4017e7a9bd47f02b6",
        "hdHdly": "1",
        "hdlc": "125.00"
    }
]
