export default {
  name: 'SvgIcon',
  props: {
    // svg 的名称
    svgName: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.svgName}`;
    },

    svgClass() {
      if (this.svgName) {
        return 'svg-icon-' + this.svgName;
      } else {
        return 'svg-icon';
      }
    }

  }
};