import { initMap, createLayers, createVectorLayer, transform_data, getLayer, getVectorSource } from "@/map";
import { fromLonLat } from "ol/proj.js";
import { mapGetters, mapMutations } from "vuex";
import { getCenter } from "ol/extent";
import { Overlay } from 'ol';
export default {
  name: "MapUnit",
  props: {},

  data() {
    return {
      map: null,
      timer: null,
      shipPopup: {
        show: false,
        width: "6.7rem"
      },
      showPopup: false,
      popupThemeVars: {
        "popup-close-icon-top": "0.3rem",
        "popup-round-border-radius": "0.3rem",
        "button-round-border-radius": "0rem 0rem 0.3rem 0.3rem",
        "button-primary-background-color": "#ecf2ff",
        "button-default-height": " 0.55rem",
        "button-border-width": "0px",
        "button-primary-color": "#336FF3",
        "button-normal-font-size": "0.24rem"
      },
      mapLv: 14,
      shipinfo: {},
      mobPoint: [],
      popupOverlay: null
    };
  },

  computed: { ...mapGetters(["vecVisible", "hbVisible", "soundgVisible", "mdVisible", "shipVisible"])
  },

  mounted() {
    this.createMap();
    this.toggleLayer("cva", this.vecVisible);
    this.toggleLayer("vec", this.vecVisible);
    this.toggleLayer("hb", this.hbVisible);
    this.toggleLayer("soundg", this.soundgVisible);
    this.toggleLayer("md", this.mdVisible);
    this.toggleLayer("ship", this.shipVisible);
    this.popupOverlay = new Overlay({
      element: this.$refs.popup,
      autoPan: {
        animation: {
          duration: 250
        }
      }
    });
    this.map.addOverlay(this.popupOverlay);
  },

  methods: { ...mapMutations(['mapLvChange', 'setShipdetail']),

    createMap() {
      this.map = initMap("map");
      this.map.on("moveend", this.viewChange); // 添加监听事件

      this.mapClick();
    },

    // 地图点击
    mapClick() {
      this.map.on("singleclick", evt => {
        let feature = this.map.forEachFeatureAtPixel(evt.pixel, feature => feature, {
          hitTolerance: 10
        });

        if (feature && feature.get("attributes")) {
          switch (feature.get("attributes").layerName) {
            case "myShip":
              {
                this.shipinfo = feature.get("attributes");

                if (this.shipinfo.hasUser) {
                  this.shipPopup.show = true;
                } else {
                  this.$dialog.alert({
                    title: '请设置本船参数'
                  });
                }

                this.shipChecked(feature.get("attributes"));
                console.log(this.shipinfo, "shipinfo");
              }
              break;

            case "mob":
              {
                let mob = feature.get("attributes");
                this.mobPoint = mob.x84.split(',');
                let coordinate = evt.coordinate;
                this.$refs.popupContent.innerHTML = `<div>${this.mobPoint[1]}</div><div>${this.mobPoint[0]}</div>`;
                this.popupOverlay.setPosition(coordinate);
                this.popupOverlay.setOffset([0, -15]);
              }
              break;
          }
        }

        this.$emit("handleHide");
      });

      let _this = this;

      this.$refs.popupCloser.onclick = function () {
        _this.popupOverlay.setPosition(undefined);

        _this.$refs.popupCloser.blur();

        return false;
      };
    },

    shipChecked() {},

    navigationRoute(lineCoordinates) {
      let routeLineLayer = this.getVectorLayer("shipRouteLine", lineCoordinates);
      this.getVectorLayer("shipRoutePoint", lineCoordinates);

      let _center = getCenter(routeLineLayer.getSource().getExtent());

      this.map.getView().animate({
        zoom: 8,
        center: _center
      });
    },

    closeNavigationRoute() {
      this.hiddenLayer("shipRouteLine");
      this.hiddenLayer("shipRoutePoint"); // this.hiddenLayer("shipFocus");
    },

    hiddenLayer(layerName) {
      let index = getLayer(this.map.getLayers().getArray(), layerName);
      if (index == -1) return false;
      this.map.getLayers().item(index).setVisible(false);
    },

    toggleLayer(layerName, layerChecked) {
      if (!this.map) return false;
      let index = getLayer(this.map.getLayers().getArray(), layerName);

      if (index == -1) {
        let _layer = createLayers(layerName);

        this.map.addLayer(_layer);
      }

      index = getLayer(this.map.getLayers().getArray(), layerName);
      this.map.getLayers().item(index).setVisible(layerChecked);
    },

    getVectorLayer(layerName, data) {
      if (!this.map) return false;
      let _layer = null;

      if (!Array.isArray(data)) {
        data = [data];
      }

      let index = getLayer(this.map.getLayers().getArray(), layerName);

      if (index == -1) {
        _layer = createVectorLayer(layerName, data);
        this.map.addLayer(_layer);
      } else {
        let source = getVectorSource(layerName, data);
        _layer = this.map.getLayers().item(index);

        _layer.setSource(source);

        this.map.getLayers().item(index).setVisible(true);
      } // let _feature = _layer
      //   .getSource()
      //   .getFeatures()[0]
      //   .getGeometry();
      //console.log(_feature);


      return _layer;
    },

    changeVectorStyle(layerName, data) {
      if (!this.map) return false;
      let _feature = null;

      if (!Array.isArray(data)) {
        data = [data];
      }

      let index = getLayer(this.map.getLayers().getArray(), layerName);
      if (index == -1) return false; //todo getstyle setstyle

      _feature = this.map.getLayers().item(index).getSource().getFeatures()[0].getStyle();
      console.log(_feature);
    },

    //设置地图中心
    setCenter(data, zoom = 14) {
      data = transform_data(data);

      if (data.longitude == "" || data.latitude == "") {
        return false;
      }

      let _center = fromLonLat([data.longitude, data.latitude], "EPSG:3857");

      this.map.getView().animate({
        zoom: zoom,
        center: _center
      });
    },

    //地图放大缩小
    changeZoom(zoomAction) {
      if (zoomAction == "plus") {
        this.mapLv += 1; //if(this.mapLv > 16) this.mapLv = 17
      }

      if (zoomAction == "sub") {
        this.mapLv -= 1; //if(this.mapLv < 11) this.mapLv = 11
      }

      this.map.getView().setZoom(this.mapLv);
      this.mapLvChange(this.mapLv);
    },

    // 动态显示地图层级
    viewChange() {
      this.mapLv = this.map.getView().getZoom(); // this.map.getView().setZoom(this.mapLv);
      // console.log("this.mapLv",this.mapLv)

      this.mapLvChange(this.mapLv);
    },

    // 跳转详情
    toDetail() {
      let navigationStatus = JSON.parse(localStorage.getItem('navigationStatus'));
      let navigationStatusData = navigationStatus.filter(function (item) {
        return item.id == this.shipinfo.navigationStatus;
      }, this);
      this.shipinfo.navigationStatus = navigationStatusData[0].type;
      let country = JSON.parse(localStorage.getItem('country'));
      let countryData = country.filter(function (item) {
        return item.id == this.shipinfo.nationality;
      }, this);
      this.shipinfo.nationality = countryData[0].chineseName;

      if (this.shipinfo.warehouseType === 1) {
        this.shipinfo.warehouseType = "是";
      } else if (this.shipinfo.warehouseType === 0) {
        this.shipinfo.warehouseType = "否";
      } else {
        this.shipinfo.warehouseType = null;
      }

      let item = {
        MMSICode: this.shipinfo.mmsi ? this.shipinfo.mmsi : "N/A",
        //MMSI
        chineseName: this.shipinfo.name ? this.shipinfo.name : "N/A",
        //中文船名
        ShipsName: this.shipinfo.englishName ? this.shipinfo.englishName : "N/A",
        //英文船名
        nationality: this.shipinfo.nationality ? this.shipinfo.nationality : "N/A",
        //国籍
        CallNum: this.shipinfo.callSign ? this.shipinfo.callSign : "N/A",
        //呼号
        IMOCallNum: this.shipinfo.imo ? this.shipinfo.imo : "N/A",
        //IMO
        type: "N/A",
        //类型
        ShipsType: this.shipinfo.shipType ? this.shipinfo.shipType : "N/A",
        //船型
        shipLength: this.shipinfo.length ? this.shipinfo.length : "N/A",
        //船长
        shipWidth: this.shipinfo.width ? this.shipinfo.width : "N/A",
        //船宽
        totalWeight: "N/A",
        //总吨
        latitude: this.shipinfo.Lat ? this.shipinfo.Lat : "N/A",
        //纬度
        longitude: this.shipinfo.Lon ? this.shipinfo.Lon : "N/A",
        //经度
        NavigationStatus: this.shipinfo.navigationStatus ? this.shipinfo.navigationStatus : "N/A",
        //状态
        GroundVelocity: this.shipinfo.speed !== undefined || this.shipinfo.speed !== null ? this.shipinfo.speed : "N/A",
        //航速
        GroundHeading: this.shipinfo.course ? this.shipinfo.course : "N/A",
        //航向
        TrueHeading: "N/A",
        //船艏向
        Destination: this.shipinfo.destinationMtName ? this.shipinfo.destinationMtName : "N/A",
        //目的港
        EstimatedArrivalTime: this.shipinfo.expectedArrivalTime ? this.shipinfo.expectedArrivalTime : "N/A",
        //预到时间
        positionTime: "N/A",
        //船位时间
        CurrentDeepestStatic: this.shipinfo.maximumDraft ? this.shipinfo.maximumDraft : "N/A",
        //最大吃水
        isClosureWarehouse: this.shipinfo.warehouseType ? this.shipinfo.warehouseType : "N/A" //有无封仓

      };
      console.log(this.shipinfo, '131');
      this.setShipdetail(item);
      this.$router.push({
        path: "/shipdetail"
      });
    }

  },

  beforeUnmount() {
    this.map.un("moveend", this.viewChange);
  }

};