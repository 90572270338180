import { mapGetters } from 'vuex';
export default {
  name: " TopNav",

  data() {
    return {
      tle: {
        netType: "",
        icon: "5g-icon00",
        value: ""
      },
      lteZoom: [-113, -92, -71, -50],
      bdsZoom: [0, 30, 60, 90],
      bds: {
        icon: "beidou-icon00",
        value: ""
      },
      ais: {
        icon: "ais-icon",
        value: ""
      }
    };
  },

  created() {},

  mounted() {
    //将要给原生调用的方法挂载到 window 上面
    window.getBDS = this.getBDS;

    try {
      let res = JSON.parse(window.AndroidFunction.getLTENetwork());
      let netType = res.networkState;

      if (netType == 4) {
        this.tle.netType = "4G";
      } else if (netType == 5) {
        this.tle.netType = "5G";
      } else {
        this.tle.icon = "5g-icon00";
      }

      let db = res.rrsiValue;

      if (db < this.lteZoom[0]) {
        this.tle.icon = "5g-icon00";
      } else if (db >= this.lteZoom[0] && db < this.lteZoom[1]) {
        this.tle.icon = "5g-icon01";
      } else if (db >= this.lteZoom[1] && db < this.lteZoom[2]) {
        this.tle.icon = "5g-icon02";
      } else if (db >= this.lteZoom[2] && db < this.lteZoom[3]) {
        this.tle.icon = "5g-icon03";
      } else {
        this.tle.icon = "5g-icon";
      }
    } catch (e) {
      console.log('非android环境下访问!');
    }
  },

  computed: { ...mapGetters(["name", "mmsi"]),

    hasUser() {
      return this.mmsi ? true : false;
    }

  },
  methods: {
    getBDS(db) {
      //this.bds.value=db;
      if (db <= this.bdsZoom[0]) {
        this.bds.icon = "beidou-icon00";
      } else if (db > this.bdsZoom[0] && db < this.bdsZoom[1]) {
        this.bds.icon = "beidou-icon01";
      } else if (db >= this.bdsZoom[1] && db < this.bdsZoom[2]) {
        this.bds.icon = "beidou-icon02";
      } else if (db >= this.bdsZoom[2]) {
        this.bds.icon = "beidou-icon";
      }
    },

    toShipinfo() {
      this.$router.push('/shipinfo');
    }

  }
};